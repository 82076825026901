import { JSEncrypt } from "@/utils/cipher/jsencrypt.min.js";
import CryptoJS from "@/utils/cipher/crypto-js.min.js";

const privateKey = "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAM4TlIVdIf+I1YcDtVDP/37zXtzClNoeql66aLKGKqLHElRCoMoGHdHEREG/xFiDeSctL+21UZRjypRXFQAer/jn6l+x8YtPU3Ceu0y2+BDjZU5o/BmAKepC19fnX0Pbn/sQdQelwE8P5pdUchxLr/AUOy7Nj+nuhAYui3fqpcDHAgMBAAECgYBF10b3p+e01eggGPajS8r4eHzG7r9J6SiucBi5oFTk0/j3S5l4vnfutsi3S8m8gdpWl67trgzW8LcgbR716nCSm+RYmBHZhwhYGdLOypZhfB7+0xpcgdiB0g29fURRrkEmyYwEERl3tQIDtpOJrDyjRnbzBRkxdjy1D7UsLRoUMQJBAPWzNv+pUBgkdQvXJCTSC/0QBFGwg3cdEZKoNLG7vXZzt5Gi3xFMPIeIxxmKhgkpzoDUOmrEY+7gLYrr1JyuE78CQQDWtyLWJThzYmXZ+NcaEg462LIoECzKazBqkz+65ws5Qu842ZXpmZpKMwtW1yANu2uvhcfWi98ToqfYd36sHnT5AkAJQ4uUUibx8hXzr1XuiXjoPr1a8yPRzufFts/dz61SFQTb2d89QLmbhp2zKdTp7YiatOXK9sKp1AwDi+bFTY7zAkEAgYeZjHaHx5PkvcpQeXnK+9UeJO0dGD/xxVlvaOFY9AJdLn6U4Ye4fGquvISq4Z3fKNQb6mIXAVFu0DfGQy9cgQJBAJWPm+JImMqC5g1bayxpnIUJx/o0Wh7jN9ZBf1AoS4BJKZnsWjiKjxordhE7iFR3h5cJKB1Vd/NxRnE01lIH4sk=";

export const decode = (result) => {
  try {
    let jsEncrypt = new JSEncrypt();
    jsEncrypt.setPrivateKey(privateKey);
    let aesKey = jsEncrypt.decrypt(result.key);

    let key = CryptoJS.enc.Utf8.parse(aesKey);
    let bytes = CryptoJS.AES.decrypt(result.cipher.toString(), key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.ZeroPadding
    });
    let srcText = bytes.toString(CryptoJS.enc.Utf8);
    try {
      return JSON.parse(srcText)
    } catch (e) {
      return srcText 
    }
  } catch (e) {
    return result;
  }
};