import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken, getCookie } from '@/utils/auth.js'
import { decode } from "@/utils/cipher/cipher.js";

// export const BASE_URL = 'http://localhost:8280';//本地测试
// export const WEBSOCKET_URL = 'ws://localhost:23210/websocket';//本地测试
export const BASE_URL = 'https://api.pp276.com/api';//正式
export const WEBSOCKET_URL = 'wss://api.pp276.com/websocket';//正式

const service = axios.create({
  baseURL: BASE_URL,
  timeout: 50000, // request timeout
  headers: {
    'Content-Type': 'application/json',
    // 'X-Access-Token':getToken()
  },
  crossDomain: true, // 设置cross跨域
  // withCredentials: true//设置cross跨域 并设置访问权限 允许跨域携带cookie信息
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers['X-Access-Token'] = getToken()
    return config
  },
  (error) => {
    // do something with request error
    return error
  }
)

function exceptionHandler(res) {
  Message({
    message: res.message,
    type: 'error',
    duration: 5 * 1000,
  })
  switch (res.code) {
    case 401:
    case 494:
    case 497:
    case 498:
    case 499:
      store.commit('loginOut')
      setTimeout(() => {
        router.push('/login/login')
      }, 1500)
      break
  }
  return Promise.reject(res)
}

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code === 200) {
      res.result = decode(res.result)
      return res
    } else {
      return exceptionHandler(res)
    }
  },
  (error) => {
    return exceptionHandler(error.response.data)
    // console.log(error.response.data.message)
    // Message({
    // 	message: error.response.data.message,
    // 	type: 'error',
    // 	duration: 5 * 1000
    // })
    // if(error.response.data.code == 401){
    // 	setTimeout(()=>{
    // 		router.push('/login')
    // 	},1000)
    // }
    // return Promise.reject(error);
  }
)

export default service
