<template>
  <div class="game"style="padding-top: 20px">
    <!-- <div class="stepList" v-if="type == 'add'">
      <el-steps :active="step" align-center>
        <el-step title="选择游戏分类">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 1 }">1</div>
          </div>
        </el-step>
        <el-step title="填写分类信息">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 2 }">2</div>
          </div>
        </el-step>
        <el-step title="填写商品信息">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 3 }">3</div>
          </div>
        </el-step>
        <el-step title="完成商品发布">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 4 }">✔</div>
          </div>
        </el-step>
      </el-steps>
    </div> -->
    <!-- <div class="lableTop">
			<img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
			<el-row class="content" style="position: relative;z-index: 99;">
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
				<el-col :span="20">
					<div>
						<div class="lableBg">
							<div class="lableTitle">游戏分类</div>
							<div class="lableDetaile">海量新游戏 等您来选</div>
						</div>

					</div>
				</el-col>
				<el-col :span="2">
					<div>&emsp;</div>
				</el-col>
			</el-row>

		</div> -->

    <div style="padding-bottom: 50px; position: relative; z-index: 99; ">
      <el-row class="content" style="position: relative; z-index: 99">
        <el-col :span="24">
          <div class="classify">
            <div style="padding: 46px 52px">
              <div>
                <span class="gameClass" :class="{ action: search.terminalType == index }" v-for="(item, index) in lableList" :key="index" @click="swiperTab(index)">{{ item }}</span>
              </div>
              <div class="searchKey fl">
                <span class="searchItem" :class="{ actions: searchAction == index }" @click="select(item, index)" v-for="(item, index) in searchKey">{{ item }}</span>
              <div class="inputBox">
                <input v-prevent-space placeholder="请输入游戏名称"  class="inputContent" v-model="gameName">  </input>
                <i class="el-icon-search"></i>
              </div>
               
              </div>
              <div class="gameList fl">
                <div class="gameItem" @click="toList(item)" v-for="(item, index) in gameList">
                  <img style="width: 80px; height: 80px; margin-bottom: 20px;border-radius: 18px;" :src="item.icon" />
                  <div class="el1" style="width: 120px; text-align: center">{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <shopping-card></shopping-card>

    <div class="popUp" v-if="realShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img @click="realShow = false" style="width: 540px; height: 484px; position: absolute; top: 200px" :src="require('@/static/image/index/realName.png')" />
        <div style="z-index: 9999; position: relative; margin-top: 380px; display: flex; flex-direction: column; width: 450px; align-items: center">
          <div class="realNameTitle">请前往实名认证</div>
          <div class="realNameDetaile">根据国家法律规制，使用网络平台服务需要实名认证，请您前往人中心账户设置-实名认证完成实名， <br />实名后交易更安全!</div>
          <button @click="$router.push('/mineIndex/28')" class="realSubmit">前往认证</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import shoppingCard from '@/components/shoppingCart.vue'
  import { gameList, realInfo } from '@/api/index.js'
  export default {
    name: 'game',
    components: {
      shoppingCard,
    },

    data() {
      return {
        gameName: '',
        realShow: false,
        type: 'select',
        step: 1,
        lableList: ['全部游戏', '网络游戏', '手机游戏'],
        searchKey: ['热门游戏', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        searchAction: 0,
        gameList: [],
        search: {
          initial: '', //首字母
          name: '',
          pageNo: 1,
          pageSize: 1000,
          terminalType: '',
        },
      }
    },
    watch: {
      // 利用watch方法检测路由变化：
      $route: function (to, from) {
        this.init()
      },
    },
    activated() {
      this.type = this.$route.params.type
      if (this.type == 'select') {
        if (this.$route.params.id != 0) {
          this.search.terminalType = this.$route.params.id
        }
      }

      this.getGameList()
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.type = this.$route.params.type
        if (this.type == 'select') {
          if (this.$route.params.id != 0) {
            this.search.terminalType = this.$route.params.id
          }
        }

        this.getGameList()
      },
      // 切换游戏类型
      swiperTab(index) {
        if (index == 0) {
          this.search.terminalType = ''
        } else {
          this.search.terminalType = index
        }
        this.getGameList()
      },
      // 选择首字母
      select(value, index) {
        if (index == 0) {
          this.search.initial = ''
        } else {
          this.search.initial = value
        }
        this.searchAction = index
        this.getGameList()
      },
      getGameList() {
        gameList(this.search).then((res) => {
          if (res.code == 200) {
            this.gameList = res.result.records
          }
        })
      },
      // 跳转商品列表
      toList(item) {
        let info = {
          id: item.id,
          terminalType: item.terminalType,
          name: item.name,
          icon: item.icon,
          addType: this.type,
        }

        if (this.type == 'select') {
          // this.encryptHex(JSON.stringify(info),'0123456789ABCDEF')
          // localStorage.setItem('gameList', JSON.stringify(info));
          this.$router.push({
            path: '/gameList',
            query: {
              info: JSON.stringify(info),
            },
          })
        } else {
          // 判断是否实名
          realInfo().then((res) => {
            if (res.code == 200) {
              if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
                this.realShow = true
              } else {
                // localStorage.setItem('publish1', JSON.stringify(info));
                this.$router.push({
                  path: '/publish1',
                  query: {
                    info: JSON.stringify(info),
                  },
                })
              }
            }
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .stepList {
    width: 600px;
    margin: 15px auto;
  }

  .step {
    width: 27px;
    height: 27px;
    color: #fff;
    // background: #1861EE  0 0 no-repeat transfor;
    text-align: center;
    // transform: rotate(45deg);
    line-height: 27px;
    position: relative;
  }

  .step::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px 6px 6px 6px;
    background: #dddddd;
    background-size: 100% 100%;
    transform: rotate(45deg);
    // transform: scaleY(-1);
  }

  .stepAction {
    width: 27px;
    height: 27px;
    color: #fff;
    // background: #1861EE  0 0 no-repeat transfor;
    text-align: center;
    // transform: rotate(45deg);
    line-height: 27px;
    position: relative;
  }

  .stepAction::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px 6px 6px 6px;
    background: #1861EE ;
    background-size: 100% 100%;
    transform: rotate(45deg);
    // transform: scaleY(-1);
  }

  .stepSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .el-step__title.is-process {
    color: #c0c4cc;
    font-weight: 500;
  }

  .game {
    background-color: #f8f8f8;
  }

  .lableTop {
    position: relative;

    .lableImage {
      width: 100%;
      height: 300px;
      position: absolute;
    }
  }

  .lableBg {
    padding-top: 40px;
    height: 220px;

    .lableTitle {
      font-weight: bold;
      font-size: 32px;
      color: #ffffff;
    }

    .lableDetaile {
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      margin-top: 12px;
    }
  }

  .classify {
    color: #777a8a;
    background: #ffffff;
    // width: 100%;
    // margin-top: -80px;
    border-radius: 20px 20px 20px 20px;
    min-height: 700px;

    .gameClass {
      font-weight: 400;
      font-size: 20px;
      color: #777a8a;
      margin-right: 30px;
      cursor: pointer;
    }

    .action {
      font-weight: 600;
      color: #1861EE ;
      position: relative;
    }

    .action::after {
      position: absolute;
      content: '';
      height: 3px;
      width: 30px;
      background: #1861EE ;
      bottom: -10px;
      left: 50%;
      margin-left: -15px;
    }
  }

  .searchKey {
    width: 100%;
    height: 60px;
    background: #f6f6f6;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 30px;

    .searchItem {
      font-weight: 600;
      font-size: 16px;
      color: #1d223c;
      cursor: pointer;
    }

    .actions {
      font-weight: 600;
      color: #1861EE ;
    }
  }

  .gameList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    .gameItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      // width: 12.5%;

      /* 间隙为5px */
      margin: 0 10px 15px 0;
      /* END */

      /* 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整 */
      width: calc((100% - 70px) / 8);
      /* END */

      /* 加入这两个后每个item的宽度就生效了 */
      min-width: calc((100% - 70px) / 8);
      max-width: calc((100% - 70px) / 8);
      cursor: pointer;
    }

    .gameItem:nth-child(8n) {
      /* 去除第3n个的margin-right */
      margin-right: 0;
    }
  }

  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;

    .realNameTitle {
      font-weight: 500;
      font-size: 24px;
      color: #1d223c;
      text-align: center;
      margin-bottom: 24px;
    }

    .realNameDetaile {
      font-weight: 400;
      font-size: 18px;
      color: #777a8a;
      text-align: center;
      line-height: 30px;
    }

    .realSubmit {
      width: 296px;
      height: 66px;
      background: linear-gradient(90deg, #03b5ff 0%, #1861EE  100%);
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      margin-top: 50px;
    }
  }
  .inputBox{
    width: 231px;
    height: 37px;
    box-sizing: border-box;
    position: relative;
    .inputContent{
      width: 231px;
      height: 37px;
      background: #FFFFFF;
      border-radius: 18px;
      border: 1px solid #1861EE;
      box-sizing: border-box;
      padding-left: 20px;
    }
    i{
      position: absolute;
      color: #1861EE;
      right: 10px;
      bottom: 50%;
      font-size: 20px;
      margin-bottom: -10px;
    }
  }
</style>
