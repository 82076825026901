<template>
	<div class="orderAll">
		<div class="orderTop">
			<div v-if="commodityInfo.pic">
				<el-carousel height="150px" type="card" >
					<el-carousel-item v-for="item in commodityInfo.pic.split(',')" :key="item" style="text-align: center">
											<el-image :src="item" style="height: 100%"></el-image>
<!--						<h3 >{{ item }}</h3>-->
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
		<!--游戏信息-->
		<div class="takeInfo">
			<div class="takeTitle">游戏信息</div>
			<div class="infoItem">
				<span>游戏：</span>
				<span style="color: #1D223C;">{{ commodityInfo.gameName }}</span>
			</div>
			<div class="infoItem">
				<span>区服名称：</span>
				<span style="color: #1D223C;">{{ commodityInfo.districtName }}</span>
			</div>
		</div>
		
		<!--商品信息-->
		<div class="takeInfo">
			<div class="takeTitle">商品信息</div>
			<div class="infoItem">
				<span>商品编号：</span>
				<span style="color: #1D223C;">{{ commodityInfo.id }}</span>
				<span v-clipboard:copy="commodityInfo.id" v-clipboard:success="onCopy" style="margin-left: 8px;color: #0085FF;">复制</span>
			</div>
			<div class="infoItem">
				<span>商品类型：</span>
				<span style="color: #1D223C;">{{ commodityInfo.commodityTypeName }}</span>
			</div>
			<div class="infoItem">
				<span>标题：</span>
				<span style="color: #1D223C;">{{ commodityInfo.title }}</span>
			</div>

			<div class="infoItem" v-if="commodityInfo.gameCommodityCategory == '1' || commodityInfo.gameCommodityCategory == '12'">
				<span>交易数量：</span>
				<span v-if="commodityInfo.deliveryType == 3 || commodityInfo.deliveryType == 5 "
							style="color: #1D223C;">1件={{ commodityInfo.num }}{{ commodityInfo.commodityUnit }}</span>
				<span v-else
							style="color: #1D223C;">{{ commodityInfo.num }}件={{ commodityInfo.num * commodityInfo.piecesNum }}{{ commodityInfo.commodityUnit }}</span>
			</div>
			<div class="infoItem">
				<span>是否下架：</span>
				<span style="color: #1D223C;">{{ commodityInfo.isOffShelf===1?'是':'否' }}</span>
			</div>

			<div class="infoItem" v-if="commodityInfo.remake != null">
				<span>交易备注：</span>
				<span style="color: #1D223C;">{{ commodityInfo.remake }}</span>
			</div>
			<div class="infoItem" v-for="(item,index) in commodityInfo.orderInfos">
				<span>{{ item.title }}：</span>
				<span style="color: #1D223C;">{{ item.content }}</span>
			</div>

			<div class="infoItem">
				<span>创建时间：</span>
				<span style="color: #1D223C;">{{ commodityInfo.createTime }}</span>
			</div>
		</div>

		<!--商品类型字段-->
		<div class="takeInfo" v-if="commodityInfo.gameCommodityFieldValueList">
			<div class="takeTitle">商品类型字段</div>
			<div class="infoItem" v-for="(item,index) in commodityInfo.gameCommodityFieldValueList">
				<el-image v-if="item.icon" style="width: 24px; height: 24px" :src="item.icon" fit="scale-down"></el-image>
				<span>{{ item.name }}：</span>
				<span style="color: #1D223C;">{{ item.value }}</span>
			</div>
		</div>
		
		<!--交易信息-->
		<div class="takeInfo" style="border-bottom: none;">
			<div class="takeTitle">交易信息</div>
			<div class="infoItem">
				<span>交易类型：</span>
				<span v-if="commodityInfo.deliveryType == '1'" style="color: #1D223C;">担保</span>
				<span v-if="commodityInfo.deliveryType == '2'" style="color: #1D223C;">寄售</span>
				<span v-if="commodityInfo.deliveryType == '3'" style="color: #1D223C;">收货</span>
				<span v-if="commodityInfo.deliveryType == '4'" style="color: #1D223C;">代练</span>
				<span v-if="commodityInfo.deliveryType == '5'" style="color: #1D223C;">热卖</span>
			</div>
			<div class="infoItem">
				<span>库存数量：</span>
				<span style="color: #1D223C;">{{ commodityInfo.num }}</span>
			</div>
			<div class="infoItem">
				<span>价格：</span>
				<span style="color: #1D223C;">{{ commodityInfo.price }}</span>
			</div>
			<div class="infoItem" v-if="commodityInfo.piecesNum">
				<span>件数：</span>
				<span style="color: #1D223C;">{{ commodityInfo.piecesNum }}</span>
			</div>
			
			<div class="infoItem">
				<span>已购订单数量：</span>
				<span style="color: #1D223C;">{{ commodityInfo.orderNums||0 }}</span>
			</div>
			<div class="infoItem">
				<span>浏览数量：</span>
				<span style="color: #1D223C;">{{ commodityInfo.seeCount }}</span>
			</div>
			<div class="infoItem">
				<span>收藏数量：</span>
				<span style="color: #1D223C;">{{ commodityInfo.collectionCount }}</span>
			</div>
		</div>

		<!--交易须知-->
		<div class="takeInfo" style="border-bottom: none;">
			<div class="takeTitle">交易须知</div>
			<div class="infoItem" v-html="commodityInfo.tradeRemark"></div>
		</div>

	</div>
</template>
<script>
import { cancelReasonList, settleReasonList } from '@/api';

export default {
	name : 'CommodityInfo',
	props: {
		commodityInfo   : {
			type   : Object,
			default: () => {},
		},
	},
	data() {
		return {
			noClick: true,
			reverse: true,

			cancelList    : [],
			causeList     : [],
			causeListCache: {},
		};
	},

	created() {
	},
	destroyed() {
	},
	mounted() {

	},

	watch  : {
		'settleInfo.settleType'(val) {
			this.loadCauseList();
		},
		'settleInfo.reasonType'(val) {
			this.loadCauseList();
		},
	},
	methods: {
		onCopy() {
			this.message('复制成功', 'success');
		},
		// 订单结算 弹窗
		settle() {
			this.settleInfo.orderId = this.commodityInfo.id;
			this.settleInfo.settlePrice = this.commodityInfo.practicalPrice;
			this.orderSettle = true;
		},
		
		//取消原因
		getCancelReason() {
			switch (this.commodityInfo.reasonType) {
				case 1:
					return '买家原因:' + this.commodityInfo.cancelReason;
				case 2:
					return '卖家原因:' + this.commodityInfo.cancelReason;
				case 3:
					return '其他原因:' + this.commodityInfo.cancelReason;
			}
		},

		//加载原因列表
		loadCauseList() {
			this.causeList = [];
			let promise = this.loadCauseListCache();
			if (promise) {
				promise.then(() => {
					this.causeList = this.causeListCache[this.settleInfo.settleType][this.settleInfo.reasonType];
				});
			}
		},
		loadCauseListCache() {
			if (this.causeListCache[this.settleInfo.settleType]) {
				return Promise.resolve();
			}
			let fun;
			if (this.settleInfo.settleType === 1) {
				return;
			} else if (this.settleInfo.settleType === 2) {
				fun = settleReasonList;
			} else if (this.settleInfo.settleType === 3) {
				fun = cancelReasonList;
			} else {
				return;
			}
			return fun({}).then(res => {
				let json = {};
				for (let item of res) {
					let types = json[item.reasonType];
					if (!types) {
						types = json[item.reasonType] = [];
					}
					types.push(item);
				}
				let cache = {};
				cache[this.settleInfo.settleType] = json;
				this.causeListCache = cache;
			});
		},

		//订单结算
		confirmSettle() {
			this.settleInfo.settlePrice = eval(this.settleInfo.settlePrice);
			this.settleInfo.settlePrice = Math.min(this.settleInfo.settlePrice, this.commodityInfo.practicalPrice);
			if (!this.settleInfo.settlePrice || this.settleInfo.settlePrice < 0) {
				this.message('请输入正确的金额', 'error');
				return;
			}
			if (this.settleInfo.settleType === 2 || this.settleInfo.settleType === 3) {
				if (this.settleInfo.cancelReason === '') {
					this.message('请选择原因', 'error');
					return;
				}
			}

			this.orderSettle = false;
			this.$emit('confirmSettle',this.settleInfo);
		},
	}
};


</script>

<style scoped lang="scss">
.notice {
	height: 72px;
	background: #0085FF;
}

.chatMain {
	//width: 1666px;
	height: 952px;
	background: #F9F9F9;
	box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
	border-radius: 20px 20px 20px 20px;
	overflow: hidden;
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
}

.chatLeft {
	width: 112px;
	padding-bottom: 40px;
	background: #212328;
	color: #A5A7B1;

	.leftItem {
		height: 112px;
		flex-direction: column;
		justify-content: center;
	}

	.leftItem1 {
		height: 90px;
		flex-direction: column;
		justify-content: center;
	}

	.leftText {
		margin-top: 8px;
	}
}

.account {
	width: 372px;
	height: 952px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background-color: #fff;
}

.search {
	// position: fixed;
	// top: 88px;
	width: 372px;
	// height: 200px;
	// background-color: #fff;
	z-index: 99;

	.searchInfo {
		margin-left: 12px;
	}
}

.account::-webkit-scrollbar {
	display: none;
}

.chatList {
	overflow: auto;
}

.chatList::-webkit-scrollbar {
	display: none;
}

.chatItem {
	margin-top: 16px;
	position: relative;
	border-radius: 12px;

	.chatClose {
		position: absolute;
		bottom: 10px;
		right: 10px;
		display: none;
	}
}

.chatItem:hover {
	.chatClose {
		position: absolute;
		bottom: 5px;
		right: 10px;
		display: block;
	}
}

.infoTitle {
	.infoAccount {
		font-size: 10px;
		background: #FF5C00;
		border-radius: 4px;
		color: #fff;
		padding: 1px 3px;
	}

	.infoAccount1 {
		background-color: #FF5C00;
	}

	.infoAccount2 {
		background-color: #B742FF;
	}

	.infoAccount3 {
		background-color: #19D972;
	}

	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	color: #000000;
	width: 170px;
}

.idItem {
	font-weight: 400;
	font-size: 14px;
	color: #A5A7B1;
	margin-bottom: 5px;
}

.chat {
	width: 644px;

	position: relative;

	.chatTop {
		display: flex;

		.chatTopItem {
			//width: 157px;
			height: 54px;
			border-radius: 4px;
			border: 1px solid #DEDEDE;
			display: flex;
			align-items: center;
			padding-left: 16px;
			padding-right: 16px;
			font-weight: 600;
			font-size: 16px;
			color: #0085FF;
		}

		.chatAction {
			background-color: #ECF9FF;
		}
	}
}

.chatCenter {
	height: 570px;
	// width: 624px;
	padding-right: 16px;
	overflow: auto;
}

// .chatCenter::-webkit-scrollbar {
// 	display: none;
// }

.recordsLeft {
	margin-top: 20px;
	margin-left: 16px;
	display: flex;

}

.recordsRight {
	margin-top: 20px;
	margin-left: 16px;
	display: flex;
	justify-content: flex-end;
}

.leftInfo {
	margin-left: 8px;

}

.rightInfo {

	display: grid;
	justify-items: end;
	/* 水平居中 */
	align-items: center;
	/* 垂直居中 */
	margin-right: 8px;

}

.infoName {
	font-weight: 400;
	font-size: 16px;
	color: #0085FF;
}

.infoName1 {
	font-weight: 400;
	font-size: 16px;
	color: #FF5C00;
}

.infoName2 {
	font-weight: 400;
	font-size: 16px;
	color: #000;
}


.infoTime {
	font-weight: 400;
	font-size: 16px;
	color: #A5A7B1;
	margin-left: 8px;
}

.infoCount {
	max-width: 296px;
	display: inline-block;

	background: #F1F1F1;
	border-radius: 8px;
	padding: 12px 16px;
	margin-top: 4px;
	word-break: break-all; //会断词

}

.bottomTop {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.bottomTips {
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		margin-right: 10px;
	}
}

.functionImage {
	width: 28px;
	height: 28px;
	margin-right: 30px;
}

::v-deep .el-textarea__inner {
	border: none;
	margin-top: 10px;
	width: 580px;
	height: 160px;
	background-color: #F9F9F9;
}

.chatArea {
	font-size: 18px;
	color: #000;
}


.orderAll {
	background-color: #fff;
	width: 538px;
	overflow: auto;

	.orderTop {
		padding: 16px;

		.orderImage {
			width: 60px;
			height: 60px;
		}

		.orderTitle {
			font-weight: 600;
			font-size: 20px;
			color: #1D223C;
		}

		.orderDetaile {
			font-weight: 400;
			font-size: 16px;
			color: #A5A7B1;
		}

		.orderState {
			display: flex;
			flex-direction: column;
			margin-left: 12px;
		}
	}

	.stateList {
		display: flex;
		justify-content: right;
		margin-top: 8px;
		padding-bottom: 8px;
		border-bottom: 1px solid #ECECEC;

		span {
			margin-left: 8px;
		}

		.state1 {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			height: 22px;
			padding: 4px 12px;
			background: #19D972;
			border-radius: 6px 6px 6px 6px;
		}

		.state2 {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			height: 22px;
			padding: 4px 12px;
			background: #FF5C00;
			border-radius: 6px 6px 6px 6px;
		}

		.state3 {
			font-weight: 400;
			font-size: 14px;
			color: #FFFFFF;
			height: 22px;
			padding: 4px 12px;
			background: #0085FF;
			border-radius: 6px 6px 6px 6px;
		}

		.state4 {
			font-weight: 400;
			font-size: 14px;
			border: 1px solid #0085FF;
			height: 22px;
			padding: 4px 12px;
			color: #0085FF;
			border-radius: 6px 6px 6px 6px;
		}

		.cancelState {
			font-weight: 400;
			font-size: 14px;
			height: 22px;
			color: #777A8A;
			padding: 3px 12px;
			border: 1px solid #DEDEDE;
			border-radius: 6px 6px 6px 6px;
		}
	}
}

.orderAll::-webkit-scrollbar {
	display: none;
}

.takeInfo {
	padding: 8px 12px;
	border-bottom: 1px solid #ECECEC;

	.takeTitle {
		font-weight: 600;
		font-size: 16px;
		color: #1D223C;
		line-height: 24px;
		margin-left: 18px;
		position: relative;
	}

	.takeTitle::before {
		position: absolute;
		content: '';
		top: 0;
		left: -15px;
		width: 3px;
		height: 24px;
		background: #FF5C00;

	}

	.takeTitle1 {
		font-weight: 600;
		font-size: 16px;
		color: #1D223C;
		line-height: 24px;
		margin-left: 18px;
		position: relative;
	}

	.takeTitle1::before {
		position: absolute;
		content: '';
		top: 0;
		left: -15px;
		width: 3px;
		height: 24px;
		background: #0085FF;

	}

	.infoItem {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		margin-top: 12px;
	}
}

.takeSubmit {

	height: 54px;
	background: #FAFAFA;
	border-radius: 6px 6px 6px 6px;
	padding-left: 12px;
	margin-top: 16px;

	.takeText {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;

		line-height: 40px;
	}

	.takeInput {
		width: 225px;
		height: 40px;
		border-radius: 4px 4px 4px 4px;
	}

	.takeBtn {
		width: 56px;
		height: 38px;
		padding: 0;
		margin-left: 8px;
	}
}

// ::v-deep .el-timeline-item__node:last-child{
// 	border: 4px solid #FF5C00;
// }
::v-deep .el-timeline {
	margin-left: -40px;
}

::v-deep .el-timeline-item__tail {
	border-left: 2px dotted #E4E7ED;

}

.infoTitle1 {
	font-weight: 600;
	font-size: 14px;
	line-height: 30px;
	color: #0085FF;
	width: 250px;
}

.idItems {
	font-weight: 400;
	font-size: 14px;
	color: #A5A7B1;
	margin-top: 3px;
}

::v-deep .el-table th {
	background: #FAFAFA;
	color: #000;
}

.leftAction {
	background: linear-gradient(270deg, rgba(0, 133, 255, 0.3) 0%, rgba(0, 133, 255, 0) 100%);
	color: #0085FF;
}

.img-style {
	margin-left: 10px;
	margin-bottom: 10px;
}

.bottomLeft {
	position: relative;

	.emoji {
		position: absolute;
		bottom: 60px;
		left: -100px;
		width: 500px;
		background-color: #fff;
		padding: 15px;
		border-radius: 5px;
		border: 1px solid #E4E7ED;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	// el-popover el-popper


}

.dialogCenter {
	border-top: 1px solid #FAFAFA;
	border-bottom: 1px solid #FAFAFA;
	flex-direction: column;
	padding: 10px 0;

	.deleteTitle {
		font-weight: 600;
		font-size: 20px;
		color: #1D223C;
		text-align: center;
		margin: 20px;
	}

	.deleteDetaile {
		font-weight: 400;
		font-size: 16px;
		color: #777A8A;
		text-align: center;
		line-height: 30px;
	}

}

.dialogLable {

	font-size: 16px;
	color: #000000;
	width: 100px;
	justify-content: space-between;
	margin-left: 40px;
	position: relative;

}

.must {
	position: relative;
}

.must::after {
	position: absolute;
	content: '*';
	color: red;
	top: 0;
	left: -20px;
}

.dialogValue {
	position: relative;

	::v-deep .el-select-dropdown {
		position: absolute !important;
		top: auto !important;
		left: auto !important;
	}
}

.search {


	.searchText {
		width: 100px;
		text-align: right;
		font-weight: 400;
		font-size: 18px;
		color: #1D223C;
	}
}

.roleTitles {

	width: 1186px;
	border-bottom: 1px solid #ECECEC;
	height: 67px;
	// line-height: 87px;
	padding-left: 12px;
	padding-right: 42px;
	margin-top: 30px;
	display: flex;
	align-items: center;

	.titleItem {
		font-weight: 400;
		font-size: 20px;
		position: relative;
		color: #1D223C;
		height: 30px;
		margin-right: 50px;
	}

	.titleItem::after {
		position: absolute;
		width: 1px;
		height: 20px;
		content: '';
		top: 2px;
		right: -25px;
		background-color: #DEDEDE;
	}

	.titleItem:last-child::after {
		width: 0px;
	}

	.action {
		color: #0085FF;
		font-weight: bold;
	}
}

.tableList {
	margin: 20px;
	width: 1400px;
	height: 500px;
	overflow-y: auto;
	scrollbar-width: none;
}

.chatStatus {
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 80px;
	text-align: center;
	font-size: 14px;
	color: #FFFFFF;
	opacity: 0.8;
	padding: 3px 0;
	letter-spacing: 2px;
	background-color: rgba(0, 0, 0, .8);
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
}

.chatStatus1 {
	position: absolute;
	top: 10px;
	right: -20px;
	width: 80px;
	text-align: center;
	font-size: 14px;
	color: #FFFFFF;
	background: #FF5C00;
	transform: rotate(45deg);
	// opacity: 0.8;
	letter-spacing: 2px;
	overflow: hidden;
}

.popUp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: rgba(0, 0, 0, .6);
	overflow: hidden;

	.popClose {
		position: absolute;
		top: 20px;
		right: 100px;
		width: 50px;
		height: 50px;
	}

	.popLeft {
		position: absolute;
		top: 450px;
		left: 30px;
		width: 50px;
		height: 50px;
	}

	.popRight {
		position: absolute;
		top: 450px;
		right: 30px;
		width: 50px;
		height: 50px;
	}
}

::v-deep .video-js.vjs-fluid {
	height: 1000px;

}

.loginOut {
	line-height: 72px;
	color: #fff;
}

.loginOut:hover {
	color: #FF5C00;
}

.chatActions {
	background-color: #e5f6ff;
}

.online {
	padding: 10px;
	text-align: center;
	position: relative;

	.lineList {
		position: absolute;
		left: 110px;
		top: 0;
		z-index: 99999;
		width: 150px;
		background-color: #212328;
		padding: 10px;
		display: flex;
		flex-direction: column;
		display: none;
	}

	.lineItem {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.isOnline {
		color: rgb(25, 217, 114);
	}
}

.online:hover {
	.lineList {
		display: block;
		position: absolute;
		left: 110px;
		top: 0;
		z-index: 99999;
		width: 150px;
		background-color: #212328;
		padding: 10px;
		display: flex;
		flex-direction: column;

	}
}

.textareaSty {
	width: 100%;
	height: 180px;
	resize: none;

	border-radius: 4px;
	overflow-y: auto;
	scrollbar-width: none;

	margin: 5px 0;
	border: none;
	background: none;

}

[contenteditable]:focus {
	outline: none;
}

.allMore {
	text-align: center;
	color: #0085FF;
	cursor: pointer;
	margin-top: 30px;
}

.endOrder {
	color: #0085FF;
	cursor: pointer;
}

//.el-carousel__item h3 {
//	//color: #475669;
//	font-size: 14px;
//	opacity: 0.75;
//	line-height: 150px;
//	margin: 0;
//}

//.el-carousel__item:nth-child(2n) {
//	background-color: #99a9bf;
//}
//
//.el-carousel__item:nth-child(2n+1) {
//	background-color: #d3dce6;
//}
</style>