import request from '@/api/request.js'

// 发送短信
export function smsSend(data) {
  return request({
    url: 'api/sms/send',
    method: 'POST',
    data
  })
}
export function updateSend(data) {
  return request({
    url: 'api/sms/updateSend',
    method: 'POST',
    data
  })
}
//文件上传
export const uploadFile = (filePath, biz) => {
  if (!biz) {
    throw new Error('图片业务分类不能为空');
  }
  return request({
    url: 'sys/common/upload',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    params : { biz: biz },
    data   : {
      file: filePath
    },
  });
};
