<template>
  <div class="app">
    <div class="lableTop">
      <el-row class="content">
        <div class="fl breadcrumb">
          <span>你的位置:</span>
          <el-breadcrumb>
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ shopInfo.districtName }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ shopInfo.commodityTypeName }}</el-breadcrumb-item>
            <el-breadcrumb-item>商品详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-col :span="24">
          <div class="screen">
            <div class="">
              <div class="fl screenTop"></div>
              <div class="info">
                <div style="display: flex">
                  <div>
                    <el-image @click="isShowPics = true" class="infoImage" :src="shopInfo.pic.split(',')[currNavbar]" fit="scale-down"> </el-image>
                    <div class="fl" style="justify-content: space-between; width: 300px">
                      <el-image @click="scrollImage(currNavbar - 1)" class="imageLeft" :src="require('@/static/image/index/imageLeft.png')"> </el-image>
                      <div class="imageList" ref="scrollableDiv">
                        <el-image @click="scrollImage(index)" v-for="(item, index) in shopInfo.pic.split(',')" class="smallImage" :class="{ imageAction: index == currNavbar }" fit="scale-down" :src="item" alt=""> </el-image>
                      </div>
                      <img @click="scrollImage(currNavbar + 1)" class="imageLeft" :src="require('@/static/image/index/imageRight.png')" alt="" />
                    </div>
                  </div>
                  <div style="margin-left: 20px">
                    <div class="infoTitle el2">
                      <span v-if="shopInfo.gameCommodityCategory == 2" class="infoImage">代练套餐</span>
                      <span v-else-if="shopInfo.gameCommodityCategory == 8" class="infoImage">代练需求</span>
                      <span v-else-if="shopInfo.gameCommodityCategory == 5 || shopInfo.gameCommodityCategory == 6" class="infoAccount">账户</span>
                      <span v-else-if="shopInfo.deliveryType == 1" class="infoImage">担保</span>
                      <span v-else-if="shopInfo.deliveryType == 2" class="infoImage" style="background-color: rgb(183, 66, 255)">寄售</span>
                      <span style="margin-left: 10px; font-size: 20px; font-weight: 600">{{ shopInfo.title }}</span>
                    </div>
                    <div class="tagList">
                      <!-- <span class="tagItem" v-for="(item,index) in shopInfo.gameCommodityFieldValueList">{{item.}}</span> -->
                      <!-- <span class="tagItem">110级</span>
											<span class="tagItem">未存在封停5天</span>
											<span class="tagItem">≥350信用分</span> -->
                    </div>
                    <div class="fl infoPrice">
                      <span class="priceText" v-if="shopInfo.gameCommodityCategory == 2 || shopInfo.gameCommodityCategory == 8">代练佣金：</span>

                      <span v-else class="priceText">商品价格：</span>
                      <span class="priceNum">¥{{ shopInfo.price }}</span>
                    </div>
                    <div class="fl infoSku" v-if="shopInfo.gameCommodityCategory == 2 || shopInfo.gameCommodityCategory == 8">
                      <span class="skuText">临时保证金：</span>
                      <span class="skuText">¥{{ shopInfo.trainDeposit }}</span>
                    </div>

                    <div v-else class="fl infoSku">
                      <span class="skuText">游戏区服：</span>
                      <span class="skuValue">{{ shopInfo.gameName }}/{{ shopInfo.districtName }}/{{ shopInfo.commodityTypeName }}</span>
                    </div>
                    <!-- 
                    <div v-if="shopInfo.gameCommodityCategory == 2 || shopInfo.gameCommodityCategory == 8" class="fl infoDetaile">
                      <div class="detaile">
                        <span class="detaileValue">{{ shopInfo.dailianTime }}小时</span>
                        <span class="detaileKey">约定完成时间</span>
                      </div>
                      <div class="detaile">
                        <span v-if="shopInfo.gameCommodityCategory == 8" class="detaileValue">普通代练</span>
                        <span v-if="shopInfo.gameCommodityCategory == 2" class="detaileValue">认证代练</span>
                        <span class="detaileKey">代练身份</span>
                      </div>
                      <div class="detaile">
                        <span class="detaileValue">{{ daysBetweenDates(shopInfo.createTime, shopInfo.expirationDate) }}天</span>
                        <span class="detaileKey">剩余有效期</span>
                      </div>
                    </div>
                    <div v-else class="fl infoDetaile">
                      <div class="detaile">
                        <span class="detaileValue">{{ shopInfo.commodityTypeName }}</span>
                        <span class="detaileKey">商品类型</span>
                      </div>
                      <div class="detaile">
                        <span class="detaileValue">{{ shopInfo.startTime }}-{{ shopInfo.endTime }}</span>
                        <span class="detaileKey">方便交易时间</span>
                      </div>
                      <div class="detaile">
                        <span class="detaileValue">{{ daysBetweenDates(shopInfo.createTime, shopInfo.expirationDate) }}天</span>
                        <span class="detaileKey">剩余有效期</span>
                      </div>
                      <div class="detaile">
                        <span class="detaileValue">{{ daysBetweenDates(shopInfo.createTime, shopInfo.expirationDate) }}天</span>
                        <span class="detaileKey">服务保障</span>
                      </div>
                    </div> -->

                    <div class="newMsg">
                      <span class="detaileKey1">商品类型:</span> <span class="detaileValue1">{{ shopInfo.commodityTypeName }} </span> <span class="detaileKey1">上架时间:</span> <span class="detaileValue1">{{ shopInfo.createTime }} </span>
                    </div>
                    <div class="titleList">
                      <div v-for="(item, index) in titleList" class="titleLi" :key="index" :style="{ color: item.color, background: item.bg }">{{ item.name }}</div>
                    </div>
                    <div class="infoNumber">
                      <div class="fl">
                        <span class="skuText" style="font-size: 14px">购买数量：</span>
                        <el-input-number v-model="num" size="small" :min="1" :max="shopInfo.num" label="描述文字"></el-input-number>
                        <span style="color: #000; margin-left: 5px; font-size: 14px">件</span>
                      </div>
                      <div style="margin-left: 30px; font-size: 14px">
                        库存<span style="color: #ff5c00">{{ shopInfo.num }}</span
                        >件
                      </div>
                    </div>
                    <div class="submitBtn">
                      <button v-if="shopInfo.gameCommodityCategory == 2" class="btn1" @click="toPay('pay')">邀请代练</button>
                      <button v-else-if="shopInfo.gameCommodityCategory == 8" class="btn1" @click="toPay('pay')">我要接单</button>
                      <button v-else class="btn1" @click="toPay('pay')">立即购买</button>
                      <!-- <button class="btn2" v-if="shopInfo.gameCommodityCategory != 2 && shopInfo.gameCommodityCategory != 8 && shopInfo.gameCommodityCategory != 5 && shopInfo.gameCommodityCategory != 6" @click="shopCartAdd">加入购物车</button> -->
                      <!-- <button class="btn3" @click="toPay('lower')"
												v-if="shopInfo.priceReduction  == 1">降价购买</button> -->
                    </div>
                  </div>
                </div>

                <div style="margin-left: 40px">
                  <img :src="require('@/static/image/index/auth.png')" style="width: 220px" />
                  <div class="shopInfo">
                    <div class="shopLogo">
                      <img style="width: 98px; height: 98px; border-radius: 50%" :src="auth.logo || require('@/static/image/index/profile.png')" />
                      <div class="shopName el1">{{ auth.storeName }}</div>
                    </div>
                    <div class="shopSku">
                      <span class="skuKey">卖家资质：</span>
                      <span class="skuValue" v-if="auth.isAuth == 0">普通商家</span>
                      <span class="skuValue" v-if="auth.isAuth == 1">认证商家</span>
                    </div>
                    <!-- <div class="shopSku">
											<span class="skuKey">交易时间：</span>
											<span class="skuValue">12:00-00:00</span>
										</div> -->
                    <!--
                    <div class="shopSku fl" style="justify-content: center" v-if="auth.shopGrade !== null && auth.shopGrade !== ''">
                      <span class="skuKey">信用等级：</span>
                      <div v-if="auth.shopGrade == 0">
                        <img style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry3.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 1">
                        <img v-for="items in 1" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 2">
                        <img v-for="items in 2" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 3">
                        <img v-for="items in 3" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 4">
                        <img v-for="items in 4" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 6">
                        <img v-for="items in 1" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 7">
                        <img v-for="items in 2" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 8">
                        <img v-for="items in 3" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 9">
                        <img v-for="items in 4" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 11">
                        <img v-for="items in 1" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 12">
                        <img v-for="items in 2" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 13">
                        <img v-for="items in 3" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                      </div>
                      <div v-if="auth.shopGrade == 14">
                        <img v-for="items in 4" style="width: 14px; height: 14px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                      </div>
                    </div>
-->

                    <div class="fl shopNumber">
                      <div class="detaile">
                        <span class="detaileValue">{{ auth.num }}</span>
                        <span class="detaileKey">商品数量</span>
                      </div>
                      <div class="detaile">
                        <span class="detaileValue">{{ auth.closeRate }}%</span>
                        <span class="detaileKey">成交率</span>
                      </div>
                    </div>
                    <el-button @click="$router.push(`/shopDetaile/${auth.userId}/${$route.params.id}`)" class="toShop" type="info" plain>查看发布商品</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="fl" style="padding-left: 20px">
              <div class="fl functions" v-clipboard:copy="shareLink" v-clipboard:success="onCopy">
                <img style="width: 24px; height: 24px; margin-right: 5px" :src="require('@/static/image/index/share.png')" />
                <span>分享</span>
              </div>
              <div class="fl functions" @click="collect">
                <img v-if="shopInfo.isCollection == 0" style="width: 24px; height: 24px; margin-right: 5px" :src="require('@/static/image/index/collect.png')" />
                <img v-if="shopInfo.isCollection == 1" style="width: 24px; height: 24px; margin-right: 5px" :src="require('@/static/image/index/isCollection.png')" />
                <span>收藏</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 商品详情 -->
    <div>
      <el-row class="content">
        <el-col :span="24">
          <div style="display: flex; justify-content: space-between">
            <!-- 左侧 -->
            <div>
              <div class="leftMain">
                <div class="mainTop fl">
                  <span class="topItem" :class="menuIndex == item.value ? 'itemAction' : ''" v-for="item in menuList" @click="menuIndex = item.value" :key="item.value">{{ item.name }}</span>
                </div>
                <div class="mainId" v-show="menuIndex == 1">
                  <div class="idItem">
                    <span>商品编号：</span>
                    <span style="color: #1d223c">{{ shopInfo.id }}</span>
                  </div>
                  <div class="idItem">
                    <span>发布时间：</span>
                    <span style="color: #1d223c">{{ shopInfo.createTime }}</span>
                  </div>
                  <!--                  <div class="idItem">-->
                  <!--                    <span>过期时间：</span>-->
                  <!--                    <span style="color: #1d223c">{{ shopInfo.expirationDate }}</span>-->
                  <!--                  </div>-->
                </div>
                <div class="skuList fl" v-show="menuIndex == 1">
                  <div class="skuItem" v-for="(item, index) in shopInfo.gameCommodityFieldValueList">
                    <img style="width: 48px; height: 48px" :src="item.icon || require('@/static/image/index/detaile3.png')" alt="" />
                    <div class="deteileText">
                      <span>{{ item.value }}</span>
                      <span class="detaileLable">{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="tips" v-if="shopInfo.detail != null && shopInfo.detail != ''" v-show="menuIndex == 1">
                  <span class="tipsLable">商品描述：</span>
                  <span class="tipsDetaile">
                    {{ shopInfo.detail }}
                  </span>
                </div>
                <div v-for="(item, index) in shopInfo.pic.split(',')" v-if="shopInfo.gameCommodityCategory != '1' && shopInfo.gameCommodityCategory != '12'" v-show="menuIndex == 1">
                  <img alt="Responsive Image" :src="item" class="original-size" />
                </div>
                <!--                <div class="tips">
                  <span class="tipsLable">温馨提示：</span>
                  <span class="tipsDetaile" v-html="shopInfo.kindReminder"> </span>
                </div>-->
              </div>

              <div class="leftMain" v-show="menuIndex == 2">
                <div class="mainTop fl">
                  <span class="topItem itemAction">服务保证</span>
                </div>
                <div class="fl serviceItem" v-if="shopInfo.deposit == 1">
                  <div class="fl">
                    <img :src="require('@/static/image/index/pei.png')" style="width: 24px; height: 24px" />
                    <span class="serviceLable">押金赔付：</span>
                    <span class="serviceValue">交易成功押金全额返还</span>
                  </div>
                  <div class="fl">
                    <img :src="require('@/static/image/index/select.png')" style="width: 24px; height: 24px" />
                    <span class="select">支持</span>
                  </div>
                </div>
                <div class="fl serviceItem" v-if="shopInfo.isSendInsure == 1">
                  <div class="fl">
                    <img :src="require('@/static/image/index/bao.png')" style="width: 24px; height: 24px" />
                    <span class="serviceLable">卖家投保：</span>
                    <span class="serviceValue">卖家赠送交易保险</span>
                  </div>
                  <div class="fl">
                    <img :src="require('@/static/image/index/select.png')" style="width: 24px; height: 24px" />
                    <span class="select">支持</span>
                  </div>
                </div>
                <div class="fl serviceItem" v-else>
                  <div class="fl">
                    <img :src="require('@/static/image/index/bao.png')" style="width: 24px; height: 24px" />
                    <span class="serviceLable">商品可投保：</span>
                    <span class="serviceValue">保证交易安全</span>
                  </div>
                  <div class="fl">
                    <img :src="require('@/static/image/index/select.png')" style="width: 24px; height: 24px" />
                    <span class="select">支持</span>
                  </div>
                </div>
              </div>
              <div class="leftMain" v-show="menuIndex == 3">
                <div class="mainTop fl">
                  <span class="topItem itemAction">交易流程</span>
                </div>
                <div style="padding: 20px">
                  <el-image :src="require('@/static/image/index/flow.png')"></el-image>
                </div>
              </div>
              <div class="leftMain" v-show="menuIndex == 4">
                <div class="mainTop fl">
                  <span class="topItem itemAction">防骗指南</span>
                </div>
                <div style="padding: 20px">
                  <el-image :src="require('@/static/image/index/noCheat.png')"></el-image>
                </div>
              </div>
            </div>
            <!-- 右侧 -->
            <div>
              <div class="rightMain">
                <div class="mainTop fl">
                  <span class="topItem itemAction">相关商品</span>
                </div>

                <div class="goodList">
                  <div class="goodItem" @click="toDetaile(item)" v-for="(item, index) in otherShop">
                    <div class="fl">
                      <img style="width: 70px; height: 70px; border-radius: 6px" :src="item.pic.split(',')[0]" />
                      <div style="margin-left: 8px">
                        <div class="goodTitle el2">{{ item.title }}</div>
                        <div class="goodPrice">¥{{ item.price }}</div>
                      </div>
                    </div>
                    <div class="goodSku">
                      <span>{{ item.gameName }}/{{ item.districtName }}</span>
                    </div>
                    <div class="goodType">{{ item.commodityTypeName }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="popUp" v-if="realShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img @click="realShow = false" style="width: 540px; height: 484px; position: absolute; top: 200px" :src="require('@/static/image/index/realName.png')" />
        <div style="z-index: 9999; position: relative; margin-top: 380px; display: flex; flex-direction: column; width: 450px; align-items: center">
          <div class="realNameTitle">请前往实名认证</div>
          <div class="realNameDetaile">根据国家法律规制，使用网络平台服务需要实名认证，请您前往人中心账户设置-实名认证完成实名， <br />实名后交易更安全!</div>
          <button @click="$router.push('/mineIndex/28')" class="realSubmit">前往认证</button>
        </div>
      </div>
    </div>

    <shopping-card></shopping-card>
    <el-image-viewer v-if="isShowPics" :on-close="closeImage" :url-list="shopInfo.pic.split(',')" />
  </div>
</template>

<script>
  import layoutTop from '@/components/layoutTop.vue'
  import shoppingCard from '@/components/shoppingCart.vue'
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  import { gameCommodityQueryById, queryByCommodityTypeId, collectionAdd, shopCartAdd, authShop, realInfo } from '@/api/index.js'
  export default {
    name: 'gameDetaile',
    components: {
      layoutTop,
      shoppingCard,
      ElImageViewer,
    },
    data() {
      return {
        menuIndex: 1,
        currNavbar: 0,
        num: 1,
        realShow: false,
        isShowPics: false,
        shopInfo: { pic: '' },
        otherShop: [],
        auth: {},
        authShow: false,
        shareLink: '',
        titleList: [
          { name: '实名认证', color: '#39CE4B', bg: '#F0FFE6 ' },
          { name: '签署合同', color: '#BB842E', bg: '#FFF5D4 ' },
          { name: '平台担保', color: '#887AEF', bg: '#F1F0FD ' },
          { name: '官方验号', color: '#3DB6E5', bg: '#E1F7FF ' },
        ],
        menuList: [
          {
            name: '商品详情',
            value: 1,
          },
          {
            name: '服务保障',
            value: 2,
          },
          {
            name: '交易流程',
            value: 3,
          },
          {
            name: '防骗指南',
            value: 4,
          },
        ],
      }
    },
    watch: {
      // 利用watch方法检测路由变化：
      $route: function (to, from) {
        if (to.params !== from.params) {
          this.shareLink = window.location.href
          this.loadShopInfo()
        }
      },
    },
    created() {
      this.shareLink = window.location.href
      this.loadShopInfo()
    },
    methods: {
      onCopy() {
        this.message('复制成功', 'success')
      },
      // 去支付或者降价购买
      toPay(type) {
        // if (this.shopInfo.num == 0) {
        // 	this.message('库存不足', 'error')
        // 	return
        // }
        // 判断是否实名
        realInfo().then((res) => {
          if (res.code == 200) {
            if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
              this.realShow = true
            } else {
              let info = {
                id: this.$route.params.id,
                num: this.num,
                type: type,
              }
              // localStorage.setItem('confirmOrder', JSON.stringify(info));
              this.$router.push({
                path: '/confirmOrder',
                query: {
                  info: JSON.stringify(info),
                },
              })
            }
          }
        })
      },
      // 添加购物车
      shopCartAdd() {
        if (this.shopInfo.num == 0) {
          this.message('库存不足', 'error')
          return
        }
        shopCartAdd({
          id: this.$route.params.id,
          num: 1,
          price: this.shopInfo.price,
        }).then((res) => {
          if (res.code == 200) {
            this.message('添加成功！', 'success')
          }
        })
      },
      // 收藏商品
      collect() {
        collectionAdd({
          commodityId: this.$route.params.id,
        }).then((res) => {
          if (this.shopInfo.isCollection == 0) {
            this.shopInfo.isCollection = 1
            this.message('收藏成功', 'success')
          } else {
            this.shopInfo.isCollection = 0
            this.message('取消收藏', 'success')
          }
        })
      },
      // 其他商品列表
      getOtherShop(id) {
        queryByCommodityTypeId({
          commodityTypeId: id,
        }).then((res) => {
          this.otherShop = res.result
        })
      },
      // 获取商家信息
      getAuthShop(id) {
        authShop({
          gameCommodityId: this.$route.params.id,
        }).then((res) => {
          this.auth = res.result
          // if (res.result.id != null) {

          // 	this.authShow = true
          // } else {
          // 	this.authShow = false
          // }
        })
      },
      toDetaile(item) {
        if (item.id === this.shopInfo.id) {
          return
        }
        this.$router.push('/gameDetaile/' + item.id)
      },
      scrollImage(index) {
        // if (index == 0) {
        // 	return
        // }
        // // 当前点击元素的左边距离
        const distanceL = document.getElementsByClassName('smallImage')[index].getBoundingClientRect().left
        const scrollBox = document.getElementsByClassName('imageList')[0].getBoundingClientRect().left
        console.log(Number(distanceL) - Number(scrollBox))
        if (Number(distanceL) - Number(scrollBox) > 186) {
          if (this.currNavbar > index) {
            document.getElementsByClassName('imageList')[0].scrollLeft -= 80
          } else if (this.currNavbar < index) {
            document.getElementsByClassName('imageList')[0].scrollLeft += 80
          }
        }
        this.currNavbar = index

        // this.currNavbar = index
        // 当元素左边距离大于屏幕一半宽度  或者  右边距离大于屏幕一半距离的时候
        // if (distanceL > (screenHW - elementHW) || distanceR > (screenHW - elementHW)) {
        // 	// 滚动条最终的滚动距离
        // 	scrollBox[0].scrollLeft = scrollL + (distanceL - screenHW + elementHW)
        // }
      },
      closeImage() {
        this.isShowPics = false
      },
      //加载商品详情
      loadShopInfo() {
        gameCommodityQueryById({
          id: this.$route.params.id,
        }).then((res) => {
          this.shopInfo = res.result
          if (this.shopInfo.deliveryType === 1) {
            this.titleList[2].name = '平台担保'
          } else if (this.shopInfo.deliveryType === 2) {
            this.titleList[2].name = '平台寄售'
          } else {
            this.titleList[2].name = ''
          }
          this.getOtherShop(this.shopInfo.commodityTypeId)
          this.getAuthShop(this.shopInfo.commodityTypeId)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .app {
    background: url('@/static/image/newImage/1.jpg') no-repeat;
    background-size: cover;
    padding-top: 10px;
  }
  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;

    .realNameTitle {
      font-weight: 500;
      font-size: 24px;
      color: #1d223c;
      text-align: center;
      margin-bottom: 24px;
    }

    .realNameDetaile {
      font-weight: 400;
      font-size: 18px;
      color: #777a8a;
      text-align: center;
      line-height: 30px;
    }

    .realSubmit {
      width: 296px;
      height: 66px;
      background: linear-gradient(90deg, #03b5ff 0%, #0085ff 100%);
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      margin-top: 50px;
    }
  }

  .screen {
    color: #777a8a;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    padding: 0 0 24px;
    margin: 16px 0;

    .screenTop {
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      padding: 20px;
    }
  }

  .breadcrumb {
    align-items: center;
    font-size: 14px;
  }

  ::v-deep .el-breadcrumb {
    font-size: 14px;
  }

  ::v-deep .el-breadcrumb__separator {
    margin: 0 5px;
  }

  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #000;
    font-weight: bold;
  }

  .info {
    padding: 20px;
    display: flex;

    // justify-content: space-around;
    .infoImage {
      width: 300px;
      height: 300px;
      border-radius: 12px 12px 12px 12px;
    }

    .imageLeft {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .imageAction {
      border: 2px solid #ff5c00;
    }

    .imageList {
      overflow-x: auto;
      width: 188px;

      display: flex;
      /* 以下样式用于隐藏滚动条，这些样式在Chrome和Safari中有效 */
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;

      /* Internet Explorer 10+ */
      ::-webkit-scrollbar {
        /* Chrome, Safari, Edge, Opera */
        display: none;
      }

      .smallImage {
        width: 72px;
        height: 72px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  }

  .infoTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    width: 552px;

    .infoAccount {
      font-size: 16px;
      background: #ff5c00;
      border-radius: 4px;
      color: #fff;
      padding: 4px 6px;
    }

    .infoImage {
      font-size: 12px;
      background: #0085ff;
      border-radius: 4px;
      color: #fff;
      padding: 4px 6px;
      margin-left: 5px;
    }
  }

  .infoTitle:hover {
    color: #ff5c00;
  }

  .tagList {
    margin-top: 10px;

    .tagItem {
      font-weight: 500;
      font-size: 18px;
      color: #ff5c00;
      padding: 0 20px;
      position: relative;
      // border-right: 1px solid #A5A7B1;
    }

    .tagItem::before {
      position: absolute;
      content: '';
      width: 1px;
      top: 3px;
      right: 0;
      height: 20px;
      background-color: #a5a7b1;
    }

    .tagItem:first-child {
      padding-left: 0;
    }

    .tagItem:last-child::before {
      width: 0;
    }
  }

  .infoPrice {
    height: 50px;
    background: #fff2e8;
    padding-left: 16px;
    margin-top: 6px;

    .priceText {
      font-size: 14px;
      color: #a5a7b1;
    }

    .priceNum {
      font-weight: bold;
      font-size: 20px;
      color: #ff5c00;
    }
  }

  .infoSku {
    height: 50px;
    background: #fff9f5;
    padding-left: 16px;
    border-bottom: 1px solid #f8f8f8;

    .skuText {
      font-size: 14px;
      color: #a5a7b1;
    }

    .skuValue {
      font-size: 12px;
      color: #1d223c;
    }
  }

  .serverSide {
    font-size: 16px;
    color: #ffe350;
    line-height: 19px;
    background-color: #1b2948;
    padding: 2px 4px;
    border-radius: 2px;
    position: relative;
    margin-left: 10px;
  }

  .serverSide::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -4px;
    transform: rotate(-45deg);
    border-top: 10px solid #1b2948;
    /* 三角形的高度和颜色 */
    border-right: 10px solid transparent;
    /* 三角形的宽度 */
  }

  .infoDetaile {
    justify-content: space-around;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f8f8f8;
  }

  .infoNumber {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 72px;
    font-size: 18px;
    border-bottom: 1px solid #f8f8f8;
  }

  .submitBtn {
    display: flex;

    .btn1 {
      width: 152px;
      height: 46px;
      background: #ff5c00;
      color: #fff;
      border-radius: 8px 8px 8px 8px;
      font-size: 18px;
      margin-right: 10px;
      cursor: pointer;
    }

    .btn2 {
      width: 152px;
      height: 46px;
      color: #ff5c00;
      background: rgba(255, 92, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #ff5c00;
      font-size: 18px;
      margin-right: 10px;
      cursor: pointer;
    }

    .btn3 {
      width: 152px;
      height: 46px;
      background: #0085ff;
      color: #fff;
      font-size: 18px;
      border-radius: 8px 8px 8px 8px;
      cursor: pointer;
    }
  }

  .shopInfo {
    // margin-left: 20px;
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ffe2a2;
    border-top: none;

    .shopLogo {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      .shopName {
        width: 150px;
        font-weight: 600;
        font-size: 16px;
        color: #1d223c;
        text-align: center;
      }
    }

    .shopSku {
      font-size: 14px;
      margin: 10px 16px;
      text-align: center;

      .skuKey {
        color: #a5a7b1;
      }

      .skuValue {
        color: #1d223c;
      }
    }
  }

  .detaile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .detaileValue {
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }

  .detaileKey {
    font-size: 12px;
    color: #a5a7b1;
  }

  .shopNumber {
    justify-content: space-around;
    padding: 16px;
    margin: 0 16px;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
  }

  .toShop {
    width: 184px;
    height: 42px;
    background: #f8f8f8;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #dedede;
    font-size: 16px;
    margin: 20px auto;

    text-align: center;
  }

  .functions {
    margin-right: 30px;
    cursor: pointer;
  }

  .leftMain {
    width: 906px;
    background: #ffffff;
    border-radius: 16px;
    padding-bottom: 1px;
    margin-bottom: 20px;
  }

  .mainTop {
    border-bottom: 1px solid #ececec;

    .topItem {
      width: 140px;
      height: 66px;
      font-weight: 600;
      font-size: 16px;
      color: #1d223c;
      line-height: 66px;
      text-align: center;
      cursor: pointer;
    }

    .itemAction {
      border-bottom: 3px solid #ff5c00;
    }
  }

  .mainId {
    height: 64px;
    border-bottom: 1px solid #ececec;
    display: flex;
    flex-wrap: wrap;
    line-height: 64px;
    padding-left: 20px;

    .idItem {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
      margin-right: 20px;
    }
  }

  .skuList {
    flex-wrap: wrap;

    .skuItem {
      width: 229px;
      height: 78px;
      padding: 24px 20px;
      display: flex;
      align-items: center;
    }

    .deteileText {
      margin-left: 12px;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      display: flex;
      flex-direction: column;
      align-content: flex-start;

      .detaileLable {
        font-weight: 400;
        font-size: 14px;
        color: #a5a7b1;
      }
    }
  }

  .tips {
    margin: 24px 20px;
    display: flex;
    flex-direction: column;

    .tipsLable {
      font-weight: 600;
      font-size: 16px;
      color: #1d223c;
      line-height: 32px;
    }

    .tipsDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #777a8a;
      line-height: 32px;
    }
  }

  .serviceItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ececec;

    .serviceLable {
      font-weight: 400;
      font-size: 18px;
      color: #1d223c;
      margin-left: 12px;
    }

    .serviceValue {
      font-weight: 400;
      font-size: 18px;
      color: #777a8a;
    }

    .select {
      font-weight: 400;
      font-size: 18px;
      color: #1d223c;
      margin-left: 8px;
    }
  }

  .serviceItem:last-child {
    border-bottom: none;
  }

  .rightMain {
    width: 284px;
    background: #ffffff;
    border-radius: 16px;
    padding-bottom: 1px;
    margin-bottom: 20px;
  }

  .goodList {
    padding: 16px;

    .goodItem {
      border-bottom: 1px solid #ececec;
      padding-bottom: 20px;
      margin-top: 20px;
      cursor: pointer;
    }

    .goodItem:last-child {
      border: none;
    }

    .goodTitle {
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }

    .goodPrice {
      font-weight: bold;
      font-size: 20px;
      color: #ff5c00;
    }

    .goodSku {
      font-weight: 400;
      font-size: 14px;
      color: #1d223c;
      margin-top: 12px;
    }

    .goodType {
      font-weight: 400;
      font-size: 12px;
      color: #777a8a;
      margin-top: 12px;
    }
  }
  .original-size {
    width: 100%; /* 或者你想要的固定宽度 */
    height: auto; /* 高度自动，保持图片比例 */
    /* 或者使用 object-fit 维持图片原尺寸 */
    /* object-fit: scale-down; 图片会保持原尺寸，此属性可能会缩小图片以适应容器 */
    // object-fit: none; /* 图片可能会被裁剪，但是不会被拉伸 */
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 16px;
  }
  .newMsg {
    margin: 23px 0;
  }
  .detaileValue1 {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-right: 50px;
  }
  .detaileKey1 {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #999;
  }
  .titleList {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 360px;
    .titleLi {
      padding: 4px 10px;
      box-sizing: border-box;
      font-size: 14px;
      border-radius: 5px;
    }
  }
</style>
