<template>
	<div>
		<div class="roleTitle">消息中心</div>
<!--		<div class="fl search">
			<div class="fl">
				<span class="searchText">游戏区服：</span>
				<el-input v-prevent-space style="width: 286px;" v-model="search.message" placeholder="请输入消息内容"></el-input>
				&lt;!&ndash; <el-select style="width: 436px;" v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select> &ndash;&gt;
			</div>
			
			<div style="margin-left: 56px;">
				<el-button icon="el-icon-refresh" @click="search.message = ''">重置</el-button>
				<el-button type="primary" icon="el-icon-search" @click="getList()">搜索</el-button>
			</div>
		</div>-->

		<div class="roleTitles">
			<el-badge value="" class="titleItem">
				<div @click="swiperMessage(false)" :class="{'action':actionIndex == 0}">未读消息</div>
			</el-badge>
			<el-badge value="" class="titleItem">
				<div @click="swiperMessage(true)" :class="{'action':actionIndex == 1}">已读消息</div>
			</el-badge>
		</div>

		<div class="operate fl">
			<div class="fl">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
					@change="handleCheckAllChange">全选</el-checkbox>
				<div v-if="actionIndex==0" style="margin-left: 24px;">
					<el-button size="mini" @click="readFlagBatch">批量已读</el-button>
<!--					<el-button size="mini">批量删除</el-button>-->
				</div>
			</div>
			<div>
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :page-sizes="[10, 20, 30, 40]" :page-size="search.pageSize"
					layout="total, sizes, prev, slot, next, jumper" :total="totalNumber">
					<span style="color: #0085FF;">{{search.pageNo}}<span
							style="color: #1D223C;">/{{Math.ceil(totalNumber / search.pageSize)}}</span></span>
				</el-pagination>
			</div>
		</div>
		<div style="overflow-y: auto;height: 840px; scrollbar-width: none; ">
			<el-checkbox-group @change="handleCheckedCitiesChange" v-model="checkedCities">
				<div class="mineOrder" v-for="(item,index) in messageList">
					<div class="orderTop fl">
						<div class="fl" style="padding-right: 24px;">
							<div class="fl" style="margin-left: 24px;">
								<el-checkbox style="margin-right: 8px;" :label="item.id"
									:key="item.id">&ensp;</el-checkbox>
								<span v-if="item.readFlag" class="credit" style="color: #1D223C;">已读：</span>
								<span v-else class="credit" style="color: #1D223C;">未读：</span>
								<span class="credit">{{item.createTime}}</span>
							</div>
						</div>
						<div>
							<div class="fl" @click="readFlag(item,index)">
								<span class="credit" v-if="!item.readFlag">标记为已读</span>
							</div>
						</div>
					</div>
					<div class="orderBottom">
						{{item.orderId}}：{{item.message}}
					</div>
				</div>
			</el-checkbox-group>
		</div>
	</div>
</template>

<script>
	import {
		messageList,
		messageEdit
	} from '@/api/index.js'
	import { readMsg } from '@/api/message'
	export default {
		data() {
			return {
				actionIndex: 0,
				checkAll: false,
				checkedCities: [],
				cities: [],
				isIndeterminate: false,
				search: {
					isRead: false,
					message: '',
					pageNo: 1,
					pageSize: 10

				},
				messageList: [],
				totalNumber: 0
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 单个标记已读
			readFlag(item,index){
				readMsg({
					ids:item.id,
				}).then(res=>{
					this.messageList[index].readFlag = true
				})
			},
			// 批量标记已读
			readFlagBatch() {
				if (this.checkedCities.length <= 0) {
					this.message('请选择数据', 'error')
					return
				}
				let ids = this.checkedCities.join(',')
				readMsg({ ids: ids, }).then(res => {
					for (let item of this.messageList) {
						if (ids.indexOf(item.id) > -1) {
							item.readFlag = true
						}
					}
				})
			},
			swiperMessage(type){
				this.actionIndex = type
				this.search.isRead = type
				this.search.pageNo =1
				this.getList()
			},
			getList() {
				messageList(this.search).then(res => {
					if (res.code == 200) {
						this.messageList = res.result.records
						this.cities = this.messageList
						this.totalNumber = res.result.total
					}
				})
			},
			handleSizeChange(e) {
				this.search.pageNo = 1
				this.search.pageSize = e
				this.getList()
			},
			handleCurrentChange(e) {
				this.search.pageNo = e
				this.getList()
			},
			handleCheckAllChange(val) {
				console.log(val)
				let cityOptions = []
				this.cities.forEach(item => {
					cityOptions.push(item.id)
				})
				this.checkedCities = val ? cityOptions : [];
				this.isIndeterminate = false;
				this.checkAll = val
			},
			handleCheckedCitiesChange(value) {
				console.log(value)
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			}
		}
	}
</script>

<style scoped lang="scss">
	.roleTitle {
font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.search {

		padding: 24px 42px;

		.searchText {
			font-weight: 400;
			font-size: 18px;
			color: #1D223C;
		}
	}

	.roleTitles {

		width: 900px;
		border-bottom: 1px solid #ECECEC;
		height: 50px;
		// line-height: 87px;
		padding-left: 42px;
		padding-right: 42px;
		display: flex;
		align-items: center;

		.titleItem {
			font-weight: 400;
			font-size: 14px;
			position: relative;
			color: #1D223C;
			height: 30px;
			margin-right: 50px;
			cursor:pointer;
		}

		.titleItem::after {
			position: absolute;
			width: 1px;
			height: 20px;
			content: '';
			top: 2px;
			right: -25px;
			background-color: #DEDEDE;
		}

		.titleItem:last-child::after {
			width: 0px;
		}
	}

	.action {
		color: #0085FF;
		position: relative;
		// border-bottom: 4px solid #0085FF;
	}

	.action::after {
		position: absolute;
		content: '';
		width: 60px;
		height: 4px;
		background: #0085FF;
		left: 0;
		bottom: -21px;
	}

	.operate {
		margin-left: 30px;
		margin-right: 50px;
		margin-top: 20px;
		justify-content: space-between;
	}

	.mineOrder {
		border: 1px solid #DEDEDE;
		width: 950px;
		border-radius: 8px;
		margin-top: 24px;
		margin-left: 30px;
	}

	.orderTop {
		// width: 1232px;
		padding-right: 24px;
		height: 44px;
		background: #FCFCFC;
		border-radius: 8px 8px 0px 0px;
		border-bottom: 1px solid #DEDEDE;
		justify-content: space-between;

		.credit {
			font-weight: 400;
			font-size: 14px;
			color: #A5A7B1;
			margin-right: 5px;
		}
	}

	.orderBottom {
		font-weight: 600;
		font-size: 14px;
		color: #1D223C;
		padding: 24px;
	}
</style>