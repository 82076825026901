<template>
	<div class="chatBottom">
		<div class="bottomTop">
			<div class="bottomLeft fl" ref="dialogBox">
				<div v-if="emojiShow">
					<transition name="el-fade-in-linear">
						<div class="emoji">
							<span v-for="(row,i) in emojiList" style="font-size: 24px" class="img-style" @click="selectEmoji(row)">{{ row }}</span>
						</div>
					</transition>
				</div>

				<img @click="emojiShow = true" :src="require('@/static/image/chat/function1.png')" class="functionImage"/>
				<img @click="selectImage" :src="require('@/static/image/chat/function3.png')" class="functionImage"/>
<!--				<img @click="selectVideo" :src="require('@/static/image/chat/function4.png')" class="functionImage"/>-->
				<div v-if="endOrder" class="endOrder" @click="endOrder">结束会话</div>
			</div>
		</div>

		<div>
			<textarea v-model="chatValue.text" class="textareaSty" type="textarea" contenteditable="true" ref="pasteContent"
								@paste="pasteImg" @keydown.ctrl.enter="$noMultipleClicks(sendMessage)" />
			<div style="float: right;">
				<el-button @click="$noMultipleClicks(sendMessage)" type="primary">发送</el-button>
			</div>
		</div>


		<input type="file" id="image" @change="onFileChange" accept="image/*" style="display: none;"/>
		<input type="file" id="video" @change="onVideoChange" accept="video/*" style="display: none;"/>
		<el-dialog :visible.sync="dialogTableVisible" title="发送图片" width="500px">
			<div style="display: flex;justify-content: center;">
				<el-image class="img_box" style="width: 200px;" :src="chatValue.img">
				</el-image>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogTableVisible = false">取消</el-button>
					<el-button type="primary" @click="$noMultipleClicks(sendImg)"> 发送 </el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>

import { uploadFile } from '@/api/public';

export default {
	name : 'ChatBottom',
	props: {
		isMe: {
			type   : Boolean,
			default: false,
		},
		name: {
			type   : String,
			default: ''
		},
		msg : {
			type   : Object,
			default: () => { },
		},
		endOrder : {
			type   : Function,
			default: null,
		},
	},
	data() {
		return {
			noClick  : true,
			emojiShow: false,
			dialogTableVisible: false,
			// 表情资源
			emojiList: [
				'😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰',
				'😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩', '🥳', '😏',
				'😒', '😞', '😔', '😟', '😕', '🙁', '😣', '😖', '😫', '😩', '🥺', '😢', '😭', '😤', '😠', '😡',
				'🤬', '🤯', '😳', '🥵', '🥶', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔', '🤭', '🤫', '🤥', '😶',
				'😐', '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '😴', '🤤', '😪', '😵', '🤐', '🥴', '🤢',
				'🤮', '🤧', '😷', '🤒', '🤕', '🤑', '🤠', '😈', '👿', '👹', '👺', '🤡', '💩', '👻', '💀', '☠',
				'👽', '👾', '🤖', '🎃', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾'
			],
			chatValue: {
				text: '',
				img : '',
			},
			clipboardBlob: null,
		};
	},

	created() {
		// 监听全局点击事件
		document.addEventListener('click', (e) => {
			if (this.$refs.dialogBox && !this.$refs.dialogBox.contains(e.target)) {
				this.emojiShow = false;
			}
		});
	},
	destroyed() {
	},
	mounted() {

	},


	methods: {
		// 选择表情
		selectEmoji(item) {
			this.chatValue.text += item;
		},

		//黏贴图片
		pasteImg(event) {
			let that = this;
			const items = (event.clipboardData || event.originalEvent.clipboardData).items;
			
			for (let i = 0; i < items.length; i++) {
				if (items[i].type.indexOf('image') !== -1) {
					this.clipboardBlob = items[i].getAsFile();
					let windowURL = window.URL || window.webkitURL;
					that.chatValue.img = windowURL.createObjectURL(this.clipboardBlob);
					that.dialogTableVisible = true;
				} else if (items[i].type.indexOf('text') !== -1) {
					return false;
				}
			}
		},
		// 选择图片
		selectImage() {
			document.getElementById('image').click();
		},
		// 选择视频
		selectVideo() {
			document.getElementById('video').click();
		},
		//  上传图片
		onFileChange(e) {
			console.log('onFileChange',e);
			uploadFile(e.target.files[0],'im-img').then((res) => {
				res=res.result
				this.chatValue.img = res;
				this.sendMessage();
			});
		},
		// 上传视频
		onVideoChange(e) {
			let toID = '';
			let type = '';
			if (this.roleIm == 'user') {
				toID = this.userInfo.chatroomId;
				type = 'groupChat';
			} else if (this.roleIm == 'buyer') {
				toID = this.buyerInfo.id;
				type = 'singleChat';
			} else if (this.roleIm == 'seller') {
				toID = this.sellerInfo.id;
				type = 'singleChat';
			}
			this.sendPrivateVideo(toID, type);
		},
		sendPrivateVideo(toID, type) {
		},
		// // 结束会话
		// endOrder() {
		// 	this.$emit('endOrder');
		// },
		//发送消息
		sendMessage() {
			if (this.chatValue.text.split(/[\s\n]/).join('') === '' && !this.chatValue.img) {
				this.message('发送内容不能为空!', 'info');
				return;
			}
			this.$emit('sendMessage', this.chatValue);
			this.chatValue = { text: '', img: '' };
		},

		//发送图片
		sendImg() {
			this.onFileChange({ target: { files: [ this.clipboardBlob] } });
			this.dialogTableVisible = false;
		},
	}
};

</script>


<style scoped lang="scss">

.chatBottom {
	border-top: 1px solid #ECECEC;
	height: 270px;
	//position: absolute;
	bottom: 0;
	z-index: 99;
	left: 0;
	width: 95%;
	//width: 608px;
	padding: 10px 20px;
}

.bottomTop {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.bottomTips {
		font-weight: 400;
		font-size: 16px;
		color: #FF5C00;
		margin-right: 10px;
	}
}

.functionImage {
	//width: 28px;
	//height: 28px;
	//margin-right: 30px;
	width: 30px;
	height: 30px;
	margin: 3px 40px 3px 3px;
	margin: 10px 40px 10px 0;
}

.bottomLeft {
	position: relative;

	.emoji {
		position: absolute;
		bottom: 60px;
		left: -100px;
		width: 500px;
		background-color: #fff;
		padding: 15px;
		border-radius: 5px;
		border: 1px solid #E4E7ED;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
	}

	// el-popover el-popper


}

.img-style {
	margin-left: 10px;
	margin-bottom: 10px;
}

.endOrder {
	color: #0085FF;
	cursor: pointer;
}

.textareaSty {
	width: 100%;
	height: 165px;
	resize: none;

	font-weight: 400;
	font-size: 16px;

	border-radius: 4px;
	overflow-y: auto;
	scrollbar-width: none;

	margin: 5px 0;
	border: none;
	background: none;

}
textarea:focus {
	outline: none;
}

</style>
