import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/base.css'

import 'element-ui/lib/theme-chalk/index.css'
import CryptoJS from 'crypto-js'
import preventReClick from '@/static/js/preventReClick' //防多次点击，重复提交

// 将防抖函数添加到Vue原型
Vue.prototype.$noMultipleClicks = preventReClick.noMultipleClicks
import './registerServiceWorker'
import router from './router'

import store from '@/store/index.js'
//复制到粘贴板插件
import VueClipboard from 'vue-clipboard2'

import publics from '@/utils/public.js'
// 在main.js中全局引入
import VideoPlayer from 'vue-video-player/src'
import MetaInfo from 'vue-meta-info'

import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'

import '@/static/js/dialogDrag'
import { connectWebSocket, onWebSocket, WebSocketTopic } from '@/assets/utils/WebIM/webSocket'
import { WEBSOCKET_URL } from '@/api/request'
import { getToken } from '@/utils/auth'
import './registerServiceWorker'
Vue.prototype.$CryptoJS = CryptoJS

// 阻止默认的空格键事件
Vue.directive('prevent-space', {
  bind(el) {
    el.addEventListener('keydown', (event) => {
      if (event.key === ' ') {
        event.preventDefault()
      }
    })
  },
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

Vue.use(VideoPlayer)
Vue.use(ElementUI)
Vue.use(VueClipboard)
Vue.use(MetaInfo)
Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
  store,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')

//初始化websocker
setTimeout(() => {
  connectWebSocket(WEBSOCKET_URL, store.state.userInfo?.id, getToken)
  onWebSocket(WebSocketTopic.orderNotify, (datas) => {
    for (let data of datas) {
      Vue.prototype.notify('订单通知', '订单[' + data.sessionId + ']: ' + data.imMessageContent.text, 'success')
    }
  })
}, 0)

//加载配置
setTimeout(() => {
  let { getConfigList } = require('@/api')
  getConfigList().then((res) => {
    let map = {}
    for (let item of res.result) {
      map[item.id] = { key: item.appKey, value: item.appValue }
    }
    store.commit('setAppConfig', map)
  })
}, 0)
