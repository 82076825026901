<template>
  <div class="box-css">
    <div class="content">
      <MenuLeft :active="active" :router="rouerList" title="交易保障" @change="changeMenu">
        <div class="content-css">
          <template v-for="item of rouerList">
            <div class="item" :key="item.path" v-if="active == item.path" v-html="item.content"></div>
          </template>
        </div>
      </MenuLeft>
    </div>
  </div>
</template>
<script>
  import MenuLeft from '@/components/menuLeft/index.vue'
  import { commonProblemList } from '@/api'
  export default {
    name: '',
    components: {
      MenuLeft,
    },
    data() {
      return {
        rouerList: [],
        active: '',
      }
    },
    mounted() {
      this.loadDocData()
      this.active = this.$route.query.id
    },
    watch: {
      // 利用watch方法检测路由变化：
      $route: function (to, from) {
        this.changeMenu(this.$route.query.id)
      },
    },

    methods: {
      //加载文档数据
      loadDocData() {
        commonProblemList({
          type: 12,
          pageNo: 1,
          pageSize: 10000,
        }).then((res) => {
          let result = []
          for (let item of res.result.records) {
            result.push({
              path: item.id,
              name: item.title,
              icon: item.icon,
              content: item.content,
              child: [],
            })
            if (item.id == this.$route.query.id) {
              this.active = item.id
            }
          }
          this.rouerList = result
          this.active = this.active || result[0].path
        })
      },
      changeMenu(key) {
        this.active = key
      },
    },
  }
</script>
<style lang="scss" scoped>
  .box-css {
    background: #f8f8f8;
    padding: 20px 50px;
    .content-css {
      padding: 50px 36px;
    }
  }
</style>
