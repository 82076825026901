import request from '@/api/request.js'

//客服资讯列表接口
export function consultList(data) {
	return request({
		url   : 'api/imMessage/servicerList',
		method: 'get',
		params: data
	})
}

//历史消息 分页列表查询
export function pageList(data) {
	return request({
		url   : 'api/imMessage/pageList',
		method: 'get',
		params: data
	})
}

//给客服评分
export function scoreById(data) {
	return request({
		url   : 'api/imMessage/scoreById',
		method: 'post',
		params: data,
	})
}

//消息已读记录-添加
export function readMsg(data) {
	return request({
		url   : 'api/messageRead/add',
		method: 'post',
		params: data,
	})
}
