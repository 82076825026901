<template>
	<div class="game" style="border-bottom: 1px solid #E0E0E0;">
		<div style="background: #FFFFFF;">
			<el-row  style="background: #FFFFFF;width: 1200px;margin: 0 auto;">
				
				<el-col :span="24">
					<div class="topCenter">
						<div class="topTab ">
							<el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal"
								@select="handleSelect" background-color="#FFFFFF" text-color="#777A8A"
								active-text-color="#0286FE">
								<el-menu-item v-for="(item,index) in titleList" :index="item.id" :key="index">
									<a @click.prevent="DDDD" :href="'/#'+item.router" :class="{'setColor' : index ==2}"  style="text-decoration: none; color:inherit;">{{item.title}}</a>
								</el-menu-item>
							</el-menu>
						</div>
						<div class="search">
							<div class="downList" ref="dialogBox">
								<div @click="selectGameShow('selectGame')">
									<div class="searchItem">
										{{selectGame.name || '游戏名称'}}
										<i class="el-icon-caret-bottom"></i>
									</div>
								</div>
								<div @click="selectGameShow('selectGameTypeList')">
									<div class="searchItem">
										{{selectGameTypeList.name || '商品类型'}}

										<i class="el-icon-caret-bottom"></i>
									</div>
								</div>
								<div v-if="sonGameTypeList.length !=0" @click="selectGameShow('selectSonGameTypeList')">
									<div class="searchItem">
										{{selectSonGameTypeList.name || '商品子类'}}

										<i class="el-icon-caret-bottom"></i>
									</div>
								</div>
								<div v-if="systemList.length !=0">
									<div class="searchItem">
										{{selectSystem.name || '系统'}}
										<i class="el-icon-caret-bottom"></i>
									</div>
								</div>
								<div v-if="districtList.length !=0">
									<div class="searchItem">
										{{selectDistrict.name || '游戏区服'}}
										<i class="el-icon-caret-bottom"></i>
									</div>
								</div>
								<div v-if="sonDistrictList.length !=0">
									<div class="searchItem">
										{{sonSelectDistrict.name || '游戏大区'}}
										<i class="el-icon-caret-bottom"></i>
									</div>
								</div>


								<div class="dialogBox" @blur="blurEvent($event)" v-if="selectShow">
									<div class="searchName">
										<div>
											<div class="typeTop fl">
												<div class="fl">
													<div class="gameType" @click="selectSearch('','type')"
														:class="{'gameTypeAction':gameSearch.terminalType == ''}">
														全部游戏</div>
													<div class="gameType" @click="selectSearch('1','type')"
														:class="{'gameTypeAction':gameSearch.terminalType == '1'}">
														网络游戏</div>
													<div class="gameType" @click="selectSearch('2','type')"
														:class="{'gameTypeAction':gameSearch.terminalType == '2'}">
														手机游戏</div>
												</div>
												<div class="fl">
													<el-input v-prevent-space placeholder="请输入内容"
														v-model="gameSearch.name">
														<i slot="prefix" class="el-input__icon el-icon-search"></i>
													</el-input>
													<span @click="getGameList1"
														style="width: 120px;text-align: center;cursor:pointer;font-size: 14px;">快速搜索</span>
												</div>
											</div>
											<div class="initial">
												<el-link class="initialItem" @click="selectSearch(item,'initial')"
													v-for="(item,index) in initial" target="_blank">{{item}}</el-link>

											</div>
										</div>
										<div class="selectList">
											<div class="selectItem" v-for="item in gameList">
												<i class="el-icon-caret-right" style="color: #ccc;"></i>
												<span @click="gameSelect(item)" class="selectSpan">{{item.name}}</span>
											</div>
										</div>
									</div>
								</div>

								<div class="dialogBox" v-if="selectShow1">
									<div class="searchName">
										<div class="fl" style="margin-bottom: 10px;">
											<span class="searchLable">请选择商品类型：</span>
											<span class="searchLable" style="color: #0085FF;">不限</span>
										</div>

										<div class="selectList">
											<div class="selectItem" v-for="item in gameTypeList">
												<i class="el-icon-caret-right" style="color: #ccc;"></i>
												<span @click="gameTypeSelect(item)"
													class="selectSpan">{{item.name}}</span>
											</div>
											<div style="color: #0085FF;" v-if="gameTypeList.length == 0">
												请先选择游戏
											</div>
										</div>
									</div>
								</div>
								<div class="dialogBox" v-if="selectShow2">
									<div class="searchName">
										<div class="fl" style="margin-bottom: 10px;">
											<span class="searchLable">请选择商品子类：</span>
											<span class="searchLable" style="color: #0085FF;">不限</span>
										</div>

										<div class="selectList">
											<div class="selectItem" v-for="item in sonGameTypeList">
												<i class="el-icon-caret-right" style="color: #ccc;"></i>
												<span @click="sonGameTypeSelect(item)"
													class="selectSpan">{{item}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="dialogBox" v-if="selectShow3">
									<div class="searchName">
										<div class="fl" style="margin-bottom: 10px;">
											<span class="searchLable">游戏区服：</span>
											<span class="searchLable" style="color: #0085FF;">不限</span>
										</div>

										<div class="selectList">
											<div class="selectItem" v-for="item in districtList">
												<i class="el-icon-caret-right" style="color: #ccc;"></i>
												<span @click="districtSelect(item)"
													class="selectSpan">{{item.name}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="dialogBox" v-if="selectShow4">
									<div class="searchName">
										<div class="fl" style="margin-bottom: 10px;">
											<span class="searchLable">游戏大区：</span>
											<span class="searchLable" style="color: #0085FF;">不限</span>
										</div>

										<div class="selectList">
											<div class="selectItem" v-for="item in sonDistrictList">
												<i class="el-icon-caret-right" style="color: #ccc;"></i>
												<span @click="sonDistrictSelect(item)"
													class="selectSpan">{{item.name}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="dialogBox" v-if="selectShow5">
									<div class="searchName">
										<div class="fl" style="margin-bottom: 10px;">
											<span class="searchLable">系统：</span>
											<span class="searchLable" style="color: #0085FF;">不限</span>
										</div>

										<div class="selectList">
											<div class="selectItem" v-for="item in systemList">
												<i class="el-icon-caret-right" style="color: #ccc;"></i>
												<span @click="systemSelect(item)"
													class="selectSpan">{{item.name}}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="searchInput">
									<input v-prevent-space
										style="border: none;font-size: 14px;margin-left: 30px;background: none;"
										v-model="searchKey" placeholder="搜索商品关键词"></input>
								</div>
							</div>
							<div class="submitSearch" @click="searchShop">
								搜索
							</div>
						</div>

						<div v-if="token != undefined" @click="$router.push('/mineIndex/1')" class="fl" style="cursor:pointer;">
							<!-- <div class="el1" style="margin-right: 10px;color: #fff;width: 50px;">
								{{$store.state.userInfo.name}}</div> -->
							<img style="width: 40px;height: 40px;border-radius: 50%;"
								:src=" $store.state.userInfo.portrait || require('@/static/image/index/profile.png')" />
						</div>
					</div>

				</el-col>
			
			</el-row>
		</div>
		
	</div>
</template>

<script>
	import {
		noticeList,
		gameCommodityCategories,
		gameList,
		gameCommodityTypes,
		districtList,
		hotList
	} from '@/api/index.js'
	import {
		getToken,
	} from '@/utils/auth.js'
	export default {
		data() {
			return {
				token: undefined,
				defaultActive: '',
				titleList: [{
						title: '首页',
						router: '/home',
						id: 1,
					},
					{
						title: '个人中心',
						router: '/mineIndex/1',
						id: 2,
					},
					{
						title: '订单聊天',
						router: '/chat/0',
						id: 3,
					},
					{
						title: '订单中心',
						router: '/mineIndex/13',
						id: 4,
					},
					{
						title: '买家中心',
						router: '/mineIndex/8',
						id: 5,
					},
					{
						title: '卖家中心',
						router: '/mineIndex/12',
						id: 6,
					},
					// {
					// 	title: '账户设置',
					// 	id: 7,
					// 	router: '/setting/1',
					// },
					{
						title: '服务中心',
						router: '/serviceCenter/0/0',
						id: 7,
					}
				],

				searchValue: '',
				selectShow: false,
				selectShow1: false,
				selectShow2: false,
				selectShow3: false,
				selectShow4: false,
				selectShow5: false,

				gameSearch: {
					initial: '',
					isHot: 0,
					name: '',
					pageNo: 1,
					pageSize: 1000,
					terminalType: ''
				},
				// 热门游戏列表
				hotSort: [],
				gameList: [],
				selectGame: {},

				gameTypeList: [],
				selectGameTypeList: {},

				sonGameTypeList: [],
				selectSonGameTypeList: {},

				// 区服列表
				districtList: [],
				selectDistrict: {},

				// 大区
				sonDistrictList: [],
				sonSelectDistrict: {},
				// 系统
				systemList: [],
				selectSystem: {},
				hotTagList: []
			}
		},
		mounted() {
			// ref="dialogBox"
			// 监听全局点击事件
			document.addEventListener('click', (e) => {
				if (e.target.className == 'selectSpan') return
				if (this.$refs.dialogBox && !this.$refs.dialogBox.contains(e.target)) {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}
			});
		},
		created() {
			let currentRoute = this.$router.currentRoute;
			if (currentRoute.path == 'home') {
				this.defaultActive = 1
			}
			if (currentRoute.path == '/mineIndex/1') {
				this.defaultActive = 2
			}
			if (currentRoute.name == 'chat') {
				this.defaultActive = 3
			}
			if (currentRoute.path == '/mineIndex/13') {
				this.defaultActive = 4
			}
			if (currentRoute.path == '/mineIndex/8') {
				this.defaultActive = 5
			}
			if (currentRoute.path == '/mineIndex/12') {
				this.defaultActive = 6
			}
			// if (currentRoute.name == 'setting') {
			// 	this.defaultActive = 7
			// }

			if (currentRoute.name == 'serviceCenter') {
				this.defaultActive = 7
			}
			this.token = getToken()
			this.getGameList()
			this.getGameList1()
			this.getHotList()
		},
		methods: {
			DDDD(){
				return false
			},
			// 获取热门搜索
			getHotList() {
				hotList({
					pageNo: 1,
					pageSize: 6
				}).then(res => {
					this.hotTagList = res.result.records
				})

			},
			handleSelect(key, keyPath) {
				if (key == 9 || key == 10) {
					window.open(this.$router.resolve({
						path: this.titleList[key - 1].router,
					}).href, '_blank');
				} else {
					let currentRoute = this.$router.currentRoute;
					if (currentRoute.path != this.titleList[key - 1].router) {
						this.$router.push(this.titleList[key - 1].router)
					}
				}

				this.defaultActive = Number(key)
			},
			getGameList() {
				gameCommodityCategories({
					pageNo: 1,
					pageSize: 10
				}).then(res => {
					if (res.code == 200) {
						this.hotSort = res.result
					}
					console.log(res)
				})
			},
			getGameList1() {
				gameList(this.gameSearch).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
					}
				})
			},

			// 选择游戏的时候清空其他数据
			selectGameShow(type) {
				console.log(type)
				if (type == 'selectGame') {
					this.selectShow = true
					this.selectShow1 = false
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectGameTypeList') {
					this.selectShow = false
					this.selectShow1 = true
					this.selectShow2 = false
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				} else if (type == 'selectSonGameTypeList') {
					this.selectShow = false
					this.selectShow1 = false
					this.selectShow2 = true
					this.selectShow3 = false
					this.selectShow4 = false
					this.selectShow5 = false
				}
			},
			toShopLists(item) {
				let info = {
					id: item.id,
					terminalType: item.terminalType,
					name: item.name,
					icon: item.icon,

				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');

			},
			// 选择游戏
			gameSelect(item) {
				this.selectGame = {
					id: item.id,
					name: item.name,
					terminalType: item.terminalType,
					icon: item.icon
				}
				// 获取商品类型
				gameCommodityTypes({
					id: item.id
				}).then(res => {
					this.gameTypeList = res.result
					this.selectShow = false
					this.selectShow1 = true

					this.selectGameTypeList = {}

					this.sonGameTypeList = []
					this.selectSonGameTypeList = {}

					this.districtList = []
					this.selectDistrict = {}

					this.sonDistrictList = []
					this.sonSelectDistrict = {}
				})
			},
			// 选择游戏类型
			gameTypeSelect(item) {
				this.selectGameTypeList = {
					id: item.id,
					name: item.name,
				}
				// 如果是手游的话先获取系统
				if (this.selectGame.terminalType == 2) {
					if (item.childrenName != null) {
						this.sonGameTypeList = item.childrenName.split(' ')
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							this.systemList = res.result
							this.selectShow1 = false
							this.selectShow2 = false
							this.selectShow5 = true
						})
					}
				} else {
					if (item.childrenName != null) {
						this.sonGameTypeList = item.childrenName.split(' ')
						this.selectShow1 = false
						this.selectShow2 = true
					} else {
						districtList({
							id: this.selectGame.id
						}).then(res => {
							this.districtList = res.result
							this.selectShow2 = false
							this.selectShow1 = false
							this.selectShow3 = true
						})
					}
				}


			},
			// 选择系统
			systemSelect(item) {
				this.selectSystem = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectSystem.id
				}).then(res => {
					this.districtList = res.result
					this.selectShow5 = false
					this.selectShow3 = true
				})
			},
			// 选择商品子类
			sonGameTypeSelect(item) {
				this.selectSonGameTypeList = {
					name: item
				}
				if (this.selectGame.terminalType == 2) {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						this.systemList = res.result
						this.selectShow2 = false
						this.selectShow5 = true
					})
				} else {
					districtList({
						id: this.selectGame.id
					}).then(res => {
						this.districtList = res.result
						this.selectShow2 = false
						this.selectShow3 = true
					})
				}


			},
			// 选择区服
			districtSelect(item) {
				this.selectDistrict = {
					id: item.id,
					name: item.name,
				}
				districtList({
					id: this.selectDistrict.id
				}).then(res => {
					this.sonDistrictList = res.result
					this.selectShow3 = false
					this.selectShow4 = true
				})
			},
			// 选择大区
			sonDistrictSelect(item) {
				this.sonSelectDistrict = {
					id: item.id,
					name: item.name,
				}
				this.selectShow4 = false
			},
			// 筛选游戏名称
			selectSearch(value, type) {
				if (type == 'type') {
					this.gameSearch.terminalType = value
				}
				if (type == 'initial') {
					if (value == '热门游戏') {
						this.gameSearch.initial = ''
					} else {
						this.gameSearch.initial = value
					}
				}
				this.getGameList1()
			},
			searchShop() {
				if (this.searchKey != '') {
					window.open(this.$router.resolve({
						path: '/gameDetaile/' + this.searchKey
					}).href, '_blank');
					return
				}
				if (Object.keys(this.selectGame).length == 0) {
					this.message('请先选择游戏', 'error')
					return
				}

				let info = {
					id: this.selectGame.id,
					name: this.selectGame.name,
					terminalType: this.selectGame.terminalType,
					icon: this.selectGame.icon,
					title: this.searchKey
				}
				if (Object.keys(this.selectGameTypeList).length != 0) {
					info.gameTypeSelect = {
						id: this.selectGameTypeList.id,
						name: this.selectGameTypeList.name
					}
				}
				if (Object.keys(this.selectSonGameTypeList).length != 0) {
					info.gameTypeSonSelect = {
						name: this.selectSonGameTypeList.name
					}
				}

				if (Object.keys(this.selectDistrict).length != 0) {
					info.selectDistrict = {
						id: this.selectDistrict.id,
						name: this.selectDistrict.name
					}
				}
				if (Object.keys(this.sonSelectDistrict).length != 0) {
					info.sonSelectDistrict = {
						id: this.sonSelectDistrict.id,
						name: this.sonSelectDistrict.name
					}
				}
				if (Object.keys(this.selectSystem).length != 0) {
					info.selectSystem = {
						id: this.selectSystem.id,
						name: this.selectSystem.name
					}
				}
				// localStorage.setItem('gameList', JSON.stringify(info));
				window.open(this.$router.resolve({
					path: '/gameList',
					query: {
						info: JSON.stringify(info)
					}
				}).href, '_blank');
				// this.$router.replace({
				// 	path: '/gameList',
				// 	query: {
				// 		info: JSON.stringify(info)
				// 	}
				// })

			}
		}


	}
</script>
<style lang="scss" scoped>
	.logo {
		width: 140px;
		height: 52px;
		// background: #D9D9D9;
		border-radius: 0px 0px 0px 0px;
		// opacity: 0.5;
	}
	::v-deep .el-menu-item{
		font-size: 12px;
		height: 60px;
			padding: 0 10px;
			line-height: 60px;
	}



	.search {
		width: 604px;
		height: 38px;
		background-color: #0085FE;
		border-radius: 5px;
		padding: 4px;
		display: flex;
		align-items: center;

		justify-content: space-between;
	}

	.submitSearch {
		width: 120px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 5px;
		cursor:pointer;
	}



	.el-dropdown-link {
		font-size: 18px;
		color: #000;
	}

	.downItem {
		width: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
	}


	// .el-menu-item {
	// 	color: #fff;
	// 	height: 70px;
	// 	font-size: 16px;
	// 	padding: 0 10px;
	// 	line-height: 70px;
	// }

	::v-deep .el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 3px solid #409EFF;
	}

	.topTab {
		height: 60px;
	}

	.all {
		color: #fff;
		margin-right: 20px;
		font-size: 16px;
		margin-top: 8px;
	}

	.hotSearch {
		height: 52px;

		.hotTitle {
			font-weight: 500;
			font-size: 16px;
			color: #1D223C;
			margin-right: 10px;
		}

		.hotItem {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			margin-right: 40px;
			white-space: nowrap;
			cursor:pointer;
		}

		.hotItem:hover {
			color: #0085FE;
		}
	}

	::v-deep .el-menu.el-menu--horizontal{
		border-bottom: none;
	}
	.downList {
		display: flex;
		align-items: center;
		background-color: #fff;
		height: 40px;
		// margin-left: 2px;
		// margin-right: 2px;
		color: #000;
		width: calc(100% - 120px);
		border-radius: 5px;
		position: relative;
	}

	.dialogBox {
		position: absolute;
		top: 60px;
		width: 600px;
		background-color: #fff;
		z-index: 99999;
		border-radius: 5px;
		padding: 20px;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
	}

	.searchItem {
		// min-width: 80px;
		text-align: center;
		white-space: nowrap;
		font-size: 12px;
		cursor:pointer;
	}



	.searchName {
		padding: 0 20px;

		.searchLable {
			color: #000;
			opacity: 0.8;
			font-size: 12px;
		}

		.searchTag {
			font-size: 14px;
			margin-left: 10px;
		}
	}

	.typeTop {
		height: 30px;
		background-color: #FAFAFA;
		justify-content: space-between;

		::v-deep .el-input__inner {
			height: 30px;
			line-height: 30px;
		}

		.gameType {
			width: 100px;
			font-size: 12px;
			line-height: 30px;
			text-align: center;
			cursor:pointer;
		}

		.gameTypeAction {
			background-color: #0085FE;
			color: #fff;
		}
	}
	:v-deep .el-input__suffix{
		top: -5px;
	}

	.selectList {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;

		.selectItem {
			width: 140px;
			margin-bottom: 20px;
			// color: #0085FE;
			font-size: 12px;
			cursor:pointer;
		}

		.selectSpan:hover {
			background-color: #0085FE;
			color: #fff;
		}

	}

	.initialList {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;

		.initialItem {
			font-size: 14px;

		}
	}

	.setColor {
		color: #FF5C00;
	}

	.topCenter {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.game{
		display: flex;
		flex-direction: column;
	}
</style>