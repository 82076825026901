<template>
  <div class="app">
    <div class="content">
      <div class="left">
        <div style="margin-right: 60px">
          <img :src="require('@/static/image/newImage/wx.png')" alt="" />
          <div>联系客服</div>
        </div>
        <div>
          <img :src="require('@/static/image/newImage/wx.png')" alt="" />
          <div>商务合作</div>
        </div>
      </div>
      <div class="centent"></div>
      <div class="right">
        <div class="rightTop">
          <div v-for="(item, index) in routerList" class="rightTopLi" :key="index" @click="goPage(item)">{{ item.name }}</div>
        </div>
        <div class="rightCentent">
          <div>
            <el-link href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank" style="color: #d1d1d1">闽ICP备2024063527号-2</el-link>
          </div>
          <div><img :src="require('@/static/image/newImage/icon19.png')" alt="" />
            <el-link href="https://beian.mps.gov.cn/#/query/websearch?code=35010402351649" rel="noreferrer" target="_blank" style="color: #d1d1d1">闽公网安备 35010402351649号</el-link>
          </div>
          <el-link href="http://oss.pp276.com/yyzz.png" target="_blank" style="color: #d1d1d1">电子营业执照</el-link>
          <div>邮箱：18259939278@163.com</div>
        </div>
        <div class="rightCentent">©版权所有 派派星球（福州）科技有限公司 All Rights Reserved 抄袭必究 全民反诈，识骗防骗</div>
        <div class="rightBottom">
          <img :src="require('@/static/image/newImage/bottom2.png')" alt="" />
          <img :src="require('@/static/image/newImage/bottom1.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // 导入组件
  export default {
    name: 'FooterBar',
    data() {
      return {
        routerList: [
          {
            name: '安全中心',
            url: '/serviceCenter/0/0',
            id: '1',
          },
          {
            name: '意见反馈',
            url: '/serviceCenter/1/0',
            id: '2',
          },
          {
            name: '帮助中心',
            url: '/serviceCenter/2/0',
            id: '3',
          },
          {
            name: '找回赔偿',
            url: '/assure?id=1859148420295970818',
            id: '4',
          },
          {
            name: '防骗提醒',
            url: '/assure?id=1859148585069203458',
            id: '5',
          },
          {
            name: '售后服务',
            url: '/assure?id=1859148518090362881',
            id: '6',
          },
        ],
      }
    },
    methods: {
      goPage(item) {
        if (item.url !== this.$router.currentRoute.fullPath) {
          this.$router.push({
            path: item.url,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .app {
    background: #fff !important;
    height: 260px;
    padding-top: 60px;
    box-sizing: border-box;
    .content {
      display: flex;
      .left {
        display: flex;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        div {
          margin-top: 10px;
          text-align: center;
        }
        img {
          width: 100px;
          height: 100px;
        }
      }
      .centent {
        width: 1px;
        height: 204px;
        background: linear-gradient(0deg, #ffffff 0%, #000000 47%, #ffffff 100%);
        opacity: 0.2;
        margin-left: 60px;
      }
      .right {
        //margin-left: 60px;
        margin: auto;
        .rightTop {
          display: flex;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
          .rightTopLi {
            cursor: pointer;
            margin-right: 60px;
          }
        }
        .rightCentent {
          display: flex;
          justify-content: space-between;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #d1d1d1;
          margin: 30px 0;
          div {
            display: flex;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        .rightBottom {
          img {
            margin-right: 15px;
          }
        }
      }
    }
  }
</style>
