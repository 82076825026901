import request from '@/api/request.js'


// 
export function login(data) {
  return request({
    url: 'api/login/login',
    method: 'post',
    data
  })
}
export function registered(data) {
  return request({
    url: 'api/login/registered',
    method: 'post',
    data
  })
}

export function loginByCode(data) {
  return request({
    url: 'api/login/loginByCode',
    method: 'post',
    data
  })
}

// 忘记密码
export function forgetPassword(data) {
  return request({
    url: 'api/login/forgetPassword',
    method: 'post',
    data
  })
}

// 微信三方登录
export function wxAuth(data) {
  return request({
    url: 'api/login/wxAuth',
    method: 'post',
    data:data
  })
}

// 微信登录登录
export function phoneLogin(data) {
  return request({
    url: 'api/login/phoneLogin',
    method: 'post',
    data:data
  })
}

// 登出
export function logout(data) {
  return request({
    url: 'api/login/logout',
    method: 'post',
    data:data
  })
}
// 注销
export function deleteById(data) {
  return request({
    url: 'api/login/delete',
    method: 'post',
    data:data
  })
}


