import request from '@/api/request.js'


//h获取用户信息
export function getUser(data) {
  return request({
    url: 'api/userUp/getUser',
    method: 'get',
    params:data
  })
}
// 更改用户头像
export function portrait(data) {
  return request({
    url: 'api/userUp/portrait',
    method: 'post',
    data
  })
}
// 更改用户信息
export function userUpEdit(data) {
  return request({
    url: 'api/userUp/edit',
    method: 'post',
    data
  })
}
//用户余额查询
export function queryBalance(data) {
  return request({
    url: 'api/userUp/queryBalance',
    method: 'get',
    params:data
  })
}