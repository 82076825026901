<template>
  <div style="background-color: #f8f8f8; height: 100vh">
    <!-- <div @click="$noMultipleClicks(cheshi)">111111111111</div> -->
    <div>
			<div style="width: 100%;background: #1c1c1c;">
				<el-row class="content">
					<el-col :span="3">
						<div class="fl">
							<img :src="require('@/static/image/newImage/logo.png')" style="height: 72px;" />
						</div>
					</el-col>
					<el-col :span="13">
						<div class="topTitle">
							PP276游戏服务网
						</div>
					</el-col>
					<el-col :span="8">
						<div class="topTitle" style="text-align: right">
							平台唯一官网
							<el-link href="http://www.pp276.com" target="_blank" style="color: #d1d1d1">www.pp276.com</el-link>
						</div>
					</el-col>

				</el-row>
			</div>
			
			
      <!--			<div style="width: 100%;background: #0085FF;">
				<el-row style="width:1200px;margin: 0 auto;">

					<el-col :span="24">
						<div class="fl">
							<div style="height:72px;width:500px;margin-right: 100px;">
								<el-carousel :interval="2000" height="72px" arrow="always">
									<el-carousel-item v-for="item in bannerList" >
										<el-image @click="$noMultipleClicks(toLink1,item)"
											style=" height: 72px;cursor:pointer;" :src="item.pic"
											fit="cover"></el-image>
									</el-carousel-item>
								</el-carousel>
							</div>
							<noticeBar :noticeData="noticeData"></noticeBar>
						</div>
					</el-col>

				</el-row>
			</div>-->

      <div :style="{ zoom: getAcale }">
        <el-row class="content">
          <el-col :span="24">
            <div class="chatMain">
              <!--							<div class="chatLeft">
								<div class="fl leftItem" @click="$router.push('/mineIndex/1')">
									<img :src="$store.state.userInfo.portrait || require('@/static/image/index/profile.png')"
										style="width: 48px;height: 48px;border-radius: 50%;" />
									<div class="el1 leftText" style="color: #fff;width:90px;">
										{{$store.state.userInfo.name}}
									</div>
								</div>

								<div class="fl leftItem1" :class="{'leftAction':leftAction == index}"
									v-for="(item,index) in leftList" 
									@click="$noMultipleClicks(leftClick,index)">
									<img :src="leftAction == index ?  item.actionImage : item.image "
										style="width: 30px;height: 30px;" />
									<div class="el1 leftText">{{item.text}}</div>
								</div>

								<div class="fl leftItem1" v-if="!isWarning" @click="$noMultipleClicks(setWarning,true)">
									<img :src="require('@/static/image/chat/chat7.png')"
										style="width: 30px;height: 30px;" />
									<div class="el1 leftText" style="color: #fff;">提示音</div>
								</div>
								<div class="fl leftItem1" v-else @click="$noMultipleClicks(setWarning,false)">
									<img :src="require('@/static/image/chat/chat14.png')"
										style="width: 30px;height: 30px;" />
									<div class="el1 leftText" style="color: #19D972;">提示音</div>
								</div>
							</div>-->

              <div class="account">
                <!--登录用户信息-->
                <div class="fl chatItem" style="background-color: #04b5ff">
                  <div style="position: relative; overflow: hidden">
                    <img :src="$store.state.userInfo.portrait || require('@/static/image/index/profile.png')" style="width: 48px; height: 48px; border-radius: 50%" />
                  </div>
                  <div style="margin-left: 12px">
                    <div class="infoTitle el1">
                      <span style="margin-left: 5px; color: white">{{ $store.state.userInfo.name }}</span>
                    </div>
                  </div>
                </div>

                <div class="search">
                  <div class="searchInfo">
                    <el-input v-prevent-space v-model="search.id" style="width: 348px; margin: 12px 0" placeholder="搜索订单编号" clearable @input="searchOrder">
                      <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                </div>
                <div class="chatList" @scroll="addOrder">
                  <div class="fl chatItem" :class="{ chatActions: item.id == userInfo.id }" v-for="(item, index) in tableData" v-if="item.searchHide !== true" @click="consultSelectOrder(item, index)">
                    <div style="position: relative; overflow: hidden">
                      <img :src="getImgView(item.targetAvatar)" style="width: 80px; height: 80px; border-radius: 12px" />
                      <span class="chatStatus1">{{ item.commodityTypeName }}</span>
                      <span class="chatStatus">{{ item.statusText }}</span>
                    </div>
                    <div style="margin-left: 12px">
                      <div class="infoTitle el1">
                        <!--												<span class="infoAccount" :class="'userType'+item.targetType">{{ item.targetTypeText }}</span>-->
                        <span style="margin-left: 5px">{{ item.targetName }}</span>
                      </div>
                      <div class="idItem el1" style="width: 180px">
                        <span>{{ item.title }}</span>
                      </div>
                      <div style="width: 200px" class="idItem el1">
                        <span>{{ item.id.split('-')[0] }}</span>
                      </div>
                      <div v-if="item.gameCommodity?.districtName" style="width: 200px" class="idItem el1">
                        <span>{{ item.gameCommodity?.districtName }}</span>
                      </div>
                    </div>

                    <div style="position: absolute; top: 10px; right: 30px">
                      <el-badge :value="item.unreadNum ? item.unreadNum : ''" class="item">
                        <div style="color: #ff5c00"></div>
                      </el-badge>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 聊天消息内容 -->
              <div v-if="Object.keys(userInfo).length != 0" style="display: flex">
                <div class="chat" :class="{ HandleDrag: isHandleDrag, isConsult: !userInfo.order && !userInfo.gameCommodity }">
                  <div class="chatTop">
                    <div :class="{ chatAction: roleIm == 'user' }" class="chatTopItem">
                      <el-badge class="item">
                        <span style="margin-left: 12px">{{ userInfo.targetName }}</span>
                      </el-badge>
                    </div>
                  </div>
                  <div class="chatCenter" ref="chatCenter">
                    <!-- @scroll="chatCenter" -->
                    <div class="allMore" @click="chatCenter">点击查看更多</div>
                    <div v-for="(item, index) in messageList">
                      <chat-msg :is-me="item.fromUserId === user" :name="userInfo.targetName" :msg="item" :img-load="handleScroll"></chat-msg>
                    </div>
                  </div>
                  <chat-bottom @sendMessage="sendMessage"></chat-bottom>
                </div>
                <!-- 右侧订单详情 -->
                <order-info v-if="userInfo.order" :order-info="userInfo.order" :trade-message="activities" @reloadData="queryOrderDetail"></order-info>
                <commodity-info v-else-if="userInfo.gameCommodity" :commodity-info="userInfo.gameCommodity"></commodity-info>
              </div>

              <!-- 聊天消息内容 背景 -->
              <div style="display: flex; align-items: center; margin: 0 auto" v-if="Object.keys(userInfo).length == 0">
                <div style="width: 200px">
                  <img style="filter: brightness(0.1); width: 200px" :src="require('@/static/image/newImage/logo.png')" />
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 取消订单弹框 -->
    <el-dialog title="取消订单" style="margin-top: 200px" :visible.sync="cancelVisible" width="700px">
      <div>
        <!-- v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" -->
        <div class="fl" v-if="userInfo.gameCommodityCategory == '2'">
          <div class="dialogLable must">哪方原因</div>
          <el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" v-model="causeType" @input="causeInput" :label="1">买家原因</el-radio>
          <el-radio v-else v-model="causeType" @input="causeInput" :label="1">代练原因</el-radio>
          <el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" v-model="causeType" @input="causeInput" :label="2">卖家原因</el-radio>
          <el-radio v-else v-model="causeType" @input="causeInput" :label="2">玩家原因</el-radio>
        </div>
        <div class="fl" v-else-if="userInfo.gameCommodityCategory == '8'">
          <div class="dialogLable must">哪方原因</div>
          <el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" v-model="causeType" @input="causeInput" :label="1">买家原因</el-radio>
          <el-radio v-else v-model="causeType" @input="causeInput" :label="1">代练原因</el-radio>
          <el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" v-model="causeType" @input="causeInput" :label="2">卖家原因</el-radio>
          <el-radio v-else v-model="causeType" @input="causeInput" :label="2">玩家原因</el-radio>
        </div>
        <div class="fl" v-else-if="userInfo.gameCommodityCategory != '2' && userInfo.gameCommodityCategory != '8'">
          <div class="dialogLable must">哪方原因</div>
          <el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" v-model="causeType" @input="causeInput" :label="1">买家原因</el-radio>
          <el-radio v-else v-model="causeType" @input="causeInput" :label="1">代练原因</el-radio>
          <el-radio v-if="userInfo.gameCommodityCategory != '8' && userInfo.gameCommodityCategory != '2'" v-model="causeType" @input="causeInput" :label="2">卖家原因</el-radio>
          <el-radio v-else v-model="causeType" @input="causeInput" :label="2">玩家原因</el-radio>
        </div>
        <div class="fl" style="margin-top: 20px">
          <div class="dialogLable must">取消原因</div>
          <div class="dialogValue">
            <el-select v-model="causeValue" style="width: 250px" :popper-append-to-body="false" placeholder="请选择取消原因">
              <el-option v-for="item in causeList" :key="item.reason" :label="item.reason" :value="item.reason"> </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelVisible = false">取 消</el-button>
        <el-button style="background-color: #ff5c00; color: #fff" @click="$noMultipleClicks(cancelState)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 申请客服介入弹框 -->
    <el-dialog title="申请客服介入" :visible.sync="applyforShow" width="700px" style="margin-top: 200px">
      <div>
        <div class="fl formItem">
          <div class="submitLable">
            <span style="color: #ff3c3c">*</span>
            <span class="lableText">申请原因：</span>
          </div>
          <div class="submitValue">
            <el-input v-prevent-space v-model="applyforValue" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="applyforShow = false">取 消</el-button>
        <el-button type="primary" @click="applyfor">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 确认收货弹框 -->
    <el-dialog :title="confirmText" :visible.sync="confirmShow" width="700px" style="margin-top: 200px">
      <div>
        <div class="fl formItem">
          <div class="submitLable">
            <span style="color: #ff3c3c">*</span>
            <span class="lableText">验证方式：</span>
          </div>
          <div class="submitValue">
            <el-select :popper-append-to-body="false" v-model="reasonValue" placeholder="请选择">
              <el-option v-for="item in reason" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="fl formItem" v-if="reasonValue == 1">
          <div class="submitLable">
            <span style="color: #ff3c3c">*</span>
            <span class="lableText">支付密码：</span>
          </div>
          <div class="submitValue">
            <el-input v-prevent-space maxlength="6" show-password v-model="payPass" placeholder="请输入您的支付密码"></el-input>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmShow = false">取 消</el-button>
        <el-button type="primary" @click="$noMultipleClicks(configStart)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 申请理赔弹框 -->
<!--    <el-dialog title="上传证据" :visible.sync="applyShow" width="700px">
      <div>
        <el-form label-width="120px" class="demo-ruleForm">
          <el-form-item label="理赔银行账户:" prop="name">
            <el-select style="width: 376px" v-model="applyInsureInfo.accountId" placeholder="请选择理赔账户">
              <el-option v-for="(item, index) in accountList" :key="item.id" :label="item.bank + '(' + idCardEncryption(item.card) + ')'" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="买家手机:">
            <el-input v-prevent-space v-model="applyInsureInfo.phone" placeholder="请输入您的手机号码" style="width: 376px"></el-input>
          </el-form-item>
          <el-form-item label="买家QQ:">
            <el-input v-prevent-space v-model="applyInsureInfo.qq" placeholder="请输入您的QQ号" style="width: 376px"></el-input>
          </el-form-item>
          <el-form-item label="申报理由">
            <div class="reason">
              <el-input v-prevent-space v-model="applyInsureInfo.applyReason" type="textarea" placeholder="请输入您的申报理由" style="width: 276px; background-color: #fff"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="截图证据:" prop="resource">
            <div class="item4">
              <div class="uploadTip">上传截图可留作纠纷验证证据，强烈建议上传截图。</div>
              <div>
                <el-upload accept=".jpg,.jpeg,.png" :action="BASE_URL + '/sys/common/upload'" list-type="picture-card" :on-success="handlePictureCardPreview" :on-remove="handleRemove" :on-error="handleError" ref="uploadFile">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
              <div class="item5">请上传jpg、png格式文件，文件大小不超过5M</div>
            </div>
          </el-form-item>
          <el-form-item label="视频证据:" prop="resource">
            <div class="item4">
              <div class="uploadTip">上传视频可留作纠纷验证证据，强烈建议上传视频。</div>
              <div>
                <el-upload accept=".mp4" :action="BASE_URL + '/sys/common/upload'" :on-success="handlePictureCardPreview1" :on-remove="handleRemove1" :on-error="handleError" ref="uploadFile" :file-list="fileList">
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </div>
              <div class="item5">请上传Mp4等格式文件，文件大小不超过50M。</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applyShow = false">取 消</el-button>
        <el-button style="background-color: #ff5c00; color: #fff" @click="$noMultipleClicks(applyClaims)">确 定</el-button>
      </span>
    </el-dialog>-->

    <input type="file" id="image" @change="onFileChange" accept="image/*" style="display: none" />
    <input type="file" id="video" @change="onVideoChange" accept="video/*" style="display: none" />
    <div v-if="music" style="z-index: -1">
      <audio ref="audioMp" :src="require('@/static/tips.mp3')" autoplay loop></audio>
    </div>

    <div class="popUp" v-if="isShowPics2">
      <div style="width: 1080px; height: 100%; margin: 0 auto">
        <video-player ref="videoPlayer" :playsinline="false" :options="playOptions(showVideoIndex)" />
      </div>
      <img @click="leftsClick" class="popLeft" :src="require('@/static/image/chat/bg_left.png')" />
      <img @click="rightsClick" class="popRight" :src="require('@/static/image/chat/bg_right.png')" />
      <img @click="isShowPics2 = false" class="popClose" :src="require('@/static/image/chat/bg_close.png')" />
    </div>

    <el-dialog :visible.sync="dialogTableVisible" title="发送图片" width="500px">
      <div style="display: flex; justify-content: center">
        <el-image class="img_box" style="width: 200px" :src="imgInfo.tempFilePath"> </el-image>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取消</el-button>
          <el-button type="primary" @click="$noMultipleClicks(sendTheImg)"> 发送 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
  import {
    timeList,
    voiceCall,
    updateStatus,
    cancelReasonList,
    getOrderList,
    getSaleOrderList,
    cancelOrder,
    cancelOrderSale,
    queryDetail,
    conform,
    withdrawList,
    applyInsure,
    orderInsureList,
    gameList,
    sessionRecordList,
    tradeMessageList,
    orderFieldListByOrderId,
    saveReceiveRole,
    applyService,
    queryOne,
    ePolicy,
    updateLastReplyTime,
    queryDetailAll,
    queryPic,
    queryPicByType,
    getConfig,
    fush,
    updateLastReplyTimeById,
    closeConsultById,
    receiveRoleList,
  } from '@/api/index.js'
  import { BASE_URL, WEBSOCKET_URL } from '@/api/request'

  import { Loading } from 'element-ui'

  import noticeBar from '@/components/noticeBar.vue'
  import headBar from '@/components/HeadBar.vue'
  import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
  import { getToken } from '@/utils/auth.js'
  import axios from 'axios'
  import { consultList, pageList, readMsg } from '@/api/message'
  import ChatMsg from '@/views/modules/chatMsg.vue'
  import ChatBottom from '@/views/modules/chatBottom.vue'
  import OrderInfo from '@/views/modules/orderInfo.vue'
  import CommodityInfo from '@/views/modules/commodityInfo.vue'
  import { buildMsgSessionId, onWebSocket, sendChat, WebSocketTopic } from '@/assets/utils/WebIM/webSocket'
  //格式化时间
  // import {formatDate} from '@/assets/utils/format.js';
  export default {
    components: {
      CommodityInfo,
      OrderInfo,
      ChatBottom,
      ChatMsg,
      noticeBar,
      headBar,
      ElImageViewer,
    },
    data() {
      return {
        isShowPics: false,
        isShowPics1: false,
        isShowPics2: false,
        showVideo: [],
        showVideoIndex: 0,
        isHandleDrag: false,

        // 申请客服介入原因
        applyforShow: false,
        applyforValue: '',

        roleValue: '',
        configRoleValue: '',

        // 代练
        formList: [],
        // configRoleValue:'',
        // roleValue:'',
        recordList: [],
        // 回收代练信息
        singleChatInfo: '',
        gameList: [],
        // 理赔账户
        accountList: [],
        accountValue: '',
        applyShow: false,
        applyPic: [],
        applyVideo: [],
        fileList: [],
        applyTotal: 0,
        applySearch: {
          gameName: '',
          id: '',
          pageNo: 1,
          pageSize: 10,
          status: '',
        },
        applyList: [],
        applyInsureInfo: {
          // 订单id
          orderId: '',
          accountId: '',
          // 买家qq
          qq: '',
          // 买家手机
          phone: '',
          // 买家申保理由
          applyReason: '',
          // 图片
          pic: '',
          // 视频
          video: '',
        },
        // 确认收货弹框
        confirmShow: false,
        confirmType: '',
        confirmText: '',
        reasonValue: 1,
        reason: [
          {
            value: 1,
            label: '支付密码验证',
          },
        ],
        payPass: '',
        music: false,
        // 和那个角色在聊天
        roleIm: 'user',
        // 取消订单弹框
        cancelVisible: false,
        emojiShow: false,
        // 表情资源
        emojiList: {},
        // 提示音
        isWarning: true,
        leftAction: '0',
        leftList: [
          {
            text: '最近会话',
            image: require('@/static/image/chat/chat1.png'),
            actionImage: require('@/static/image/chat/chat8.png'),
            value: '1',
          },
          {
            text: '历史订单',
            image: require('@/static/image/chat/chat2.png'),
            actionImage: require('@/static/image/chat/chat9.png'),
            value: '2',
          },
          {
            text: '买家订单',
            image: require('@/static/image/chat/chat3.png'),
            actionImage: require('@/static/image/chat/chat10.png'),
            value: '14',
          },
          {
            text: '卖家订单',
            image: require('@/static/image/chat/chat4.png'),
            actionImage: require('@/static/image/chat/chat11.png'),
            value: '15',
          },
          {
            text: '代练订单',
            image: require('@/static/image/chat/chat5.png'),
            actionImage: require('@/static/image/chat/chat12.png'),
            value: '4',
          },
          {
            text: '售后咨询',
            image: require('@/static/image/chat/chat6.png'),
            actionImage: require('@/static/image/chat/chat13.png'),
            value: '3',
          },

          {
            text: '保单列表',
            image: require('@/static/image/chat/chat15.png'),
            actionImage: require('@/static/image/chat/chat16.png'),
            value: '7',
          },
          {
            text: '商家聊天',
            image: require('@/static/image/chat/chat6.png'),
            actionImage: require('@/static/image/chat/chat13.png'),
            value: '8',
          },
        ],
        tableData: [],
        chatValue: '',
        user: '', //自己应用下的用户id
        pwd: '111111', //用户密码
        inputMessage: '',
        messageList: [], //消息
        activities: [],

        leftType: 1,
        search: {
          id: '',
          pageNo: 1,
          pageSize: 10,
        },
        isLoading: true,
        userInfo: {},
        userInfoIndex: '',
        serveInfo: {},
        causeType: 1,
        causeValue: '',
        causeList: [],
        intervalId: null,
        intervalId1: null,
        totalSeconds: '',
        totalSeconds1: '',
        // 快捷键code
        keyCodeList: [],
        isSearchFocus: false,
        checkSubmitFlg: false,
        imgInfo: {},
        dialogTableVisible: false,
        bannerList: [],
        payPassword: '',
        //* 在data中创建一个noClick默认启动
        noClick: true,
        getAcale: 1,
        roleList: [],

        BASE_URL: BASE_URL,
        scrollLock: false,
      }
    },
    computed: {
      minutes() {
        return Math.floor(this.totalSeconds / 60)
      },
      seconds() {
        return this.totalSeconds % 60
      },

      minutes1() {
        return Math.floor(this.totalSeconds1 / 60)
      },
      seconds1() {
        return this.totalSeconds1 % 60
      },
    },
    mounted() {
      let that = this
      window.addEventListener('keydown', this.handleKeyDown)
      window.addEventListener('keyup', this.handleKeyUp)
      // document.addEventListener("visibilitychange", function() {
      // 	if (that.$route.name == 'chat') {
      // 		if (document.visibilityState == "hidden") {
      // 			console.log('隐藏');
      // 		} else if (document.visibilityState == "visible") {
      // 			if (conn.logOut) {
      // 				that.userInfo = {}
      // 				that.getOrderList()
      // 				that.loginIM().then(result => {
      //
      // 				})
      // 			}
      // 		}
      // 	}
      // });

      let devicewidth = window.screen.availWidth //获取当前分辨率下的可是区域宽度
      if (devicewidth < 2200) {
        let scale = devicewidth / 2200 // 分母——设计稿的尺寸
        this.getAcale = scale //放大缩小相应倍数
      }
    },
    destroyed() {
      window.removeEventListener('keydown', this.handleKeyDown)
      window.removeEventListener('keyup', this.handleKeyUp)
      //取消键盘监听事件
      window.document.onkeydown = null
      // conn.close();
    },

    created() {
      let id = this.$route.params.id
      if (id && id !== '0') {
        this.search.sessionId = buildMsgSessionId(this.$route.query.sessionId, this.$store.state.userInfo.id, this.$route.params.id)
      }
      this.getConsultList().then((res) => {
        if (this.search.sessionId) {
          for (let i = 0; i < this.tableData.length; i++) {
            let item = this.tableData[i]
            if (item.id === this.search.sessionId) {
              this.consultSelectOrder(item, i)
              break
            }
          }
        }
      })

      this.user = this.$store.state.userInfo.id
      // 监听全局点击事件
      document.addEventListener('click', (e) => {
        if (this.$refs.dialogBox && !this.$refs.dialogBox.contains(e.target)) {
          this.emojiShow = false
        }
      })

      this.loginIM()

      // this.user = this.$store.state.userInfo.id
      // localStorage.setItem('chatPromptTone', true)
      // // let _that = this
      // // _that.bodyScale()
      // this.isWarning = localStorage.getItem('chatPromptTone')
      // console.log(this.isWarning)
      // // 监听全局点击事件
      // document.addEventListener('click', (e) => {
      // 	if (this.$refs.dialogBox && !this.$refs.dialogBox.contains(e.target)) {
      // 		this.emojiShow = false
      // 	}
      // });
      // if (conn.logOut) {
      // 	if (this.$route.params.id.indexOf('youxi') != -1) {
      // 		this.leftAction = 7
      // 		this.getSessionRecordList()
      // 		queryOne({
      // 			userId: this.$route.params.id,
      // 			type: localStorage.getItem('recordType')
      // 		}).then(res => {
      // 			if (res.code == 200) {
      // 				this.singleChat(res.result)
      // 			}
      // 		})
      //
      // 	} else if (this.$route.params.id != 0) {
      // 		this.queryDetail(this.$route.params.id)
      //
      // 	}
      // 	this.getMessage();
      // } else {
      // 	this.loginIM()
      // // }
      // this.getOrderList()
      // queryPicByType({
      // 	type: 9
      // }).then(res => {
      // 	this.bannerList = res.result
      // })
      //
      // fush().then(res => {
      // 	this.payPassword = res.result.payPassword
      // })
    },

    methods: {
      toLink1(item) {
        if (!this.isEmpty(item.url)) {
          window.open(item.url)
        }
      },
      sendTheImg() {
        this.dialogTableVisible = false
        let that = this
        const file = {
          data: null, // file 对象。
          filename: '', //文件名称。
          filetype: '', //文件类型。
        }
        const url = window.URL || window.webkitURL
        const img = new Image() //手动创建一个Image对象

        let chatType = ''
        let targetId = ''

        if (Object.keys(this.singleChatInfo).length != 0) {
          chatType = 'singleChat'
          if (this.singleChatInfo.userId == this.user) {
            targetId = this.singleChatInfo.consultId
          } else {
            targetId = this.singleChatInfo.userId
          }
        } else {
          if (this.roleIm == 'user') {
            chatType = 'groupChat'
            targetId = this.userInfo.chatroomId
          } else {
            chatType = 'singleChat'
            targetId = this.serveInfo.id
          }
        }

        let option = {
          type: 'img',
          file: file,
          to: targetId,
          chatType: chatType,
          onFileUploadError: function () {
            console.log('onFileUploadError')
          },
          onFileUploadProgress: function (e) {
            console.log(e)
          },
          // 图片文件上传成功。
          onFileUploadComplete: function () {},
          thumbnailHeight: 200,
          thumbnailWidth: 200,
        }
        const imgFile = this.imgInfo.imgFile
        file.data = imgFile
        file.filename = imgFile.name
        file.filetype = imgFile.type
        console.log(imgFile)
        img.src = url.createObjectURL(imgFile) //创建Image的对象的url
        img.onload = async () => {
          try {
            // 创建一条图片消息。
            let msg = WebIM.message.create(option)
            if (conn.logOut) {
              this.loginIM().then((result) => {
                setTimeout((res) => {
                  conn
                    .send(msg)
                    .then((res) => {
                      that.sendMessageSuccessful(res.message, targetId, 'img')
                    })
                    .catch((e) => {})
                }, 1000)
              })
            } else {
              conn
                .send(msg)
                .then((res) => {
                  that.sendMessageSuccessful(res.message, targetId, 'img')
                })
                .catch((e) => {
                  // 图片文件发送失败。
                  console.log('Fail')
                })
            }
          } catch (error) {
            if (error.type && error?.data) {
              handleSDKErrorNotifi(error.type, error.data.error || 'none')
            } else {
              handleSDKErrorNotifi(0, 'none')
            }
          }
        }
      },
      pasteImg(event) {
        let that = this
        const items = (event.clipboardData || event.originalEvent.clipboardData).items
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            const blob = items[i].getAsFile()
            const reader = new FileReader()
            reader.onload = (e) => {
              that.imgInfo = {
                imgFile: blob,
                tempFilePath: e.target.result,
              }
              that.dialogTableVisible = true
            }

            reader.readAsDataURL(blob)
          } else if (items[i].type.indexOf('text') !== -1) {
            return false
          }
        }
      },
      setConfirm(text, type) {
        if (type == 'completedOrder') {
          // 判断是否设置支付密码
          if (this.payPassword != undefined && this.payPassword != null && this.payPassword != '') {
            this.confirmText = text
            this.confirmType = type
            this.confirmShow = true
          } else {
            this.message('请先设置支付密码', 'error')
            setTimeout(() => {
              this.$router.push('/mineIndex/30')
            }, 1500)
          }
        } else {
          this.confirmText = text
          this.confirmType = type
          this.confirmShow = true
        }
      },
      handleEnd(e) {
        e.preventDefault()
        this.isHandleDrag = false
      },
      handleDrop(e) {
        let that = this
        e.preventDefault()
        let files = e.dataTransfer.files

        this.isHandleDrag = false
        if (files.length == 0) return

        const file = files[0]
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          console.log(e.target.result)

          let chatType = ''
          let targetId = ''

          if (Object.keys(this.singleChatInfo).length != 0) {
            chatType = 'singleChat'
            if (this.singleChatInfo.userId == this.user) {
              targetId = this.singleChatInfo.consultId
            } else {
              targetId = this.singleChatInfo.userId
            }
          } else {
            if (this.roleIm == 'user') {
              chatType = 'groupChat'
              targetId = this.userInfo.chatroomId
            } else {
              chatType = 'singleChat'
              targetId = this.serveInfo.id
            }
          }
          let option = {
            // 消息类型。
            type: 'video',
            file: {
              size: file.size,
              name: file.name,
              type: file.type,
              blob: e.target.result,
            },
            to: targetId,
            // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
            chatType: chatType,

            // 图片文件上传成功。
            onFileUploadComplete: function () {
              console.log('onFileUploadComplete')
            },
            thumbnailHeight: 200,
            thumbnailWidth: 200,
          }
          // 创建一条图片消息。
          let msg = WebIM.message.create(option)
          // 调用 `send` 方法发送该图片消息。
          conn
            .send(msg)
            .then((res) => {
              // that.messageList.push(res.message);
              // setTimeout(() => {
              // 	that.handleScroll()
              // }, 200)
              console.log(res.message)
              // 图片文件成功发送。
              that.sendMessageSuccessful(res.message, targetId, type)
            })
            .catch((e) => {
              // 图片文件发送失败。
              console.log('Fail')
            })
        }
        fileReader.readAsArrayBuffer(file)
        // 读取为 DataURL

        // let file = WebIM.utils.getFileUrl(reader.readAsDataURL(blob));

        // console.log(file)
        // if (files[0].type.indexOf('video') == -1 && files[0].type.indexOf('image') == -1) {
        // 	this.message('请上传图片或视频格式', 'error')
        // 	return
        // }

        // var file = files[0]
        // let param = new FormData() // 创建form对象
        // param.append('file', files[0])
        // let config = {
        // 	headers: {
        // 		'Content-Type': 'multipart/form-data',
        // 		'X-Access-Token': getToken()
        // 	}
        // } // 添加请求头
        // axios.post('http://api.cc007.com/jeecg-boot/sys/common/upload', param, config)
        // 	.then(response => {
        // 		// 上传成功
        // 		if (response.data.code == 200) {
        // 			// response.data.result
        // 			let chatType = '';
        // 			let targetId = '';
        // 			let type='';
        // 			if (Object.keys(this.singleChatInfo).length != 0) {
        // 				chatType = 'singleChat'
        // 				if (this.singleChatInfo.userId == this.user) {
        // 					targetId = this.singleChatInfo.consultId
        // 				} else {
        // 					targetId = this.singleChatInfo.userId
        // 				}
        // 			} else {
        // 				if (this.roleIm == 'user') {
        // 					chatType = 'groupChat'
        // 					targetId = this.userInfo.chatroomId
        // 				} else {
        // 					chatType = 'singleChat'
        // 					targetId = this.serveInfo.id
        // 				}
        // 			}

        // 			if (files[0].type.indexOf('image') != -1){
        // 				type = 'img'
        // 			}else{
        // 				type = 'video'
        // 			}

        // 			console.log(response.data.result)
        // 			let option = {
        // 				// 消息类型。
        // 				type:type,
        // 				url: response.data.result,
        // 				to: targetId,
        // 				// 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
        // 				chatType: chatType,

        // 				// 图片文件上传成功。
        // 				onFileUploadComplete: function() {
        // 					console.log("onFileUploadComplete");
        // 				},
        // 				thumbnailHeight: 200,
        // 				thumbnailWidth: 200,
        // 			};
        // 			// 创建一条图片消息。
        // 			let msg = WebIM.message.create(option);
        // 			// 调用 `send` 方法发送该图片消息。
        // 			conn
        // 				.send(msg)
        // 				.then((res) => {
        // 					// that.messageList.push(res.message);
        // 					// setTimeout(() => {
        // 					// 	that.handleScroll()
        // 					// }, 200)
        // 					console.log(res.message)
        // 					// 图片文件成功发送。
        // 					that.sendMessageSuccessful(res.message, targetId, type);
        // 				})
        // 				.catch((e) => {
        // 					// 图片文件发送失败。
        // 					console.log("Fail");
        // 				});
        // 		} else {
        // 			this.message('发送失败', 'error')
        // 		}
        // 	})
      },
      handleDragOver(e) {
        e.preventDefault()
        this.isHandleDrag = true
      },
      searchFocus() {
        this.isSearchFocus = true
        console.log('获得焦点')
      },
      searchBlur() {
        this.isSearchFocus = false
        console.log('失去焦点')
      },
      // // 监听键盘事件
      // handleKeyDown(e) {
      // 	// 快捷键思路 列如alt+z 触发事件 按下是将alt的code码push到一个数组内 在次触发按下事件时假如监听的code时z就触发方法
      // 	// 已按下shift建
      // 	if (e?.keyCode == 16) {
      // 		this.keyCodeList?.push(e?.keyCode)
      // 	}
      // 	// 是否已经按下了shift键
      // 	const isShift = this.keyCodeList?.includes(16)
      // 	// 触发工单 shift+回车
      // 	const isWorkOrder = isShift && e?.keyCode == 13
      //
      // 	if (isWorkOrder) {
      // 		console.log('按下shift+回车键')
      // 		// this.chatValue += '\n'
      // 		this.keyCodeList = []
      // 		return
      // 	}
      // 	if (e?.keyCode == 13) {
      // 		if (Object.keys(this.singleChatInfo).length != 0 && this.leftAction == 7) {
      // 			this.sendSingleMessage()
      // 		} else {
      // 			if (this.isSearchFocus == true) {
      // 				this.getOrderList()
      // 			} else {
      // 				this.sendMessage()
      // 			}
      //
      // 		}
      //
      //
      // 	}
      // },
      handleKeyUp() {
        this.keyCodeList = []
      },
      // 催促发货
      startCountdown() {
        this.intervalId = setInterval(() => {
          if (this.totalSeconds > 0) {
            this.totalSeconds--
          } else {
            this.stopCountdown()
          }
        }, 1000)
      },
      startCountdown1() {
        this.intervalId1 = setInterval(() => {
          if (this.totalSeconds1 > 0) {
            this.totalSeconds1--
          } else {
            this.stopCountdown()
          }
        }, 1000)
      },
      stopCountdown() {
        clearInterval(this.intervalId)
        clearInterval(this.intervalId1)
      },
      // updateLastReplyTimes() {
      // 	updateLastReplyTime().then()
      // },
      // 切换取消原因
      causeInput(e) {
        this.causeType = e
        this.causeValue = ''
        this.getCancelReasonList()
      },
      // 获取取消原因
      getCancelReasonList() {
        cancelReasonList({
          reasonType: this.causeType,
        }).then((res) => {
          this.causeList = res.result
        })
      },

      //咨询列表
      getConsultList() {
        this.search.type = this.leftAction
        return consultList(this.search).then((res) => {
          if (!res || res.code !== 200 || !res.result) {
            return
          }
          for (let item of res.result) {
            if (item.lastMessage) {
              item.lastMessage.createTime = Date.parse(item.lastMessage.createTime)
            }
            // item.targetType = this.getTargetType(item);
            // item.targetTypeText = this.getTargetTypeText(item.targetType);
            if (item.order) {
              item.title = '订单:' + item.order.title
              item.order.gameCommodityTitle = item.gameCommodity.title
              item.order.gameCommodityTypeText = item.gameCommodity?.commodityTypeName
            } else if (item.gameCommodity) {
              item.title = '商品:' + item.gameCommodity.title
            }
            item.statusText = this.getOrderStatusText(item.order?.status)
            item.commodityTypeName = item.gameCommodity?.commodityTypeName
            item.unreadNum = item.unreadNum || 0
          }

          if (this.search.pageNo == 1) {
            this.tableData = res.result
          } else {
            this.tableData = [...this.tableData, ...res.result]
          }
          // this.tableData = res.result.records
        })
      },
      //咨询客服 选择会话
      consultSelectOrder(item) {
        if (item.id === this.userInfo.id) {
          return
        }
        item.unreadNum = 0
        this.isLoading = true
        this.buyerInfo = {}
        this.sellerInfo = {}
        this.roleIm = 'user'
        // this.userInfo = JSON.parse(JSON.stringify(item));
        this.userInfo = Object.assign({}, item)
        // this.userInfo.id = '';
        this.userInfo.sessionTopic = item.id.split('-')[0]
        this.userInfo.userId = item.targetId
        this.messageList = []
        this.getHistoryMessages(this.userInfo.chatroomId, 'groupChat')
        if (item.order) {
          this.queryOrderDetail(item.order)
        }
        // if (this.userInfo.chatroomId != null) {
        // }
      },
      //查询订单详情
      queryOrderDetail(order) {
        queryDetail({ id: order.id }).then((res) => {
          res = res.result
          this.userInfo.order = Object.assign(this.userInfo.order, res)
          // this.getChatUserInfo();
          for (let item of this.tableData) {
            if (item.id.indexOf(this.userInfo.order.id) > -1) {
              item.statusText = this.getOrderStatusText(this.userInfo.order.status)
            }
          }
          this.getTradeMessageList(this.userInfo.order.id)
        })
      },
      // 选择订单
      selectOrder(item, index) {
        this.isLoading = true
        this.roleIm = 'user'
        this.queryDetail(item.id)
        this.userInfoIndex = index
        // this.userInfo = item
        this.messageList = []
        this.serveInfo = {}
        this.stopCountdown()
        if (item.chatroomId != null) {
          this.getHistoryMessages(item.chatroomId, 'groupChat')
        }
        this.getTradeMessageList(item.id, 'groupChat')
      },
      getTradeMessageList(id) {
        tradeMessageList({
          id: id,
        }).then((res) => {
          this.activities = res.result.records
        })
      },

      // 获取单聊列表
      getSessionRecordList() {
        let search = {
          pageNo: this.search.pageNo,
          pageSize: this.search.pageSize,
        }
        sessionRecordList(search).then((res) => {
          if (search.pageNo == 1) {
            this.recordList = res.result.records
          } else {
            this.recordList = [...this.recordList, ...res.result.records]
          }
        })
      },
      // 获取游戏列表
      getGameList() {
        gameList({
          pageNo: 1,
          pageSize: 1000,
        }).then((res) => {
          this.gameList = res.result.records
        })
      },
      // 理赔列表
      getApplyList() {
        orderInsureList(this.applySearch).then((res) => {
          this.applyList = res.result.records
          this.applyTotal = res.result.total
        })
      },
      applySize(e) {
        this.applySearch.pageSize = e
        this.getApplyList()
      },
      applyCurrent(e) {
        this.applySearch.pageNo = e
        this.getApplyList()
      },
      // 买家订单数据
      getBuyerOrder() {
        let info = {
          isTrain: 0,
          orderType: '',
          type: 1,
        }
        getOrderList(Object.assign(this.search, info)).then((res) => {
          if (res.code == 200) {
            if (this.search.pageNo == 1) {
              this.tableData = res.result.records
            } else {
              this.tableData = [...this.tableData, ...res.result.records]
            }
          }
        })
      },
      // 卖家订单数据
      getSaleOrderList() {
        let info = {
          isTrain: 0,
          orderType: '',
          type: 1,
        }
        getSaleOrderList(Object.assign(this.search, info)).then((res) => {
          if (res.code == 200) {
            if (this.search.pageNo == 1) {
              this.tableData = res.result.records
            } else {
              this.tableData = [...this.tableData, ...res.result.records]
            }
          }
        })
      },
      //登陆
      loginIM() {
        // connectWebSocket(WEBSOCKET_URL, this.$store.state.userInfo.id, getToken)
        //订阅消息
        onWebSocket(WebSocketTopic.chat, this.messageConsume)
        onWebSocket(WebSocketTopic.orderNotify, this.orderNotifyConsume)
      },

      //消息消费
      messageConsume(datas) {
        console.log('消息消费', datas)
        let index = {}
        for (let item of this.tableData) {
          index[item.id] = item
        }

        let readList = []
        for (let data of datas) {
          let key = data.sessionId

          let dataItem = index[key]
          if (!dataItem) {
            this.getConsultList()
            return
          }
          dataItem.lastMessage = data

          if (dataItem.id === this.userInfo.id) {
            //当前会话
            let flag = true
            for (let item of this.messageList) {
              if (item.id === data.id) {
                flag = false
                Object.assign(item, data)
                break
              }
            }
            if (flag) {
              this.messageList.push(data)
              this.handleScroll()
            }
            readList.push(data)
          } else {
            //非当前会话
            dataItem.unreadNum++
          }
        }
        this.tableData.sort((a, b) => {
          let aTime = a.lastMessage?.createTime || 0
          let bTime = b.lastMessage?.createTime || 0
          return bTime - aTime
        })
        //消息已读
        this.readFlag(readList)
      },
      //订单通知消费
      orderNotifyConsume(datas) {
        console.log('订单通知消费', datas)
        for (let data of datas) {
          this.notify('订单通知', '订单[' + data.sessionId + ']: ' + data.imMessageContent.text, 'success')
          if (data.sessionId === this.userInfo?.order?.id) {
            console.log('重新加载订单信息')
            //延迟查询 避免后端事务未提交导致的幻读
            setTimeout(() => {
              this.queryOrderDetail(this.userInfo.order)
            }, 500)
          }
        }
      },
      //发送消息
      sendMessage(msgContent) {
        let info = this.getTargetInfo()
        sendChat(this.userInfo.sessionTopic, info.id, info.type, msgContent)
      },
      // 单聊处理发送信息
      sendSingleMessage() {
        if (conn.logOut) {
          this.loginIM().then((result) => {
            if (this.chatValue.split(/[\s\n]/).join('') == '') {
              // this.message('发送内容不能为空!', 'info');
              return
            }
            let that = this
            let contentMsg = that.chatValue

            let toID = this.user == this.singleChatInfo.userId ? this.singleChatInfo.consultId : this.singleChatInfo.userId
            let type = 'singleChat'

            //收信息用户id
            let id = conn.getUniqueId() // 生成本地消息id
            let msg = new WebIM.message('txt', id) // 创建文本消息
            msg.set({
              msg: contentMsg, // 消息内容
              to: toID, // 接收消息对象（用户id）
              chatType: type, // 设置为单聊
              ext: {
                type: type,
                chatroomId: toID,
              },
              success: function (id, serverMsgId) {
                console.log('成功发送消息')
                that.sendMessageSuccessful(contentMsg, toID, 'txt')
              },
              fail: function (e) {
                console.log('发送消息失败')
              },
            })
            setTimeout((res) => {
              conn
                .send(msg.body)
                .then(() => {
                  that.chatValue = ''
                })
                .catch((e) => {
                  that.message('信息发送失败，请重新登录', 'error')
                })
            }, 1000)
          })
        } else {
          if (this.chatValue.split(/[\s\n]/).join('') == '') {
            // this.message('发送内容不能为空!', 'info');
            return
          }
          let that = this
          let contentMsg = that.chatValue

          let toID = this.user == this.singleChatInfo.userId ? this.singleChatInfo.consultId : this.singleChatInfo.userId
          let type = 'singleChat'

          //收信息用户id
          let id = conn.getUniqueId() // 生成本地消息id
          let msg = new WebIM.message('txt', id) // 创建文本消息
          msg.set({
            msg: contentMsg, // 消息内容
            to: toID, // 接收消息对象（用户id）
            chatType: type, // 设置为单聊
            ext: {
              type: type,
              chatroomId: toID,
            },
            success: function (id, serverMsgId) {
              console.log('成功发送消息')
              that.sendMessageSuccessful(contentMsg, toID, 'txt')
            },
            fail: function (e) {
              console.log('发送消息失败')
            },
          })
          conn
            .send(msg.body)
            .then(() => {
              that.chatValue = ''
            })
            .catch((e) => {
              console.log(e)
              that.message('信息发送失败，请重新登录', 'error')
            })
        }
      },

      // 选择图片
      selectImage() {
        document.getElementById('image').click()
      },
      // 选择视频
      selectVideo() {
        document.getElementById('video').click()
      },
      //  上传图片
      onFileChange(e) {
        this.sendPrivateImg()
      },
      // 上传视频
      onVideoChange(e) {
        this.sendPrivateVideo()
      },
      sendPrivateVideo() {
        let that = this
        if (conn.logOut) {
          this.loginIM().then((result) => {
            // 选择本地视频文件。
            let input = document.getElementById('video')
            let file = WebIM.utils.getFileUrl(input)
            let allowType = {
              mp4: true,
              wmv: true,
              avi: true,
              rmvb: true,
              mkv: true,
            }
            if (file.filetype.toLowerCase() in allowType) {
              let chatType = ''
              let targetId = ''

              if (Object.keys(this.singleChatInfo).length != 0) {
                chatType = 'singleChat'
                if (this.singleChatInfo.userId == this.user) {
                  targetId = this.singleChatInfo.consultId
                } else {
                  targetId = this.singleChatInfo.userId
                }
              } else {
                if (this.roleIm == 'user') {
                  chatType = 'groupChat'
                  targetId = this.userInfo.chatroomId
                } else {
                  chatType = 'singleChat'
                  targetId = this.serveInfo.id
                }
              }
              let option = {
                // 消息类型。
                type: 'video',
                file: file,
                // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
                to: targetId,
                // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
                chatType: chatType,
                onFileUploadError: function () {
                  // 视频文件上传失败。
                  console.log('onFileUploadError')
                },
                onFileUploadProgress: function (e) {
                  // 视频文件上传进度。
                  console.log(e)
                },
                onFileUploadComplete: function () {
                  // 视频文件上传成功。
                  console.log('onFileUploadComplete')
                },
                ext: {},
              }
              // 创建一条视频消息。
              let msg = WebIM.message.create(option)
              // 调用 `send` 方法发送该视频消息。
              setTimeout((res) => {
                conn
                  .send(msg)
                  .then((res) => {
                    that.sendMessageSuccessful(res.message, targetId, 'video')
                  })
                  .catch((e) => {
                    console.log('Fail')
                  })
              }, 1000)
            }
          })
        } else {
          // 选择本地视频文件。
          let input = document.getElementById('video')
          let file = WebIM.utils.getFileUrl(input)
          let allowType = {
            mp4: true,
            wmv: true,
            avi: true,
            rmvb: true,
            mkv: true,
          }
          if (file.filetype.toLowerCase() in allowType) {
            let chatType = ''
            let targetId = ''

            if (Object.keys(this.singleChatInfo).length != 0) {
              chatType = 'singleChat'
              if (this.singleChatInfo.userId == this.user) {
                targetId = this.singleChatInfo.consultId
              } else {
                targetId = this.singleChatInfo.userId
              }
            } else {
              if (this.roleIm == 'user') {
                chatType = 'groupChat'
                targetId = this.userInfo.chatroomId
              } else {
                chatType = 'singleChat'
                targetId = this.serveInfo.id
              }
            }
            let option = {
              // 消息类型。
              type: 'video',
              file: file,
              // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
              to: targetId,
              // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
              chatType: chatType,
              onFileUploadError: function () {
                // 视频文件上传失败。
                console.log('onFileUploadError')
              },
              onFileUploadProgress: function (e) {
                // 视频文件上传进度。
                console.log(e)
              },
              onFileUploadComplete: function () {
                // 视频文件上传成功。
                console.log('onFileUploadComplete')
              },
              ext: {},
            }
            // 创建一条视频消息。
            let msg = WebIM.message.create(option)
            // 调用 `send` 方法发送该视频消息。
            conn
              .send(msg)
              .then((res) => {
                // that.messageList.push(res.message);
                // setTimeout(() => {
                // 	that.handleScroll()
                // }, 200)
                console.log(res)
                that.sendMessageSuccessful(res.message, targetId, 'video')
              })
              .catch((e) => {
                // 视频文件发送失败。例如，本地用户被禁言或封禁。
                console.log('Fail')
              })
          }
        }
      },
      sendPrivateImg() {
        let that = this
        // 选择本地图片文件。
        if (conn.logOut) {
          this.loginIM().then((result) => {
            let input = document.getElementById('image')

            let file = WebIM.utils.getFileUrl(input)
            let allowType = {
              jpg: true,
              gif: true,
              png: true,
              bmp: true,
            }
            console.log(file)
            if (file.filetype.toLowerCase() in allowType) {
              let chatType = ''
              let targetId = ''

              if (Object.keys(this.singleChatInfo).length != 0) {
                chatType = 'singleChat'
                if (this.singleChatInfo.userId == this.user) {
                  targetId = this.singleChatInfo.consultId
                } else {
                  targetId = this.singleChatInfo.userId
                }
              } else {
                if (this.roleIm == 'user') {
                  chatType = 'groupChat'
                  targetId = this.userInfo.chatroomId
                } else {
                  chatType = 'singleChat'
                  targetId = this.serveInfo.id
                }
              }

              let option = {
                // 消息类型。
                type: 'img',
                file: file,
                ext: {
                  // 图片文件长度，单位为字节。
                  file_length: file.data.size,
                },
                // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
                to: targetId,
                // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
                chatType: chatType,
                // 图片文件上传失败。
                onFileUploadError: function () {
                  console.log('onFileUploadError')
                },
                // 图片文件上传进度。
                onFileUploadProgress: function (e) {
                  console.log(e)
                },
                // 图片文件上传成功。
                onFileUploadComplete: function () {
                  console.log('onFileUploadComplete')
                },
                thumbnailHeight: 200,
                thumbnailWidth: 200,
              }
              // 创建一条图片消息。
              let msg = WebIM.message.create(option)
              // 调用 `send` 方法发送该图片消息。
              setTimeout((res) => {
                conn
                  .send(msg)
                  .then((res) => {
                    that.sendMessageSuccessful(res.message, targetId, 'img')
                  })
                  .catch((e) => {
                    console.log('Fail')
                  })
              }, 1000)
            }
          })
        } else {
          let input = document.getElementById('image')

          let file = WebIM.utils.getFileUrl(input)
          let allowType = {
            jpg: true,
            gif: true,
            png: true,
            bmp: true,
          }
          console.log(file)
          if (file.filetype.toLowerCase() in allowType) {
            let chatType = ''
            let targetId = ''

            if (Object.keys(this.singleChatInfo).length != 0) {
              chatType = 'singleChat'
              if (this.singleChatInfo.userId == this.user) {
                targetId = this.singleChatInfo.consultId
              } else {
                targetId = this.singleChatInfo.userId
              }
            } else {
              if (this.roleIm == 'user') {
                chatType = 'groupChat'
                targetId = this.userInfo.chatroomId
              } else {
                chatType = 'singleChat'
                targetId = this.serveInfo.id
              }
            }

            let option = {
              // 消息类型。
              type: 'img',
              file: file,
              ext: {
                // 图片文件长度，单位为字节。
                file_length: file.data.size,
              },
              // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
              to: targetId,
              // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
              chatType: chatType,
              // 图片文件上传失败。
              onFileUploadError: function () {
                console.log('onFileUploadError')
              },
              // 图片文件上传进度。
              onFileUploadProgress: function (e) {
                console.log(e)
              },
              // 图片文件上传成功。
              onFileUploadComplete: function () {
                console.log('onFileUploadComplete')
              },
              thumbnailHeight: 200,
              thumbnailWidth: 200,
            }
            // 创建一条图片消息。
            let msg = WebIM.message.create(option)
            // 调用 `send` 方法发送该图片消息。
            conn
              .send(msg)
              .then((res) => {
                // that.messageList.push(res.message);
                // setTimeout(() => {
                // 	that.handleScroll()
                // }, 200)
                console.log(res.message)
                // 图片文件成功发送。
                that.sendMessageSuccessful(res.message, targetId, 'img')
              })
              .catch((e) => {
                // 图片文件发送失败。
                console.log('Fail')
              })
          }
        }
      },

      //成功发送消息，进行消息加入到聊天信息数组中
      sendMessageSuccessful(data, toID, type) {
        console.log(data)
        let userMsg = {}
        userMsg.to = toID
        userMsg.from = this.user
        userMsg.msg = data
        if (!this.isEmpty(data.url)) {
          userMsg.url = data.url
        }

        userMsg.time = new Date().getTime()
        userMsg.msgType = type
        userMsg.type = type
        //存储信息
        console.log(userMsg)
        this.messageList.push(userMsg)

        // 进行本地存储
        localStorage.setItem(
          toID,
          JSON.stringify({
            msgList: this.messageList,
            unRead: '',
          })
        )
        setTimeout(() => {
          this.handleScroll()
        }, 200)
      },
      // 集成收到文本信息方法
      getMessage() {
        let that = this
        conn.listen({
          onOpened: function (message) {
            console.log('用户已上线') // 连接成功
          },
          onError: function (error) {
            console.log('进入error')
            console.log(error)
            // that.loginIM()
          },

          onTextMessage: function (message) {
            if (message.from == that.$store.state.userInfo.id) {
              return
            }
            that.music = true
            setTimeout(() => {
              that.music = false
            }, 1000)

            // 普通文本信息
            let value = {
              type: 'txt',
              id: message.id,
              msg: message.data,
              to: message.to,
              from: message.from,
              time: Number(message.time),
              chatType: message.type,
            }
            if (!that.isEmpty(message.ext.profile)) {
              value.profile = message.ext.profile
            }

            if (
              value.msg.indexOf('发货完成') != -1 ||
              value.msg.indexOf('交易成功') != -1 ||
              value.msg.indexOf('成功') != -1 ||
              value.msg.indexOf('交易取消') != -1 ||
              value.msg.indexOf('申请理赔') != -1 ||
              value.msg.indexOf('完成售后') != -1 ||
              value.msg.indexOf('支付订单成功') != -1 ||
              value.msg.indexOf('处理订单') != -1 ||
              value.msg.indexOf('申请售后') != -1 ||
              value.msg.indexOf('等待买家付款') != -1 ||
              value.msg.indexOf('接单成功') != -1 ||
              value.msg.indexOf('接单成功') != -1 ||
              value.msg.indexOf('申请取消') != -1 ||
              value.msg.indexOf('开始代练') != -1 ||
              value.msg.indexOf('代练完成') != -1 ||
              value.msg.indexOf('已支付代练费用') != -1
            ) {
              setTimeout(() => {
                that.search.pageNo = 1
                if (that.leftList[that.leftAction].value == 1 || that.leftList[that.leftAction].value == 2 || that.leftList[that.leftAction].value == 3) {
                  that.leftType = that.leftList[that.leftAction].value
                  that.getOrderList()
                } else if (that.leftList[that.leftAction].value == 4) {
                  that.getBuyerOrder()
                } else if (that.leftList[that.leftAction].value == 5) {
                  that.getSaleOrderList()
                }
              }, 1500)
            }
            if (that.userInfo.chatroomId == message.to) {
              if (
                value.msg.indexOf('发货完成') != -1 ||
                value.msg.indexOf('交易成功') != -1 ||
                value.msg.indexOf('支付成功') != -1 ||
                value.msg.indexOf('交易取消') != -1 ||
                value.msg.indexOf('申请理赔') != -1 ||
                value.msg.indexOf('完成售后') != -1 ||
                value.msg.indexOf('收货角色') != -1 ||
                value.msg.indexOf('处理订单') != -1 ||
                value.msg.indexOf('申请售后') != -1 ||
                value.msg.indexOf('等待买家付款') != -1 ||
                value.msg.indexOf('接单成功') != -1 ||
                value.msg.indexOf('申请取消') != -1 ||
                value.msg.indexOf('开始代练') != -1 ||
                value.msg.indexOf('代练完成') != -1 ||
                value.msg.indexOf('已支付代练费用') != -1 ||
                value.msg.indexOf('代练角色名') != -1 ||
                value.msg.indexOf('申请客服') != -1
              ) {
                setTimeout(() => {
                  that.queryDetail(that.userInfo.id)
                  that.getTradeMessageList(that.userInfo.id)
                }, 1500)
              }

              that.messageList.push(value) // 添加到聊天信息数组中
              if (localStorage.getItem(message.to) == null) {
                localStorage.setItem(
                  message.to,
                  JSON.stringify({
                    msgList: that.messageList,
                    unRead: '',
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.to))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.to, JSON.stringify(oldDate))
              }
              setTimeout(() => {
                that.handleScroll()
              }, 300)

              return
            }

            if (message.type == 'groupchat' || message.type == 'groupChat') {
              if (that.roleIm == 'user' && message.to == that.userInfo.chatroomId) {
                that.messageList.push(value) // 添加到聊天信息数组中
                if (localStorage.getItem(message.to) == null) {
                  localStorage.setItem(
                    message.to,
                    JSON.stringify({
                      msgList: that.messageList,
                      unRead: '',
                    })
                  )
                } else {
                  let oldDate = JSON.parse(localStorage.getItem(message.to))
                  oldDate.msgList = [...oldDate.msgList, ...[value]]
                  oldDate.unRead = ''
                  localStorage.setItem(message.to, JSON.stringify(oldDate))
                }
                if (that.userInfo.chatroomId == message.ext.chatroomId) {
                  setTimeout(() => {
                    that.handleScroll()
                  }, 300)
                }

                return
              }
              console.log(message.to)
              // 进行本地存储
              if (localStorage.getItem(message.to) == null) {
                console.log('进行本地存储')
                // 进行本地存储
                localStorage.setItem(
                  message.to,
                  JSON.stringify({
                    msgList: [value],
                    unRead: 1,
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.to))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = Number(oldDate.unRead) + 1
                localStorage.setItem(message.to, JSON.stringify(oldDate))
              }
            } else {
              if (that.roleIm == 'service' && message.from == that.serveInfo.id) {
                that.messageList.push(value) // 添加到聊天信息数组中
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.from, JSON.stringify(oldDate))
                setTimeout(() => {
                  that.handleScroll()
                }, 300)
                return
              }

              if (message.from == that.singleChatInfo.userId || message.from == that.singleChatInfo.consultId) {
                that.messageList.push(value) // 添加到聊天信息数组中
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.from, JSON.stringify(oldDate))
                // setTimeout(() => {
                // 	that.handleScroll()
                // }, 300)
                return
              }

              // 进行本地存储
              if (localStorage.getItem(message.from) == null) {
                console.log('进行本地存储')
                // 进行本地存储
                localStorage.setItem(
                  message.from,
                  JSON.stringify({
                    msgList: [value],
                    unRead: 1,
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = Number(oldDate.unRead) + 1
                localStorage.setItem(message.from, JSON.stringify(oldDate))
              }
            }

            that.$forceUpdate()
          },
          onPictureMessage: function (message) {
            if (message.from == that.$store.state.userInfo.id) {
              return
            }
            let value = {
              type: 'img',
              id: message.id,
              url: message.url,
              to: message.to,
              from: message.from,
              time: Number(message.time),
              chatType: message.type,
            }
            if (!that.isEmpty(message.ext.profile)) {
              value.profile = message.ext.profile
            }

            // that.messageList.push(value) // 添加到聊天信息数组中
            if (message.type == 'groupchat' || message.type == 'groupChat') {
              if (that.roleIm == 'user' && message.to == that.userInfo.chatroomId) {
                that.messageList.push(value) // 添加到聊天信息数组中
                if (localStorage.getItem(message.to) == null) {
                  localStorage.setItem(
                    message.to,
                    JSON.stringify({
                      msgList: that.messageList,
                      unRead: '',
                    })
                  )
                } else {
                  let oldDate = JSON.parse(localStorage.getItem(message.to))
                  oldDate.msgList = [...oldDate.msgList, ...[value]]
                  oldDate.unRead = ''
                  localStorage.setItem(message.to, JSON.stringify(oldDate))
                }
                if (that.userInfo.chatroomId == message.ext.chatroomId) {
                  setTimeout(() => {
                    that.handleScroll()
                  }, 300)
                }

                return
              }
              // 进行本地存储
              if (localStorage.getItem(message.to) == null) {
                console.log('进行本地存储')
                // 进行本地存储
                localStorage.setItem(
                  message.to,
                  JSON.stringify({
                    msgList: [value],
                    unRead: 1,
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.to))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = Number(oldDate.unRead) + 1
                localStorage.setItem(message.to, JSON.stringify(oldDate))
              }
            } else {
              if (that.roleIm == 'service' && message.from == that.serveInfo.id) {
                that.messageList.push(value) // 添加到聊天信息数组中
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.from, JSON.stringify(oldDate))
                // setTimeout(() => {
                // 	that.handleScroll()
                // }, 300)
                return
              }

              if (message.from == that.singleChatInfo.userId || message.from == that.singleChatInfo.consultId) {
                that.messageList.push(value) // 添加到聊天信息数组中
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.from, JSON.stringify(oldDate))
                setTimeout(() => {
                  that.handleScroll()
                }, 300)
                return
              }

              // 进行本地存储
              if (localStorage.getItem(message.from) == null) {
                console.log('进行本地存储')
                // 进行本地存储
                localStorage.setItem(
                  message.from,
                  JSON.stringify({
                    msgList: [value],
                    unRead: 1,
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = Number(oldDate.unRead) + 1
                localStorage.setItem(message.from, JSON.stringify(oldDate))
              }
            }

            that.$forceUpdate()
          },
          onVideoMessage: function (message) {
            if (message.from == that.$store.state.userInfo.id) {
              return
            }
            console.log('收到视频消息')
            console.log(message)
            let value = {
              type: 'video',
              id: message.id,
              url: message.url,
              to: message.to,
              from: message.from,
              time: Number(message.time),
              chatType: message.type,
            }
            if (!that.isEmpty(message.ext.profile)) {
              value.profile = message.ext.profile
            }

            // that.messageList.push(value) // 添加到聊天信息数组中
            if (message.type == 'groupchat' || message.type == 'groupChat') {
              if (that.roleIm == 'user' && message.to == that.userInfo.chatroomId) {
                that.messageList.push(value) // 添加到聊天信息数组中
                if (localStorage.getItem(message.to) == null) {
                  localStorage.setItem(
                    message.to,
                    JSON.stringify({
                      msgList: that.messageList,
                      unRead: '',
                    })
                  )
                } else {
                  let oldDate = JSON.parse(localStorage.getItem(message.to))
                  oldDate.msgList = [...oldDate.msgList, ...[value]]
                  oldDate.unRead = ''
                  localStorage.setItem(message.to, JSON.stringify(oldDate))
                }
                if (that.userInfo.chatroomId == message.ext.chatroomId) {
                  setTimeout(() => {
                    that.handleScroll()
                  }, 300)
                }

                return
              }
              // 进行本地存储
              if (localStorage.getItem(message.to) == null) {
                console.log('进行本地存储')
                // 进行本地存储
                localStorage.setItem(
                  message.to,
                  JSON.stringify({
                    msgList: [value],
                    unRead: 1,
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.to))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = Number(oldDate.unRead) + 1
                localStorage.setItem(message.to, JSON.stringify(oldDate))
              }
            } else {
              if (that.roleIm == 'service' && message.from == that.serveInfo.id) {
                that.messageList.push(value) // 添加到聊天信息数组中
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.from, JSON.stringify(oldDate))
                // setTimeout(() => {
                // 	that.handleScroll()
                // }, 300)
                return
              }

              if (message.from == that.singleChatInfo.userId || message.from == that.singleChatInfo.consultId) {
                that.messageList.push(value) // 添加到聊天信息数组中
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = ''
                localStorage.setItem(message.from, JSON.stringify(oldDate))
                setTimeout(() => {
                  that.handleScroll()
                }, 300)
                return
              }

              // 进行本地存储
              if (localStorage.getItem(message.from) == null) {
                console.log('进行本地存储')
                // 进行本地存储
                localStorage.setItem(
                  message.from,
                  JSON.stringify({
                    msgList: [value],
                    unRead: 1,
                  })
                )
              } else {
                let oldDate = JSON.parse(localStorage.getItem(message.from))
                oldDate.msgList = [...oldDate.msgList, ...[value]]
                oldDate.unRead = Number(oldDate.unRead) + 1
                localStorage.setItem(message.from, JSON.stringify(oldDate))
              }
            }

            that.$forceUpdate()
          },
        })
      },
      // 获取会话列表
      getHistoryMessages(id, type) {
        console.log('获取会话消息列表', this.userInfo)
        // this.loadLocalMsg();
        this.loadRemoteMsg()
        // this.messageList = []
        // conn.getHistoryMessages({
        // 	targetId: id, // 单聊为对端用户 ID，群组聊天为群组 ID。
        // 	chatType: type, // 会话类型：单聊、群组聊天和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
        // 	pageSize: 20, // 每次获取的消息数量，取值范围为 [1,50]，默认值为 `20`。
        // 	searchDirection: 'up', // 消息搜索方向。`up` 表示按消息时间戳递减的方向获取，即先获取最新消息；`down` 表示按消息时间戳递增的方向获取，即先获取最老的消息。
        // }).then(res => {
        // 	this.messageList = res.messages.reverse()
        // 	setTimeout(() => {
        // 		this.handleScroll()
        // 	}, 800)
        // })
        // // 如果有缓存取缓存
        // if (localStorage.getItem(id) == null) {
        //
        // } else {
        //
        // 	let oldDate = JSON.parse(localStorage.getItem(id))
        // 	// console.log('oldDate')
        // 	// console.log(oldDate)
        // 	// this.messageList = oldDate.msgList
        //
        // 	oldDate.unRead = ''
        // 	localStorage.setItem(id, JSON.stringify(oldDate));
        // 	// setTimeout(() => {
        // 	// 	this.handleScroll()
        // 	// }, 800)
        // }
      },

      //从服务器加载消息 分页
      loadRemoteMsg(scroll = true) {
        if (!this.userInfo.pageNo) {
          this.userInfo.pageNo = 1
        }
        if (this.userInfo.noHasMore) {
          this.message('没有更多的记录', 'error')
          return
        }
        pageList({ targetId: this.userInfo.userId, sessionId: this.userInfo.id, pageNo: this.userInfo.pageNo }).then((res) => {
          res = res.result
          let arr = res.records
          if (arr.length < res.size) {
            this.userInfo.noHasMore = true
          }
          for (let item of arr) {
            item.createTime = Date.parse(item.createTime)
          }
          this.chatMsgHandle(arr)
          if (scroll) {
            this.handleScroll()
          }
        })
      },

      //消息合并
      chatMsgHandle(arr) {
        //消息已读
        this.readFlag(arr)
        //加入旧消息
        arr.push(...this.messageList)
        //消息去重
        arr = this.distinct(arr)
        //消息排序
        arr.sort((a, b) => {
          return a.createTime - b.createTime
        })

        this.messageList = arr
      },

      //消息去重
      distinct(msgArr) {
        let arr = []
        let idMap = {}
        for (let msg of msgArr) {
          if (idMap[msg.id]) {
            continue
          }
          arr.push(msg)
          idMap[msg.id] = true
        }
        return arr
      },

      //消息 已读
      readFlag(msgArr) {
        let ids = msgArr
          .filter((i) => {
            return i.fromUserId !== this.user && !i.readFlag
          })
          .map((i) => {
            return i.id
          })
          .join(',')
        if (!ids) {
          return
        }
        readMsg({ ids: ids }).then((res) => {})
      },

      chatScroll(event) {
        const { scrollTop, clientHeight, scrollHeight } = event.target
        // 当滚动到底部时，距离顶部+自身高度 >= 总高度
        this.chatScrollLock = scrollTop + clientHeight < scrollHeight - 50
      },
      handleScroll() {
        if (this.chatScrollLock) {
          return
        }
        setTimeout(() => {
          if (Object.keys(this.userInfo).length === 0) {
            return
          }
          console.log('处理滚动', this.$refs.chatCenter.scrollTop, this.$refs.chatCenter.scrollHeight, this.$refs.chatCenter.offsetHeight)
          this.$refs.chatCenter.scrollTop = this.$refs.chatCenter.scrollHeight - this.$refs.chatCenter.offsetHeight - 0
          // this.$refs.chatCenter.scrollTop = this.$refs.chatCenter.scrollHeight;
        }, 20)
      },

      // 选择表情
      selectEmoji(item) {
        if (this.chatValue == null) {
          this.chatValue = ''
        }
        this.chatValue += item
        this.$refs.chatValue.focus()
      },
      renderTxt(txt = '') {
        let rnTxt = []
        let match = null
        const regex = /(\[.*?\])/g
        let start = 0
        let index = 0
        while ((match = regex.exec(txt))) {
          index = match.index
          if (index > start) {
            rnTxt.push(txt.substring(start, index))
          }
          if (match[1] in emoji.obj) {
            const v = emoji.obj[match[1]]
            rnTxt.push(this.customEmoji(v))
          } else {
            rnTxt.push(match[1])
          }
          start = index + match[1].length
        }
        rnTxt.push(txt.substring(start, txt.length))

        return rnTxt.toString().replace(/,/g, '')
      },
      customEmoji(value) {
        return `<img src="/faces/${value}"  style="width:20px"/>`
      },
      chatCenter() {
        console.log('chatCenter', this.isLoading)
        this.userInfo.pageNo++
        this.loadRemoteMsg(false)
      },
      checkBottom(event) {
        const { scrollTop, scrollHeight, clientHeight } = event.target
        return scrollTop <= 5 // 5px的缓冲区
      },
      // 提醒卖家发货
      remind(type) {
        if (this.totalSeconds == 0 || this.totalSeconds == '') {
          voiceCall({
            id: this.userInfo.id,
            type: type,
          }).then((res) => {
            if (res.code == 200) {
              this.message('提醒成功', 'success')
              this.totalSeconds = 180
              this.startCountdown()
            }
          })
        }
      },
      // 确认收货
      configStart() {
        if (this.payPass == '') {
          this.message('请输入支付密码', 'error')
        }
        if (this.confirmType == 'completedOrder') {
          conform({
            id: this.userInfo.id,
            password: this.payPass,
          }).then((res) => {
            if (res.code == 200) {
              if (this.userInfo.gameCommodityCategory == 2 || this.userInfo.gameCommodityCategory == 8) {
                this.message('代练结束', 'success')
              } else {
                this.message('收货成功', 'success')
              }

              this.queryDetail(this.userInfo.id)
              this.confirmShow = false
              this.payPass = ''
            }
          })
        } else {
          queryDetailAll({
            id: this.userInfo.id,
            password: this.payPass,
          }).then((res) => {
            if (res.code == 200) {
              this.message('验证成功', 'success')
              this.userInfo = res.result
              this.confirmShow = false
              this.payPass = ''
            }
          })
        }
      },
      //卖家发货
      deliver(type) {
        if (this.userInfo.receiveRole == null && (this.userInfo.orderType == 3 || this.userInfo.orderType == 5)) {
          this.message('买家未填写收货角色', 'error')
          return
        }
        this.$confirm('请确保您已发货完成！若未发货请关闭此弹窗，发货完成后再点击发货按钮！')
          .then((_) => {
            updateStatus({
              orderId: this.userInfo.id,
              status: type,
            }).then((res) => {
              if (res.code == 200) {
                this.message('操作成功', 'success')
              }
            })
          })
          .catch((_) => {})
      },
      deliver1(type) {
        if (this.userInfo.receiveRole == null) {
          this.message('未填写代练角色', 'error')
          return
        }
        if (type == 2) {
          this.$confirm('请确认协商完毕，发生纠纷将以聊天记录和订单内容为辅助证据')
            .then((_) => {
              updateStatus({
                orderId: this.userInfo.id,
                status: type,
              }).then((res) => {
                if (res.code == 200) {
                  this.message('操作成功', 'success')
                  // this.userInfo.status = type
                  // this.tableData[this.userInfoIndex].status = type
                }
              })
            })
            .catch((_) => {})
        } else if (type == 3) {
          this.$confirm('请确认您已代练完成，并截图或录制完工视频发送聊天窗口/保存本地，发生纠纷将作为佐证')
            .then((_) => {
              updateStatus({
                orderId: this.userInfo.id,
                status: type,
              }).then((res) => {
                if (res.code == 200) {
                  this.message('操作成功', 'success')
                  // this.userInfo.status = type
                  // this.tableData[this.userInfoIndex].status = type
                }
              })
            })
            .catch((_) => {})
        } else {
          updateStatus({
            orderId: this.userInfo.id,
            status: type,
          }).then((res) => {
            if (res.code == 200) {
              this.message('操作成功', 'success')
              // this.userInfo.status = type
              // this.tableData[this.userInfoIndex].status = type
            }
          })
        }
      },
      dataFilter(val) {
        this.roleValue = val
      },
      dataFilter1(val) {
        this.configRoleValue = val
      },
      customerService() {
        console.log(this.userInfo)
        if (this.userInfo.status == 4 || this.userInfo.status == 5 || this.userInfo.status == 6 || this.userInfo.status == -1) {
          if (this.userInfo.afterSellServiceId == null) {
            this.applyforValue = ''
            this.applyforShow = true
            return
          } else {
            this.message('已申请客服介入', 'success')
            // this.serveInfo = {
            // 	id: this.userInfo.afterSellServiceId
            // }
            // this.roleIm = 'service'
            // this.getHistoryMessages(this.serveInfo.id, 'singleChat')
          }
        } else {
          if (this.userInfo.isService == 0) {
            this.applyforValue = ''
            this.applyforShow = true
          } else {
            // this.serveInfo = {
            // 	id: this.userInfo.serviceId
            // }
            // this.roleIm = 'service'
            // this.getHistoryMessages(this.serveInfo.id, 'singleChat')
            this.message('已申请客服介入', 'success')
          }
        }
      },
      applyfor() {
        let that = this
        if (this.applyforValue == '') {
          this.message('请输入申请原因', 'error')
          return
        }
        if (this.userInfo.status == 4 || this.userInfo.status == 5 || this.userInfo.status == -1) {
          updateStatus({
            orderId: this.userInfo.id,
            applyReason: this.applyforValue,
            status: 5,
          }).then((res) => {
            if (res.code == 200) {
              this.applyforShow = false

              // this.userInfo.status = 5
              // this.tableData[this.userInfoIndex].status = 5
              let toID = this.userInfo.chatroomId
              let type = 'groupChat'

              //收信息用户id
              let id = conn.getUniqueId() // 生成本地消息id
              let msg = new WebIM.message('txt', id) // 创建文本消息
              let countMsg = '申请客服介入原因:' + this.applyforValue
              msg.set({
                msg: countMsg, // 消息内容
                to: toID, // 接收消息对象（用户id）
                chatType: type, // 设置为单聊
                ext: {
                  type: type,
                  chatroomId: toID,
                },
                success: function (id, serverMsgId) {
                  console.log('成功发送消息')
                  that.sendMessageSuccessful(countMsg, toID, 'txt')
                },
                fail: function (e) {
                  console.log('发送消息失败')
                },
              })
              conn.send(msg.body)
              this.applyforValue = ''
            }
          })
        } else {
          applyService({
            orderId: this.userInfo.id,
            applyReason: this.applyforValue,
          }).then((res) => {
            if (res.code == 200) {
              this.applyforShow = false

              // this.userInfo.isService = 1
              let toID = this.userInfo.chatroomId
              let type = 'groupChat'

              //收信息用户id
              let id = conn.getUniqueId() // 生成本地消息id
              let msg = new WebIM.message('txt', id) // 创建文本消息
              let countMsg = '申请客服介入原因:' + this.applyforValue
              msg.set({
                msg: countMsg, // 消息内容
                to: toID, // 接收消息对象（用户id）
                chatType: type, // 设置为单聊
                ext: {
                  type: type,
                  chatroomId: toID,
                },
                success: function (id, serverMsgId) {
                  console.log('成功发送消息')
                  that.sendMessageSuccessful(countMsg, toID, 'txt')
                },
                fail: function (e) {
                  console.log('发送消息失败')
                },
              })
              conn.send(msg.body)
              this.applyforValue = ''
            }
          })
        }
      },
      // customerAfterSale() {
      // 	this.serveInfo = {
      // 		id: this.userInfo.afterSellServiceId
      // 	}
      // 	this.roleIm = 'service'
      // 	this.getHistoryMessages(this.serveInfo.id, 'singleChat')
      // },
      getUnRead(id) {
        if (localStorage.getItem(id) == null) {
          return ''
        } else {
          let oldDate = JSON.parse(localStorage.getItem(id))
          return oldDate.unRead
        }
      },
      getUnReads(id1, id2) {
        let num1 = 0
        let num2 = 0
        if (localStorage.getItem(id1) != null) {
          let oldDate = JSON.parse(localStorage.getItem(id1))
          num1 = oldDate.unRead
        }
        if (localStorage.getItem(id2) != null) {
          let oldDate = JSON.parse(localStorage.getItem(id2))
          num2 = oldDate.unRead
        }
        let num3 = num1 + num2
        if (num3 == 0) {
          return ''
        }
        return num3
      },
      // 关闭聊天框
      closeChat(item, index) {
        // if (this.$store.state.serviceRole == 3) {
        closeConsultById({
          id: item.id,
          status: 1,
        }).then((res) => {
          if (res.code == 200) {
            this.tableData.splice(index, 1)
            if (this.userInfo.id == item.id) {
              this.userInfo = {}
            }
          }
        })
        // } else {
        // 	closeById({
        // 		id: item.id,
        // 		status: 1
        // 	}).then(res => {
        // 		if (res.code == 200) {
        // 			this.tableData.splice(index, 1)
        // 			if (this.userInfo.id == item.id) {
        // 				this.userInfo = {}
        // 			}
        // 		}
        // 	})
        // }
      },
      getSingleUnReads(id) {
        let num1 = 0
        if (localStorage.getItem(id) != null) {
          let oldDate = JSON.parse(localStorage.getItem(id))
          num1 = oldDate.unRead
        }
        if (num1 == 0) {
          return ''
        }
        return num1
      },
      // 设置提示音
      setWarning(value) {
        this.isWarning = value
        localStorage.setItem('chatPromptTone', value)
      },
      onCopy() {
        this.message('复制成功', 'success')
      },
      // 触底分页
      addOrder(event) {
        const { scrollTop, clientHeight, scrollHeight } = event.target
        // 当滚动到底部时，距离顶部+自身高度 >= 总高度
        if (scrollTop + clientHeight > scrollHeight - 50) {
          if (this.scrollLock) {
            return
          }
          this.scrollLock = true
          let len1 = this.tableData.length
          // 触发刷新操作
          // 处理左侧点击菜单
          this.search.pageNo++
          this.getConsultList().finally(() => {
            let len2 = this.tableData.length
            if (len2 > len1) {
              this.scrollLock = false
            }
          })
        }
      },
      // 取消订单弹框
      cancelOrder() {
        if (this.userInfo.userMessageId != this.user) {
          this.causeType = 1
          this.causeValue = ''
        } else {
          this.causeType = 1
          this.causeValue = ''
        }
        this.getCancelReasonList()
        this.cancelVisible = true
      },
      // 取消订单
      cancelState() {
        if (this.checkSubmitFlg) {
          return
        }
        this.checkSubmitFlg = true
        setTimeout(() => {
          this.checkSubmitFlg = false
        }, 2500)
        this.cancelVisible = false
        // 卖方
        if (this.userInfo.userMessageId != this.user) {
          cancelOrderSale({
            cancelReason: this.causeValue,
            orderId: this.userInfo.id,
            reasonType: this.causeType,
          }).then((res) => {
            if (res.code == 200) {
              // this.message('取消成功', 'success')
              this.queryDetail(this.userInfo.id)
            }
          })
        } else {
          // 买方
          cancelOrder({
            cancelReason: this.causeValue,
            orderId: this.userInfo.id,
            reasonType: this.causeType,
          }).then((res) => {
            if (res.code == 200) {
              // this.message('取消成功', 'success')
              this.queryDetail(this.userInfo.id)
            }
          })
        }
      },
      // 刷新订单
      queryDetail(id) {
        queryDetail({
          id: id,
        }).then((res) => {
          if (res.code == 200) {
            this.userInfo = res.result
            if (this.userInfoIndex != '') {
              this.tableData[this.userInfoIndex] = this.userInfo
            }
            this.cancelVisible = false
            receiveRoleList({
              districtId: this.userInfo.districtId,
              spannedId: '',
              pageNo: '1',
              pageSize: '1000',
            }).then((res) => {
              if (res.code == 200) {
                this.roleList = res.result.records
              }
            })

            if (this.userInfo.status == 0) {
              // 下单时间
              let date = new Date(this.userInfo.payOvertime) / 1000
              // 当前时间
              let date1 = new Date().getTime() / 1000
              let newData = Number(date.toFixed(0) - date1.toFixed(0))
              if (newData > 0) {
                this.totalSeconds = newData
                this.startCountdown()
              } else {
                this.totalSeconds = ''
              }
            } else {
              // 下单时间
              let date = new Date(this.userInfo.remindTime) / 1000
              // 当前时间
              let date1 = new Date().getTime() / 1000
              let newData = Number(180 - (date1.toFixed(0) - date.toFixed(0)))
              if (newData > 0) {
                this.totalSeconds = newData
                this.startCountdown()
              } else {
                this.totalSeconds = ''
              }
            }
            let configId = 52
            // 热卖
            if (this.userInfo.isHot == 1) {
              configId = 54
              // 寄售
            } else if (this.userInfo.deliveryType == 2) {
              configId = 53
              // 收货
            } else if (this.userInfo.orderType == 3) {
              configId = 51
              // 代练
            } else if (this.userInfo.gameCommodityCategory == 2 || this.userInfo.gameCommodityCategory == 8) {
              configId = 55
              // 账号
            } else if (this.userInfo.gameCommodityCategory == 5 || this.userInfo.gameCommodityCategory == 6) {
              configId = 56
            }
            if (this.userInfo.status == 3) {
              getConfig({
                id: configId,
              }).then((time) => {
                // 下单时间
                let date = new Date(this.userInfo.deliveryTime) / 1000
                // 当前时间
                let date1 = new Date().getTime() / 1000
                let newData = 0
                if (this.userInfo.gameCommodityCategory == 2 || this.userInfo.gameCommodityCategory == 8) {
                  newData = Number(Number(time.result) * 60 * 60 - (date1.toFixed(0) - date.toFixed(0)))
                } else {
                  newData = Number(Number(time.result) * 60 - (date1.toFixed(0) - date.toFixed(0)))
                }

                if (newData > 0) {
                  this.totalSeconds1 = newData
                  this.startCountdown1()
                } else {
                  this.totalSeconds1 = ''
                }
              })
            }
          }
        })
        updateLastReplyTimeById({
          id: id,
        }).then()
      },
      // // 申请理赔弹框
      // claims() {
      //   withdrawList({
      //     pageNo: 1,
      //     pageSize: 1000,
      //     type: 1,
      //   }).then((res) => {
      //     if (res.code == 200) {
      //       this.accountList = res.result.records
      //       this.applyInsureInfo.orderId = this.userInfo.id
      //       this.applyPic = []
      //       this.applyVideo = []
      //       this.fileList = []
      //       this.applyShow = true
      //     }
      //   })
      // },
      applyClaims() {
        this.applyInsureInfo.pic = this.applyPic.join(',')
        this.applyInsureInfo.video = this.applyVideo.join(',')
        applyInsure(this.applyInsureInfo).then((res) => {
          if (res.code == 200) {
            this.message('理赔发起成功', 'success')
            this.applyShow = false
            this.queryDetail(this.userInfo.id)
          }
        })
      },
      // 上传失败事件
      handleError(e) {
        this.$message('上传失败', 'error')
      },
      // 上传成功事件
      handlePictureCardPreview(e) {
        this.applyPic.push(e.result)
      },
      // 删除图片
      handleRemove(file, fileList) {
        this.applyPic = []
        fileList.forEach((item) => {
          this.applyPic.push(item.response.result)
        })
      },

      // 上传成功事件
      handlePictureCardPreview1(e) {
        let name = e.result.split('/')
        console.log(name[name.length - 1])
        this.applyVideo.push(e.result)
        this.fileList.push({
          name: name[name.length - 1],
          url: e.result,
        })
      },
      // 删除图片
      handleRemove1(file, fileList) {
        console.log(file)
        console.log(fileList)
        this.applyVideo = []
        this.fileList = fileList
        fileList.forEach((item) => {
          this.applyVideo.push(item.response.result)
        })
      },
      setType(value) {
        this.applySearch.status = value
        this.applySearch.pageNo = 1
        this.getApplyList()
      },
      singleChat(item) {
        console.log(item)
        this.singleChatInfo = item
        if (this.user == item.userId) {
          this.getHistoryMessages(item.consultId, 'singleChat')
        } else {
          this.getHistoryMessages(item.userId, 'singleChat')
        }
      },
      // 验证角色
      configRole() {
        let that = this
        if (this.roleValue == '') {
          this.message('请填写收货角色', 'error')
          return
        }
        if (this.roleValue != this.configRoleValue) {
          this.message('两次填写不一致', 'error')
          return
        }
        saveReceiveRole({
          orderId: this.userInfo.id,
          receiveRole: this.roleValue,
        }).then((res) => {
          if (res.code == 200) {
            this.message('提交成功', 'success')
            // this.queryDetail(this.userInfo.id)

            // let toID = this.userInfo.chatroomId;
            // let type = 'groupChat'

            // //收信息用户id
            // let id = conn.getUniqueId(); // 生成本地消息id
            // let msg = new WebIM.message('txt', id); // 创建文本消息
            // let countMsg = ''
            // if (this.userInfo.gameCommodityCategory == 8 || this.userInfo
            // 	.gameCommodityCategory == 2) {
            // 	countMsg = '我已填写代练角色名'
            // } else {
            // 	countMsg = '我已填写收货角色'
            // }

            // msg.set({
            // 	msg: countMsg, // 消息内容
            // 	to: toID, // 接收消息对象（用户id）
            // 	chatType: type, // 设置为单聊
            // 	ext: {
            // 		type: type,
            // 		chatroomId: toID
            // 	},
            // 	success: function(id, serverMsgId) {
            // 		console.log('成功发送消息');
            // 		that.sendMessageSuccessful(countMsg, toID, 'txt');
            // 	},
            // 	fail: function(e) {
            // 		console.log("发送消息失败");
            // 	}
            // });
            // conn.send(msg.body);
          }
        })
      },
      closeImage() {
        this.isShowPics = false
        this.isShowPics1 = false
        this.isShowPics2 = false
      },
      playOptions() {
        let playOptions = {
          // height: "100%",
          // width: "100%",
          playbackRates: [1.0], // 可选的播放速度
          autoplay: true, // 如果为true,浏览器准备好时开始回放
          muted: false, // 默认情况下静音播放
          loop: false, // 是否视频一结束就重新开始
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据，auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值，值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小，换句话说，它将按比例缩放以适应其容器
          sources: [
            {
              type: 'video/mp4', // 类型
              src: this.showVideo[this.showVideoIndex], // url地址，在使用本地的资源时，需要用require()引入，否则控制台会报错
            },
          ],

          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息
          controlBar: {
            currentTimeDisplay: true,
            progressControl: true, // 是否显示进度条
            playbackRateMenuButton: true, // 是否显示调整播放倍速按钮
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: true, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        }
        return playOptions
      },
      leftsClick() {
        if (this.showVideoIndex == 0) {
        } else {
          this.showVideoIndex -= 1
        }
      },
      rightsClick() {
        if (this.showVideoIndex + 1 == this.showVideo.length) {
          // this.showVideoIndex += 1
        } else {
          this.showVideoIndex += 1
        }
      },
      downPoliy(item) {
        ePolicy({
          orderId: item.orderId,
        }).then((res) => {
          if (res.code == 200) {
            window.open(res.result, '_blank')
          }
          console.log(res)
        })
      },
      // 打开商品详情
      openLine(item) {
        // this.$router.push('/gameDetaile/' + item.gameCommodityId)
        window.open(
          this.$router.resolve({
            path: '/gameDetaile/' + item.gameCommodityId,
          }).href,
          '_blank'
        )
      },

      //获取目标用户ID
      getTargetInfo() {
        if (this.roleIm === 'user') {
          return { id: this.userInfo.userId, type: 2 }
        } else if (this.roleIm === 'buyer') {
          return { id: this.buyerInfo.id, type: 2 }
        } else if (this.roleIm === 'seller') {
          return { id: this.sellerInfo.id, type: 3 }
        }
        return null
      },
      //获取目标用户身份类型 文本
      getTargetTypeText(type) {
        switch (type) {
          case 1:
            return '担保'
          case 2:
            return '寄售'
          case 3:
            return '买家'
          case 4:
            return '咨询'
        }
      },
      //获取目标用户身份类型 1担保卖家 2寄售卖家 3买家 4咨询
      getTargetType(item) {
        if (item.targetId === item.order?.userId) {
          return 3
        }
        if (item.targetId === item.order?.sellerId) {
          return item.order?.deliveryType || item.gameCommodity?.deliveryType
        }
        return 4
      },
      //获取订单状态文本
      getOrderStatusText(status, reasonType) {
        switch (status) {
          case -1:
            return '已取消'
          case -2:
            return '已拒绝'
          case 0:
            return '待支付'
          case 1:
            return reasonType ? '申请取消' : '待发货'
          case 2:
            return '待收货'
          case 3:
            return '已发货'
          case 4:
            return '已完成'
          case 5:
            return '售后中'
          case 6:
            return '已赔付'
          case 7:
            return '已退款'
        }
        return status
      },
      //订单搜索
      searchOrder() {
        for (let item of this.tableData) {
          item.searchHide = item.order?.id.indexOf(this.search.id) === -1 && item.gameCommodity?.id.indexOf(this.search.id) === -1
        }
      },
    },
  }
</script>
<style scoped lang="scss">
  .notice {
    height: 72px;
    background: #0085ff;
  }

  .chatMain {
    width: 1566px;
    height: 952px;
    background: #f9f9f9;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
    border-radius: 20px 20px 20px 20px;
    overflow: hidden;
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .chatLeft {
    width: 112px;
    padding-bottom: 40px;
    background: #212328;
    color: #a5a7b1;
    overflow: auto;

    .leftItem {
      height: 112px;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }

    .leftItem1 {
      height: 90px;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
    }

    .leftText {
      margin-top: 8px;
    }
  }

  .chatLeft::-webkit-scrollbar {
    display: none;
  }

  .account {
    width: 372px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: #fff;
  }

  .search {
    width: 372px;
    z-index: 99;

    .searchInfo {
      margin-left: 12px;
    }
  }

  .account::-webkit-scrollbar {
    display: none;
  }

  .chatList {
    overflow: auto;
  }

  .chatList::-webkit-scrollbar {
    display: none;
  }

  .chatItem {
    margin-top: 16px;
    position: relative;
    border-radius: 12px;
    cursor: pointer;
    .chatClose {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: none;
    }
  }
  .chatItem:hover {
    .chatClose {
      position: absolute;
      bottom: 5px;
      right: 10px;
      display: block;
    }
  }

  .infoTitle {
    .infoAccount {
      font-size: 10px;
      background: #ff5c00;
      border-radius: 4px;
      color: #fff;
      padding: 1px 3px;
    }

    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
    width: 250px;
  }

  .idItem {
    font-weight: 400;
    font-size: 14px;
    color: #a5a7b1;
    margin-bottom: 5px;
  }

  .chat {
    width: 644px;

    position: relative;

    .chatTop {
      display: flex;

      .chatTopItem {
        width: 157px;
        height: 54px;
        border-radius: 4px;
        border: 1px solid #dedede;
        display: flex;
        align-items: center;
        padding-left: 16px;
        font-weight: 600;
        font-size: 16px;
        color: #0085ff;
      }

      .chatAction {
        background-color: #ecf9ff;
      }
    }
  }

  .isConsult {
    width: 1190px;
  }

  .chatCenter {
    height: 570px;
    // width: 624px;
    padding-right: 16px;
    overflow: auto;
  }

  // .chatCenter::-webkit-scrollbar {
  // 	display: none;
  // }

  .recordsLeft {
    margin-top: 20px;
    margin-left: 16px;
    display: flex;
  }

  .recordsRight {
    margin-top: 20px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .leftInfo {
    margin-left: 8px;
  }

  .rightInfo {
    display: grid;
    justify-items: end;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    margin-right: 8px;
  }

  .infoName {
    font-weight: 400;
    font-size: 16px;
    color: #0085ff;
  }

  .infoName2 {
    font-weight: 400;
    font-size: 16px;
    color: #ff5c00;
  }

  .infoTime {
    font-weight: 400;
    font-size: 16px;
    color: #a5a7b1;
    margin-left: 8px;
  }

  .infoTime1 {
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-left: 8px;
  }

  .infoCount {
    word-break: break-all; //会断词
    max-width: 296px;
    display: inline-block;

    background: #f1f1f1;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 4px;
  }

  .bottomTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bottomTips {
      font-weight: 400;
      font-size: 16px;
      color: #ff5c00;
      margin-right: 10px;
    }
  }

  .functionImage {
    width: 28px;
    height: 28px;
    margin-right: 30px;
    cursor: pointer;
  }

  ::v-deep .el-textarea__inner {
    border: none;
    margin-top: 10px;
    // width: 580px;
    height: 160px;
    background-color: #f9f9f9;
  }

  .chatArea {
    font-size: 18px;
    // height: 200px;
    color: #000;
  }

  .orderAll {
    background-color: #fff;
    width: 538px;
    overflow: auto;

    .orderTop {
      padding: 16px;

      .orderImage {
        width: 60px;
        height: 60px;
      }

      .orderTitle {
        font-weight: 600;
        font-size: 20px;
        color: #1d223c;
      }

      .orderDetaile {
        font-weight: 400;
        font-size: 16px;
        color: #a5a7b1;
      }

      .orderState {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
      }
    }

    .stateList {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #ececec;
      width: 100%;

      span {
        margin-left: 8px;
      }

      .state1 {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        height: 22px;
        padding: 4px 12px;
        background: #19d972;
        border-radius: 6px 6px 6px 6px;
        cursor: pointer;
      }

      .state2 {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        height: 22px;
        padding: 4px 12px;
        background: #ff5c00;
        border-radius: 6px 6px 6px 6px;
        cursor: pointer;
      }

      .cancelState {
        font-weight: 400;
        font-size: 14px;
        height: 22px;
        color: #777a8a;
        padding: 3px 12px;
        border: 1px solid #dedede;
        border-radius: 6px 6px 6px 6px;
        cursor: pointer;
      }
    }
  }

  .orderAll::-webkit-scrollbar {
    display: none;
  }

  .takeInfo {
    padding: 8px 12px;
    border-bottom: 1px solid #ececec;

    .takeTitle {
      font-weight: 600;
      font-size: 16px;
      color: #1d223c;
      line-height: 24px;
      margin-left: 18px;
      position: relative;
    }

    .takeTitle::before {
      position: absolute;
      content: '';
      top: 0;
      left: -15px;
      width: 3px;
      height: 24px;
      background: #ff5c00;
    }

    .takeTitle1 {
      font-weight: 600;
      font-size: 16px;
      color: #1d223c;
      line-height: 24px;
      margin-left: 18px;
      position: relative;
    }

    .takeTitle1::before {
      position: absolute;
      content: '';
      top: 0;
      left: -15px;
      width: 3px;
      height: 24px;
      background: #0085ff;
    }

    .infoItem {
      font-weight: 400;
      font-size: 16px;
      color: #a5a7b1;
      margin-top: 12px;
    }
  }

  .takeSubmit {
    height: 54px;
    background: #fafafa;
    border-radius: 6px 6px 6px 6px;
    padding-left: 12px;
    margin-top: 16px;

    .takeText {
      font-weight: 400;
      font-size: 16px;
      color: #a5a7b1;

      line-height: 40px;
    }

    .takeInput {
      width: 225px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
    }

    .takeBtn {
      width: 56px;
      height: 38px;
      padding: 0;
      margin-left: 8px;
    }
  }

  // ::v-deep .el-timeline-item__node:last-child{
  // 	border: 4px solid #FF5C00;
  // }
  ::v-deep .el-timeline {
    margin-left: -40px;
  }

  ::v-deep .el-timeline-item__tail {
    border-left: 2px dotted #e4e7ed;
  }

  .infoTitle1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #0085ff;
    width: 250px;
    cursor: pointer;
  }

  .idItems {
    font-weight: 400;
    font-size: 14px;
    color: #a5a7b1;
    margin-top: 3px;
  }

  ::v-deep .el-table th {
    background: #fafafa;
    color: #000;
  }

  .leftAction {
    background: linear-gradient(270deg, rgba(0, 133, 255, 0.3) 0%, rgba(0, 133, 255, 0) 100%);
    color: #0085ff;
  }

  .img-style {
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .bottomLeft {
    position: relative;

    .emoji {
      position: absolute;
      bottom: 60px;
      left: -100px;
      width: 300px;
      background-color: #fff;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid #e4e7ed;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    // el-popover el-popper
  }

  .dialogCenter {
    border-top: 1px solid #fafafa;
    border-bottom: 1px solid #fafafa;
    flex-direction: column;
    padding: 10px 0;

    .deleteTitle {
      font-weight: 600;
      font-size: 20px;
      color: #1d223c;
      text-align: center;
      margin: 20px;
    }

    .deleteDetaile {
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      line-height: 30px;
    }
  }

  .dialogLable {
    font-size: 16px;
    color: #000000;
    width: 100px;
    justify-content: space-between;
    margin-left: 40px;
    position: relative;
  }

  .must {
    position: relative;
  }

  .must::after {
    position: absolute;
    content: '*';
    color: red;
    top: 0;
    left: -20px;
  }

  .dialogValue {
    position: relative;

    ::v-deep .el-select-dropdown {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
    }
  }

  .formItem {
    margin-bottom: 20px;

    .submitLable {
      width: 100px;
      text-align: right;

      .lableText {
        font-weight: 400;
        font-size: 18px;
        color: #1d223c;
        line-height: 30px;
        position: relative;
        white-space: nowrap;
      }
    }

    .submitValue {
      margin: 0 20px;
      position: relative;

      .getCode {
        position: absolute;
        color: #0085ff;
        right: 10px;
        top: 10px;
      }

      ::v-deep .el-cascader {
        width: 400px;
      }

      ::v-deep .el-input__inner {
        width: 300px;
      }

      ::v-deep .el-popper {
        position: absolute !important;
        top: auto !important;
        left: auto !important;
      }

      ::v-deep .el-select-dropdown {
        position: absolute !important;
        top: auto !important;
        left: auto !important;
      }
    }

    .submitRemark {
      // width: 40%;
      margin-left: 50px;
      color: red;
    }
  }

  .chatStatus {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 80px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    opacity: 0.8;
    padding: 3px 0;
    letter-spacing: 2px;
    background-color: rgba(0, 0, 0, 0.8);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .chatStatus1 {
    position: absolute;
    top: 10px;
    right: -20px;
    width: 80px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    background: #ff5c00;
    transform: rotate(45deg);
    // opacity: 0.8;
    letter-spacing: 2px;
    overflow: hidden;
  }

  .formTip {
    font-weight: 400;
    font-size: 16px;
    color: #a5a7b1;
    line-height: 21px;
    text-align: left;
    margin-left: 20px;
  }

  .uploadText {
    font-weight: 400;
    font-size: 18px;
    color: #0085ff;
    line-height: 21px;
    margin-top: 10px;
  }

  .uploadTip {
    font-weight: 400;
    font-size: 16px;
    color: #ff5c00;
    // line-height: 30px;
  }

  .reason {
    ::v-deep .el-textarea__inner {
      background: none;
      width: 376px;
      border: 1px solid #c0c4cc;
      height: 100px;
    }
  }

  .search {
    .searchText {
      width: 100px;
      text-align: right;
      font-weight: 400;
      font-size: 18px;
      color: #1d223c;
    }
  }

  .roleTitles {
    width: 1186px;
    border-bottom: 1px solid #ececec;
    height: 67px;
    // line-height: 87px;
    padding-left: 12px;
    padding-right: 42px;
    margin-top: 30px;
    display: flex;
    align-items: center;

    .titleItem {
      font-weight: 400;
      font-size: 20px;
      position: relative;
      color: #1d223c;
      height: 30px;
      margin-right: 50px;
    }

    .titleItem::after {
      position: absolute;
      width: 1px;
      height: 20px;
      content: '';
      top: 2px;
      right: -25px;
      background-color: #dedede;
    }

    .titleItem:last-child::after {
      width: 0px;
    }

    .action {
      color: #0085ff;
      font-weight: bold;
    }
  }

  .tableList {
    margin: 20px;
    width: 1400px;
    height: 500px;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;

    .popClose {
      position: absolute;
      top: 20px;
      right: 100px;
      width: 50px;
      height: 50px;
    }

    .popLeft {
      position: absolute;
      top: 450px;
      left: 30px;
      width: 50px;
      height: 50px;
    }

    .popRight {
      position: absolute;
      top: 450px;
      right: 30px;
      width: 50px;
      height: 50px;
    }
  }

  ::v-deep .video-js.vjs-fluid {
    height: 100vh;
  }

  .chatActions {
    background-color: #e5f6ff;
  }

  .HandleDrag {
    // background-color: rgba(0, 0, 0, .6);
    // z-index: 9999;
    opacity: 0.4;
  }

  .payOvertime {
    color: #ff5c00;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 200px;
    text-align: center;
  }

  .textareaSty {
    width: 100%;
    height: 180px;
    resize: none;

    border-radius: 4px;
    overflow-y: auto;
    scrollbar-width: none;

    margin: 5px 0;
    border: none;
    background: none;
  }

  [contenteditable]:focus {
    outline: none;
  }

  .mag50 {
    margin-left: 50px;
  }

  .content {
    margin: 0 auto;
    width: 1600px;
  }

  .role {
    ::v-deep .el-input__suffix {
      display: none;
    }
    ::v-deep .el-input__inner {
      cursor: text;
    }
  }
  .allMore {
    text-align: center;
    color: #0085ff;
    margin-top: 8px;
    cursor: pointer;
  }
  .topTitle {
    line-height: 72px;
    width: 500px;
    color: #d1d1d1;
  }
</style>
