<template>
	<div>
		<div class="roleTitle">实名认证
			<span class="balance">｜按照国家要求，虚拟物品交易平台用户需要进行实名认证</span>
		</div>

		<div class="state1" v-if="state == 1">
			<div class="noAccount fl">
				<div class="accountLeft">
					<span class="account1">您未实名认证</span>
					<span class="account2">请选择以下认证方式开始认证</span>
					<div class="toAdd" @click="state = 2">
						<img :src="require('@/static/image/mine/icon3.png')"
							style="width: 24px;height: 24px;margin-right: 8px;" />
						<span>身份证号认证</span>
					</div>

					<div class="toAdd" @click="getUrl">
						<img :src="require('@/static/image/mine/icon3.png')"
								 style="width: 24px;height: 24px;margin-right: 8px;" />
						<span>人脸识别认证</span>
					</div>
					
				</div>
				<img :src="require('@/static/image/mine/noAdd1.png')"
					style="width: 170px;height: 170px;margin-right: 80px;" />
			</div>
		</div>


		<div v-if="state == 2">

			<div class="submitForm">
				<el-form :model="realInfo" :rules="realRule" ref="realForm">

					<div class="fl formItem">
						<div class="submitLable">
							<span class="lableText">真实姓名：</span>
						</div>

						<div class="submitValue" style="margin-top: 20px;">
							<el-form-item label="" prop="realName">
								<el-input v-prevent-space v-model="realInfo.realName" placeholder="请输入您的真实姓名"></el-input>
							</el-form-item>
						</div>


					</div>


					<div class="fl formItem">
						<div class="submitLable">
							<span class="lableText">身份证号：</span>
						</div>

						<div class="submitValue" style="margin-top: 20px;">
							<el-form-item label="" prop="idCard">
								<el-input v-prevent-space v-model="realInfo.idCard" placeholder="请输入您的身份证号"></el-input>
							</el-form-item>
						</div>
					</div>

				</el-form>
				<div class="fl formItem">
					<div class="submitLable">&emsp;</div>
					<div class="submitValue">
						<el-button type="primary" @click="authSubmit">立即提交</el-button>
					</div>
				</div>
			</div>

			<div class="remind">
				<div class="remind1">温馨提示：</div>
				<div class="remind2">1、当前认证的实名名字和身份证号必须真实</div>
				

			</div>
		</div>

		<!-- 实名成功 -->
		<div class="state1" v-if="state == 3">
			<div class="noAccount fl">
				<div class="accountLeft">
					<span v-if="realSuccessInfo.idCardAudit == 1 && realSuccessInfo.faceAudit == 1" class="account1">您已实人认证</span>
					<span v-else class="account1">未实人认证</span>
					<span class="account2">真实姓名：{{nameEncryption(realSuccessInfo.realName) }}</span>
					<span class="account2">身份证号：{{idCardEncryption(realSuccessInfo.idCard)}}</span>
					<span v-if="realSuccessInfo.idCardAudit == 1 && realSuccessInfo.faceAudit == 1" class="account2">认证时间：{{realSuccessInfo.createTime}}</span>
					<div class="toAdd" v-else @click="getUrl">
						<img :src="require('@/static/image/mine/icon3.png')"
							style="width: 24px;height: 24px;margin-right: 8px;" />
						<span>人脸识别认证</span>
					</div>
				</div>
				<img v-if="realSuccessInfo.idCardAudit == 1 && realSuccessInfo.faceAudit == 1" :src="require('@/static/image/mine/noAdd2.png')"
					style="width: 170px;height: 170px;margin-right: 80px;" />
					<img v-else :src="require('@/static/image/mine/noAdd1.png')"
						style="width: 170px;height: 170px;margin-right: 80px;" />
			</div>
		</div>

		<!-- 实名失败 -->
		<div class="state1" v-if="state == 4">
			<div class="noAccount fl">
				<div class="accountLeft">
					<span class="account1">认证失败</span>
					<span class="account2">您的实名信息认证失败，请点击下方按钮开始重新认证</span>
					<div class="toAdd">
						<img :src="require('@/static/image/mine/icon3.png')"
							style="width: 24px;height: 24px;margin-right: 8px;" />
						<span>人脸识别认证</span>
					</div>
				</div>
				<img :src="require('@/static/image/mine/noAdd3.png')"
					style="width: 170px;height: 170px;margin-right: 80px;" />
			</div>
		</div>


		<div class="popUp" v-if="realShow">
			<div style="width: 100%;justify-content: center;position: relative;" class="fl">
				<img @click="closeReal" style="width: 522px;height:484px;position: absolute;top: 100px;"
					:src="require('@/static/image/index/realName.png')" />
				<div class="popCount" >
					<!-- <img class="close" @click="realShow = false"  :src="require('@/static/image/index/close.png')" /> -->
					<img style="width: 240px;height: 240px;margin-top: 40px;" :src="realImage" alt="" />
					<div class="popText">可使用 微信/支付宝/浏览器<br />扫码进行人脸实人验证</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	realInfoAdd,
	realInfo,
	getLivenessToken

} from '@/api/index.js'
	export default {
		data() {
			return {
				timeInterval:null,
				realImage: '',
				state: 1,
				realShow: false,
				realInfo: {
					realName: '',
					idCard: ''
				},
				realSuccessInfo:{},
				// 验证码登录验证
				realRule: {
					realName: [{
							required: true,
							message: '请输入姓名',
							trigger: 'blur'
						},
						// {
						// 	required: true,
						// 	pattern: /^1\d{10}$/,
						// 	message: '手机号格式不正确',
						// 	trigger: 'blur'
						// }
					],
					idCard: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur'
					}]
				},
				isFaceAudit: false,
			}
		},
		created() {
			this.loadRealInfo()
		},
		beforeDestroy(){
			clearInterval(this.timeInterval)
			this.timeInterval  = null
		},
		methods: {
			authSubmit() {
				this.$refs.realForm.validate((valid) => {
					if (valid) {
						if (this.isFaceAudit) {
							this.getLivenessToken()
						} else {
							realInfoAdd(this.realInfo).then(res => {
								this.state = 3
								this.realShow = false
								this.message('实名认证成功','success')
								this.loadRealInfo()
							})
						}
					}
				})
			},
			getUrl() {
				if (this.realSuccessInfo.faceAudit === 1) {
					this.message('已经实人认证', 'error')
					return
				}
				if (this.realSuccessInfo.idCardAudit === 1) {
					this.getLivenessToken()
				} else {
					this.isFaceAudit = true
					this.state = 2
				}
			},
			getLivenessToken() {
				let url = window.location.origin + '/#/realNameResult/' + this.$store.state.userInfo.id
				getLivenessToken(Object.assign({ returnQrcode: true, returnUrl: url }, this.realInfo)).then((res) => {
					this.realImage = res.result
					this.realShow = true
					this.getResult()
				})
			},
			getResult(){
				this.timeInterval = setInterval(()=>{
					// 判断是否实名
					realInfo().then(res => {
						if ( res.result.idCardAudit == 1 && res.result.faceAudit == 1) {
							this.state = 3
							this.realShow = false
							this.message('实名认证成功','success')
							this.realSuccessInfo = res.result
							clearInterval(this.timeInterval)
							this.timeInterval  = null
						}
					})
				},3000)
				
			},
			closeReal(){
				this.realShow = false
				clearInterval(this.timeInterval)
				this.timeInterval = null
			},

			loadRealInfo() {
				// 判断是否实名
				realInfo().then(res => {
					if (res.result == null || res.result.idCardAudit == 0) {
						this.state = 1
					}else{
						this.realSuccessInfo = res.result
						this.state = 3
					}
				})
			}
			
		}


	}
</script>

<style scoped lang="scss">
	.popUp {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, .3);
		overflow: hidden;
	}

	.popCount {
		z-index: 9999;
		position: relative;
		margin-top: 260px;
		display: flex;
		flex-direction: column;
		width: 522px;
		border-radius: 24px;
		align-items: center;
		background-color: #fff;
		padding-bottom: 30px;

		.popText {
			width: 264px;
			font-weight: 400;
			font-size: 16px;
			color: #777A8A;
			text-align: center;
			margin-top: 20px;
		}

		.payOrder {
			width: 394px;
			height: 100px;
			background: #F6F8FA;
			border-radius: 12px 12px 12px 12px;
			margin-top: 40px;
			padding: 0 24px;
			color: #A5A7B1;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.roleTitle {
		font-weight: 600;
		font-size: 24px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
	}

	.noAccount {
		width: 950px;
		// height: 255px;
		padding: 12px 0;
		background: #F8F8F8;
		border-radius: 12px 12px 12px 12px;
		justify-content: space-between;
		margin-left: 42px;
		margin-top: 68px;

		.accountLeft {
			display: flex;
			flex-direction: column;
			margin-left: 40px;
		}

		.account1 {
			font-weight: 600;
			font-size: 18px;
			color: #1D223C;
		}

		.account2 {
			font-weight: 400;
			font-size: 15px;
			color: #A5A7B1;
			margin-top: 10px;

		}



	}

	.toAdd {
		width: 208px;
		height: 52px;
		border-radius: 8px 8px 8px 8px;
		border: 2px solid #0085FF;
		font-weight: 600;
		font-size: 18px;
		color: #0085FF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 36px;

	}

	.submitForm {
		margin-top: 50px;

		.formItem {
			margin-bottom: 30px;

			.submitLable {
				width: 200px;
				text-align: right;

				.lableText {
					font-weight: 400;
					font-size: 16px;
					color: #A5A7B1;
					line-height: 30px;
					position: relative;
				}

			}

			.submitValue {
				width: 336px;
				margin: 0 20px;
				position: relative;

				::v-deep .el-input__inner {
					width: 376px;
					height: 46px;
				}

				::v-deep .el-button--primary {
					width: 200px;
					height: 64px;
					font-weight: 600;
					font-size: 22px;
				}
			}

			.lableNumber {
				margin: 0 20px;


				font-weight: bold;
				font-size: 24px;
				color: #FF5C00;


			}

			.submitRemark {
				width: 40%;
				color: #A5A7B1;
			}
		}
	}

	.remind {
		margin-left: 42px;
		margin-top: 50px;

		.remind1 {
			font-weight: 600;
			font-size: 22px;
			color: #000000;
			margin-bottom: 32px;

		}

		.remind2 {
			font-weight: 400;
			font-size: 18px;
			color: #777A8A;
			margin-bottom: 28px;
		}
	}
</style>