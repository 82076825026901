<template>
	<div>
		认证结束
	</div>
</template>

<script>
import { getLivenessResult } from '@/api'

export default {
	name      : 'home',
	components: {},
	data() {
		return {}
	},
	created() {
		this.isLivenessCheckReturn()
	},
	mounted() {

	},

	methods: {
		//判断是否活体检测完成返回
		isLivenessCheckReturn() {
			let userId = this.$route.params.userId
			//检测成功
			if (this.$route.query.success) {
				getLivenessResult({userId:userId}).then((res) => {
					Object.assign(this.$store.state.userInfo, {
						idCardAudit: 1,
						faceAudit  : 1
					})
					this.$store.commit('saveUserInfo', this.$store.state.userInfo)
					this.message('认证成功', 'success')
				})
			} else {
				this.message('认证失败，请重新认证', 'error')
			}
		},
	}

}
</script>

<style lang="scss" scoped>
</style>