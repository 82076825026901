<template>
	<div>
		<div class="recordsRight" v-if="isMe">
			<div class="rightInfo">
				<div style="text-align: right;">
<!--					<span class="infoTime" style="margin-right: 10px">{{ isRead(msg.readFlag) }}</span>-->
					<span class="infoName">我</span>
					<span class="infoTime">{{ sjVal(msg.createTime, 2) }}</span>
				</div>
				<div v-if="msg.imMessageContent.text" class="infoCount" style="white-space: pre-wrap;" v-html="msg.imMessageContent.text"></div>
				<div v-if="msg.imMessageContent.img" class="infoCount">
					<el-image :preview-src-list="[msg.imMessageContent.img]" style="width: 200px;" :src="msg.imMessageContent.img" fit="cover" @load="imgLoad"></el-image>
				</div>
				<div v-if="msg.imMessageContent.video" class="infoCount">
					<video width="300" controls :src="msg.imMessageContent.video"></video>
				</div>
			</div>
		</div>
		<div class="recordsLeft" v-else>
			<div class="leftInfo">
				<div>
					<!--头像-->
					<img v-if="msg.profile != undefined" style="width: 50px;height: 50px;border-radius: 50%;margin-right: 8px;" :src="msg.profile" alt=""/>

					<span class="infoName">{{ name }}</span>
					<span class="infoTime">{{ sjVal(msg.createTime, 2) }}</span>
				</div>
				<div v-if="msg.imMessageContent.text" class="infoCount" style="white-space: pre-wrap;" v-html="msg.imMessageContent.text"></div>
				<div class="infoCount" v-if="msg.imMessageContent.img">
					<el-image :preview-src-list="[msg.imMessageContent.img]" style="width: 200px;" :src="msg.imMessageContent.img" fit="cover" @load="imgLoad"></el-image>
				</div>
				<div class="infoCount" v-if="msg.imMessageContent.video">
					<video width="300" controls :src="msg.imMessageContent.video"></video>
				</div>

				<div v-if="msg.imMessageContent.isScore" class="infoCount">
					<el-rate v-model="msg.imMessageContent.scoreNum" disabled-void-color="#C6D1DE" show-text :texts="textArr" @change="score($event, msg)"></el-rate>
				</div>
			</div>
		</div>
	</div>

</template>
<script>

import { scoreById } from '@/api/message'

export default {
	name: 'ChatMsg',
	props     : {
		isMe: {
			type   : Boolean,
			default: false,
		},
		name: {
			type   : String,
			default: ''
		},
		msg : {
			type   : Object,
			default: () => { },
		},
		imgLoad : {
			type   : Function,
			default: () => { },
		},
		
	},
	data() {
		return {
			value: null,
			textArr:[
				'非常不满意','不满意','一般','满意','非常满意'
			]
		};
	},

	created() {
		if (this.msg.imMessageContent.isScore) {
			this.value = this.msg.imMessageContent.scoreNum || 0;
		}
	},
	destroyed() {
	},
	mounted() {

	},


	methods: {
		//客服评分
		score(e, row) {
			scoreById({
				id: row.id,
				scoreNum: e
			}).then((res) => {});
		},
		
		isRead(readFlag) {
			return readFlag ? '已读' : '未读';
		},
	}
};

</script>

<style scoped lang="scss">
.recordsLeft {
	margin-top: 20px;
	margin-left: 16px;
	display: flex;

}

.recordsRight {
	margin-top: 20px;
	margin-left: 16px;
	display: flex;
	justify-content: flex-end;
}

.leftInfo {
	margin-left: 8px;

}

.rightInfo {

	display: grid;
	justify-items: end;
	/* 水平居中 */
	align-items: center;
	/* 垂直居中 */
	margin-right: 8px;

}

.infoName {
	font-weight: 400;
	font-size: 16px;
	color: #0085FF;
}

.infoName1 {
	font-weight: 400;
	font-size: 16px;
	color: #FF5C00;
}

.infoName2 {
	font-weight: 400;
	font-size: 16px;
	color: #000;
}


.infoTime {
	font-weight: 400;
	font-size: 16px;
	color: #A5A7B1;
	margin-left: 8px;
}

.infoCount {
	max-width: 296px;
	display: inline-block;

	background: #F1F1F1;
	border-radius: 8px;
	padding: 12px 16px;
	margin-top: 4px;
	word-break: break-all; //会断词

}
</style>