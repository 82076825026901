<template>
  <div style="height: 100vh">
    <div style="background-color: #000; height: 60px; line-height: 60px">
      <el-row>
        <el-col :span="2">
          <div>&emsp;</div>
        </el-col>
        <el-col :span="20">
          <div class="loginTop">
            <div>&emsp;</div>
            <div class="topRight">
              <p class="rightItem" @click="$router.push('/')">首页</p>
              <p class="rightItem" @click="$router.push('/serviceCenter/2/0')">帮助中心</p>
            </div>
          </div>
        </el-col>
        <el-col :span="2">
          <div>&emsp;</div>
        </el-col>
      </el-row>
    </div>
    <div class="loginBg">
      <img :src="require('@/static/image/newImage/beijing.png')" alt="" />
    </div>
    <div class="main">
      <div class="loginCard" v-if="type == 'login'">
        <el-card style="padding: 10px; width: 380px; border-radius: 18px">
          <div class="loginText">
            <div class="login" @click="swiperType(1)" :class="{ action: loginType == 1 }">账户登录</div>
            <div class="login1" @click="swiperType(0)" :class="{ action: loginType == 0 }">短信登录</div>
          </div>
          <div v-if="loginType == 0">
            <el-form :validate-on-rule-change="false" :model="loginForm" :rules="loginRule" ref="loginForm">
              <el-form-item label="" prop="account">
                <el-input v-prevent-space v-model="loginForm.account" placeholder="手机号">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="code">
                <div class="send">
                  <el-input v-prevent-space v-model="loginForm.code" type="text" placeholder="请输入验证码"> </el-input>
                  <span @click="getCode('1', loginForm.account)" class="getCode">{{ countDown || '获取验证码' }}</span>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="loginType == 1">
            <el-form :validate-on-rule-change="false" :model="loginPassForm" :rules="loginPassRule" ref="loginPassForm">
              <el-form-item label="" prop="account">
                <el-input v-prevent-space placeholder="手机号或登录ID" v-model="loginPassForm.account">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password" style="margin-top: -20px">
                <el-input v-prevent-space v-model="loginPassForm.password" show-password style="margin-top: 20px" placeholder="请输入登录密码"> </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="feature">
            <p class="feature1" @click="type = 'retrievePassword'">忘记密码</p>
            <p class="feature2" @click="type = 'register'">立即注册</p>
          </div>
          <el-button @click="login" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" type="primary">立即登录</el-button>
          <div class="agreement">
            <el-checkbox v-model="checked"></el-checkbox>
            <div style="font-size: 16px; white-space: normal; margin-left: 10px">
              我已阅读并同意<span style="color: #0085ff" @click="$router.push('/serviceCenter/2/1846102303710965762')">《用户协议》</span>和<span style="color: #0085ff" @click="$router.push('/serviceCenter/2/1846102498603495426')">《隐私政策》</span>
            </div>
          </div>
        </el-card>
      </div>

      <div class="loginCard" v-if="type == 'bind'">
        <el-card style="padding: 40px; width: 380px; border-radius: 18px">
          <div class="loginText">
            <div class="login action">绑定手机号</div>
          </div>
          <div>
            <el-form :validate-on-rule-change="false" :model="bindForm" :rules="bindRule" ref="bindForm">
              <el-form-item label="" prop="account">
                <el-input v-prevent-space v-model="bindForm.account" placeholder="手机号">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
            </el-form>
          </div>

          <el-button @click="bindlogin" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" type="primary">绑定手机号</el-button>
        </el-card>
      </div>

      <div class="loginCard" v-if="type == 'bindPassLogin'">
        <el-card style="padding: 40px; width: 380px; border-radius: 18px">
          <div class="loginText">
            <div class="login action">登录</div>
          </div>
          <div>
            <el-form :validate-on-rule-change="false" :model="loginPassForm" :rules="loginPassRule" ref="loginPassForm">
              <el-form-item label="" prop="account">
                <el-input v-prevent-space placeholder="手机号" v-model="loginPassForm.account">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input v-prevent-space v-model="loginPassForm.password" show-password style="margin-top: 20px" placeholder="请输入登录密码"> </el-input>
              </el-form-item>
            </el-form>
          </div>

          <el-button @click="login" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" type="primary">立即登录</el-button>
        </el-card>
      </div>

      <div class="loginCard" v-if="type == 'bindPassRegister'">
        <el-card style="padding: 40px; width: 380px; border-radius: 18px">
          <el-form :model="registerForm" :rules="registerRule" ref="registerForm">
            <div class="loginText">
              <div class="login action">注册登录</div>
            </div>
            <div>
              <el-form-item label="" prop="account">
                <el-input v-prevent-space placeholder="手机号" maxlength="11" v-model="registerForm.account">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <div class="send">
                <el-form-item label="" prop="code">
                  <el-input v-prevent-space v-model="registerForm.code" placeholder="请输入验证码"> </el-input>
                  <span @click="getCode('1', registerForm.account)" class="getCode">{{ countDown || '获取验证码' }}</span>
                </el-form-item>
              </div>
              <el-form-item label="" prop="password">
                <el-input v-prevent-space v-model="registerForm.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-prevent-space v-model="registerForm.invitationCode" placeholder="请输入邀请码"></el-input>
              </el-form-item>
            </div>
            <el-button @click="register" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" type="primary">确定注册</el-button>
          </el-form>
        </el-card>
      </div>

      <div class="loginCard" v-if="type == 'register'">
        <el-card style="padding: 40px; width: 380px; border-radius: 18px">
          <el-form :model="registerForm" :rules="registerRule" ref="registerForm">
            <div class="loginText">
              <div class="login action">手机号注册</div>
            </div>
            <div>
              <el-form-item label="" prop="account">
                <el-input v-prevent-space placeholder="手机号" maxlength="11" v-model="registerForm.account">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <div class="send">
                <el-form-item label="" prop="code">
                  <el-input v-prevent-space v-model="registerForm.code" placeholder="请输入验证码"> </el-input>
                  <span @click="getCode('1', registerForm.account)" class="getCode">{{ countDown || '获取验证码' }}</span>
                </el-form-item>
              </div>
              <el-form-item label="" prop="password">
                <el-input v-prevent-space v-model="registerForm.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-prevent-space v-model="registerForm.invitationCode" placeholder="请输入邀请码"></el-input>
              </el-form-item>
            </div>

            <div class="feature">
              <p class="feature1"></p>
              <p class="feature3" @click="type = 'login'">已有账户?立即登录</p>
            </div>
            <el-button @click="register" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" type="primary">确定注册</el-button>
            <div class="agreement">
              <el-checkbox v-model="checked"></el-checkbox>
              <div style="font-size: 16px; white-space: normal; margin-left: 10px">
                我已阅读并同意<span style="color: #0085ff" @click="$router.push('/serviceCenter/2/1846102303710965762')">《用户协议》</span>和<span style="color: #0085ff" @click="$router.push('/serviceCenter/2/1846102498603495426')">《隐私政策》</span>
              </div>
            </div>
          </el-form>
        </el-card>
      </div>

      <div class="loginCard" v-if="type == 'retrievePassword'">
        <el-card style="padding: 40px; width: 380px; border-radius: 18px">
          <div class="loginText">
            <div class="login action">找回密码</div>
          </div>
          <div style="margin-bottom: 47px; margin-top: 47px">
            <el-steps :active="step" align-center>
              <el-step title="手机号验证">
                <div slot="icon">
                  <div class="step" :class="{ stepAction: step >= 1 }">1</div>
                </div>
              </el-step>
              <el-step title="设置新密码">
                <div slot="icon">
                  <div class="step" :class="{ stepAction: step >= 2 }">2</div>
                </div>
              </el-step>
              <el-step title="设置成功">
                <div slot="icon">
                  <div class="step" :class="{ stepAction: step >= 3 }">✔</div>
                </div>
              </el-step>
            </el-steps>
          </div>
          <div v-if="step == 1">
            <el-form :model="forgetForm" :rules="forgetRule" ref="forgetForm">
              <el-form-item label="" prop="account">
                <el-input v-prevent-space v-prevent-space placeholder="手机号" v-model="forgetForm.account">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="code">
                <div class="send">
                  <el-input v-prevent-space v-prevent-space v-model="forgetForm.code" placeholder="请输入验证码"> </el-input>
                  <span @click="getCode('2', forgetForm.account)" class="getCode">{{ countDown || '获取验证码' }}</span>
                </div>
              </el-form-item>
            </el-form>
            <div class="feature">
              <p class="feature1" @click="type = 'register'">立即注册</p>
              <p class="feature2" @click="type = 'login'">去登录</p>
            </div>
          </div>
          <div v-if="step == 2">
            <el-form :model="forgetForm1" :rules="forgetRule1" ref="forgetForm1">
              <el-form-item label="" prop="password">
                <el-input v-prevent-space v-model="forgetForm1.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
              <el-form-item label="" prop="rePassword">
                <el-input v-prevent-space v-model="forgetForm1.rePassword" placeholder="请确认登录密码" show-password></el-input>
              </el-form-item>
            </el-form>
            <div class="feature">
              <p class="feature1" @click="step = '1'">上一步</p>
              <p class="feature2" @click="type = 'login'">去登录</p>
            </div>
          </div>
          <div v-if="step == 3" class="stepSuccess">
            <img :src="require('@/static/image/login/success.png')" style="width: 168px; height: 168px" />
            <span class="textSuccess">密码设置成功</span>
            <span class="textDetaile">为保障帐号安全，请不要将密码随意泄露给他人定期修改密码将提高帐号安全性</span>
          </div>

          <div class="feature">
            <div>&emsp;</div>
            <!-- <p class="feature1"></p>
						<p class="feature3" @click="type = 'login'">已有账户?立即登录</p> -->
          </div>
          <el-button @click="forgetNext(step)" v-if="step == 1 || step == 2" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" type="primary">下一步</el-button>
          <el-button @click="type = 'login'" v-if="step == 3" style="width: 100%; background-color: #0085ff; border-radius: 8px; height: 42px; font-size: 16px" loginPassRule type="primary">立即登录</el-button>
        </el-card>
      </div>
    </div>

    <slidingBlock :shoWData="shoWData" @submit="verifySubmit"></slidingBlock>
  </div>
</template>

<script>
  import { getToken, setToken, setCookie, getCookie, removeToken } from '@/utils/auth.js'
  // 导入请求方式
  import { registered, loginByCode, login, forgetPassword, wxAuth, phoneLogin } from '@/api/login.js'
  import { smsSend, updateSend } from '@/api/public.js'
  import slidingBlock from '@/components/slidingBlock.vue'
  let beforeRouter = ''
  export default {
    components: {
      slidingBlock,
    },
    data() {
      return {
        shoWData: false,
        loginType: '1',
        step: 1,
        type: 'login',
        // 倒计时
        countDown: 0,
        countdownInterval: null,
        // 绑定手机号表单
        bindForm: {
          account: '',
          code: '',
          password: '',
        },
        // 注册表单
        registerForm: {
          account: '',
          code: '',
          password: '',
          invitationCode: '',
        },
        // 验证码登录表单
        loginForm: {
          account: '',
          code: '',
        },
        // 密码登录表单
        loginPassForm: {
          account: '',
          password: '',
        },
        // 忘记密码表单
        forgetForm: {
          account: '',
          code: '',
        },
        // 忘记密码表单
        forgetForm1: {
          password: '',
          rePassword: '',
        },
        // 验证码登录验证
        forgetRule: {
          account: [
            {
              required: true,
              message: '请输入手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          code: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ],
        },
        // 密码登录验证
        loginPassRule: {
          account: [
            {
              required: true,
              message: '请输入登录手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],
        },
        // 验证码登录验证
        loginRule: {
          account: [
            {
              required: true,
              message: '请输入登录手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          code: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ],
        },
        // 注册验证
        registerRule: {
          account: [
            {
              required: true,
              message: '请输入注册手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          code: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],
        },
        // 注册验证
        bindRule: {
          account: [
            {
              required: true,
              message: '请输入注册手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          code: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],
        },
        openId: '',
        checked: false,
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        //  这里的vm指的就是vue实例，可以用来当做this使用
        // console.log(to)
        console.log(from.fullPath)
        beforeRouter = from.fullPath
      })
    },

    created() {
      if (this.$route.params.type == 'forget') {
        this.type = 'retrievePassword'
      } else if (this.$route.params.type == 'register') {
        this.type = 'register'
      } else {
        this.type = 'login'
      }
    },

    methods: {
      // 切换登录方式
      swiperType(type) {
        this.loginType = type
        if (type) {
          this.$refs['loginForm'].resetFields() // 重置form表单
        } else {
          this.$refs['loginPassForm'].resetFields() // 重置form表单
        }
      },
      getParamQueryString(url, name) {
        let subStr = name + '='
        if (url.indexOf(subStr) !== -1) {
          let arr = url.split(subStr)
          let sub1 = arr[1]
          if (sub1.indexOf('&') !== -1) {
            let arr2 = sub1.split('&')
            let sub2 = arr2[0]
            return sub2
          } else {
            return sub1
          }
        }
        return ''
      },
      // 登录
      login() {
        let ref = null
        // 短信登录
        if (this.loginType == 0) {
          ref = this.$refs.loginForm
          // 验证码登录
        } else {
          ref = this.$refs.loginPassForm
        }
        ref.validate((valid) => {
          if (!valid) {
            return
          }
          if (this.type == 'login' || this.type == 'register') {
            if (!this.checked) {
              this.message('请阅读并勾选协议', 'error')
              return
            }
          }
          this.verifySubmit(null, true)
        })
      },
      verifySubmit(e, verSuccess) {
        if (!verSuccess) {
          return
        }
        this.shoWData = false
        // 短信登录
        if (this.loginType == 0) {
          this.$refs.loginForm.validate((valid) => {
            if (valid) {
              this.$store.dispatch('loginCode', this.loginForm).then(() => {
                this.loginSuccess()
              })
            }
          })
          // 验证码登录
        } else {
          this.$refs.loginPassForm.validate((valid) => {
            if (valid) {
              if (this.openId != '') {
                this.loginPassForm.openId = this.openId
              }
              this.$store.dispatch('login', this.loginPassForm).then((res) => {
                this.loginSuccess()
              })
            }
          })
        }
      },
      loginSuccess() {
        if (!this.$store.state.userInfo.hasPwd) {
          this.message('请先设置登录密码', 'success')
          setTimeout(() => {
            this.$router.push({
              path: '/mineIndex/29',
            })
          }, 1000)
        } else {
          this.message('登陆成功', 'success')
          setTimeout(() => {
            this.$router.push({
              path: beforeRouter,
            })
          }, 1000)
        }
      },

      // 注册表单
      register() {
        if (this.type == 'login' || this.type == 'register') {
          if (!this.checked) {
            this.message('请阅读并勾选协议', 'error')
            return
          }
        }
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            if (this.openId != '') {
              this.registerForm.openId = this.openId
            }
            registered(this.registerForm).then((res) => {
              if (res.code == 200) {
                // this.type = 'login'
                clearInterval(this.countdownInterval)
                this.countDown = 0

                this.$store.commit('saveToken', res.result.token)
                this.$store.commit('saveUserInfo', res.result.user)
                setToken(res.result.token)
                setCookie('saveUserInfo', JSON.stringify(res.result.user))
                this.message('登陆成功', 'success')
                setTimeout(() => {
                  this.$router.push({
                    path: '/',
                  })
                }, 1000)
              }
            })
          }
        })
      },

      // 找回密码第一步
      forgetNext(type) {
        if (type == 1) {
          this.$refs.forgetForm.validate((valid) => {
            if (valid) {
              this.step = 2
            }
          })
        } else {
          this.$refs.forgetForm1.validate((valid) => {
            if (valid) {
              if (this.forgetForm1.password != this.forgetForm1.rePassword) {
                this.message('两次密码不一致', 'error')
                return
              }
              forgetPassword({
                account: this.forgetForm.account,
                code: this.forgetForm.code,
                password: this.forgetForm1.password,
              }).then((res) => {
                if (res.code == 200) {
                  this.message('密码重置成功', 'success')
                  this.step = 3
                }
              })
            }
          })
        }
      },
      // 获取验证码
      getCode(type, value) {
        if (!/^1\d{10}$/.test(value)) {
          this.message('手机号码格式不正确', 'error')
          return
        }
        if (this.countDown > 0) {
          return
        }
        smsSend({
          type: type,
          phoneNum: value,
        }).then((res) => {
          if (res.code == 200) {
            this.message('发送成功', 'success')
            // 开始倒计时
            this.startCountdown()
          }
        })
      },
      startCountdown() {
        this.countDown = 60 // 初始倒计时时间（秒）
        this.countdownInterval = setInterval(() => {
          this.countDown--
          if (this.countDown <= 0) {
            clearInterval(this.countdownInterval)
            this.countDown = 0
            // 可以选择是否自动重新生成验证码
            // this.generateCode();
          }
        }, 1000)
      },
      qqLogin() {
        let redirect_uri = encodeURIComponent(window.location.origin + '/#/login/login')
        let appid = '102101597'
        window.location.href = `https://graph.qq.com/oauth2.0/authorize?client_id=${appid}&redirect_uri=${redirect_uri}&response_type=code&state=123#wechat_redirect`
      },
      // 微信登录
      weixinLogin() {
        let redirect_uri = encodeURIComponent(window.location.origin + '/#/login/login')
        let appid = 'wx34ce80f4fc983974'
        window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=123#wechat_redirect`
      },
      bindlogin() {
        if (this.bindForm.account == '') {
          this.message('请输入要绑定的手机号', 'error')
          return
        }
        phoneLogin({
          account: this.bindForm.account,
        }).then((res) => {
          if (res.code == 200) {
            if (res.result == 1) {
              this.loginType = 1
              this.loginPassForm.account = this.bindForm.account
              this.type = 'bindPassLogin'
            } else {
              this.registerForm.account = this.bindForm.account
              this.type = 'bindPassRegister'
            }
          }
        })
        // this.$refs.bindForm.validate((valid) => {
        // 	if (valid) {

        // 		loginByCode({
        // 			account: this.bindForm.account,
        // 			code: this.bindForm.code,
        // 			openId:this.openId,
        // 			password: this.bindForm.password
        // 		}).then(res => {
        // 			if (res.code == 200) {
        // 				this.message('登录成功', 'success')

        // 			}

        // 		})

        // 	}
        // })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main {
    // background-image: url('../../static/image/login/bg.png');
    // background-repeat: no-repeat;
    // background-size: 100vw 100vh;
    padding-top: 80px;
    height: calc(100vh - 140px);
  }

  .step {
    width: 27px;
    height: 27px;
    color: #fff;
    // background: #0085FF 0 0 no-repeat transfor;
    text-align: center;
    // transform: rotate(45deg);
    line-height: 27px;
    position: relative;
  }

  .step::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px 6px 6px 6px;
    background: #dddddd;
    background-size: 100% 100%;
    transform: rotate(45deg);
    // transform: scaleY(-1);
  }

  .stepAction {
    width: 27px;
    height: 27px;
    color: #fff;
    // background: #0085FF 0 0 no-repeat transfor;
    text-align: center;
    // transform: rotate(45deg);
    line-height: 27px;
    position: relative;
  }

  .stepAction::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px 6px 6px 6px;
    background: #0085ff;
    background-size: 100% 100%;
    transform: rotate(45deg);
    // transform: scaleY(-1);
  }

  ::v-deep .el-step__title.is-process {
    color: #c0c4cc;
    font-weight: 500;
  }

  ::v-deep .el-step__line-inner {
    border-style: dotted;
  }

  ::v-deep .el-checkbox__inner {
    width: 20px;
    height: 20px;
  }

  ::v-deep .el-checkbox__inner::after {
    width: 6px;
    height: 10px;
    margin-left: 1px;
  }

  .loginTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .topRight {
    color: #fff;
    display: flex;
    height: 100%;
    line-height: 100%;
  }

  .rightItem {
    margin-right: 40px;
    font-size: 18px;
  }

  .rightItem:hover {
    color: #0085ff;
  }

  .loginText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #a5a7b1;
    margin-bottom: 20px;
    margin-top: 10px;

    .login {
      margin-right: 20px;
    }

    .action {
      color: #0085ff;
      font-weight: bold;
    }

    .login1 {
      padding-left: 20px;
      border-left: 3px solid #ececec;
    }
  }

  .feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777a8a;
    font-size: 16px;

    .feature2 {
      color: #0085ff;
    }

    .feature3 {
      color: #0085ff;
      font-size: 14px;
    }
  }

  .agreement {
    margin-top: 20px;
    font-size: 16px;
    display: flex;
  }

  .loginCard {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 450px;
    top: 280px;
  }

  .send {
    position: relative;

    .getCode {
      position: absolute;
      // top: 10px;
      right: 5px;
      // line-height: 80px;
      font-size: 14px;
      color: #0085ff;
    }

    .getCode::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 20px;
      left: -10px;
      top: 10px;
      background-color: #ececec;
    }
  }

  .stepSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .textSuccess {
    font-weight: 500;
    font-size: 20px;
    color: #54d8a4;
    margin-bottom: 16px;
  }

  .textDetaile {
    font-weight: 400;
    font-size: 14px;
    color: #777a8a;
    text-align: center;
  }

  .loginBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
