<template>
  <div style="background-color: #fff">
    <el-row>
      <div class="content" style="display: flex; align-items: center; background-color: #fff">
        <el-col :span="24">
          <div class="s bg-purple fl headerBox">
            <el-menu :default-active="$store.state.headBarActive" class="el-menu-demo" style="height: 40px" mode="horizontal" @select="handleSelect" background-color="#fff" text-color="#777A8A" active-text-color="#0286FE">
              <el-menu-item v-for="(item, index) in titleList" :index="item.id" :key="index" style="position: relative" class="menuBox">
                <a :href="'/#' + item.router" :class="{ setColor: index == 2 }" class="btnBox" :style="{ 'border-bottom': item.id == $store.state.headBarActive ? '2px solid #1861EE' : 'none' }">{{ item.title }}</a>
              </el-menu-item>
            </el-menu>
          </div>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
  import { getToken } from '@/utils/auth.js'
  export default {
    name: 'HeadBar',
    activated() {
      this.token = getToken()
      this.setHeadBarActive()
    },
    data() {
      return {
        token: undefined,
        titleList: [
          {
            title: '首页',
            router: '/home',
            id: '1',
          },
          {
            title: '我要买',
            router: '/game/select/0',
            id: '7',
          },
          {
            title: '我要卖',
            router: '/game/add/add',
            id: '8',
          },
          {
            title: '交易保障',
            router: '/assure',
            id: '9',
          },
          {
            title: '帮助中心',
            router: '/serviceCenter/2/0',
            id: '10',
          },
        ],
      }
    },
    created() {
      this.token = getToken()
      this.setHeadBarActive()
    },
    // mounted(){
    // 		let currentRoute = this.$router.currentRoute;
    // 		console.log(currentRoute)
    // },
    methods: {
      setHeadBarActive() {
        let currentRoute = this.$router.currentRoute
        for (let item of this.titleList) {
          if (item.router == currentRoute.path) {
            this.$store.commit('setHeadBarActive', item.id)
            break
          } else {
            this.$store.commit('setHeadBarActive', '0')
          }
        }
      },
      handleSelect(key, keyPath) {
        let currentRoute = this.$router.currentRoute
        for (let item of this.titleList) {
          if (item.id === key) {
            if (currentRoute.path !== item.router) {
              this.$router.push(item.router)
              this.$store.commit('setHeadBarActive', item.id)
            }
            return
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-menu-demo .el-menu-item {
    border-bottom-color: #ffffff !important;
    font-size: 16px;
  }
  .grid-content {
    background-color: #fff;
  }

  ::v-deep .el-menu.el-menu--horizontal {
    border: none;
  }

  ::v-deep .el-dropdown-menu__item,
  .el-menu-item {
    font-family: 'PingFangSC';
    /* 使用你定义的字体 */
    font-size: 14px;
    padding: 0 10px;
  }

  ::v-deep .el-header {
    padding: 0;
  }

  ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 4px solid #409eff;
  }

  .topRight {
    color: #fff;
    display: flex;
    // height: 100%;
    // line-height: 100%;
    justify-content: flex-end;
    background-color: #fff;
  }

  .rightItem {
    margin-right: 20px;
    font-size: 14px;
    width: 100px;
    cursor: pointer;
  }
  .headerBox {
    justify-content: space-between;
    height: 40px;
  }
  .rightItem1 {
    width: 72px;
    height: 32px;
    background: #0085ff;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 32px;
  }

  .rightItem2 {
    width: 72px;
    height: 32px;
    border: 1px solid #0085ff;
    border-radius: 6px 6px 6px 6px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #0085ff;
    line-height: 32px;
    margin-left: 16px;
  }

  .noticeTitle {
    height: 32px;
    font-weight: 600;
    font-size: 22px;
    color: #1d223c;
    padding: 16px;
    border-bottom: 1px solid #ececec;
  }
  .btnBox {
    text-decoration: none;
    color: inherit;
    font-size: 18px;
    padding-bottom: 5px;
  }
  .btnBox:hover {
    color: #0085ff !important;
  }
  .noticeItem {
    width: 362px;
    font-weight: 400;
    font-size: 20px;
    color: #1d223c;
    padding-top: 16px;
    padding-left: 16px;
  }

  .setColor {
    color: #ff5c00;
  }

  .hot {
    // background-color:#FF5C00;
    // color: #fff;
    // border-radius: 5px;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 2px;
    // right:43px;
    right: -5px;
    text-align: center;
    font-size: 12px;
  }

  .hot::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #ff5c00;
    position: absolute;
    /* 根据需要调整位置 */
    top: 15px;
    /* 示例，调整位置 */
    left: 8px;
    /* 示例，调整位置 */
    // background-color:#FF5C00;
  }

  .leftText {
    font-weight: 400;
    font-size: 12px;
    color: #a5a7b1;
    margin-right: 20px;
  }

  .loginType {
    width: 77px;
    height: 20px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    justify-content: center;
    margin-left: 8px;
  }

  ::v-deep .el-menu-item {
    font-size: 14px;
  }

  ::v-deep .el-menu {
    height: 40px; /* 设置菜单高度 */
  }

  ::v-deep .el-menu-item {
    line-height: 40px; /* 设置每个菜单项的垂直居中 */
    height: 40px; /* 设置菜单高度 */
  }

  ::v-deep is-active {
    height: 40px; /* 设置菜单高度 */
  }
  ::v-deep .el-menu.el-menu--horizontal {
    border: none;
  }
  .menuBox {
    margin-right: 20px;
    background: none !important;
  }
</style>
