<template>
	<div>
		<div v-if="state==1">
			<div class="roleTitle">商家入驻服务</div>

			<div style="overflow-y: auto;height: 1050px; scrollbar-width: none; ">
				<div v-if="tableList1.length != 0">
					<div class="titleType">已入驻服务</div>
					<div style="min-height: 148px;" v-for="(item,index) in tableList1">
						<div class="serve ">
							<div class="fl">
								<div class="serveItem fl">
									<img :src="require('@/static/image/mine/serve1.png')" style="width: 80px;height: 80px;" />
									<div class="serve1 ">
										<span class="serveTitle">{{item.settledTypeName}}</span>
										<span v-if="item.brief !=null && item.brief !=''" class="serveDetaile">{{item.brief}}</span>
									</div>
								</div>
								<div class="servePrice fl">
									<span>保证金:</span>
									<span
										style="color: #FF5C00;font-size: 18px;font-weight: bold;margin-left: 5px;">¥{{item.deposit}}</span>
								</div>
								<div class="btns">
									<span class="btn2" @click="updateInfos(item)">进入店铺</span>
									<span class="btn1" @click="tableList1[index].introShow = !tableList1[index].introShow">了解详情</span>
								</div>
								
							</div>
							
							<div class="intro" v-if="tableList1[index].introShow">
								<div v-if="item.brief !=null && item.brief !=''  ">简介:{{item.brief}}</div>
								<div v-html="item.detail"></div>
							</div>
						</div>
					
						
					</div>
				</div>

				<div class="titleType">可入驻服务</div>
				<div style="min-height: 148px;" v-for="(item,index) in tableList">
					<div class="serve ">
						<div class="fl">
							<div class="serveItem fl">
								<img :src="item.icon" style="width: 80px;height: 80px;" />
								<div class="serve1 ">
									<span class="serveTitle">{{item.settledTypeName}}</span>
									<span v-if="item.brief !=null && item.brief !=''" class="serveDetaile">{{item.brief}}</span>
								</div>
							</div>
							
							<div v-if="item.settledStore != null && item.settledStore.status == -1 " class="servePrice fl" style="margin-left: -300px;width: 300px;">
								<span>驳回原因:</span>
								<span
									>{{item.settledStore.reason}}</span>
							</div>
							<div class="servePrice fl">
								<span>保证金:</span>
								<span
									style="color: #FF5C00;font-size: 24px;font-weight: bold;margin-left: 5px;">¥{{item.deposit}}</span>
							</div>
							<div class="btns">
								<span v-if="item.settledStore == null" class="btn3" @click="applyFor(item)">立即申请</span>
								<span v-else-if="item.settledStore.status == -2" class="btn3" @click="applyFor(item)">立即申请</span>
								<span v-else-if="item.settledStore.status == -1 " class="btn3" @click="applyFor(item)">驳回(重新申请)</span>
								<span v-else-if="item.settledStore.status == 0" class="btn3" >待审核</span>
								<span class="btn1" @click="tableList[index].introShow = !tableList[index].introShow">了解详情</span>
							</div>
						</div>


						<div class="intro" v-if="tableList[index].introShow">
							<div v-if="item.brief !=null && item.brief !=''  ">简介:{{item.brief}}</div>
							
							<div v-html="item.detail"></div>
						</div>
					</div>

				</div>

				<div class="remind">
					<div class="remind1">温馨提示：</div>
					<div class="remind2">1、请您先在个人中心-账户信息里完成实名认证。</div>
					<div class="remind2">2、为保证商家服务质量，根据商家类型不同，在申请商家时需要缴纳一定的诚信保证金，合作期间无违反商家规定，合作结束退还诚信保证金。</div>
					<div class="remind2">3、提现会收取一定的手续费，具体查看上方收费标准</div>
					<div class="remind2">4、提现金额规则提示文本内容</div>
					<div class="remind2">5、提现会收取一定的手续费，具体查看上方收费标准</div>
				</div>
			</div>

		</div>


		<div v-if="state==2" style="overflow-y: auto;height: 1100px; scrollbar-width: none; ">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>入驻{{item.settledTypeName}}</span>
				<div class="fl" @click="state = 1">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>

			<div class="stepList">
				<el-steps :active="step" align-center>
					<el-step title="填写资料">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=1 }">1</div>
						</div>
					</el-step>
					<el-step title="支付保证金">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=2 }">2</div>
						</div>
					</el-step>
					<el-step title="平台审核">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=3 }">3</div>
						</div>
					</el-step>
					<el-step title="申请成功">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=4 }">
								✔
							</div>
						</div>
					</el-step>
				</el-steps>
			</div>

			<div style="margin-left: 100px;">

				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">认证费用：</span>
					</div>
					<div class="lableNumber">￥{{item.deposit}}</div>

				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">实名认证：</span>
					</div>
					<div style="position: relative;">
						<el-input v-prevent-space class="submitValue" disabled placeholder="请选择您的开户人名"
							:value="`${nameEncryption(realList[0].realName)}(${idCardEncryption(realList[0].idCard)})`"></el-input>
					</div>
					<div class="submitAuth" v-if="realList.length != 0">
						<img :src="require('@/static/image/mine/auth3.png')"
							style="width: 16px;height: 16px;margin-right: 5px;" />
						<span>已实名认证</span>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">申请业务：</span>
					</div>
					<div style="position: relative;">
						<el-select @change="typeChange" :popper-append-to-body="false" class="submitValue"
							v-model="submitForm.settledType" placeholder="请选择您要申请的业务">
							<el-option v-for="item in tableList2" :key="item.settledType" :label="item.settledTypeName"
								:value="item.settledType">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="fl formItem" v-if="submitForm.settledType == 4 || submitForm.settledType == 5">
					<div class="submitLable">
						<span class="lableText must">游戏类目：</span>
					</div>
					<div style="position: relative;">
						<el-cascader clearable :append-to-body="false" v-model="districtId" class="submitValue"
							:options="gameList" :props="districtProps"></el-cascader>
					</div>
				</div>

				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">商家名称：</span>
					</div>
					<div style="position: relative;">
						<el-input v-prevent-space class="submitValue" v-model="submitForm.storeName" placeholder="请选择您的商家名称"></el-input>
					</div>
					<div class="submitRemark" style="color: #FF3C3C;">您的商家名称将展示在发布的类目上</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText ">申请人姓名：</span>
					</div>
					<div style="position: relative;">
						<el-input v-prevent-space class="submitValue" v-model="submitForm.name" placeholder="请选择您的名字"></el-input>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">申请人电话：</span>
					</div>
					<div style="position: relative;">
						<el-input v-prevent-space class="submitValue" v-model="submitForm.phone" placeholder="请选择您的电话"></el-input>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText must">联系QQ：</span>
					</div>
					<div style="position: relative;">
						<el-input v-prevent-space class="submitValue" v-model="submitForm.qq" placeholder="请选择您的QQ号"></el-input>
					</div>
				</div>
				<div class="fl formItem">
					<div class="submitLable">
						<span class="lableText">店铺logo：</span>
					</div>
					<div class="submitValue">
						<el-upload accept=".jpg,.jpeg,.png" :action="BASE_URL + '/sys/common/upload?biz=settled_store-logo'"
							list-type="picture-card" :on-success="handlePictureCardPreview" :on-remove="handleRemove"
							:on-error="handleError" :file-list="fileList" ref="uploadFile" :limit="1">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>

				<div class="fl formItem">
					<div class="submitLable ">
						<span class="lableText must">店铺简介：</span>
					</div>
					<el-input v-prevent-space class="submitValue" v-model="submitForm.detail" type="textarea" placeholder="请输入内容"
						maxlength="200" show-word-limit>
					</el-input>
				</div>

				<div class="fl formItem">
					<div class="submitLable">&emsp;</div>
					<div class="submitValue">
						<el-button v-if="type == 'add'" type="primary" @click="payMoney">支付保证金</el-button>
						<el-button v-if="type == 'update'" type="primary" @click="updateInfo">修改信息</el-button>
					</div>
				</div>
			</div>
		</div>

		<div v-if="state==3">
			<div class="roleTitle fl" style="justify-content: space-between;">
				<span>入驻收货商家</span>
				<div class="fl" @click="state = 1">
					<img :src="require('@/static/image/mine/back.png')" style="width: 24px;height: 24px;" />
					<span class="balance" style="margin: 0 60px 0 4px; ">返回上一级</span>
				</div>
			</div>
			<div class="stepList">
				<el-steps :active="step" align-center>
					<el-step title="填写资料">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=1 }">1</div>
						</div>
					</el-step>
					<el-step title="支付保证金">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=2 }">2</div>
						</div>
					</el-step>
					<el-step title="平台审核">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=3 }">3</div>
						</div>
					</el-step>
					<el-step title="申请成功">
						<div slot="icon">
							<div class="step" :class="{'stepAction': step>=4 }">
								✔
							</div>
						</div>
					</el-step>
				</el-steps>
			</div>
		</div>
		<!-- <div class="status" v-if="status == 1 ">
			<img :src="require('@/static/image/mine/status1.png')" style="width: 172px;height: 164px;" />
			<span class="statusTitle">恭喜您！资料提交成功</span>
			<span class="statusDetaile">平台将会在1-3个工作日内审核您的申请～</span>
			<el-button type="primary" style="margin-top: 12px;font-size: 22px;">返回个人中心</el-button>
		</div>
		<div class="status" v-if="status == 2 ">
			<img :src="require('@/static/image/mine/status2.png')" style="width: 172px;height: 164px;" />
			<span class="statusTitle">遗憾！申请失败</span>
			<span class="statusDetaile">平台已将您的保证金原路返回，你可继续提交申请～</span>
			<el-button type="primary" style="margin-top: 12px;font-size: 22px;">重新提交</el-button>
		</div>
		<div class="status" v-if="status == 3 ">
			<img :src="require('@/static/image/mine/status3.png')" style="width: 172px;height: 164px;" />
			<span class="statusTitle">你的申请资料填写有误</span>
			<span class="statusDetaile">请修改资料后继续提交申请～</span>
			<el-button type="primary" style="margin-top: 12px;font-size: 22px;">修改资料</el-button>
		</div>
		<div class="status" v-if="status == 4 ">
			<img :src="require('@/static/image/mine/status4.png')" style="width: 172px;height: 164px;" />
			<span class="statusTitle">恭喜您！入驻申请成功</span>
			<span class="statusDetaile">即可开启服务</span>
			<el-button type="primary" style="margin-top: 12px;font-size: 22px;">店铺管理</el-button>
		</div> -->


	</div>
</template>

<script>
	import {
		settledStoreList,
		realInfo,
		settledStoreAdd,
		settledStoreEdit,
		gameList
	} from '@/api/index.js'
  import { BASE_URL } from '@/api/request';
	export default {
		data() {
			return {
				type: 'add',
				state: 1,
				status: 4,
				step: 1,
				tableList2: [],
				item: {},
				realList: [],
				tableList1: [],
				tableList: [],
				addImage: [],
				fileList: [],
				gameList: [],
				districtProps: {
					multiple: false,
					value: 'id',
					label: 'name',
				},
				districtId: '',
				submitForm: {
					// 申请人姓名	
					name: '',
					// 保证金	
					deposit: '',
					// 简介
					detail: '',
					//申请游戏	
					gameIds: '',
					// 电话
					phone: '',
					logo: '',
					// qq
					qq: '',
					// 申请业务
					settledType: null,
					// 商家名称
					storeName: '',
					id: null,
					userId: null

				},
        BASE_URL: BASE_URL
			}
		},
		created() {
			console.log(this.$store.state.userInfo)
			this.submitForm.phone = this.$store.state.userInfo.phone
			this.submitForm.qq = this.$store.state.userInfo.qq
			this.getList()
			this.getGameList()
			this.getRealName()
		},
		methods: {
			getGameList() {
				// 获取游戏列表
				gameList({
					pageNo: 1,
					pageSize: 1000
				}).then(res => {
					if (res.code == 200) {
						this.gameList = res.result.records
					}
				})
			},
			// 跳转修改资料
			updateInfos(item) {
				if(this.realList.length == 0){
					this.message('请先实名认证','error')
					setTimeout(()=>{
						this.$router.push('/mineIndex/28')
					},1500)
					return 
				}
				this.type = 'update'
				this.fileList = []
				this.addImage = []
				if (item.settledStore != null) {
					this.submitForm.storeName = item.settledStore.storeName
					this.submitForm.detail = item.settledStore.detail
					this.submitForm.gameIds = item.settledStore.gameIds
					this.submitForm.phone = item.settledStore.phone
					this.submitForm.qq = item.settledStore.qq
					this.submitForm.name = item.settledStore.name
					this.submitForm.id = item.settledStore.id
					this.submitForm.userId = item.settledStore.userId
					this.districtId = item.settledStore.gameIds.split(',')
					if (item.settledStore.logo != "" && item.settledStore.logo != null) {
						this.fileList = [{
							name: 'logo.png',
							url: item.settledStore.logo
						}]
						this.addImage.push(item.settledStore.logo)
					}
				}
				this.item = item
				this.submitForm.settledType = Number(item.settledType)
				console.log(this.submitForm)
				this.state = 2
				
			},
			// 提交资料
			payMoney() {
				this.submitForm.deposit = this.item.deposit
				this.submitForm.logo = this.addImage.join(',')
				
				if (this.districtId != '') {
					this.submitForm.gameIds = this.districtId.join(',')
				}

				settledStoreAdd(this.submitForm).then(res => {
					if (res.code == 200) {
						// this.message('提交成功', 'success')
						let info = this.submitForm
						info.id = this.item.id
						info.price = this.item.deposit
						info.title = '入驻保证金'
						info.createTime = this.sjCurrent(6)
						info.type = 'enter'
						// localStorage.setItem('orderPay', JSON.stringify(info));
						this.$router.push({
							path: '/orderPay',
							query: {
								info: JSON.stringify(info)
							}
						})
					}
				})
			},
			// 修改店铺信息
			updateInfo() {
				this.submitForm.deposit = this.item.deposit
				this.submitForm.auditStatus = this.item.settledStore.status
				this.submitForm.logo = this.addImage.join(',')
				if (this.districtId != '') {
					this.submitForm.gameIds = this.districtId.join(',')
				}
				console.log(this.submitForm)
				settledStoreEdit(this.submitForm).then(res => {
					if (res.code == 200) {
						this.message('提交成功', 'success')
						this.state = 1
						this.getList()
					}
				})
			},
			getList() {
				settledStoreList().then(res => {
					console.log(res.result)
					console.log(this.tableList)
					if (res.code == 200) {
						this.tableList = res.result.notSettled.map(item=>{
							return {...item,introShow:false}
						})
						this.tableList1 = res.result.settled.map(item=>{
							return {...item,introShow:false}
						})
						
						this.tableList2 = [...res.result.settled, ...res.result.notSettled]
					}

				})
			},
			typeChange(e) {
				console.log(e)
				this.tableList2.forEach(item => {
					if (item.settledType == e) {
						this.item = item

						this.fileList = []
						this.addImage = []
						if (item.settledStore != null) {
							this.submitForm.storeName = item.settledStore.storeName
							this.submitForm.detail = item.settledStore.detail
							this.submitForm.gameIds = item.settledStore.gameIds
							this.submitForm.phone = item.settledStore.phone
							this.submitForm.qq = item.settledStore.qq
							this.submitForm.name = item.settledStore.name
							this.submitForm.id = item.settledStore.id
							this.submitForm.userId = item.settledStore.userId
							this.districtId = item.settledStore.gameIds.split(',')
							if (item.settledStore.logo != "" && item.settledStore.logo != null) {
								this.fileList = [{
									name: 'logo.png',
									url: item.settledStore.logo
								}]
								this.addImage.push(item.settledStore.logo)
							}
						}

						this.submitForm.settledType = Number(item.settledType)
					}
				})
			},
			// 申请商家去填写资料
			applyFor(item) {
				if(this.realList.length == 0){
					this.message('请先实名认证','error')
					setTimeout(()=>{
						this.$router.push('/mineIndex/28')
					},1500)
					return 
				}
				this.type = 'add'
				this.fileList = []
				this.addImage = []
				if (item.settledStore != null) {
					this.submitForm.storeName = item.settledStore.storeName
					this.submitForm.detail = item.settledStore.detail
					this.submitForm.gameIds = item.settledStore.gameIds
					this.submitForm.phone = item.settledStore.phone
					this.submitForm.qq = item.settledStore.qq
					this.submitForm.name = item.settledStore.name
					this.submitForm.id = item.settledStore.id
					this.submitForm.userId = item.settledStore.userId
					this.districtId = item.settledStore.gameIds.split(',')
					if (item.settledStore.logo != "" && item.settledStore.logo != null) {
						this.fileList = [{
							name: 'logo.png',
							url: item.settledStore.logo
						}]
						this.addImage.push(item.settledStore.logo)
					}

				}
				this.item = item
				this.submitForm.settledType = Number(item.settledType)
				this.state = 2
				// this.getRealName()
			},
			getRealName() {
				realInfo().then(res => {
					this.realList = []
					if (res.code == 200) {
						
						if (res.result != null && res.result.idCardAudit == 1) {
							this.realList.push(res.result)
						}
					}
				})
			},
			// 上传失败事件
			handleError(e) {
				this.message('上传失败', 'error')
			},
			// 上传成功事件
			handlePictureCardPreview(e) {
				this.addImage.push(e.result)
			},
			// 删除图片
			handleRemove(file, fileList) {
				this.addImage = []
				this.fileList.forEach(item => {
					this.addImage.push(item.response.result)
				})

			},
		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-textarea__inner {
		height: 152px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.stepList {
		width: 600px;
		margin: 30px auto;
	}

	.step {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.step::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #DDDDDD;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepAction {
		width: 27px;
		height: 27px;
		color: #fff;
		// background: #0085FF 0 0 no-repeat transfor;
		text-align: center;
		// transform: rotate(45deg);
		line-height: 27px;
		position: relative;
	}

	.stepAction::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 6px 6px 6px 6px;
		background: #0085FF;
		background-size: 100% 100%;
		transform: rotate(45deg);
		// transform: scaleY(-1);

	}

	.stepSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	::v-deep .el-step__title.is-process {
		color: #C0C4CC;
		font-weight: 500;

	}

	.roleTitle {
		font-weight: 600;
		font-size: 18px;
		color: #1D223C;
		width: 950px;
		border-bottom: 1px solid #ECECEC;
		height: 60px;
		line-height: 60px;
		padding-left: 42px;
	}

	.remind {
		margin-left: 42px;
		margin-top: 50px;

		.remind1 {
			font-weight: 600;
			font-size: 18px;
			color: #000000;
			margin-bottom: 10px;

		}

		.remind2 {
			font-weight: 400;
			font-size: 14px;
			color: #777A8A;
			margin-bottom: 5px;
		}
	}

	.titleType {
		font-weight: 500;
		font-size: 14px;
		color: #777A8A;

		margin: 16px 42px;
	}

	.serve {
		width: 932px;
		min-height: 108px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #DEDEDE;
		margin-left: 42px;
		margin-bottom: 20px;
		padding-top: 20px;
		.intro{
			margin-left: 22px;
			margin-top: 20px;
			font-size: 16px;
			margin-bottom: 20px;
		}
		.servePrice {
			font-size: 16px;
			color: #A5A7B1;
			width: 200px;

		}

		.serveItem {
			margin-left: 24px;
			width: 550px;

			.serve1 {
				display: flex;
				flex-direction: column;
				margin-left: 12px;
			}

			.serveTitle {
				font-weight: 600;
				font-size: 16px;
				color: #000000;
			}

			.serveDetaile {
				font-weight: 400;
				font-size: 12px;
				color: #A5A7B1;
				margin-top: 16px;
			}
		}

		.btns {
			width: 148px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.btn1 {
			width: 128px;
			height: 41px;
			font-weight: 500;
			font-size: 14px;
			color: #A5A7B1;
			text-align: center;
			line-height: 41px;
			margin-bottom: 8px;
			cursor:pointer;
		}

		.btn2 {
			width: 128px;
			height: 35px;
			background: rgba(0, 133, 255, 0.06);
			border-radius: 6px 6px 6px 6px;
			font-weight: 600;
			font-size: 14px;
			color: #0085FF;
			border: 1px solid #0085FF;
			text-align: center;
			line-height: 35px;
			margin-bottom: 8px;
			cursor:pointer;
		}

		.btn3 {
			width: 128px;
			height: 41px;
			background: rgba(0, 133, 255, 0.06);
			border-radius: 6px 6px 6px 6px;
			font-weight: 600;
			font-size: 16px;
			color: #fff;
			background-color: #0085FF;
			text-align: center;
			line-height: 41px;
			margin-bottom: 8px;
			cursor:pointer;
		}
	}

	.balance {
		font-weight: 400;
		font-size: 16px;
		color: #A5A7B1;
		cursor:pointer;
	}

	.formItem {
		margin-bottom: 30px;
		position: relative;

		::v-deep .el-select-dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		::v-deep .el-cascader__dropdown {
			position: absolute !important;
			top: auto !important;
			left: auto !important;
		}

		.submitLable {
			width: 200px;
			text-align: right;

			.lableText {
				font-weight: 400;
				font-size: 16px;
				color: #A5A7B1;
				line-height: 30px;
				position: relative;
			}

		}

		.submitValue {
			width: 336px;
			margin: 0 20px;
			position: relative;
		}

		.lableNumber {
			margin: 0 20px;


			font-weight: bold;
			font-size: 24px;
			color: #FF5C00;


		}

		.submitRemark {
			// width: 40%;
			color: #A5A7B1;
		}
	}

	.must {
		position: relative;
	}

	.must::after {
		position: absolute;
		content: '*';
		color: #FF5C00;
		top: -5px;
		left: -10px
	}

	.submitAuth {
		font-weight: 400;
		font-size: 16px;
		color: #19D972;

		width: 116px;
		height: 30px;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #19D972;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.status {
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		margin-top: 100px;

		.statusTitle {
			font-weight: 600;
			font-size: 22px;
			color: #1D223C;
			margin-top: 12px;
		}

		.statusDetaile {
			font-weight: 400;
			font-size: 18px;
			color: #A5A7B1;
			margin-top: 12px;
		}
	}
</style>