<template>
  <div ref="obtain" class="app" style="display: flex; flex-direction: column; position: relative; padding: 10px 0">
    <div class="lableTop">
      <!-- <img @click="toLink" class="lableImage" :src="require('@/static/image/newImage/1.png')" /> -->
      <!-- <img class="lableImage" :src="require('@/static/image/index/classBg.png')" /> -->
    </div>
    <div class="content">
      <div class="fl screenTop">
        <div class="fl breadcrumb">
          <span>你的位置:</span>
          <el-breadcrumb>
            <el-breadcrumb-item :to="{ path: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/game/select/0' }">游戏分类</el-breadcrumb-item>
            <el-breadcrumb-item>商品列表</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="fl" style="font-size: 14px">
          <div>
            为您找到: {{ gameInfo.name }}
            <span v-if="Object.keys(sonGameTypeSelect).length != 0" type="info">-{{ sonGameTypeSelect.name }}</span>
            <span v-if="Object.keys(systemSelect).length != 0" type="info">-{{ systemSelect.name }}</span>
            <span v-if="Object.keys(gameAreaSelect).length != 0" type="info">-{{ gameAreaSelect.name }}</span>
            <span v-if="Object.keys(sonGameAreaSelect).length != 0" type="info">-{{ sonGameAreaSelect.name }}</span>
            <span v-if="Object.keys(gameTypeSelect).length != 0" type="info">-{{ gameTypeSelect.name }}</span>
            相关记录<span style="color: #ff5c00">{{ totalNum }}</span
            >条
          </div>
          <div class="screenDown" @click="screenShow = !screenShow">收起筛选<i class="el-icon-arrow-down"></i></div>
        </div>
      </div>
    </div>
    <div class="lableTop" style="margin-top: -20px">
      <el-row style="width: 1200px; margin: 0 auto">
        <el-col :span="24">
          <div style="position: relative" ref="myElement">
            <div class="screen">
              <el-row class="content">
                <el-col :span="24">
                  <div class="lableBg" @click="toLink">
                    <img style="width: 60px; height: 60px; border-radius: 10px; margin-right: 15px" :src="gameInfo.icon" />
                    <div class="gameClass">
                      <div class="lableTitle">{{ gameInfo.name }}</div>
                      <div v-if="gameInfo.terminalType == 1" class="lableDetaile">网络游戏</div>
                      <div v-if="gameInfo.terminalType == 2" class="lableDetaile">手机游戏</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-collapse-transition>
                <div v-if="screenShow">
                  <div class="screenItem fl" v-if="Object.keys(gameTypeSelect).length != 0 || Object.keys(sonGameTypeSelect).length != 0 || Object.keys(systemSelect).length != 0 || Object.keys(gameAreaSelect).length != 0 || Object.keys(sonGameAreaSelect).length != 0">
                    <div class="screenLable">您的选择:</div>
                    <div class="screenOptions">
                      <div v-if="Object.keys(gameTypeSelect).length != 0" class="screenOptionsItem" @mouseenter="($event) => handleMouseEnter($event)" @mouseleave="handleMouseLeave">
                        <div style="position: relative; z-index: 999999 !important; font-size: 14px">
                          <el-tag :class="{ screenTagHover: hoverShow }" @close="colse('gameTypeSelect')" type="info" closable>{{ gameTypeSelect.name }}</el-tag>
                        </div>
                        <div class="hoverList" v-show="hoverShow">
                          <div class="hoverTag">
                            <div class="tagItem" @click="gameTypeClick(index)" v-for="(item, index) in gameType">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>

                      <div v-if="Object.keys(sonGameTypeSelect).length != 0" class="screenOptionsItem" @mouseenter="($event) => sonGameTypeSelectEnter($event)" @mouseleave="sonGameTypeSelectLeave">
                        <div style="position: relative; z-index: 999999 !important">
                          <el-tag :class="{ screenTagHover: sonGameTypeSelectShow }" @close="colse('sonGameTypeSelect')" type="info" closable>{{ sonGameTypeSelect.name }}</el-tag>
                        </div>
                        <div class="hoverList" v-show="sonGameTypeSelectShow">
                          <div class="hoverTag">
                            <div class="tagItem" @click="sonGameTypeClick(index)" v-for="(item, index) in gameType[gameTypeIndex].childrenName.split(' ')">
                              {{ item }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="Object.keys(systemSelect).length != 0" class="screenOptionsItem" @mouseenter="($event) => systemSelectEnter($event)" @mouseleave="systemSelectLeave">
                        <div style="position: relative; z-index: 999999 !important">
                          <el-tag :class="{ screenTagHover: systemSelectShow }" @close="colse('systemSelect')" type="info" closable>{{ systemSelect.name }}</el-tag>
                        </div>
                        <div class="hoverList" v-show="systemSelectShow">
                          <div class="hoverTag">
                            <div class="tagItem" @click="systemListClick(index)" v-for="(item, index) in systemList">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>
                      <div v-if="Object.keys(gameAreaSelect).length != 0" class="screenOptionsItem" @mouseenter="($event) => gameAreaSelectEnter($event)" @mouseleave="gameAreaSelectLeave">
                        <div style="position: relative; z-index: 999999 !important">
                          <el-tag :class="{ screenTagHover: gameAreaSelectShow }" @close="colse('gameAreaSelect')" type="info" closable>{{ gameAreaSelect.name }}</el-tag>
                        </div>
                        <div class="hoverList" v-show="gameAreaSelectShow">
                          <div class="hoverTag">
                            <div class="tagItem" @click="gameAreaClick(index)" v-for="(item, index) in gameArea">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>

                      <div v-if="Object.keys(sonGameAreaSelect).length != 0" class="screenOptionsItem" @mouseenter="($event) => sonGameAreaSelectEnter($event)" @mouseleave="sonGameAreaSelectLeave">
                        <div style="position: relative; z-index: 999999 !important">
                          <el-tag :class="{ screenTagHover: sonGameAreaSelectShow }" @close="colse('sonGameAreaSelect')" type="info" closable>{{ sonGameAreaSelect.name }}</el-tag>
                        </div>
                        <div class="hoverList" v-show="sonGameAreaSelectShow">
                          <div class="hoverTag">
                            <div class="tagItem" @click="sonGameAreaClick(index)" v-for="(item, index) in sonGameArea">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>

                      <div v-if="Object.keys(son1GameAreaSelect).length != 0" class="screenOptionsItem" @mouseenter="($event) => son1GameAreaSelectEnter($event)" @mouseleave="son1GameAreaSelectLeave">
                        <div style="position: relative; z-index: 999999 !important">
                          <el-tag :class="{ screenTagHover: son1GameAreaSelectShow }" @close="colse('son1GameAreaSelect')" type="info" closable>{{ son1GameAreaSelect.name }}</el-tag>
                        </div>
                        <div class="hoverList" v-show="son1GameAreaSelectShow">
                          <div class="hoverTag">
                            <div class="tagItem" @click="son1GameAreaClick(index)" v-for="(item, index) in son1GameArea">{{ item.name }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="screenItem fl">
                    <div class="screenLable">商品类型:</div>
                    <div class="screenOptions">
                      <!-- <a class="optionsItem" :href="dynamicUrl('gameType', '')" @click.prevent="gameTypeClick('')" :class="{ optionsAction: gameTypeIndex === '' }">全部</a> -->
                      <a :href="dynamicUrl('gameType', item)" class="optionsItem" @click.prevent="gameTypeClick(index)" :class="{ optionsAction: gameTypeSelect.name === item.name }" v-for="(item, index) in gameType">{{ item.name }}</a>
                    </div>
                  </div>
                  <div class="screenItem fl" v-if="gameTypeIndex !== '' && gameType[gameTypeIndex].childrenName !== null && gameType[gameTypeIndex].childrenName !== ''">
                    <div class="screenLable">商品子类型:</div>
                    <div class="screenOptions">
                      <a class="optionsItem" :href="dynamicUrl('gameTypeChildren', item)" @click.prevent="sonGameTypeClick(index)" :class="{ optionsAction: sonGameTypeIndex === index }" v-for="(item, index) in gameType[gameTypeIndex].childrenName.split(' ')">{{ item }}</a>
                    </div>
                  </div>
                  <div class="screenItem fl" v-if="spannedList.length != 0">
                    <div class="screenLable">跨区选择:</div>
                    <div class="screenOptions">
                      <span class="optionsItem" @click.prevent="spannedClick(item)" :class="{ optionsAction: spannedListSelect.name == item.name }" v-for="(item, index) in spannedList">{{ item.name }}</span>
                    </div>
                  </div>

                  <div class="screenItem fl" v-if="systemList.length != 0">
                    <div class="screenLable">操作系统:</div>
                    <div class="screenOptions">
                      <a :href="dynamicUrl('systemList', item)" class="optionsItem" @click.prevent="systemListClick(index)" :class="{ optionsAction: systemList.name === item.name }" v-for="(item, index) in systemList">{{ item.name }}</a>
                    </div>
                  </div>

                  <div class="screenItem fl" v-if="gameArea.length != 0 && Object.keys(gameAreaSelect).length == 0">
                    <div class="screenLable">游戏区服:</div>
                    <div class="screenOptions">
                      <a class="optionsItem" :href="dynamicUrl('gameArea', item)" @click.prevent="gameAreaClick(index)" :class="{ optionsAction: gameAreaSelect.name == item.name }" v-for="(item, index) in gameArea">{{ item.name }}</a>
                    </div>
                  </div>
                  <div class="screenItem fl" v-if="sonGameArea.length != 0 && Object.keys(sonGameAreaSelect).length == 0">
                    <div class="screenLable">游戏区:</div>
                    <div class="screenOptions">
                      <a class="optionsItem" :href="dynamicUrl('sonGameArea', item)" @click.prevent="sonGameAreaClick(index)" :class="{ optionsAction: sonGameAreaSelect.name == item.name }" v-for="(item, index) in sonGameArea">{{ item.name }}</a>
                    </div>
                  </div>
                  <div class="screenItem fl" v-if="son1GameArea.length != 0 && Object.keys(son1GameAreaSelect).length == 0">
                    <div class="screenLable">游戏区:</div>
                    <div class="screenOptions">
                      <a :href="dynamicUrl('son1GameArea', item)" class="optionsItem" @click.prevent="son1GameAreaClick(index)" :class="{ optionsAction: son1GameAreaSelect.name == item.name }" v-for="(item, index) in son1GameArea">{{ item.name }}</a>
                    </div>
                  </div>
                  <div class="screenItem1 fl" v-if="otherList.length != 0" @mouseleave="otherMouseLeave">
                    <div class="screenLable1">其他选项:</div>
                    <div class="screenOptions1 fl" style="position: relative">
                      <div class="otherItem fl" @mouseenter="($event) => otherMouseEnter($event, index)" v-for="(item, index) in otherList">
                        <!-- <el-select @change="($event)=>setCommand($event,index)" collapse-tags
													popper-class="popperClass" transfer="true"
													:popper-append-to-body="false" v-model="item.value" multiple
													:placeholder="item.name">
													<el-option v-for="(items,indexs) in item.options.split(' ')"
														:key="items" :label="items" :value="items">
													</el-option>
												</el-select> -->
                        <div class="el1" style="width: 150rpx" v-if="item.value.length != 0">
                          {{ item.value.join(',') }}
                        </div>
                        <div class="el1" style="width: 150rpx; font-size: 14px" v-else>
                          {{ item.name }}
                        </div>
                        <i v-if="index == otherIndex" style="color: blue" class="el-icon-arrow-up"></i>
                        <i v-else class="el-icon-arrow-down"></i>
                      </div>

                      <div class="otherValue" v-show="otherShow">
                        <el-checkbox-group v-model="otherList[otherIndex].value" @change="($event) => setCommand($event, otherIndex)">
                          <el-checkbox v-for="(items, indexs) in otherList[otherIndex].options.split(' ')" :label="items">
                            <span style="font-size: 14px">{{ items }}</span></el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="lableTop" v-if="receiveList.length != 0 && displayType == 'take'">
      <el-row class="content">
        <el-col :span="24">
          <div style="display: flex; justify-content: space-between; position: relative">
            <div class="mainRight">
              <div class="rightTop fl">
                <div class="th2">
                  <span class="thText">商品信息</span>
                </div>
                <div class="th3">
                  <span class="thText">比例</span>
                </div>
                <div class="th1">
                  <span class="thText">收货数量</span>
                </div>
                <div class="th4">
                  <span class="thText">购买数量</span>
                </div>

                <div class="th5">
                  <span class="thText">服务保证</span>
                </div>
                <div class="th6"></div>
              </div>
              <div class="hotGoodList">
                <div class="hotGoodItem fl" v-for="(item, index) in receiveList">
                  <div class="th2">
                    <div class="gotArea" style="text-align: left; margin-top: 0">
                      {{ item.districtName }}
                      <span class="serverSide" v-if="item.spannedId_dictText != null">{{ item.spannedId_dictText }}</span>
                    </div>
                    <div class="hotInfoTag">
                      <span>发货均时：</span>
                      <span style="color: #ff5c00; font-weight: 600">{{ item.meanTime }}分钟</span>
                    </div>
                    <div class="hotInfoTag">
                      <span>无货赔付率：</span>
                      <span style="color: #ff5c00; font-weight: 600">{{ item.paidRate }}%</span>
                    </div>
                  </div>
                  <div class="th3">
                    <span style="border: 1px solid #ff5c00; color: #ff5c00; padding: 4px 6px" v-if="item.tradeType == 1">邮寄交易</span>
                    <span style="border: 1px solid #ff5c00; color: #ff5c00; padding: 4px 6px" v-if="item.tradeType == 2">拍卖交易</span>
                    <span style="border: 1px solid #ff5c00; color: #ff5c00; padding: 4px 6px" v-if="item.tradeType == 3">摆摊交易</span>
                    <span style="border: 1px solid #ff5c00; color: #ff5c00; padding: 4px 6px" v-if="item.tradeType == 4">邮当面交易</span>
                    <div class="hotInfoTag" style="color: #ff5c00; font-weight: 600">1元={{ item.piecesNum.toFixed(4) }}{{ item.commodityUnit }}</div>
                    <div class="hotInfoTag" style="color: #777a8a; font-weight: 600">1{{ item.commodityUnit }} ={{ (1 / item.piecesNum).toFixed(2) }}/元</div>
                  </div>
                  <div class="th1">
                    <div>
                      <span style="color: #ff5c00">{{ Number(item.num) - Number(item.orderNum) }}</span
                      >{{ item.commodityUnit }}
                    </div>
                    <div>起收≥{{ item.minNum }}</div>
                    <div>最高≤{{ item.maxNum }}</div>
                  </div>
                  <div class="th4">
                    <div class="fl">
                      <div class="inputNumber" style="margin: 10px 0">
                        <input v-prevent-space @input="($event) => setTitle($event, index)" v-model="item.value" type="number" />
                        <span class="unit">{{ item.commodityUnit }}</span>
                      </div>
                      <div>&emsp;=</div>
                      <div class="hotInfoTag" style="margin-top: 0">
                        <span style="color: #ff5c00; font-weight: 600">&emsp;{{ ((item.value * 1) / item.piecesNum || 0).toFixed(2) }}元</span>
                      </div>
                    </div>

                    <!-- 	<div class="hotInfoTag">
											<span>库存数量：</span>
											<span
												style="color:#FF5C00;font-weight: 600;">{{item.piecesNum}}{{item.commodityUnit}}</span>
										</div> -->
                  </div>
                  <div class="th5">
                    <div class="th5Text fl">
                      <img :src="require('@/static/image/index/pei1.png')" style="width: 24px; height: 24px; margin-right: 8px" />
                      <span>无货赔付</span>
                    </div>
                    <div class="th5Text fl">
                      <img :src="require('@/static/image/index/chao.png')" style="width: 24px; height: 24px; margin-right: 8px" />
                      <span>超时赔付</span>
                    </div>
                  </div>
                  <div class="th6">
                    <div class="th6Submit" @click="sellOrder(item, index)">立即出售</div>
                  </div>
                </div>

                <div class="fl" style="justify-content: space-between; font-size: 18px; margin: 20px">
                  <div>
                    为您找到
                    <span style="color: #ff5c00">{{ receiveTotal }}</span>
                    条记录
                  </div>
                  <el-pagination @size-change="receiveSizeChange" @current-change="receiveChange" :current-page="receiveSearch.pageNo" :page-sizes="[10, 20, 30, 40]" :page-size="receiveSearch.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="receiveTotal"> </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- v-if="hotList.length != 0 && displayType != 'take'" -->

    <div class="lableTop" style="min-height: 500px" v-if="displayType == ''">
      <el-row style="width: 1200px; margin: 0 auto">
        <el-col :span="24">
          <div class="screen" style="margin-top: 0px; padding-bottom: 20px">
            <div class="screenItem fl">
              <div class="fl" style="padding: 10px 20px; background-color: #eff6ff; width: 100%; justify-content: space-between">
                <div class="fl" style="width: 35%">
                  <div class="sortList fl">
                    <div v-if="gameTypeIndex !== '' && (gameType[gameTypeIndex].gameCommodityCategory == 1 || gameType[gameTypeIndex].gameCommodityCategory == 12)" class="sortItem" @click="typeSearch(5)" :class="{ sortActin: search.orderType == '3' }">
                      <div>比例</div>
                      <div class="ratio fl" v-if="ratioTip">
                        点击这里更便宜
                        <img @click.stop="ratioTip = false" :src="require('@/static/image/index/close.png')" style="width: 12px; height: 12px; margin-left: 5px" />
                      </div>
                    </div>
                    <div class="sortItem" @click="typeSearch(1)" :class="{ sortActin: search.orderType == '1' }">综合</div>
                    <div class="sortItem" @click="typeSearch(2)" :class="{ sortActin: search.orderType == '2' }">时间</div>

                    <div class="sortItem fl" @click="typeSearch(3)" :class="{ sortActin: search.orderType == 4 || search.orderType == 5 }">
                      <span>价格</span>
                      <i v-if="search.orderType != 5" class="el-icon-top"></i>
                      <i v-if="search.orderType == 5" class="el-icon-bottom"></i>
                    </div>
                  </div>
                  <div class="screenPrice">
                    <div class="price2 fl">￥<input v-prevent-space v-model="search.priceMin" style="border: none; font-size: 14px" type="number" /></div>
                    <div style="margin: 0 8px">-</div>
                    <div class="price1 fl">
                      <div class="price fl">￥<input v-prevent-space v-model="search.priceMax" style="border: none; font-size: 14px" type="text" /></div>
                    </div>
                    <div @click="searchPrice" style="text-align: center; margin-left: 40px; border-radius: 5px; line-height: 30px; background: #1861ee; width: 40px; color: #fff; cursor: pointer; font-size: 14px; height: 30px">确定</div>
                  </div>
                </div>

                <div style="color: #000; font-size: 14px; font-weight: bold; width: 15%; text-align: center">价格</div>
                <!-- <div
									style="color: #000;font-size: 14px;font-weight: bold;width: 15%;text-align: center;">
									库存</div> -->

                <div v-if="gameTypeIndex !== '' && (gameType[gameTypeIndex].gameCommodityCategory == 1 || gameType[gameTypeIndex].gameCommodityCategory == 12)" style="color: #000; font-size: 14px; font-weight: bold; width: 15%; text-align: center">比例</div>
                <div style="color: #000; font-size: 14px; font-weight: bold; width: 20%; text-align: center">服务保证</div>

                <div class="publish" @click="toPublic">
                  <span style="font-size: 12px">
                    <i class="el-icon-plus"></i>
                    快捷发布
                  </span>
                  <span style="font-size: 12px"> 快捷发布当前类目商品 </span>
                </div>
              </div>
            </div>
            <div class="screenItem fl" style="justify-content: space-between" v-for="(item, index) in gameList">
              <div class="fl guarantee">
                <div style="width: 35%; display: flex">
                  <el-image v-if="item.gameCommodityCategory != 1 && item.gameCommodityCategory != 7" style="width: 108px; height: 108px; border-radius: 4px; flex-shrink: 0" :src="item.pic.split(',')[0]" fit="cover"></el-image>
                  <div>
                    <div style="margin-left: 8px; position: relative">
                      <div class="infoTitle">
                        <div class="el2">
                          <span v-if="item.deliveryType_dictText == '寄售'" class="infoImage" style="background-color: rgb(183, 66, 255)">{{ item.deliveryType_dictText }}</span>
                          <span v-else class="infoImage">{{ item.deliveryType_dictText }}</span>
                          <span style="position: relative; font-size: 14px; margin-left: 8px" @click="toDetaile(item)">{{ item.title }} </span>
                        </div>

                        <div class="showInfo" v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6">
                          <div class="acc-triangle"></div>
                          <div class="showTitle">{{ item.title }}</div>
                          <div class="showItem" v-for="items in item.gameCommodityFieldValueList">
                            <div class="itemName">{{ items.name }} :</div>
                            <div class="itemValue">{{ items.value }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="infoSpan" v-if="item.gameCommodityFieldValueList">
                        <span class="spanItem" v-for="(items, indexs) in item.gameCommodityFieldValueList">{{ items.value }}</span>
                      </div>
                      <div class="area" style="display: flex; align-items: center">
                        <span style="color: #a5a7b1">库存：</span>
                        <span style="color: #1861ee; font-weight: bold; width: 30px">{{ item.num }}</span>
                        <span style="color: #a5a7b1; margin-left: 10px">区服类型：</span>
                        <span>{{ item.districtName }}</span>
                        <!-- <span v-if="item.spannedName != null" class="serverSide">{{ item.spannedName }}</span> -->
                      </div>

                      <div class="area fl" v-if="item.shopGrade !== null && item.shopGrade !== ''">
                        <span v-if="item.gameCommodityCategory == 1" style="color: #a5a7b1">发布时间：</span>
                        <span v-if="item.gameCommodityCategory == 1">{{ sjVal(item.createTime, 6) }}</span>
                        <!--                        <span style="color: #a5a7b1; margin-left: 10px">信用等级：</span>
                        <div v-if="item.shopGrade == 0">
                          <img style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry3.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 1">
                          <img v-for="items in 1" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 2">
                          <img v-for="items in 2" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 3">
                          <img v-for="items in 3" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 4">
                          <img v-for="items in 4" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry1.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 6">
                          <img v-for="items in 1" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 7">
                          <img v-for="items in 2" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 8">
                          <img v-for="items in 3" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 9">
                          <img v-for="items in 4" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 11">
                          <img v-for="items in 1" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 12">
                          <img v-for="items in 2" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 13">
                          <img v-for="items in 3" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                        </div>
                        <div v-if="item.shopGrade == 14">
                          <img v-for="items in 4" style="width: 14px; height: 14px; margin-top: 3px" :src="require('@/static/image/index/masonry2.png')" alt="" />
                        </div>-->
                      </div>

                      <div v-if="item.gameCommodityCategory != 1" class="area">
                        <span style="color: #a5a7b1">发布时间：</span>
                        <span>{{ sjVal(item.createTime, 6) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accountPrice">￥{{ item.price }}</div>
                <!-- <div class="accountNumber">{{item.num}}</div> -->
                <div class="accountNumber1" v-if="gameTypeIndex !== '' && gameType[gameTypeIndex].gameCommodityCategory == 1">
                  <div class="proportion">{{ (item.piecesNum / item.price).toFixed(5) }}{{ item.commodityUnit }} = 1元</div>
                  <div class="proportion1">1{{ item.commodityUnit }} = {{ (item.price / item.piecesNum).toFixed(5) }}元</div>
                </div>
                <div class="accountService">
                  <div class="fl serviceItem" v-if="item.isSendInsure == 1">
                    <img style="width: 14px; height: 14px; margin-right: 5px" :src="require('@/static/image/index/bao.png')" />
                    卖家投保
                    <div class="servieHover">卖家已为商品投保，商品被找回、封号、回收等，可申请理赔</div>
                  </div>
                  <div class="fl serviceItem" v-else>
                    <img style="width: 14px; height: 14px; margin-right: 5px" :src="require('@/static/image/index/bao.png')" />
                    商品可投保

                    <div class="servieHover">此商品支持购买保险，商品被找回、封号、回收等，可申请理赔。</div>
                  </div>
                  <!--                  <div v-if="item.deposit == 1" class="fl serviceItem" style="margin-top: 5px">
                    <img style="width: 14px; height: 14px; margin-right: 5px" :src="require('@/static/image/index/pei.png')" />
                    押金赔付
                    <div class="servieHover">交易成功押金全额返还,商品被找回、封号、回收等，可获得赔付</div>
                  </div>-->
                </div>
                <div class="accountPay">
                  <div v-if="item.gameCommodityCategory == 5 || item.gameCommodityCategory == 6" @click="toDetaile(item)" class="accountDetaile">查看详情</div>
                  <div v-else @click="toOrderPay(item)" class="accountDetaile">立即购买</div>
                  <!-- <div v-if="item.gameCommodityCategory != 5 && item.gameCommodityCategory != 6" class="addCard" @click="addCard(item)">
                    <img style="width: 20px; height: 20px" :src="require('@/static/image/index/addCard.png')" />
                  </div> -->
                </div>
              </div>
            </div>

            <div class="fl" style="justify-content: space-between; font-size: 14px; margin-top: 20px">
              <div>
                为您找到
                <span style="color: #ff5c00">{{ totalNum }}</span>
                条记录
              </div>
              <el-pagination @size-change="handleSizeChange" @current-change="handlePageChange" :current-page="search.pageNo" :page-sizes="[10, 20, 30, 40]" :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNum"> </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <shopping-card></shopping-card>
  </div>
</template>

<script>
  import shoppingCard from '@/components/shoppingCart.vue'
  import { gameCommodityList, shopCartAdd, districtList, gameCommodityTypes, spannedList, fieldList, receiveGoods, realInfo, saleGoods, gameQueryById } from '@/api/index.js'
  export default {
    components: {
      shoppingCard,
    },
    name: 'gameList',
    data() {
      return {
        mainsTop: 443,
        ratioTip: true,
        sonGameAreaSelectShow: false,
        son1GameAreaSelectShow: false,
        gameAreaSelectShow: false,
        systemSelectShow: false,
        sonGameTypeSelectShow: false,
        hoverShow: false,
        screenShow: true,
        // 游戏详情
        gameInfo: {},
        otherList: [],
        popoverOffset: 0,
        search: {
          // 排序
          orderType: 1,
          gameId: '',
          pageNo: 1,
          pageSize: 20,
          // 最小价格
          priceMin: '',
          // 最大价格
          priceMax: '',
          // 大分类
          commodityTypeId: '',
          // 子分类
          childCommodityType: '',
          // 区服id
          districtId: '',
          flag: '',
          title: '',
          spannedId: '',
          // isHot: 0
        },
        hotSeach: {
          pageNo: 1,
          pageSize: 2,
        },
        hotTotal: 0,
        hotTotalShow: false,
        receiveSearch: {
          orderType: '3',
          commodityTypeId: '',
          gameId: '',
          isOffShelf: 0,
          districtId: '',
          pageNo: 1,
          pageSize: 2,
          title: '',
        },
        receiveTotal: 0,
        receiveList: [],
        totalNum: 0,
        gameList: [],
        hotList: [],
        // 游戏区
        gameArea: [],
        gameAreaIndex: '',
        gameAreaSelect: {},
        // 游戏子区
        sonGameArea: [],
        sonGameAreaIndex: '',
        sonGameAreaSelect: {},

        // 游戏子区
        son1GameArea: [],
        son1GameAreaIndex: '',
        son1GameAreaSelect: {},

        // 系统
        systemList: [],
        systemIndex: '',
        systemSelect: {},

        // 游戏类型
        gameType: [],
        gameTypeIndex: '',
        gameTypeSelect: {},
        sonGameTypeIndex: '',
        sonGameTypeSelect: {},
        // 跨区列表
        spannedList: [],
        spannedIndex: '',
        // 其他选项
        otherList: [],
        otherSelect: {},
        displayType: '',
        topBanner: '',
        topBannerLink: '',
        otherShow: false,
        otherIndex: 0,
      }
    },
    watch: {
      // 利用watch方法检测路由变化：
      $route: function (to, from) {
        if (to.params !== from.params) {
          let info = JSON.parse(this.$route.query.info)
          if (info == null) {
            this.$router.push('/')
          }

          this.gameInfo = info
          this.search.gameId = info.id
          this.search.title = info.title
          this.receiveSearch.title = info.title
          this.receiveSearch.gameId = info.id
          // 处理携带过来的参数
          if (info.gameTypeSelect != undefined) {
            this.gameTypeSelect = info.gameTypeSelect
            this.search.commodityTypeId = this.gameTypeSelect.id
            this.receiveSearch.commodityTypeId = this.gameTypeSelect.id
            this.getOtherList(this.search.commodityTypeId)
          }
          if (info.gameTypeSonSelect != undefined) {
            this.sonGameTypeSelect = info.gameTypeSonSelect
            this.search.childCommodityType = this.sonGameTypeSelect.name
          }
          let districtId = ''
          if (info.selectSystem != undefined) {
            this.systemSelect = info.selectSystem
            districtId = this.systemSelect.id + '/'
            this.getSonGameArea(this.systemSelect.id, 'system')
          }

          if (info.selectDistrict != undefined) {
            this.gameAreaSelect = info.selectDistrict
            districtId += this.gameAreaSelect.id
            this.getSonGameArea(this.gameAreaSelect.id, '')
          }
          if (info.sonSelectDistrict != undefined) {
            this.sonGameAreaSelect = info.sonSelectDistrict
            districtId = districtId + '/' + this.sonGameAreaSelect.id
            this.getDistrictList1(this.sonGameAreaSelect.id)
          }
          if (info.son1SelectDistrict != undefined) {
            this.son1GameAreaSelect = info.son1SelectDistrict
            districtId = districtId + '/' + this.son1GameAreaSelect.id
          }
          this.search.districtId = districtId
          this.receiveSearch.districtId = districtId

          if (info.displayType != undefined) {
            this.displayType = info.displayType
          }
          if (info.orderType != undefined) {
            this.search.orderType = info.orderType
          }
          if (info.priceMin != undefined) {
            this.search.priceMin = info.priceMin
          }
          if (info.priceMax != undefined) {
            this.search.priceMax = info.priceMax
          }

          if (this.displayType == 'take') {
            this.receiveSearch.pageSize = 20
            // this.getReceiveGoods()
          } else if (this.displayType == 'hot') {
            this.hotSeach.pageSize = 20
            this.hotTotalShow = true
            // this.getGameCommodityList1()
          } else {
            this.getGameCommodityList()
            // this.getGameCommodityList1()
            // this.getReceiveGoods()
          }

          if (this.gameInfo.terminalType == 1) {
            this.getDistrictList()
          } else {
            this.getSystemList()
          }

          this.getGameCommodityTypes()
          this.getBanner()
        }
      },
    },
    created() {
      // let info = JSON.parse(localStorage.getItem('gameList'))
      let info = JSON.parse(this.$route.query.info)
      if (info == null) {
        this.$router.push('/')
      }

      this.gameInfo = info
      this.search.gameId = info.id
      this.search.title = info.title
      this.receiveSearch.title = info.title
      this.receiveSearch.gameId = info.id
      // 处理携带过来的参数
      if (info.gameTypeSelect != undefined) {
        this.gameTypeSelect = info.gameTypeSelect
        this.search.commodityTypeId = this.gameTypeSelect.id
        this.receiveSearch.commodityTypeId = this.gameTypeSelect.id
        this.getOtherList(this.search.commodityTypeId)
      }
      if (info.gameTypeSonSelect != undefined) {
        this.sonGameTypeSelect = info.gameTypeSonSelect
        this.search.childCommodityType = this.sonGameTypeSelect.name
      }
      let districtId = ''
      if (info.selectSystem != undefined) {
        this.systemSelect = info.selectSystem
        districtId = this.systemSelect.id + '/'
        this.getSonGameArea(this.systemSelect.id, 'system')
      }

      if (info.selectDistrict != undefined) {
        this.gameAreaSelect = info.selectDistrict
        districtId += this.gameAreaSelect.id
        this.getSonGameArea(this.gameAreaSelect.id, '')
      }
      if (info.sonSelectDistrict != undefined) {
        this.sonGameAreaSelect = info.sonSelectDistrict
        districtId = districtId + '/' + this.sonGameAreaSelect.id
        this.getDistrictList1(this.sonGameAreaSelect.id)
      }
      if (info.son1SelectDistrict != undefined) {
        this.son1GameAreaSelect = info.son1SelectDistrict
        districtId = districtId + '/' + this.son1GameAreaSelect.id
      }
      this.search.districtId = districtId
      this.receiveSearch.districtId = districtId

      if (info.displayType != undefined) {
        this.displayType = info.displayType
      }
      if (info.orderType != undefined) {
        this.search.orderType = info.orderType
      }
      if (info.priceMin != undefined) {
        this.search.priceMin = info.priceMin
      }
      if (info.priceMax != undefined) {
        this.search.priceMax = info.priceMax
      }

      if (this.displayType == 'take') {
        this.receiveSearch.pageSize = 20
        // this.getReceiveGoods()
      } else if (this.displayType == 'hot') {
        this.hotSeach.pageSize = 20
        this.hotTotalShow = true
        // this.getGameCommodityList1()
      } else {
        this.getGameCommodityList()
        // this.getGameCommodityList1()
        // this.getReceiveGoods()
      }

      if (this.gameInfo.terminalType == 1) {
        this.getDistrictList()
      } else {
        this.getSystemList()
      }

      this.getGameCommodityTypes()
      this.getBanner()
    },
    methods: {
      dynamicUrl(type, value) {
        if (type == 'gameType') {
          let info = {
            id: this.gameInfo.id,
            terminalType: this.gameInfo.terminalType,
            name: this.gameInfo.name,
            icon: this.gameInfo.icon,
            displayType: this.displayType,
            gameTypeSelect: {},
          }
          if (value != '') {
            info.gameTypeSelect.id = value.id
            info.gameTypeSelect.name = value.name
          }
          return window.location.origin + '/#/gameList?info=' + JSON.stringify(info)
        } else if (type == 'gameTypeChildren') {
          let info = {
            id: this.gameInfo.id,
            terminalType: this.gameInfo.terminalType,
            name: this.gameInfo.name,
            icon: this.gameInfo.icon,
            displayType: this.displayType,
            gameTypeSelect: {
              id: this.gameTypeSelect.id,
              name: this.gameTypeSelect.name,
            },
            gameTypeSonSelect: {
              name: value,
            },
          }

          return window.location.origin + '/#/gameList?info=' + JSON.stringify(info)
        } else if (type == 'gameArea') {
          let info = {
            id: this.gameInfo.id,
            terminalType: this.gameInfo.terminalType,
            name: this.gameInfo.name,
            icon: this.gameInfo.icon,
            displayType: this.displayType,
            selectDistrict: {
              id: value.id,
              name: value.name,
            },
          }
          return window.location.origin + '/#/gameList?info=' + JSON.stringify(info)
        } else if (type == 'sonGameArea') {
          let info = {
            id: this.gameInfo.id,
            terminalType: this.gameInfo.terminalType,
            name: this.gameInfo.name,
            icon: this.gameInfo.icon,
            displayType: this.displayType,
            selectDistrict: {
              id: this.gameAreaSelect.id,
              name: this.gameAreaSelect.name,
            },
            sonSelectDistrict: {
              id: value.id,
              name: value.name,
            },
          }
          return window.location.origin + '/#/gameList?info=' + JSON.stringify(info)
        } else if (type == 'son1GameArea') {
          let info = {
            id: this.gameInfo.id,
            terminalType: this.gameInfo.terminalType,
            name: this.gameInfo.name,
            icon: this.gameInfo.icon,
            displayType: this.displayType,
            selectDistrict: {
              id: this.gameAreaSelect.id,
              name: this.gameAreaSelect.name,
            },
            sonSelectDistrict: {
              id: this.sonGameAreaSelect.id,
              name: this.sonGameAreaSelect.name,
            },
            son1SelectDistrict: {
              id: value.id,
              name: value.name,
            },
          }
          return window.location.origin + '/#/gameList?info=' + JSON.stringify(info)
        } else if (type == 'systemList') {
          let info = {
            id: this.gameInfo.id,
            terminalType: this.gameInfo.terminalType,
            name: this.gameInfo.name,
            icon: this.gameInfo.icon,
            displayType: this.displayType,
            selectSystem: {
              id: value.id,
              name: value.name,
            },
          }
          return window.location.origin + '/#/gameList?info=' + JSON.stringify(info)
        }
      },
      toLink() {
        if (!this.isEmpty(this.topBannerLink)) {
          window.open(this.topBannerLink)
        }
      },
      getBanner() {
        gameQueryById({
          id: this.search.gameId,
        }).then((res) => {
          if (res.code == 200) {
            this.topBanner = res.result.pic
            this.topBannerLink = res.result.url
          }
        })
      },
      receiveSizeChange(e) {
        this.receiveSearch.pageSize = e
        // this.getReceiveGoods()
      },
      receiveChange(e) {
        this.receiveSearch.pageNo = e
        // this.getReceiveGoods()
      },
      change(e) {
        console.log(e)
        this.receiveSearch.pageNo = e + 1
        // this.getReceiveGoods()
      },
      hothandleSizeChange(e) {
        this.search.pageSize = e
        this.getGameCommodityList()
      },
      hotselectSearch(e) {
        this.search.pageNo = e
        this.getGameCommodityList()
      },
      handleSizeChange(e) {
        this.search.pageSize = e
        this.getGameCommodityList()
      },
      handlePageChange(e) {
        console.log(e)
        this.search.pageNo = e
        this.getGameCommodityList()
      },
      otherMouseEnter(event, index) {
        this.otherShow = true
        this.otherIndex = index
      },
      // 鼠标划过事件
      handleMouseEnter(event) {
        this.hoverShow = true
      },
      handleMouseLeave() {
        this.hoverShow = false
      },
      sonGameTypeSelectEnter() {
        this.sonGameTypeSelectShow = true
      },
      sonGameTypeSelectLeave() {
        this.sonGameTypeSelectShow = false
      },
      systemSelectEnter() {
        this.systemSelectShow = true
      },
      systemSelectLeave() {
        this.systemSelectShow = false
      },
      gameAreaSelectEnter() {
        this.gameAreaSelectShow = true
      },
      gameAreaSelectLeave() {
        this.gameAreaSelectShow = false
      },
      sonGameAreaSelectEnter() {
        this.sonGameAreaSelectShow = true
      },
      sonGameAreaSelectLeave() {
        this.sonGameAreaSelectShow = false
      },
      son1GameAreaSelectEnter() {
        this.son1GameAreaSelectShow = true
      },
      son1GameAreaSelectLeave() {
        this.son1GameAreaSelectShow = false
      },

      // 收货
      sellOrder(item, index) {
        console.log(item)
        if (item.value == '' || item.value == 0 || item.value == undefined) {
          this.message('请填写出售数量', 'error')
          return
        }
        // 判断是否实名
        realInfo().then((res) => {
          if (res.code == 200) {
            if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
              this.message('未实名认证，请先实名认证', 'error')
              setTimeout(() => {
                this.$router.push('/mineIndex/28')
              }, 1500)
            } else {
              if (item.value < item.minNum) {
                this.message(`单次出售不得低于${item.minNum}`, 'error')
                return
              }
              if (item.value > item.maxNum) {
                this.message(`单次出售不得高于${item.maxNum}`, 'error')
                return
              }
              let info = {
                id: item.id,
                numValue: item.value,
                // priceValue: item.priceValue,
                num: item.value,
                type: 'receiving',
              }
              // localStorage.setItem('confirmOrder', JSON.stringify(info));
              this.$router.push({
                path: '/confirmOrder',
                query: {
                  info: JSON.stringify(info),
                },
              })
            }
          }
        })

        // if (this.isEmpty(item.value)) {
        // 	return
        // }
        // saleGoods({
        // 	id: item.id,
        // 	num: item.value
        // }).then(res => {
        // 	if (res.code == 200) {
        // 		this.message('操作成功', 'success')
        // 		this.receiveList[index].value = ''
        // 	}
        // })
      },
      // 获取收货列表
      getReceiveGoods() {
        if (this.receiveSearch.commodityTypeId == '') {
          this.receiveSearch.commodityTypeId = null
        }
        receiveGoods(this.receiveSearch).then((res) => {
          if (res.code == 200) {
            this.receiveList = res.result.records
            this.receiveTotal = res.result.total
          }
        })
      },
      selectSearch() {
        this.search.title == ''
        this.receiveSearch.title == ''
        // 游戏商品分类
        if (Object.keys(this.gameTypeSelect).length != 0) {
          this.search.commodityTypeId = this.gameTypeSelect.id
          this.receiveSearch.commodityTypeId = this.gameTypeSelect.id
        } else {
          this.search.commodityTypeId = ''
          this.receiveSearch.commodityTypeId = ''
        }
        // 游戏商品子类
        if (Object.keys(this.sonGameTypeSelect).length != 0) {
          this.search.childCommodityType = this.sonGameTypeSelect.name
        } else {
          this.search.childCommodityType = ''
        }
        let districtId = ''
        // 区服处理-操作系统
        if (Object.keys(this.systemSelect).length != 0) {
          districtId = this.systemSelect.id
        } else {
          districtId = ''
        }
        // 区服处理-大区
        if (Object.keys(this.gameAreaSelect).length != 0) {
          if (this.gameInfo.terminalType == 1) {
            districtId = this.gameAreaSelect.id
          } else {
            districtId = districtId + '/' + this.gameAreaSelect.id
          }
        } else {
          districtId = districtId
        }
        // 区服处理-服务器
        if (Object.keys(this.sonGameAreaSelect).length != 0) {
          districtId = districtId + '/' + this.sonGameAreaSelect.id
        }
        // 区服处理-服务器
        if (Object.keys(this.son1GameAreaSelect).length != 0) {
          districtId = districtId + '/' + this.son1GameAreaSelect.id
        }
        this.search.districtId = districtId

        this.receiveSearch.districtId = districtId
        this.receiveSearch.pageNo = 1
        this.receiveSearch.pageSize = 2
        this.hotSeach.pageNo = 1
        this.hotSeach.pageSize = 2
        this.displayType = ''

        let info = {
          id: this.gameInfo.id,
          terminalType: this.gameInfo.terminalType,
          name: this.gameInfo.name,
          icon: this.gameInfo.icon,
          displayType: this.displayType,
          orderType: this.search.orderType,
          priceMin: this.search.priceMin,
          priceMax: this.search.priceMax,
        }
        if (!this.isEmpty(this.gameTypeSelect)) {
          info.gameTypeSelect = this.gameTypeSelect
        }
        if (!this.isEmpty(this.sonGameTypeSelect)) {
          info.gameTypeSonSelect = this.sonGameTypeSelect
        }

        if (!this.isEmpty(this.systemSelect)) {
          info.selectSystem = this.systemSelect
        }
        if (!this.isEmpty(this.gameAreaSelect)) {
          info.selectDistrict = this.gameAreaSelect
        }
        if (!this.isEmpty(this.sonGameAreaSelect)) {
          info.sonSelectDistrict = this.sonGameAreaSelect
        }
        if (!this.isEmpty(this.son1GameAreaSelect)) {
          info.son1SelectDistrict = this.son1GameAreaSelect
        }
        this.getGameCommodityList()
        // this.getGameCommodityList1()
        // this.getReceiveGoods()
        // console.log(this.$router)
        // localStorage.setItem('gameList', JSON.stringify(info));
        this.$router.replace({
          path: '/gameList',
          query: {
            info: JSON.stringify(info),
          },
        })
      },

      // 跨区选择
      spannedClick(item) {
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.search.spannedId = item.id

        let info = {
          id: item.pdistrictId,
          name: item.pdistrictName,
        }
        let newInfo = {
          id: item.districtId,
          name: item.districtName,
        }
        this.gameAreaSelect = info
        this.sonGameAreaSelect = newInfo
        this.getDistrictList()
        this.getSonGameArea(info.id)

        this.selectSearch()
      },
      // 选择商品类型
      gameTypeClick(index) {
        if (index) {
          if (this.gameType[index].gameCommodityCategory == 1 || this.gameType[index].gameCommodityCategory == 12) {
            this.search.orderType = 3
          }
          if (this.gameType[index].gameCommodityCategory == 2 || this.gameType[index].gameCommodityCategory == 8) {
            this.$router.push('/gameLeveling')
            return
          }
        }
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.ratioTip = true
        this.hotSeach.pageNo = 1
        this.hotSeach.pageSize = 2
        this.hotTotalShow = false
        if (index !== '') {
          this.gameTypeIndex = index
          let info = {
            id: this.gameType[this.gameTypeIndex].id,
            name: this.gameType[this.gameTypeIndex].name,
          }
          this.gameTypeSelect = info
          this.getOtherList(info.id)
        } else {
          this.gameTypeIndex = ''
          this.gameTypeSelect = {}
        }

        this.selectSearch()
        // this.getSpannedList()
      },
      // 选择商品子类型
      sonGameTypeClick(index) {
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.sonGameTypeIndex = index
        if (index !== '') {
          this.sonGameTypeIndex = index
          let info = {
            name: this.gameType[this.gameTypeIndex].childrenName.split(' ')[this.sonGameTypeIndex],
          }
          this.sonGameTypeSelect = info
        } else {
          this.sonGameTypeIndex = ''
          this.gameTypeSelect = {}
        }

        this.selectSearch()
      },
      // 选择操作系统
      systemListClick(index) {
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.systemIndex = index
        let info = {
          id: this.systemList[this.systemIndex].id,
          name: this.systemList[this.systemIndex].name,
        }
        this.systemSelect = info
        this.getSonGameArea(info.id, 'system')

        this.selectSearch()
      },
      // 选择游戏区服
      gameAreaClick(index) {
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.gameAreaIndex = index
        let info = {
          id: this.gameArea[this.gameAreaIndex].id,
          name: this.gameArea[this.gameAreaIndex].name,
        }
        this.gameAreaSelect = info
        this.sonGameAreaSelect = {}
        this.getSonGameArea(info.id, '')

        this.selectSearch()
      },
      // 选择游戏区
      sonGameAreaClick(index) {
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.sonGameAreaIndex = index
        let info = {
          id: this.sonGameArea[this.sonGameAreaIndex].id,
          name: this.sonGameArea[this.sonGameAreaIndex].name,
        }
        this.sonGameAreaSelect = info
        this.getDistrictList1(this.sonGameAreaSelect.id)

        this.selectSearch()
      },
      // 选择游戏区
      son1GameAreaClick(index) {
        this.son1GameAreaSelectShow = false
        this.sonGameAreaSelectShow = false
        this.gameAreaSelectShow = false
        this.systemSelectShow = false
        this.sonGameTypeSelectShow = false
        this.hoverShow = false
        this.son1GameAreaIndex = index
        let info = {
          id: this.son1GameArea[this.son1GameAreaIndex].id,
          name: this.son1GameArea[this.son1GameAreaIndex].name,
        }
        this.son1GameAreaSelect = info
        // this.getDistrictList1(this.sonGameAreaSelect.id)

        this.selectSearch()
      },
      // 其他选项
      getOtherList(id) {
        fieldList({
          gameTypeId: id,
        }).then((res) => {
          this.otherList = []
          res.result.forEach((data) => {
            data.value = []
            if (data.form != 1) {
              this.otherList.push(data)
            }
          })
        })
      },
      // 子区数据
      getSonGameArea(id, type) {
        districtList({
          // id:this.gameArea[this.gameAreaIndex].id
          id: id,
        }).then((res) => {
          if (res.code == 200) {
            if (type == 'system') {
              this.gameArea = res.result
            } else {
              this.sonGameArea = res.result
            }
          }
        })
      },
      // 跨区列表
      getSpannedList() {
        spannedList({
          gameTypeId: this.gameType[this.gameTypeIndex].id,
        }).then((res) => {
          if (res.code == 200) {
            this.spannedList = res.result
          }
        })
      },

      // 获取商品类型
      getGameCommodityTypes() {
        let that = this
        gameCommodityTypes({
          id: this.search.gameId,
        }).then((res) => {
          if (res.code == 200) {
            this.gameType = res.result
            if (this.gameInfo.gameTypeSelect != undefined) {
              res.result.forEach((item, index) => {
                if (item.id == that.gameTypeSelect.id) {
                  that.gameTypeIndex = Number(index)
                }
              })
            }
            if (this.gameInfo.gameTypeSonSelect != undefined) {
              this.gameType[this.gameTypeIndex].childrenName.split(' ').forEach((item, index) => {
                console.log(item)
                console.log(this.sonGameTypeSelect.name)
                if (item == that.sonGameTypeSelect.name) {
                  that.sonGameTypeIndex = Number(index)
                }
              })
            }
          }
        })
      },
      // 获取操作系统
      getSystemList() {
        districtList({
          id: this.search.gameId,
        }).then((res) => {
          if (res.code == 200) {
            this.systemList = res.result
          }
        })
      },
      // 获取游戏区服
      getDistrictList(id) {
        districtList({
          id: this.search.gameId,
        }).then((res) => {
          if (res.code == 200) {
            this.gameArea = res.result
          }
        })
      },
      // 获取游戏区服
      getDistrictList1(id) {
        districtList({
          id: id,
        }).then((res) => {
          if (res.code == 200) {
            this.son1GameArea = res.result
          }
        })
      },

      // 商品列表
      getGameCommodityList() {
        gameCommodityList(
          Object.assign(
            {
              isHot: 0,
            },
            this.search,
            this.otherSelect
          )
        ).then((res) => {
          if (res.code == 200) {
            this.gameList = res.result.records
          } else {
            this.gameList = []
          }
          this.totalNum = res.result.total
        })
      },

      getGameCommodityList1() {
        let search = JSON.parse(JSON.stringify(this.search))
        search.pageNo = this.hotSeach.pageNo
        search.pageSize = this.hotSeach.pageSize
        // search.isHot = 1
        gameCommodityList(
          Object.assign(
            {
              isHot: 1,
            },
            search,
            this.otherSelect
          )
        ).then((res) => {
          if (res.code == 200) {
            if (search.pageNo == 1) {
              this.hotList = res.result.records
              this.hotList.forEach((data, index) => {
                this.$set(this.hotList[index], 'numValue', data.minOrderNum)
              })
            } else {
              this.hotList = [...this.hotList, ...res.result.records]
              this.hotList.forEach((data, index) => {
                this.$set(this.hotList[index], 'numValue', data.minOrderNum)
              })
            }
            this.hotTotal = res.result.total
          } else {
            this.hotList = []
          }
        })
      },
      // 最小最大价格查询
      searchPrice() {
        if (this.search.priceMax < this.search.priceMin) {
          this.message('不能小于最小价格', 'error')
          this.search.priceMax = ''
          return
        }
        this.search.pageNo = 1

        let info = {
          id: this.gameInfo.id,
          terminalType: this.gameInfo.terminalType,
          name: this.gameInfo.name,
          icon: this.gameInfo.icon,
          displayType: this.displayType,
          orderType: this.search.orderType,
          priceMin: this.search.priceMin,
          priceMax: this.search.priceMax,
        }
        if (!this.isEmpty(this.gameTypeSelect)) {
          info.gameTypeSelect = this.gameTypeSelect
        }
        if (!this.isEmpty(this.sonGameTypeSelect)) {
          info.gameTypeSonSelect = this.sonGameTypeSelect
        }

        if (!this.isEmpty(this.systemSelect)) {
          info.selectSystem = this.systemSelect
        }
        if (!this.isEmpty(this.gameAreaSelect)) {
          info.selectDistrict = this.gameAreaSelect
        }
        if (!this.isEmpty(this.sonGameAreaSelect)) {
          info.sonSelectDistrict = this.sonGameAreaSelect
        }
        this.getGameCommodityList()
        // localStorage.setItem('gameList', JSON.stringify(info));
        this.$router.replace({
          path: '/gameList',
          query: {
            info: JSON.stringify(info),
          },
        })
      },
      otherMouseLeave() {
        this.otherShow = false
      },
      // 分类查询
      typeSearch(type) {
        if (type == 5) {
          this.search.orderType = 3
        } else if (type == 3) {
          if (this.search.orderType == 4) {
            this.search.orderType = 5
          } else {
            this.search.orderType = 4
          }
        } else {
          this.search.orderType = type
        }
        this.search.pageNo = 1

        let info = {
          id: this.gameInfo.id,
          terminalType: this.gameInfo.terminalType,
          name: this.gameInfo.name,
          icon: this.gameInfo.icon,
          displayType: this.displayType,
          orderType: this.search.orderType,
          priceMin: this.search.priceMin,
          priceMax: this.search.priceMax,
        }
        if (!this.isEmpty(this.gameTypeSelect)) {
          info.gameTypeSelect = this.gameTypeSelect
        }
        if (!this.isEmpty(this.sonGameTypeSelect)) {
          info.gameTypeSonSelect = this.sonGameTypeSelect
        }

        if (!this.isEmpty(this.systemSelect)) {
          info.selectSystem = this.systemSelect
        }
        if (!this.isEmpty(this.gameAreaSelect)) {
          info.selectDistrict = this.gameAreaSelect
        }
        if (!this.isEmpty(this.sonGameAreaSelect)) {
          info.sonSelectDistrict = this.sonGameAreaSelect
        }
        this.getGameCommodityList()
        // localStorage.setItem('gameList', JSON.stringify(info));
        this.$router.replace({
          path: '/gameList',
          query: {
            info: JSON.stringify(info),
          },
        })
      },
      // 添加购物车
      addCard(item) {
        shopCartAdd({
          id: item.id,
          num: 1,
          price: item.price,
        }).then((res) => {
          if (res.code == 200) {
            this.message('添加成功！', 'success')
          }
        })
      },
      toDetaile(item) {
        this.$router.push('/gameDetaile/' + item.id)
      },
      setCommand(e, index) {
        // this.$set(this.otherList[index], "value", e)
        let newShopForm = []
        this.otherList.forEach((item) => {
          if (item.value.length != 0) {
            let key = {
              nameKey: `field${item.fieldNum}Type`,
              nameValue: item.fieldType,
              valueKey: `field${item.fieldNum}Value`,
              valueValue: item.value,
            }
            newShopForm.push(key)
          }
        })
        this.otherSelect = newShopForm.reduce((obj, item) => {
          // if (item.valueValue.length <= 1) {
          //   obj[item.nameKey] = 0
          // } else {
          obj[item.nameKey] = 1
          // }
          obj[item.valueKey] = item.valueValue
          return obj
        }, {})
        // console.log(this.otherList)
        // console.log(this.otherSelect)
        // return
        this.selectSearch()
      },

      // 关闭标签
      colse(type) {
        if (type == 'gameTypeSelect') {
          this.gameTypeSelect = {}
          this.gameTypeIndex = ''
          this.sonGameTypeSelect = {}
          this.sonGameTypeIndex = ''
        }
        if (type == 'sonGameTypeSelect') {
          this.sonGameTypeSelect = {}
          this.sonGameTypeIndex = ''
        }

        if (type == 'systemSelect') {
          this.systemSelect = {}
          this.systemIndex = ''

          this.gameArea = []
          this.gameAreaSelect = {}
          this.gameAreaIndex = ''

          this.sonGameArea = []
          this.sonGameAreaSelect = {}
          this.sonGameAreaIndex = ''
        }
        if (type == 'gameAreaSelect') {
          this.gameAreaSelect = {}
          this.gameAreaIndex = ''

          this.sonGameArea = []
          this.sonGameAreaSelect = {}
          this.sonGameAreaIndex = ''
          this.search.spannedId = ''
        }
        if (type == 'sonGameAreaSelect') {
          this.sonGameAreaSelect = {}
          this.sonGameAreaIndex = ''
          this.search.spannedId = ''
        }
        if (type == 'son1GameAreaSelect') {
          this.son1GameAreaSelect = {}
          this.son1GameAreaIndex = ''
          this.search.spannedId = ''
        }

        this.selectSearch()
      },
      setType(index, type) {
        this.$set(this.hotList[index], 'payType', type)
      },
      setTitle(e, index) {
        this.receiveList[index].value = parseInt(this.receiveList[index].value)

        // e = parseInt(e)
      },
      setTitle1(e, index) {
        this.hotList[index].numValue = parseInt(this.hotList[index].numValue)
      },
      toPay(item) {
        if (item.numValue == '' || item.numValue == 0 || item.numValue == undefined) {
          this.message('请填写购买数量', 'error')
          return
        }
        if (item.numValue < item.minOrderNum) {
          this.message(`单次最少购买${item.minOrderNum}件`, 'error')
          return
        }

        // 判断是否实名
        realInfo().then((res) => {
          if (res.code == 200) {
            if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
              // this.realShow = true
              this.message('请先完成实名认证', 'error')
              setTimeout(() => {
                this.$router.push('/mineIndex/28')
              }, 1000)
            } else {
              let info = {
                numValue: item.numValue,
                priceValue: item.priceValue,
                id: item.id,
                num: 1,
                payType: item.payType,
                type: 'hot',
                minOrderNum: item.minOrderNum,
              }
              // localStorage.setItem('confirmOrder', JSON.stringify(info));
              this.$router.push({
                path: '/confirmOrder',
                query: {
                  info: JSON.stringify(info),
                },
              })
              // this.$router.push('/confirmOrder')
            }
          }
        })
      },
      toPublic() {
        // 判断是否实名
        realInfo().then((res) => {
          if (res.code == 200) {
            if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
              this.message('请先完成实名认证', 'error')
              setTimeout(() => {
                this.$router.push('/mineIndex/28')
              }, 1000)
            } else {
              let info = {
                id: this.gameInfo.id,
                terminalType: this.gameInfo.terminalType,
                name: this.gameInfo.name,
                icon: this.gameInfo.icon,
                gameTypeIndex: this.gameTypeIndex,
                addType: 'add',
                selectChildTypeIndex: this.sonGameTypeIndex,
                systemSelect: this.systemSelect.id,
                gameAreaSelect: this.gameAreaSelect.id,
                sonGameAreaSelect: this.sonGameAreaSelect.id,
              }
              // localStorage.setItem('publish1', JSON.stringify(info));
              this.$store.commit('setHeadBarActive', '0')
              this.$router.push({
                path: '/publish1',
                query: {
                  info: JSON.stringify(info),
                },
              })
            }
          }
        })
      },
      toOrderPay(item) {
        // 判断是否实名
        realInfo().then((res) => {
          if (res.code == 200) {
            if (res.result == null || res.result.idCardAudit != 1 || res.result.faceAudit != 1) {
              // this.realShow = true
              this.message('请先完成实名认证', 'error')
              setTimeout(() => {
                this.$router.push('/mineIndex/28')
              }, 1000)
            } else {
              let info = {
                id: item.id,
                num: 1,
                payType: item.payType,
                type: 'pay',
              }
              // localStorage.setItem('confirmOrder', JSON.stringify(info));
              this.$router.push({
                path: '/confirmOrder',
                query: {
                  info: JSON.stringify(info),
                },
              })
            }
          }
        })
      },
      checkElementPosition() {
        // 访问元素
        const element = this.$refs.myElement
        // 获取元素的边界信息
        const rect = element.getBoundingClientRect()
        if (rect.top > 0) {
          this.mainsTop = rect.top
        } else {
          this.mainsTop = 0
        }
      },
      toGameList(type) {
        let info = {
          id: this.gameInfo.id,
          terminalType: this.gameInfo.terminalType,
          name: this.gameInfo.name,
          icon: this.gameInfo.icon,
          displayType: type,
          orderType: this.search.orderType,
          priceMin: this.search.priceMin,
          priceMax: this.search.priceMax,
        }
        if (!this.isEmpty(this.gameTypeSelect)) {
          info.gameTypeSelect = this.gameTypeSelect
        }
        if (!this.isEmpty(this.sonGameTypeSelect)) {
          info.gameTypeSonSelect = this.sonGameTypeSelect
        }

        if (!this.isEmpty(this.systemSelect)) {
          info.selectSystem = this.systemSelect
        }
        if (!this.isEmpty(this.gameAreaSelect)) {
          info.selectDistrict = this.gameAreaSelect
        }
        if (!this.isEmpty(this.sonGameAreaSelect)) {
          info.sonSelectDistrict = this.sonGameAreaSelect
        }
        // localStorage.setItem('gameList', JSON.stringify(info));
        window.open(
          this.$router.resolve({
            path: '/gameList',
            query: {
              info: JSON.stringify(info),
            },
          }).href,
          '_blank'
        )
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.checkElementPosition)
    },
    mounted() {
      // this.$nextTick(() => {
      //   this.$refs.dcPopover.updatePopper() // 注意主要是这里
      // })
      window.addEventListener('scroll', this.checkElementPosition)
    },
  }
</script>

<style lang="scss" scoped>
  .lableTop {
    position: relative;

    .lableImage {
      width: 100%;

      // height: 150px;
      position: absolute;
      z-index: 0;
      cursor: pointer;
    }
  }

  .lableBg {
    margin-top: 30px;
    margin-bottom: 20px;
    // height: 150px;
    display: flex;
    cursor: pointer;
    background: #fff;
    height: 90px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px #e0e0e0;
    border-radius: 10px;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    .lableTitle {
      font-weight: bold;
      font-size: 24px;
      color: #000;
    }

    .lableDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #000;
      margin-top: 12px;
    }
  }

  .breadcrumb {
    align-items: center;
    font-size: 14px;
  }

  ::v-deep .el-breadcrumb {
    font-size: 14px;
  }

  ::v-deep .el-breadcrumb__separator {
    margin: 0 5px;
  }

  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #000;
    font-weight: bold;
  }

  .screen {
    color: #777a8a;

    // margin-top: -80px;
    border-radius: 20px 20px 20px 20px;
    position: relative;

    // padding: 0 0 24px;
  }

  .screenTop {
    justify-content: space-between;
    // border-bottom: 1px solid #ECECEC;
    position: relative;
    z-index: 99;
  }

  .screenDown {
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #ececec;
    padding: 4px 8px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
  }

  ::v-deep .el-tag.el-tag--info .el-tag__close {
    color: #ff5c00;
  }

  ::v-deep .el-tag.el-tag--info .el-tag__close:hover {
    background-color: rgba(255, 92, 0, 0.3);
  }

  .screenItem {
    color: #a5a7b1;
    align-items: center;
    min-height: 44px;
    background: #f7f7f7;
    width: 100%;

    .screenLable {
      width: 120px;
      text-align: center;
      min-height: 44px;
      // line-height: 64px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .screenOptions {
      margin-left: 20px;
      padding: 10px 8px;
      width: 100%;
      min-height: 44px;
      background: #fff;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // overflow-x: auto;
    }
  }

  .screenItem1 {
    color: #a5a7b1;
    align-items: center;
    // min-height: 64px;
    background: #f7f7f7;

    .screenOptions1 {
      margin-left: 20px;
      padding: 10px 8px;
      width: 100%;
      min-height: 44px;
      background: #fff;
      // position: relative;
      // z-index: 9999;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      // overflow-x: auto;
      // overflow-y: visible;
    }

    .screenLable1 {
      width: 120px;
      text-align: center;
      min-height: 44px;
      // line-height: 64px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .el-tag {
    margin-right: 20px;
    font-size: 14px;
  }

  .optionsItem {
    text-decoration: none;
    margin-right: 25px;
    font-size: 14px;
    color: #1d223c;
    padding: 5px 8px;
    // margin-bottom: 10px;
    display: inline-block;
    cursor: pointer;
  }

  .optionsItem:hover {
    color: #ff5c00;
  }

  .optionsAction {
    background: #1861ee;
    border-radius: 24px 24px 24px 24px;
    color: #fff;
  }

  .el-dropdown-link {
    font-size: 18px;
    color: #000;
  }

  .otherValue {
    position: absolute;
    top: 45px;
    background: #fcfcfc;
    display: block;
    /* 或者其他支持边框的值 */
    border: 1px solid #d6d6d6;
    z-index: 9999;
    left: 0;
    width: calc(100% - 60px);
    padding: 20px 30px;
    display: inline-block;
  }

  .otherItem {
    margin-right: 25px;
    // margin-bottom: 20px;
    position: relative;
    color: #000;
    width: 200px;

    ::v-deep .el-select__tags {
      width: auto;
      flex-wrap: nowrap;
    }

    ::v-deep .el-dropdown-menu {
      position: absolute !important;
      top: 30px !important;
      left: -20px !important;
    }

    ::v-deep .el-select {
      // width: auto;
      max-width: 200px;
    }

    ::v-deep .el-select-dropdown.el-popper {
      top: auto !important;
      left: auto !important;
      display: block;
      width: auto;
      z-index: 999999 !important;
    }

    ::v-deep .el-input__inner {
      border: none;
      width: auto;
      padding: 0 10px;
    }

    ::v-deep .el-input__inner::placeholder {
      color: #000;
      font-size: 18px;
    }

    ::v-deep .el-input {
      width: auto;
      width: 180px;
    }
  }

  .sortList {
    height: 33px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #ececec;
    overflow: hidden;

    .sortItem {
      font-weight: 400;
      font-size: 12px;
      color: #1d223c;
      text-align: center;
      height: 33px;
      width: 50px;
      line-height: 33px;
      padding: 0 4px;
      border-right: 1px solid #ececec;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .sortItem:nth-child(3) {
      border: none;
    }

    .sortActin {
      background: #1861ee;
      color: #fff;
    }
  }

  .screenPrice {
    display: flex;
    align-items: center;
    margin-left: 15px;

    .price1 {
      width: 50px;
      background-color: #fff;
      border: 1px solid #ececec;
    }

    .price {
      width: 80px;
      background-color: #fff;
      height: 30px;
      line-height: 30px;
      border-radius: 4px 4px 4px 4px;

      input {
        width: 50px;
      }
    }

    .price2 {
      width: 76px;
      border: 1px solid #ececec;
      height: 30px;
      line-height: 30px;
      border-radius: 4px 4px 4px 4px;
      background-color: #fff;

      input {
        width: 50px;
      }
    }
  }

  .publish {
    display: flex;
    flex-direction: column;
    width: 164px;
    height: 48px;
    background: #ff5c00;
    border-radius: 4px 4px 4px 4px;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }

  .guarantee {
    padding: 20px;
    width: 100%;
    font-size: 18px;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid #ececec;
    align-items: center;
  }

  .infoTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    color: #000000;
    // width: 100%;
    position: relative;
    cursor: pointer;
    display: inline-block;
    // min-width: 50px;

    .infoAccount {
      font-size: 12px;
      background: #ff5c00;
      border-radius: 4px;
      color: #fff;
      padding: 4px 6px;
      margin-right: 10px;
    }

    .infoImage {
      font-size: 12px;
      background: #1861ee;
      border-radius: 4px;
      color: #fff;
      padding: 4px 6px;
      margin-left: 5px;
    }

    .hoverDiv {
      // display: none;
      // position: absolute;
      // right: -10px;
    }

    .showInfo {
      display: none;
    }
  }

  .infoTitle:hover {
    color: #ff5c00;
    position: relative;
    display: inline-block;

    .showInfo {
      display: block;
      position: absolute;
      right: -480px;

      top: 50%;
      // left: 50%;
      transform: translate(0, -50%);

      padding: 16px 16px 15px 16px;
      background: #fff;
      border: 2px solid #1861ee;
      width: 436px;
      // height:400px;
      z-index: 300000;
      // top: 50%;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

      .acc-triangle {
        width: 12px;
        height: 12px;
        border: 2px solid #1861ee;
        border-right: 0;
        border-bottom: 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        position: absolute;
        left: -7px;
        background: #fff;
        z-index: 3000;
        top: 48%;
      }

      .showTitle {
        line-height: 21px;
        font-size: 14px;
        padding-bottom: 16px;
        border-bottom: 1px dashed #ededed;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .showItem {
        display: flex;

        .itemName {
          min-width: 120px;
          font-size: 12px;
          color: #999;
          margin-right: 10px;
        }

        .itemValue {
          font-size: 12px;
        }
      }
    }
  }

  .infoSpan {
    line-height: 32px;

    .spanItem {
      height: 30px;
      font-weight: 400;
      font-size: 12px;
      color: #777a8a;
      background: #f5f5f5;
      border-radius: 2px 2px 2px 2px;
      padding: 5px 8px;
      margin-right: 10px;
    }
  }

  .serverSide {
    font-size: 16px;
    color: #ffe350;
    line-height: 19px;
    background-color: #1b2948;
    padding: 2px 4px;
    border-radius: 2px;
    position: relative;
    margin-left: 10px;
  }

  .serverSide::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -4px;
    transform: rotate(-45deg);
    border-top: 10px solid #1b2948;
    /* 三角形的高度和颜色 */
    border-right: 10px solid transparent;
    /* 三角形的宽度 */
  }

  .area {
    font-weight: 400;
    font-size: 14px;
    color: #777a8a;
    margin-top: 10px;
  }

  .accountPrice {
    color: #ff5c00;
    font-weight: bold;
    font-size: 18px;
    width: 15%;
    text-align: center;
  }

  .accountNumber {
    color: #000;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    width: 15%;
  }

  .accountNumber1 {
    color: #000;
    font-weight: bold;
    font-size: 18px;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .accountService {
    width: 20%;
    // margin: 0 auto;
    font-size: 18px;
    color: #1d223c;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accountPay {
    display: flex;

    .accountDetaile {
      width: 118px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ff5c00;
      font-size: 14px;
      color: #ff5c00;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }

    .accountDetaile:hover {
      background-color: #ff5c00;
      color: #fff;
    }

    .addCard {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ff5c00;
      padding: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  ::v-deep .el-pagination span:not([class*='suffix']) {
    font-size: 18px;
  }

  ::v-deep .el-pager li {
    font-size: 18px;
  }

  ::v-deep .el-dropdown-menu {
    min-width: 120px;
    position: absolute !important;
    top: 35px !important;
    // left: -45px !important;
  }

  .popTitle {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px;
  }

  .popLable {
    font-weight: 400;
    font-size: 18px;
    color: #a5a7b1;
    line-height: 32px;
    text-align: left;
    width: 200px;
  }

  .popValue {
    font-weight: 600;
    font-size: 18px;
    color: #1d223c;
    line-height: 32px;
    text-align: left;
  }

  .shopLeft {
    // width: 161px;
    // height: 872px;
    // background: #FFFFFF;
    // border-radius: 8px 8px 8px 8px;
    // border: 1px solid #FF5C00;
  }

  .hotMains {
    position: fixed;
    left: calc(50% - 950px + 100px);
    margin-left: 10px;
    z-index: 999;
    width: 220px;
    background: #ffffff;
    border: 1px solid #ff5c00;
    border-radius: 8px 8px 8px 8px;
  }

  /* 宽度低于1920，给主体内容左右加留白 */
  @media screen and (max-width: 1200px) {
    .hotMains {
      display: none;
    }
  }

  .hotMain {
    width: 220px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;

    .mainLeft {
      height: 25px;
      background: #fff4e8;
      padding: 16px;
      justify-content: space-between;
      border-radius: 8px 8px 0 0;
      font-weight: 600;
      font-size: 14px;
      color: #ff5c00;
    }

    .topRight {
      width: 78px;
      height: 28px;
      // background: #FF5C00;
      border-radius: 6px 6px 6px 6px;
      font-weight: 400;
      font-size: 14px;
      color: #666;
      line-height: 21px;
      text-align: center;
      line-height: 28px;
    }

    // .inputNumber {
    // 	border: 1px solid #DEDEDE;
    // 	width: 150px;
    // 	display: flex;
    // 	justify-content: space-between;
    // 	margin: 0 auto;
    // }
  }

  .gotArea {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    text-align: center;
    margin-top: 24px;
  }

  .tagList {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    .tagItem {
      font-weight: 500;
      font-size: 16px;
      color: #1861ee;
      padding: 1px 5px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #1861ee;
      margin-right: 8px;
    }
  }
  .tagList1 {
    margin-top: 8px;
    .tagItem1 {
      font-weight: 500;
      font-size: 12px;
      color: #ff5c00;
      padding: 1px 5px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #ff5c00;
      margin-right: 8px;
    }
  }

  .discount {
    font-weight: 600;
    font-size: 16px;
    color: #ff5c00;
    text-align: center;
    margin-top: 16px;
  }

  .discount1 {
    font-weight: 500;
    font-size: 14px;
    color: #777a8a;
    text-align: center;
    margin-top: 8px;
  }

  .collectNumber {
    margin: 10px 36px 8px;
    font-weight: 400;
    font-size: 14px;
    color: #a5a7b1;
    line-height: 19px;
  }

  .inputNumber {
    border: 1px solid #dedede;
    width: 189px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    height: 35px;

    input {
      border: none;
    }

    .unit {
      width: 74px;
      height: 33px;
      background: #f7f7f7;
      border-radius: 0px 4px 4px 0px;
      border: 1px solid #dedede;
      text-align: center;
      line-height: 33px;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
  }

  .sellSubmit {
    width: 90%;
    height: 35px;
    background: #ff5c00;
    border-radius: 6px 6px 6px 6px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    margin: 16px;
  }

  // .takeItem {
  // 	position: relative;
  // }

  // .takeItem::after {
  // 	position: absolute;
  // 	bottom: 0;
  // 	left: 16px;
  // 	content: '';
  // 	width: 248px;
  // 	height: 1px;
  // 	background-color: #ECECEC;
  // }

  .mainRight {
    width: 100%;
    border: 1px solid #ff5c00;
    border-radius: 8px 8px 8px 8px;

    .th1 {
      width: 15%;
    }

    .th2 {
      width: 20%;
    }

    .th3 {
      width: 15%;
    }

    .th4 {
      width: 30%;
    }

    .th5 {
      width: 10%;
    }

    .th6 {
      text-align: right;
      width: 10%;
    }

    .rightTop {
      width: calc(100% - 40px);
      height: 64px;
      background: #fff4e8;
      padding: 0 20px;
      border-radius: 8px 8px 0px 0px;
    }

    .th1Text {
      font-weight: 600;
      font-size: 16px;
      color: #ff5c00;
      // margin-right: 24px;
    }

    .th6Text {
      width: 88px;
      height: 33px;
      background: #ff5c00;
      border-radius: 6px 6px 6px 6px;
      font-size: 14px;
      color: #ffffff;
      padding: 3px 8px;
    }

    .thText {
      font-weight: 600;
      font-size: 14px;
      color: #1d223c;
    }
  }

  .hotGoodList {
    .hotGoodItem {
      padding: 10px 20px;
      border-bottom: 1px solid #ececec;

      .hotGoodTitle {
        width: 70px;
        margin-top: 12px;
        font-weight: 600;
        font-size: 14px;
        color: #1d223c;
        text-align: center;
      }

      .hotInfoTag {
        font-weight: 400;
        font-size: 14px;
        color: #a5a7b1;
        margin-top: 8px;
      }

      .th5Text {
        font-weight: 400;
        font-size: 14px;
        color: #1d223c;
        margin-bottom: 12px;
      }

      .th6Submit {
        width: 118px;
        height: 35px;
        background: #ff5c00;
        border-radius: 6px 6px 6px 6px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 35px;
        text-align: center;
      }

      .th3top {
        display: flex;
        width: 110px;
        height: 30px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ff5c00;
        font-weight: 600;
        font-size: 14px;
        color: #ff5c00;
        line-height: 30px;
      }

      .th3Image {
        width: 30px;
        height: 30px;
        background: #ff5c00;
        border-radius: 3px 0px 0px 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hotType {
        width: 152px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #ff5c00;

        div {
          width: 50%;
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          color: #a5a7b1;
        }

        .typeAction {
          background-color: #ff5c00;
          color: #fff;
          height: 32px;
          line-height: 32px;
        }
      }
    }

    .hotGoodItem:nth-child(n) {
      border: none;
    }
  }

  .hotLeft {
    width: 141px;
    border: 1px solid #ff5c00;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-left: 10px;

    .leftTop {
      width: 121px;
      height: 64px;
      background: #fff4e8;
      border-radius: 8px 8px 0 0;

      font-weight: 600;
      font-size: 18px;
      color: #ff5c00;
      line-height: 64px;
      padding-left: 20px;
    }
  }

  .shopItem {
    padding: 16px;

    .shopName {
      font-weight: 600;
      width: 110px;
      font-size: 16px;
      color: #000000;
    }
  }

  .toDetaile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #1861ee;
    text-align: center;
  }

  .seeAll {
    font-weight: 400;
    font-size: 16px;
    color: #ff5c00;
    text-align: center;
    cursor: pointer;
  }

  .popperClass {
    position: relative;
    z-index: 99999 !important;
    // position: absolute !important;
    // left: 500px !important;
    // top: auto !important;
  }

  .hoverList {
    position: absolute;
    left: 0;
    top: 31px;
    background-color: #fff;
    width: 700px;
    // height: 564px;

    // border-radius: 8px;
    border: 1px solid #ff5c00;
    z-index: 9999;

    .hoverTitle {
      font-size: 26px;
      font-weight: 600;
      padding: 20px 40px;
    }

    .hoverTag {
      padding: 10px 20px;
      display: flex;
      flex-wrap: wrap;
      color: #000;

      .tagItem {
        width: 150px;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        // margin-right: 15px;
      }

      .tagItem:hover {
        color: #ff5c00;
      }
    }
  }

  .screenOptionsItem {
    position: relative;
  }

  .screenTagHover {
    border-left: 1px solid #ff5c00;
    border-top: 1px solid #ff5c00;
    border-right: 1px solid #ff5c00;
  }

  .screenOptionsItem:hover {
    margin-top: 1px;
  }

  .proportion {
    font-size: 14px;
    color: #ff5b01;
    text-align: center;
  }

  .proportion1 {
    font-size: 14px;
    text-align: center;
  }

  .ratio {
    position: absolute;
    left: -5px;
    top: -8px;
    width: 110px;
    height: 22px;
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    background-color: #ff5a01;
    z-index: 99999 !important;
    text-align: center;
    border-radius: 2px;
    padding-left: 10px;
  }

  .ratio:after {
    content: '';
    position: absolute;
    top: 20px;
    /* 或你想要的其他位置 */
    left: 30%;
    /* 为了使三角形居中 */
    margin-left: -5px;
    /* 三角形宽度的一半 */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    /* 左边框透明 */
    border-right: 10px solid transparent;
    /* 右边框透明 */
    border-top: 10px solid #ff5a01;
    /* 上边框颜色为黑色，形成三角形 */
  }

  .serviceItem {
    position: relative;
    font-size: 14px;

    .servieHover {
      position: absolute;
      top: 35px;
      left: 35px;
      font-size: 12px;
      border: 1px solid #000;
      color: #000;
      padding: 3px 10px;
      display: none;
      white-space: nowrap;
    }

    .servieHover:hover {
      color: #000;
    }
  }

  .serviceItem:hover {
    color: #1861ee;

    .servieHover {
      position: absolute;
      top: 35px;
      left: 35px;
      font-size: 12px;
      border: 1px solid #000;
      color: #000;
      padding: 3px 10px;
      display: block;
      white-space: nowrap;
    }

    .servieHover:hover {
      color: #000;
    }
  }

  ::v-deep .el-carousel__indicators {
    display: none;
  }

  .empty {
    color: #ff5b01;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

  ::v-deep .el-checkbox__label {
    font-size: 18px;
  }

  ::v-deep .el-pagination span {
    font-size: 14px;
  }
  .app {
    background: url('@/static/image/newImage/1.jpg') no-repeat;
    background-size: cover;
    // background: #1861EE;
  }
</style>
