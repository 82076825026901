<template>
  <div class="box-css">
    <div class="content" style="width: 1400px">
      <MenuLeft :router="rouerList" :active="active" title="帮助中心" @change="changeMenu">
        <div class="content-css">
          <template v-for="(item, index) of rouerList">
            <div class="item" :key="item.path">
              <div class="list" v-if="active == index && active == 0">
                <div ref="listItem" class="listItem">
                  <div class="noHover" ref="itemShow">
                    <img :src="require('@/static/image/mine/service1.png')" style="width: 80px; height: 80px" />
                    <span class="noHoverTitle">客服QQ验证</span>
                    <span class="noHoverDetaile">仅供验证客服QQ真伪使用<br />不作为客服身份验证依据</span>
                  </div>
                  <div class="noHover1" ref="ItemHide">
                    <span class="noHoverTitle1">客服QQ验证</span>
                    <el-input v-prevent-space class="noHoverInput" v-model="qq" placeholder="请输入内容"></el-input>
                    <span class="noHoverDetaile1">建议进入QQ资料里面获取QQ号进行验证</span>
                    <el-button class="result" style="width: 300px; height: 60px; font-size: 14px" type="primary" @click="verify('1')">开始验证</el-button>
                  </div>
                </div>

                <div class="listItem1">
                  <div class="noHover2" ref="itemShow1">
                    <img :src="require('@/static/image/mine/service2.png')" style="width: 80px; height: 80px" />
                    <span class="noHoverTitle">平台网址验证</span>
                    <span class="noHoverDetaile">不要随意点击别人发送的网址<br />小心电脑中毒被他人操纵</span>
                  </div>
                  <div class="noHover3" ref="ItemHide1">
                    <span class="noHoverTitle1">平台网址验证</span>
                    <el-input style="width: 300px" v-prevent-space class="noHoverInput" v-model="link" placeholder="请输入内容"></el-input>
                    <el-button style="width: 300px; height: 60px; font-size: 14px" class="result" type="primary" @click="verify('2')">开始验证</el-button>
                  </div>
                </div>

                <div class="listItem2">
                  <div class="noHover4" ref="itemShow2">
                    <img :src="require('@/static/image/mine/service3.png')" style="width: 80px; height: 80px" />
                    <span class="noHoverTitle">官方微信验证</span>
                    <span class="noHoverDetaile">不要轻信陌生人发送的消息<br />小心上当受骗</span>
                  </div>
                  <div class="noHover5" ref="ItemHide2">
                    <span class="noHoverTitle1">官方微信验证</span>
                    <el-input style="width: 300px" v-prevent-space class="noHoverInput" v-model="weixin" placeholder="请输入您要验证的官方微信号"></el-input>
                    <el-button style="width: 300px; height: 60px; font-size: 14px" class="result" type="primary" @click="verify('3')">开始验证</el-button>
                  </div>
                </div>

                <div class="listItem3">
                  <div class="noHover6" ref="itemShow3">
                    <img :src="require('@/static/image/mine/service4.png')" style="width: 80px; height: 80px" />
                    <span class="noHoverTitle">我要举报</span>
                    <span class="noHoverDetaile">在这里你可以提交要举报的信息</span>
                  </div>
                  <div class="noHover7" ref="ItemHide3">
                    <span class="noHoverTitle1">我要举报</span>
                    <el-input v-prevent-space class="noHoverInput" v-model="accountAppeal.complainId" placeholder="请输入姓名/QQ/微信/支付宝等信息"></el-input>
                    <el-input v-prevent-space type="number" style="margin-bottom: 16px" v-model="accountAppeal.content" placeholder="涉及金额"></el-input>
                    <div class="fl" style="justify-content: space-between; width: 460px; border: none">
                      <el-input v-prevent-space :rows="7" v-model="accountAppeal.complainContent" class="noHoverArea" type="textarea" placeholder="过程描述"></el-input>
                      <el-upload :action="BASE_URL + '/sys/common/upload?biz=complain-pic'" list-type="picture-card" :on-success="handlePictureCardPreview" :on-error="handleError" ref="uploadFile">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </div>
                    <el-button class="result" type="primary" @click="submit(2)">举报</el-button>
                  </div>
                </div>
              </div>
              <div v-else-if="active == 1 && index == 1" style="margin-top: 42px; margin-left: 30px">
                <div class="formItem fl">
                  <div class="item1">
                    <span style="color: #1d223c; font-size: 24px; font-weight: bold">意见反馈：</span>
                  </div>
<!--                  <div class="item2">-->
<!--                    <span style="color: #0085ff">｜申诉结果查询</span>-->
<!--                  </div>-->
                </div>
                <el-form :model="accountAppeal" :rules="accountAppealRule" ref="ruleForm">
<!--                  <div class="formItem fl" style="height: 50px">-->
<!--                    <div class="item1"><span style="color: #ff3c3c">*</span>反馈类型：</div>-->
<!--                    <div class="item2">-->
<!--                      <el-radio-group v-model="accountAppeal.type">-->
<!--                        <el-radio v-model="accountAppeal.type" label="1">意见反馈</el-radio>-->
<!--                        <el-radio v-model="accountAppeal.type" label="2">用户举报</el-radio>-->
<!--                      </el-radio-group>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="formItem">
                    <div class="item1"><span style="color: #ff3c3c">*</span>反馈内容：</div>
                    <div class="item2">
                      <el-form-item label="" prop="unsealReason">
                        <el-input v-prevent-space v-model="accountAppeal.complainContent" type="textarea" :rows="4" placeholder="请填写反馈内容"></el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="formItem">
                    <div style="display: flex">
                      <div class="item1"><span style="color: #ff3c3c"></span>相关图片(选填)：</div>
                      <div style="margin-left: 8px">
                        <div class="item4" style="border: none">
                          <el-upload :action="BASE_URL + '/sys/common/upload?biz=complain-pic'" list-type="picture-card" :on-success="handlePictureCardPreview" :on-error="handleError" ref="uploadFile">
                            <i class="el-icon-plus"></i>
                          </el-upload>
                        </div>
                      </div>
                    </div>
                  </div>

<!--                  <div class="formItem fl">-->
<!--                    <div class="item1"><span style="color: #ff3c3c">*</span>您的联系电话：</div>-->
<!--                    <div class="item2">-->
<!--                      <el-form-item label="" prop="phone">-->
<!--                        <el-input v-prevent-space style="margin-top: 20px" v-model="accountAppeal.phone" placeholder="请输入您的联系电话"></el-input>-->
<!--                      </el-form-item>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="formItem fl">-->
<!--                    <div class="item1"><span style="color: #ff3c3c">*</span>您的QQ号码：</div>-->
<!--                    <div class="item2">-->
<!--                      <el-form-item label="" prop="qq">-->
<!--                        <el-input v-prevent-space style="margin-top: 20px" v-model="accountAppeal.qq" placeholder="请输入您的qq号码"></el-input>-->
<!--                      </el-form-item>-->
<!--                    </div>-->
<!--                  </div>-->
                </el-form>

                <div class="formItem fl">
                  <div class="item1">&ensp;</div>
                  <div class="item2">
                    <el-button @click="submit(1)" style="font-size: 14px; width: 288px; height: 46px" type="primary">提交申诉</el-button>
                  </div>
                </div>
              </div>

              <div v-else-if="active.includes('2-') || active.includes('3-')" style="display: flex">
                <template v-for="(child, childIndex) in item.child">
                  <div :key="childIndex" v-if="active == child.path" v-html="child.content"></div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </MenuLeft>
    </div>
  </div>
</template>

<script>
import { noticeList, commonProblemList, validate, complainAdd } from '@/api/index.js'
  import headBar from '@/components/HeadBar.vue'
  import shoppingCard from '@/components/shoppingCart.vue'
  import footerBars from '@/components/footerBars.vue'
  import MenuLeft from '@/components/menuLeft/index.vue'
  import { BASE_URL } from '@/api/request';

  export default {
    data() {
      return {
        action: 0,
        active: '0',
        qq: '',
        weixin: '',
        link: '',
        // 申诉表单
        addImage: [],
        accountAppeal: {},
        accountAppealRule: {
          complainContent: [
            {
              required: true,
              message: '请输入反馈内容',
              trigger: 'blur',
            },
          ],
        },
        rouerList: [
          {
            path: '0', //
            name: '安全中心',
            icon: require('@/static/image/newImage/center/center-1.png'), // menu的icon
            child: [], // menu的子级menu
            content: '', // 页面渲染的内容，适用于富文本渲染的，非富文本的页面，无需赋值
          },
          {
            path: '1', //
            name: '意见反馈',
            icon: require('@/static/image/newImage/center/center-2.png'), // menu的icon
            child: [], // menu的子级menu
            content: '', // 页面渲染的内容，适用于富文本渲染的，非富文本的页面，无需赋值
          },
          {
            path: '2', //
            name: '帮助中心',
            icon: require('@/static/image/newImage/center/center-3.png'), // menu的icon
            child: [], // menu的子级menu
            content: '', // 页面渲染的内容，适用于富文本渲染的，非富文本的页面，无需赋值
          },
          {
            path: '3', //
            name: '网站公告',
            icon: require('@/static/image/newImage/center/center-5.png'), // menu的icon
            child: [], // menu的子级menu
            content: '', // 页面渲染的内容，适用于富文本渲染的，非富文本的页面，无需赋值
          },
          // {
          //   path: "4", //
          //   name: "协议中心",
          //   icon: require("@/static/image/newImage/center/center-4.png"), // menu的icon
          //   child: [], // menu的子级menu
          //   content: "", // 页面渲染的内容，适用于富文本渲染的，非富文本的页面，无需赋值
          // },
        ],
        search: {
          pageNo: 1,
          pageSize: 100,
        },
        protocolList: [],
        protocolIndex: 0,
        noticeList: [],
        noticeIndex: 0,
        commonProblemList: [],
        commonProblemIndex: 0,
        infoId: 0,
        BASE_URL: BASE_URL
      }
    },
    watch: {
      // 利用watch方法检测路由变化：
      $route: function (to, from) {
        if (to.params !== from.params) {
          this.active = this.$route.params.index + ''
          this.loadData()
        }
      },
    },
    components: {
      headBar,
      shoppingCard,
      footerBars,
      MenuLeft,
    },
    created() {
      this.action = eval(this.$route.params.index)
      this.active = this.$route.params.index
      this.loadData()
      // this.getProtocolList()
      // this.getNoticeList()
      // this.getCommonProblemList()
    },
    mounted() {
      console.log('mounted')
    },
    methods: {
      verify(type) {
        if (type == 1) {
          if (this.qq == '') {
            this.message('请填写官方QQ', 'error')
            return
          }
          validate({
            type: 1,
            content: this.qq,
          }).then((res) => {
            if (res.result == 0) {
              this.message('客服QQ为假', 'error')
            } else {
              this.message('客服QQ为真', 'success')
            }
          })
        } else if (type == 2) {
          if (this.link.indexOf('pp276.com') != -1) {
            this.message('官网链接正确', 'success')
          } else {
            this.message('错误的官网链接', 'error')
          }
        } else if (type == 3) {
          if (this.weixin == '') {
            this.message('请填写官方微信', 'error')
            return
          }
          validate({
            type: 2,
            content: this.weixin,
          }).then((res) => {
            if (res.result == 0) {
              this.message('官方微信为假', 'error')
            } else {
              this.message('官方微信为真', 'success')
            }
          })
        }
      },
      setInfoId(type, index, id) {
        this.infoId = id
        if (type == 'commonProblemIndex') {
          this.commonProblemIndex = index
        } else if (type == 'noticeIndex') {
          this.noticeIndex = index
        }
      },
      repaleTo(index, id) {
        this.action = index
        this.loadData()
      },
      loadData() {
        this.getNoticeList()
        this.getCommonProblemList()
      },
      // 获取帮助中心数据
      getCommonProblemList() {
        commonProblemList({
          type_MultiString: '1,3,4,5,6,7,8,9,10,11,13,255',
          pageNo: '1',
          pageSize: '1000',
        }).then((res) => {
          this.commonProblemList = res.result.records
          let arr = []
          for (let i = 0; i < res.result.records.length; i++) {
            const item = res.result.records[i]
            arr.push({
              name: item.title,
              path: `2-${i}`,
              icon: '',
              content: item.content,
            })
          }
          this.$set(this.rouerList[2], 'child', arr)
          if (this.$route.params.index == '2') {
            this.active = `2-0`
            for (let i = 0; i < this.commonProblemList.length; i++) {
              let data = this.commonProblemList[i]
              if (data.id == this.$route.params.id) {
                this.active = `2-${i}`
                break
              }
            }
          }
        })
      },
      // 获取公告列表
      getNoticeList() {
        noticeList({
          pageNo: '1',
          pageSize: '1000',
        }).then((res) => {
          if (res.code == 200) {
            this.noticeList = res.result.records
            let arr = []
            for (let i = 0; i < res.result.records.length; i++) {
              const item = res.result.records[i]
              arr.push({
                name: item.title,
                path: `3-${i}`,
                icon: '',
                content: item.content,
              })
            }
            this.$set(this.rouerList[3], 'child', arr)
            if (this.$route.params.index == '3') {
              this.active = `3-0`
              res.result.records.forEach((data, index) => {
                if (data.id == this.$route.params.id) {
                  this.active = `3-${index}`
                }
              })
            }
          }
        })
      },
      submit(type) {
        this.accountAppeal.complainType = type
        this.accountAppeal.complainPic = this.addImage.join(',')
        complainAdd(this.accountAppeal).then((res) => {
          if (res.code == 200) {
            this.message('提交成功', 'success')
          }
        })
      },
      // 上传失败事件
      handleError(e) {
        this.message('上传失败', 'error')
      },
      // 上传成功事件
      handlePictureCardPreview(e) {
        this.addImage.push(e.result)
      },
      handleChange(e, index) {
        this.helpIndex = e[e.length - 1]
      },
      handleChange1(e, index) {
        this.protocolIndex = e[e.length - 1]
      },
      changeMenu(key) {
        this.active = key
        this.addImage = []
        this.accountAppeal = {}
      },
    },
  }
</script>

<style scoped lang="scss">
  .box-css {
    background: #f8f8f8;
    padding: 20px 0 50px;
    .content-css {
      padding: 50px 10px;
    }
  }
  .list {
    display: flex;
    // margin-top: 60px;
  }

  .listItem {
    border: 1px solid #dedede;
    height: 560px;
    width: 25%;

    .noHover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHover1 {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHoverTitle {
      font-weight: 600;
      font-size: 18px;
      color: #1d223c;
      margin-top: 34px;
    }

    .noHoverTitle1 {
      font-weight: 600;
      font-size: 24px;
      color: #1d223c;
    }

    .noHoverInput {
      width: 300px;
      height: 66px;
      margin-top: 76px;
      margin-bottom: 16px;
    }

    .noHoverArea {
      width: 300px;
    }

    ::v-deep .el-input__inner {
      height: 66px;
      font-size: 20px;
    }

    .noHoverDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
      margin-top: 12px;
      text-align: center;
    }

    .noHoverDetaile1 {
      font-weight: 400;
      font-size: 14px;
      color: #1d223c;
      line-height: 21px;
      width: 300px;
      text-align: left;
    }

    .result {
      width: 460px;
      height: 66px;
      background: #8ec9ff;
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      margin-top: 20px;
    }
  }
  .listItem:hover {
    width: 40%;
    .noHover {
      display: none;
    }
    .noHover1 {
      display: flex;
    }
  }

  .listItem1 {
    border: 1px solid #dedede;
    height: 560px;
    width: 25%;

    .noHover2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHover3 {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHoverTitle {
      font-weight: 600;
      font-size: 18px;
      color: #1d223c;
      margin-top: 34px;
    }

    .noHoverTitle1 {
      font-weight: 600;
      font-size: 24px;
      color: #1d223c;
    }

    .noHoverInput {
      width: 460px;
      height: 66px;
      margin-top: 76px;
      margin-bottom: 16px;
    }

    .noHoverArea {
      width: 300px;
    }

    ::v-deep .el-input__inner {
      height: 66px;
      font-size: 20px;
    }

    .noHoverDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
      margin-top: 12px;
      text-align: center;
    }

    .noHoverDetaile1 {
      font-weight: 400;
      font-size: 18px;
      color: #1d223c;
      line-height: 21px;
      width: 460px;
      text-align: left;
    }

    .result {
      width: 460px;
      height: 66px;
      background: #8ec9ff;
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      margin-top: 20px;
    }
  }
  .listItem1:hover {
    width: 40%;
    .noHover2 {
      display: none;
    }
    .noHover3 {
      display: flex;
    }
  }

  .listItem2 {
    border: 1px solid #dedede;
    height: 560px;
    width: 25%;

    .noHover4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHover5 {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHoverTitle {
      font-weight: 600;
      font-size: 18px;
      color: #1d223c;
      margin-top: 34px;
    }

    .noHoverTitle1 {
      font-weight: 600;
      font-size: 24px;
      color: #1d223c;
    }

    .noHoverInput {
      width: 460px;
      height: 66px;
      margin-top: 76px;
      margin-bottom: 16px;
    }

    .noHoverArea {
      width: 300px;
    }

    ::v-deep .el-input__inner {
      height: 66px;
      font-size: 20px;
    }

    .noHoverDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
      margin-top: 12px;
      text-align: center;
    }

    .noHoverDetaile1 {
      font-weight: 400;
      font-size: 18px;
      color: #1d223c;
      line-height: 21px;
      width: 460px;
      text-align: left;
    }

    .result {
      width: 460px;
      height: 66px;
      background: #8ec9ff;
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      margin-top: 20px;
    }
  }
  .listItem2:hover {
    width: 40%;
    .noHover4 {
      display: none;
    }
    .noHover5 {
      display: flex;
    }
  }

  .listItem3 {
    border: 1px solid #dedede;
    height: 560px;
    width: 25%;

    .noHover6 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHover7 {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 560px;
    }
    .noHoverTitle {
      font-weight: 600;
      font-size: 18px;
      color: #1d223c;
      margin-top: 34px;
    }

    .noHoverTitle1 {
      font-weight: 600;
      font-size: 24px;
      color: #1d223c;
    }

    .noHoverInput {
      width: 460px;
      height: 66px;
      margin-top: 76px;
      margin-bottom: 16px;
    }

    .noHoverArea {
      width: 300px;
    }

    ::v-deep .el-input__inner {
      height: 66px;
      font-size: 20px;
    }

    .noHoverDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
      margin-top: 12px;
      text-align: center;
    }

    .noHoverDetaile1 {
      font-weight: 400;
      font-size: 18px;
      color: #1d223c;
      line-height: 21px;
      width: 460px;
      text-align: left;
    }

    .result {
      width: 460px;
      height: 66px;
      background: #8ec9ff;
      border-radius: 8px 8px 8px 8px;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
      margin-top: 20px;
    }
  }
  .listItem3:hover {
    width: 40%;
    .noHover6 {
      display: none;
    }
    .noHover7 {
      display: flex;
    }
  }

  .formItem {
    margin-top: 8px;
    color: #000;
    display: flex;

    .item1 {
      width: 250px;
      text-align: right;
    }

    .item2 {
      width: 400px;
      margin-left: 10px;
    }

    .item3 {
      font-weight: 400;
      font-size: 16px;
      color: #0085ff;
      margin-left: 20px;
    }

    .item4 {
      border: 1px solid #dedede;
      border-radius: 4px 4px 4px 4px;
    }

    .item5 {
      border-top: 1px solid #dedede;
      font-weight: 400;
      font-size: 16px;
      color: #a5a7b1;
      height: 56px;
      line-height: 56px;
      padding-left: 20px;
    }
  }

  .helpLeft {
    width: 280px;
    height: 1246px;
    background-color: #f8f8f8;
    overflow-y: auto;

    .leftTitle {
      padding-left: 42px;
      height: 94px;
      font-weight: 600;
      font-size: 16px;
      color: #1d223c;
      line-height: 94px;
      cursor: pointer;
    }

    .leftItem {
      padding-left: 12px;
      height: 68px;
      width: 180px;
      font-weight: 500;
      font-size: 14px;
      color: #777a8a;
      line-height: 68px;
    }

    .helpIndex {
      background: #ffffff;
      color: #1d223c;
      border-left: 4px solid #0085ff;
    }
  }
  .helpLeft::-webkit-scrollbar {
    width: 0;
  }

  .helpRight {
    padding: 32px;
    width: 1250px;
    height: 1150px;
    overflow: auto;

    ::v-deep .el-collapse {
      border: none;
    }

    ::v-deep .el-collapse-item__header {
      height: 71px;
    }

    .detailes {
      padding-left: 20px;
      font-size: 18px;
    }

    .rightDetaile {
      font-weight: 400;
      font-size: 20px;
      color: #777a8a;
      line-height: 42px;
    }
  }

  // .helpRight {}

  .rightTitle {
    width: 1252px;
    height: 71px;
    font-weight: 600;
    font-size: 20px;
    color: #1d223c;
    line-height: 71px;
    padding-left: 20px;
  }

  .rightItemAction {
    overflow: hidden;
    border-radius: 12px 12px 12px 12px;
    border: 1px solid #0085ff;
  }

  .rightTitleAction {
    color: #0085ff;
  }
</style>
