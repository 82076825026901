<template>
  <div>
    <div class="stepList" v-if="type == ''">
      <el-steps :active="step" align-center>
        <el-step title="选择商品">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 1 }">1</div>
          </div>
        </el-step>
        <el-step title="结算订单">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 2 }">2</div>
          </div>
        </el-step>
        <el-step title="确认收货">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 3 }">3</div>
          </div>
        </el-step>
        <el-step title="交易完成">
          <div slot="icon">
            <div class="step" :class="{ stepAction: step >= 4 }">✔</div>
          </div>
        </el-step>
      </el-steps>
    </div>

    <!-- <div class="lableTop">
      <img class="lableImage" :src="require('@/static/image/index/classBg.png')" />
      <el-row class="content">
        <el-col :span="24">
          <div class="lableBg">
            <div class="gameClass">
              <div class="lableTitle">收银台</div>
              <div class="lableDetaile">网络游戏</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div> -->

    <div class="lableTop">
      <el-row class="content">
        <el-col :span="24">
          <div class="screen">
            <div class="orderTop">
              <div class="fl">
                <img :src="require('@/static/image/newImage/sure.png')" style="width: 56px; height: 56px" />
                <div class="orderLeft" v-if="totalSeconds > 0">
                  <span class="orderTitle">订单提交成功！</span>
                  <div class="orderInfo">
                    请您核对订单信息，并在
                    <span style="color: #ff5c00">{{ minutes }} 分 {{ seconds }} 秒</span> 内完成支付，超时订单将会取消
                  </div>
                  <!-- <div v-else class="orderInfo">
										<span style="color: #FF5C00;">已过期</span>
									</div> -->
                </div>
                <div class="orderLeft" v-else>
                  <span class="orderTitle" style="color: #ff5c00">已过期</span>
                </div>
              </div>
              <!-- <div class="orderRight">
                <div class="orderPrice">
                  <span>应付金额：</span>
                  <span style="color: #ff5c00; font-size: 24px; font-weight: bold">{{ payInfo.price }}</span>
                  <span style="color: #ff5c00">元</span>
                </div>

                <div style="color: #ff5c00; font-size: 14px" @click="screenShow = !screenShow">
                  <span>订单详情</span>
                </div>
              </div> -->
            </div>
            <el-collapse-transition v-if="type == '' || type == 'enter'">
              <div class="infoList" v-if="screenShow">
                <div class="infoItem">
                  <span class="infoKey">订单编号：</span>
                  <span class="infoVale">{{ payInfo.id }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoKey">商品名称：</span>
                  <span class="infoVale">{{ payInfo.title }}</span>
                </div>
                <!-- <div class="infoItem">
									<span class="infoKey">游戏区服：</span>
									<span class="infoVale">{{payInfo.districtName}}</span>
								</div> -->
              </div>
            </el-collapse-transition>
          </div>

          <div class="screen" style="margin-top: 16px; margin-bottom: 56px">
            <div class="fl payItem">
              <div class="fl">
                <!-- <img v-if="balancePay == true" :src="require('@/static/image/index/noChecked.png')" style="width: 10px; height: 20px; cursor: pointer" />
                <img v-if="balancePay == false" :src="require('@/static/image/index/checked.png')" style="width: 20px; height: 20px; cursor: pointer" />
                <img :src="require('@/static/image/index/payType2.png')" style="width: 40px; height: 40px; margin-left: 12px" /> -->
                <span class="payText">支付方式</span>
              </div>
            </div>
            <div class="fl" style="margin-left: 50px">
              <div class="paymentPattern" @click="payTypeIndex = index" :class="{ action: index == payTypeIndex }" v-for="(item, index) in payType">
                <img :src="item.image" style="width: 40px; height: 40px" />
                <span style="margin-left: 8px">{{ item.text }}</span>
                <img v-if="index == payTypeIndex" class="actionImage" :src="require('@/static/image/index/payAction.png')" />
              </div>
            </div>
            <!-- <div class="fl payItem" @click="balancePay = true">
              <div class="fl">
                <img v-if="balancePay == false" :src="require('@/static/image/index/noChecked.png')" style="width: 20px; height: 20px; cursor: pointer" />
                <img v-if="balancePay == true" :src="require('@/static/image/index/checked.png')" style="width: 20px; height: 20px; cursor: pointer" />
                <img :src="require('@/static/image/index/payType1.png')" style="width: 40px; height: 40px; margin-left: 12px" />
                <span class="payText">余额支付</span>
                <span class="balanceText">可用金额{{ balanceNum || '0' }}元</span>
              </div>
            </div> -->
            <div class="newBox">
              应付金额:<span>{{ payInfo.price }}</span
              >元
            </div>
            <div class="newBox">
              <button class="purchase" @click="pay">立即支付</button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <shopping-card></shopping-card>

    <div class="popUp" v-if="onlineShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img style="width: 522px; height: 166px; position: absolute; top: 100px" :src="require('@/static/image/index/payImage.png')" />
        <div class="popCount">
          <img class="close" @click="closePay" :src="require('@/static/image/index/close.png')" />
          <iframe :src="iframeUrl" width="240" height="250" style="border: none; margin-top: 40px"></iframe>
          <div class="popText">请扫码支付<br />支付即视为你同意 <span style="color: #0085ff">相关协议</span> 条例</div>
          <div class="payOrder">
            <span>实付金额： </span>
            <div>
              <span style="color: #ff5c00; font-weight: bold; font-size: 36px">{{ payInfo.price }}</span>
              <span style="color: #ff5c00">元</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popUp" v-if="realShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img style="width: 522px; height: 166px; position: absolute; top: 100px" :src="require('@/static/image/index/payImage1.png')" />
        <div class="popCount">
          <img
            class="close"
            @click="
              realShow = false
              password = ['', '', '', '', '', '']
            "
            :src="require('@/static/image/index/close.png')" />
          <div class="payment-password-input" v-if="showPass">
            <input v-prevent-space ref="inputList" :ref="`input${index}`" v-for="(item, index) in passwordLength" :key="index" type="text" v-model="password[index]" @input="handleInput($event, index)" @keydown="onKeydownBackSpace($event, index)" />
            <i class="el-icon-view" @click="showPass = !showPass" v-if="!showPass" style="font-size: 28px; margin-left: 10px"></i>
            <i class="el-icon-view" @click="showPass = !showPass" v-else style="font-size: 28px; margin-left: 10px; color: #0085ff"></i>
          </div>
          <div class="payment-password-input" v-else>
            <input v-prevent-space ref="inputList" :ref="`input${index}`" v-for="(item, index) in passwordLength" :key="index" type="password" autoComplete="new-password" v-model="password[index]" @input="handleInput($event, index)" @keydown="onKeydownBackSpace($event, index)" />
            <i class="el-icon-view" @click="showPass = !showPass" v-if="!showPass" style="font-size: 28px; margin-left: 10px"></i>
            <i class="el-icon-view" @click="showPass = !showPass" v-else style="font-size: 28px; margin-left: 10px; color: #0085ff"></i>
          </div>

          <div class="payOrder">
            <span>实付金额： </span>
            <div>
              <span style="color: #ff5c00; font-weight: bold; font-size: 36px">{{ payInfo.price }}</span>
              <span style="color: #ff5c00">元</span>
            </div>
          </div>
          <el-button @click="twoPay(4)" style="width: 234px; margin-top: 30px" type="primary">立即支付</el-button>
        </div>
      </div>
    </div>

    <div class="popUp" v-if="balanceShow">
      <div style="width: 100%; justify-content: center; position: relative" class="fl">
        <img style="width: 541px; height: 217px; position: absolute; top: 100px" :src="require('@/static/image/index/balanceImage.png')" />
        <div class="popCount1">
          <img class="close" @click="balanceShow = false" :src="require('@/static/image/index/close.png')" />
          <div class="popTitle">请前往设置支付密码</div>
          <div class="popText1">请您前往人中心账户设置-支付密码完成设置， <br />设置密码后交易更安全!</div>
          <button class="realSubmit" @click="$router.push('/mineIndex/30')">前往设置</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onePay, twoPay, fush, orderDepositPay, orderQueryById, orderDepositQueryById } from '@/api/index.js'
  import layoutTop from '@/components/layoutTop.vue'
  import shoppingCard from '@/components/shoppingCart.vue'
  import footerBars from '@/components/footerBars.vue'
  export default {
    components: {
      layoutTop,
      shoppingCard,
      footerBars,
    },
    data() {
      return {
        showPass: false,
        pollTime: null,
        payCode: '',
        onlineShow: false,
        step: 2,
        screenShow: false,
        realShow: false,
        balanceShow: false,
        balancePay: false,
        balanceNum: '',
        password: Array(6).fill(''), // 初始化一个长度为6的数组用于存储密码
        passwordLength: 6, // 支付密码的长度
        payType: [
          {
            image: require('@/static/image/index/alipay.png'),
            text: '支付宝',
            value: 5,
          },
          {
            image: require('@/static/image/newImage/wxb.png'),
            text: '微信',
            value: 7,
          },

          {
            image: require('@/static/image/newImage/mi.png'),
            text: '钻石支付',
            value: 8,
          },
          // {
          // 	image: require('@/static/image/index/wechat.png'),
          // 	text: '微信',
          // 	value:4
          // },
          // {
          // 	image: require('@/static/image/index/bankCard.png'),
          // 	text: '银行卡',
          // 	value:3
          // }
        ],
        payTypeIndex: 0,
        payInfo: {},
        hasPayPwd: '',
        type: '',
        totalSeconds: 0,
        intervalId: null,
        iframeUrl: '',
      }
    },
    computed: {
      minutes() {
        return Math.floor(this.totalSeconds / 60)
      },
      seconds() {
        return this.totalSeconds % 60
      },
      // type() {
      //   return this.showPass ? 'text' : 'password'
      // },
    },
    created() {
      if (JSON.parse(this.$route.query.info) == null) {
        this.$router.push('/')
      }

      this.type = JSON.parse(this.$route.query.info).type
      if (JSON.parse(this.$route.query.info).type == 'enter') {
        this.payInfo = JSON.parse(this.$route.query.info)
      } else if (JSON.parse(this.$route.query.info).gameIds != undefined) {
        this.payInfo.price = JSON.parse(this.$route.query.info).deposit
      } else {
        this.payInfo = JSON.parse(this.$route.query.info)
      }

      fush().then((res) => {
        this.balanceNum = res.result.earningsBalance
        this.hasPayPwd = res.result.hasPayPwd
      })

      this.getConfig(73).then((res) => {
        let time = Number(res.result)
        // 下单时间
        let date = new Date(JSON.parse(this.$route.query.info).createTime).getTime() / 1000
        // 当前时间
        let date1 = new Date().getTime() / 1000
        let newData = Number(time * 60 - (date1.toFixed(0) - date.toFixed(0)))
        if (newData > 0) {
          this.totalSeconds = newData
          this.startCountdown()
        }
      })
    },
    beforeDestroy() {
      this.stopCountdown()
      clearInterval(this.pollTime)
    },
    methods: {
      startCountdown() {
        this.intervalId = setInterval(() => {
          if (this.totalSeconds > 0) {
            this.totalSeconds--
          } else {
            this.stopCountdown()
          }
        }, 1000)
      },
      stopCountdown() {
        clearInterval(this.intervalId)
        clearInterval(this.pollTime)
      },
      closePay() {
        this.onlineShow = false
        clearInterval(this.pollTime)
      },
      onKeydownBackSpace(event, index) {
        // 检查是否是删除键或退格键被按下
        if (event.key === 'Delete' || event.key === 'Backspace') {
          this.password[index] = ''
          if (index > 0) {
            this.$nextTick(() => {
              const nextInput = this.$refs[`input${index - 1}`][0]
              if (nextInput) {
                nextInput.focus()
              }
            })
          }
        }
      },
      handleInput(event, index) {
        if (event.inputType != 'deleteContentBackward') {
          // 当输入框的值改变时，只允许输入数字并限制输入长度
          if (event.target.value.length > 1) {
            this.password[index] = event.data
          }
          if (index < this.passwordLength - 1) {
            this.$nextTick(() => {
              const nextInput = this.$refs[`input${index + 1}`][0]
              if (nextInput) {
                nextInput.focus()
              }
            })
          }
        }
      },
      pay() {
        let type = ''
        if (this.payTypeIndex == 2) {
          this.balancePay = true
        }
        if (this.balancePay == true) {
          // 判断是否设置支付密码
          if (this.hasPayPwd) {
            this.realShow = true
          } else {
            this.balanceShow = true
          }
        } else {
          const loading = this.$loading({
            lock: true,
            text: '正在支付',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          let promise
          // if (this.type == 'enter') {
          //   type = this.payType[this.payTypeIndex].value
          //   promise = orderDepositPay({
          //     depositType: JSON.parse(this.$route.query.info).settledType,
          //     payType: type,
          //   }).then((res) => {
          //     if (res.code == 200) {
          //       this.payCode = res.result.qrcode
          //       this.onlineShow = true
          //       clearInterval(this.pollTime)
          //       this.getPaySuccess(res.result.id)
          //     }
          //   })
          // } else if (this.type == 'earnestMoney') {
          //   type = this.payType[this.payTypeIndex].value
          //   if (JSON.parse(this.$route.query.info).depositType != '' && JSON.parse(this.$route.query.info).depositType != null && JSON.parse(this.$route.query.info).depositType != undefined) {
          //     promise = orderDepositPay({
          //       depositType: JSON.parse(this.$route.query.info).depositType,
          //       id: JSON.parse(this.$route.query.info).id,
          //       payType: type,
          //     }).then((res) => {
          //       if (res.code == 200) {
          //         this.payCode = res.result.qrcode
          //         this.onlineShow = true
          //         clearInterval(this.pollTime)
          //         this.getPaySuccess(res.result.id)
          //       }
          //     })
          //   } else {
          //     promise = orderDepositPay({
          //       // depositType: JSON.parse(this.$route.query.info).depositType,
          //       // password: this.password.join(''),
          //       id: JSON.parse(this.$route.query.info).id,
          //       payType: type,
          //     }).then((res) => {
          //       if (res.code == 200) {
          //         this.payCode = res.result.qrcode
          //         this.onlineShow = true
          //         clearInterval(this.pollTime)
          //         this.getPaySuccess(res.result.id)
          //       }
          //     })
          //   }
          // } else {
          //   type = this.payType[this.payTypeIndex].value
          //   promise = twoPay({
          //     id: this.payInfo.id,
          //     type: type,
          //     returnType: 1,
          //   }).then((res) => {
          //     this.iframeUrl = res.result
          //     this.onlineShow = true
          //     clearInterval(this.pollTime)
          //     this.orderQueryById(this.payInfo.id)
          //   })
          // }
          type = this.payType[this.payTypeIndex].value
          promise = twoPay({
            id: this.payInfo.id,
            type: type,
            returnType: 1,
          }).then((res) => {
            window.open(res.result)
            // this.iframeUrl = res.result
            // this.onlineShow = true
            // clearInterval(this.pollTime)
            // this.orderQueryById(this.payInfo.id)
          })
          promise.finally(() => {
            loading.close()
          })
        }
      },
      getPaySuccess(id) {
        let that = this
        this.pollTime = setInterval(() => {
          orderDepositQueryById({
            id: id,
          }).then((res) => {
            if (res.result.status == 1) {
              clearInterval(that.pollTime)

              that.pollTime = null
              that.message('补交成功', 'success')
              that.onlineShow = false
              that.$router.push('/mineIndex/17')
            }
          })
        }, 3000)
      },
      orderQueryById(id) {
        let that = this
        this.pollTime = setInterval(() => {
          orderQueryById({
            id: id,
          }).then((res) => {
            if (res.result.status == 1) {
              clearInterval(that.pollTime)
              that.message('支付成功', 'success')
              that.onlineShow = false
              that.$router.push('/chat/0')
            }
          })
        }, 3000)
      },
      twoPay(type) {
        const loading = this.$loading({
          lock: true,
          text: '正在支付',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        let promise
        let that = this
        if (this.type == 'enter') {
          promise = orderDepositPay({
            depositType: JSON.parse(this.$route.query.info).settledType,
            password: this.password.join(''),
            // id: JSON.parse(this.$route.query.info).id,
            payType: type,
          }).then((res) => {
            if (res.code == 200) {
              this.message('支付成功', 'success')
              that.$router.push('/mineIndex/17')
              this.realShow = false
            }
          })
        } else if (this.type == 'earnestMoney') {
          if (JSON.parse(this.$route.query.info).depositType != '' && JSON.parse(this.$route.query.info).depositType != null && JSON.parse(this.$route.query.info).depositType != undefined) {
            promise = orderDepositPay({
              depositType: JSON.parse(this.$route.query.info).depositType,
              password: this.password.join(''),
              id: JSON.parse(this.$route.query.info).id,
              payType: type,
            }).then((res) => {
              if (res.code == 200) {
                this.message('支付成功', 'success')
                this.realShow = false
                this.$router.push('/mineIndex/26')
              }
            })
          } else {
            promise = orderDepositPay({
              // depositType: JSON.parse(this.$route.query.info).depositType,
              password: this.password.join(''),
              id: JSON.parse(this.$route.query.info).id,
              payType: type,
            }).then((res) => {
              if (res.code == 200) {
                this.message('支付成功', 'success')
                this.realShow = false
              }
            })
          }
        } else {
          promise = twoPay({
            id: this.payInfo.id,
            type: type,
            password: this.password.join(''),
          }).then((res) => {
            if (res.code == 200) {
              this.message('支付成功', 'success')
              this.realShow = false
              this.$router.push('/chat/0')
            } else {
              this.password = Array(6).fill('')
              this.$nextTick(() => {
                const nextInput = this.$refs[`input0`][0]
                if (nextInput) {
                  nextInput.focus()
                }
              })
            }
          })
        }
        promise.finally(() => {
          loading.close()
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .popUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }

  .lableTop {
    // position: relative;

    background-color: #f8f8f8;
    padding-top: 20px;
    box-sizing: border-box;

    .lableImage {
      width: 100%;
      height: 150px;
      position: absolute;
      z-index: 0;
    }
  }

  .lableBg {
    padding-top: 20px;
    height: 100px;
    display: flex;

    .lableTitle {
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
    }

    .lableDetaile {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      margin-top: 12px;
    }
  }

  .stepList {
    width: 600px;
    margin: 30px auto;
  }

  .step {
    width: 27px;
    height: 27px;
    color: #fff;
    // background: #0085FF 0 0 no-repeat transfor;
    text-align: center;
    // transform: rotate(45deg);
    line-height: 27px;
    position: relative;
  }

  .step::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px 6px 6px 6px;
    background: #dddddd;
    background-size: 100% 100%;
    transform: rotate(45deg);
    // transform: scaleY(-1);
  }

  .stepAction {
    width: 27px;
    height: 27px;
    color: #fff;
    // background: #0085FF 0 0 no-repeat transfor;
    text-align: center;
    // transform: rotate(45deg);
    line-height: 27px;
    position: relative;
  }

  .stepAction::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 6px 6px 6px 6px;
    background: #0085ff;
    background-size: 100% 100%;
    transform: rotate(45deg);
    // transform: scaleY(-1);
  }

  .stepSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ::v-deep .el-step__title.is-process {
    color: #c0c4cc;
    font-weight: 500;
  }

  .screen {
    color: #777a8a;
    background: #ffffff;
    // margin-top: -120px;
    border-radius: 20px 20px 20px 20px;
    position: relative;
    z-index: 999;
  }

  .orderTop {
    padding: 46px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .orderLeft {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 76px;
    }

    .orderTitle {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 24px;
      color: #38de27;
    }

    .orderInfo {
      font-weight: 400;
      font-size: 14px;
      color: #777a8a;
    }

    .orderPrice {
      font-size: 14px;
      color: #a5a7b1;
    }

    .orderRight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      height: 76px;
    }
  }

  .payText {
    font-weight: 600;
    font-size: 16px;
    color: #1d223c;
    margin-left: 12px;
  }

  .balanceText {
    font-weight: 400;
    font-size: 16px;
    color: #a5a7b1;
    margin-left: 24px;
  }

  .payItem {
    justify-content: space-between;
    padding: 32px 28px;
  }

  .payItem:last-child {
    border-bottom: 1px solid #ececec;
  }

  .paymentPattern {
    width: 254px;
    height: 70px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 2px solid #dedede;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;

    font-weight: 600;
    font-size: 16px;
    color: #1d223c;
    position: relative;
  }

  .action {
    border: 2px solid #ff5c00;
    position: relative;
  }

  .purchase {
    width: 280px;
    height: 56px;
    background: #ff5c00;
    border-radius: 8px 8px 8px 8px;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    margin-left: 20px;
    margin: auto;
    margin-left: 50px;
    margin-bottom: 26px;
    cursor: pointer;
  }

  .actionImage {
    width: 30px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 4px;
  }

  .infoList {
    margin: 0 28px;
    border-top: 1px solid #ececec;
    padding: 30px 0;
  }

  .infoItem {
    font-weight: 400;
    font-size: 18px;
    color: #a5a7b1;
    margin-bottom: 20px;

    .infoVale {
      color: #1d223c;
      font-weight: bold;
    }
  }

  .popCount {
    z-index: 9999;
    position: relative;
    margin-top: 220px;
    display: flex;
    flex-direction: column;
    width: 522px;
    border-radius: 24px;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .popText {
      width: 264px;
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      margin-top: 20px;
    }

    .payOrder {
      width: 394px;
      height: 100px;
      background: #f6f8fa;
      border-radius: 12px 12px 12px 12px;
      margin-top: 40px;
      padding: 0 24px;
      color: #a5a7b1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .popCount1 {
    z-index: 9999;
    position: relative;
    margin-top: 280px;
    display: flex;
    flex-direction: column;
    width: 541px;
    border-radius: 24px;
    align-items: center;
    background-color: #fff;
    padding-bottom: 30px;

    .popText1 {
      width: 384px;
      font-weight: 400;
      font-size: 16px;
      color: #777a8a;
      text-align: center;
      margin-top: 20px;
      line-height: 30px;
    }

    .popTitle {
      width: 384px;
      font-weight: 600;
      font-size: 24px;
      color: #1d223c;
      line-height: 30px;
      text-align: center;
      margin-top: 36px;
    }
  }

  .close {
    position: absolute;
    top: -100px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
  }

  .realSubmit {
    width: 296px;
    height: 66px;
    background: linear-gradient(90deg, #03b5ff 0%, #0085ff 100%);
    border-radius: 8px 8px 8px 8px;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    margin-top: 50px;
  }

  .payment-password-input {
    margin-top: 30px;
  }

  .payment-password-input input {
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .payment-password-input input:focus {
    border-color: #007bff;
  }
  .newBox {
    width: 100%;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #1a1a1a;
    span {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 40px;
      color: #ff5c00;
    }
    text-align: center;
    margin: 40px 0;
  }
</style>
