<template>
  <div style="background: linear-gradient(0deg, #efefef 0%, #ffffff 100%); padding-bottom: 20px">
    <!--		<div v-if="headShow" style="height: 190px;">-->
    <!--			&lt;!&ndash; <head-bar ref="headBar" v-if="headShow"></head-bar> &ndash;&gt;-->
    <!--			<headBarNew></headBarNew>-->
    <!--		</div>-->

    <!-- 搜索 -->
    <!-- <div style="background-color: #fff;padding-bottom: 80px;">
			
		</div> -->
    <!-- <div style="background-color: #fff;">
			<el-row style="width: 1200px;margin: 0 auto;">
				<el-col :span="24">
					<div>
						<el-row style="display: flex;align-items: center;">
								<div style="width: 270px;">
									<img :src="require('@/static/image/newImage/logo.png')" style="width: 180px;" />
								</div>
								<div  style="justify-content: flex-end; width: 680px;">
									<div >
										<div class="search">
											<div class="downList" ref="dialogBoxs">
												<div @click="selectGameShow('selectGame')">
													<div class="searchItem">
														{{selectGame.name || '游戏名称'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div @click="selectGameShow('selectGameTypeList')">
													<div class="searchItem">
														{{selectGameTypeList.name || '商品类型'}}

														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="sonGameTypeList.length !=0"
													@click="selectGameShow('selectSonGameTypeList')">
													<div class="searchItem">
														{{selectSonGameTypeList.name || '商品子类'}}

														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="systemList.length !=0">
													<div class="searchItem">
														{{selectSystem.name || '系统'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="districtList.length !=0">
													<div class="searchItem">
														{{selectDistrict.name || '游戏区服'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>
												<div v-if="sonDistrictList.length !=0">
													<div class="searchItem">
														{{sonSelectDistrict.name || '游戏大区'}}
														<i class="el-icon-arrow-down"></i>
													</div>
												</div>


												<div class="dialogBox" v-if="selectShow">
													<div class="searchName">
														<div>
															<div class="typeTop fl">
																<div class="fl">
																	<div class="gameType"
																		@click="selectSearch('','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == ''}">
																		全部游戏</div>
																	<div class="gameType"
																		@click="selectSearch('1','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == '1'}">
																		网络游戏</div>
																	<div class="gameType"
																		@click="selectSearch('2','type')"
																		:class="{'gameTypeAction':gameSearch.terminalType == '2'}">
																		手机游戏</div>
																</div>
																<div class="fl">
																	<el-input v-prevent-space placeholder="请输入内容"
																		v-model="gameSearch.name">
																		<i slot="prefix"
																			class="el-input__icon el-icon-search"></i>
																	</el-input>
																	<span @click="getGameList1"
																		style="width: 120px;text-align: center;cursor:pointer;font-size: 14px;">快速搜索</span>
																</div>
															</div>
															<div class="initialList">
																<el-link class="initialItem"
																	@click="selectSearch(item,'initial')"
																	v-for="(item,index) in initial"
																	target="_blank">{{item}}</el-link>

															</div>
														</div>
														<div class="selectList">
															<div class="selectItem" v-for="item in gameList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="gameSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>

												<div class="dialogBox" v-if="selectShow1">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">请选择商品类型：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in gameTypeList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="gameTypeSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
															<div style="color: #0085FF;"
																v-if="gameTypeList.length == 0">
																请先选择游戏
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow2">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">请选择商品子类：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in sonGameTypeList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="sonGameTypeSelect(item)"
																	class="selectSpan">{{item}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow3">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">游戏区服：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in districtList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="districtSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow4">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">游戏大区：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in sonDistrictList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="sonDistrictSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="dialogBox" v-if="selectShow5">
													<div class="searchName">
														<div class="fl" style="margin-bottom: 10px;">
															<span class="searchLable">系统：</span>
															<span class="searchLable" style="color: #0085FF;">不限</span>
														</div>

														<div class="selectList">
															<div class="selectItem" v-for="item in systemList">
																<i class="el-icon-caret-right" style="color: #ccc;"></i>
																<span @click="systemSelect(item)"
																	class="selectSpan">{{item.name}}</span>
															</div>
														</div>
													</div>
												</div>
												<div class="searchInput">
													<input v-prevent-space
														style="border: none;font-size: 14px;margin-left: 130px;min-width:100px;background: none;"
														v-model="searchKey" placeholder="搜索商品关键词"></input>
												</div>
											</div>
											<div class="submitSearch" @click="searchShop">
												搜索
											</div>
										</div>
										<div class="hotTag">
											<div class="hotList">
												<div style="margin-right: 5px;">热门搜索:</div>
												<div class="hotItem" @click="toShopLists(item)"
													v-for="(item,index) in hotTagList" :key="index">
													{{item.name}}
												</div>
											</div>
										</div>
									</div>
								</div>
               <div class="rightBtn">
                <div class="rightBtnBox" @click="handelGoShop(1)">
                    我要买
                  </div>
                  <div class="rightBtnBox" @click="handelGoShop(2)">
                    我要卖
                  </div>
               </div>
						</el-row>

					</div>
				</el-col>
      
			</el-row>
      <div style="width: 1200px;margin: 0 auto;">
      <newBar></newBar>
      </div>
      
		</div> -->

    <!-- 热门游戏 -->
    <!-- style="background: linear-gradient( 180deg, #8FB6FF 0%, rgba(143,182,255,0.3) 47%, rgba(143,182,255,0) 100%);background-position: 0px 55px; "> -->
    <!--		<div :style="{ background: 'linear-gradient('+(bannerList[initialIndex].color||'#C9D2ED')+',rgba(143,182,255,0) 60%)',backgroundPosition: '0px 55px ',backgroundRepeat:'no-repeat'}">-->
    <div :style="{ background: `linear-gradient(${bannerList[initialIndex]?.color},rgba(143,182,255,0) 60%)`, backgroundRepeat: 'no-repeat', 'padding-top': '10px' }">
      <div class="content">
        <el-row>
          <el-col :span="24">
            <div style="display: flex; width: 1200px; justify-content: space-between">
              <div style="width: 220px">
                <div class="sort" @mouseleave="handleMouseLeave">
                  <div class="sortTop" @click="$router.push('/game/select/0')">
                    <p class="topLeft">热门游戏</p>
                    <p class="topRight">
                      进入游戏大厅
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </div>
                  <div class="sortItemAll">
                    <div class="sortItem" v-for="(item, index) in hotGameList" :key="index">
                      <div style="display: flex; align-items: center">
                        <img :src="item.icon" style="width: 24px; height: 24px; border-radius: 5px" />
                        <div style="margin-left: 5px">
                          <div class="sortTitle el1" @click="toShopList(item)">{{ item.name }}</div>
                          <div v-if="item.gameTypes.length != 0" style="margin-top: 5px">
                            <span @click="toShopList(item, items)" class="sortTag el1" v-for="(items, indexs) in item.gameTypes" :key="indexs">{{ items.name }}</span>
                          </div>
                        </div>
                      </div>
                      <i style="color: #dbdbdb; cursor: pointer" class="el-icon-arrow-right" @click="toShopList(item)"></i>
                    </div>
                  </div>
                  <!--<div class="hoverList" v-show="hoverShow">
																			<div class="hoverTitle">
																				{{hotSort[hoverIndex].name}}
																			</div>
																			<div class="hoverTag" v-if="hotSort[hoverIndex].games.length != 0">
																				<div class="tagItem el1" @click="toShopList(hotSort[hoverIndex],item)"
																					v-for="(item,index) in hotSort[hoverIndex].games">{{item.name}}
																				</div>
																			</div>
																		</div>-->
                </div>
              </div>

              <div style="width: 710px">
                <div>
                  <div class="centerImage">
                    <el-carousel :initial-index="initialIndex" @change="bannerChange" :interval="4000" height="420px" arrow="always">
                      <el-carousel-item v-for="item in bannerList" :key="item.id">
                        <img @click="toLink(item)" :src="item.pic" style="border-radius: 5px; width: 100%; height: 420px" />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
                <!-- <div style="display: flex;margin-top: 20px;justify-content: space-between;">
									<div @click="toLink1(item)" class="functionImage" v-for="(item,index) in funList">
										<img :src="item.pic" />
									</div>
								</div> -->
              </div>

              <div style="width: 250px">
                <div>
                  <el-card shadow="hover" style="height: 270px; padding: 10px 20px; box-sizing: border-box; background: linear-gradient(0deg, #e7f7fe 0%, #ffffff 100%); border-radius: 12px">
                    <div v-if="$store.state.token">
                      <img :src="$store.state.userInfo.portrait" class="rightImg" alt="" />
                      <div class="rightText">{{ $store.state.userInfo.name }},欢迎回来PP276</div>
                    </div>
                    <div v-else>
                      <img :src="require('@/static/image/newImage/actor.png')" class="rightImg" alt="" />
                      <div class="rightText">Hi~你好,欢迎来到PP276</div>
                      <div class="rightText1">登录之后更精彩，<span @click="handelGoLogin">去登录></span></div>
                    </div>
                    <div class="bottom">
                      <div class="bottomBtnBox" @click="handelGoShop(1)">我要买</div>
                      <div class="bottomBtnBox" @click="handelGoShop(2)">我要卖</div>
                    </div>
                    <!-- #/login/login -->
                    <!-- <div class="loginText">
											<div class="login" :class="{'actionLogin' : loginType == 1}"
												@click="swiperType(1)">账户登录
											</div>
											<div class="login1" :class="{'actionLogin' : loginType == 0}"
												@click="swiperType(0)">短信登录
											</div>
										</div>

										<div v-if="loginType == 0">
											<el-form :validate-on-rule-change="false" :model="loginForm"
												:rules="loginRule" ref="loginForm">
												<el-form-item label="" prop="account">
													<el-input v-prevent-space v-model="loginForm.account" type="number"
														placeholder="手机号">
														<template slot="prepend">+86</template>
													</el-input>
												</el-form-item>
												<el-form-item label="" prop="code">
													<div class="send">
														<el-input v-prevent-space v-model="loginForm.code" type="number"
															placeholder="请输入验证码">
														</el-input>
														<span @click="getCodes('1',loginForm.account)"
															class="getCode">{{ countDown || '获取验证码'}}</span>
													</div>
												</el-form-item>
											</el-form>
										</div>
										<div v-if="loginType == 1">
											<el-form :validate-on-rule-change="false" :model="loginPassForm"
												:rules="loginPassRule" ref="loginPassForm">
												<el-form-item label="" prop="account">
													<el-input v-prevent-space placeholder="手机号" type="number"
														v-model="loginPassForm.account">
														<template slot="prepend">+86</template>
													</el-input>
												</el-form-item>
												<el-form-item label="" prop="password">
													<el-input v-prevent-space v-model="loginPassForm.password"
														show-password placeholder="请输入登录密码">
													</el-input>
												</el-form-item>
											</el-form>
										</div>
										<div class="feature">
											<p class="feature1" @click="$router.push('/login/forget');">忘记密码</p>
											<p class="feature2" @click="$router.push('/login/register');">立即注册</p>
										</div>
										<el-button @click="login"
											style="width: 100%;background-color: #0085FF;border-radius: 8px;height: 40px;font-size: 14px;"
											type="primary">立即登录</el-button>
										<div class="agreement">
											<el-checkbox v-model="checked"></el-checkbox>
											<div style="font-size: 14px;white-space:normal;margin-left: 10px;">
												我已阅读并同意<span style="color: #0085FF;"
													@click="$router.push('/serviceCenter/3/1804450566674083841')">《用户协议》</span>和<span
													style="color: #0085FF;"
													@click="$router.push('/serviceCenter/3/1806708534589366274')">《隐私政策》</span>
											</div>
										</div> -->
                  </el-card>
                </div>
                <div class="functions">
                  <div class="function">
                    <div class="lables">
                      <!-- <span v-if="token == undefined" class="lable" @click="toServiceCenter(0)"
												:class="{'lableAction':lableAction == 0}">网站公告</span>
											<span v-else class="lable" @click="toServiceCenter(0)"
												:class="{'lableAction':lableAction == 0}">新手指南</span>

											<span class="lable" @click="toServiceCenter(2)"
												:class="{'lableAction':lableAction == 2}">用户协议</span>
											<span class="lable" @click="toServiceCenter(1)"
												:class="{'lableAction':lableAction == 1}">帮助中心</span> -->
                      <div class="lableBox">
                        <img :src="require('@/static/image/newImage/titleIcon.png')" alt="" />
                        <div>公告消息</div>
                      </div>
                      <a href="/#/serviceCenter/3/0" class="lableBox" style="font-size: 14px; cursor: pointer; color: #5ca7c7; text-decoration: none">
                        <div>更多</div>
                        <i class="el-icon-arrow-right"></i>
                      </a>
                    </div>
                    <div class="noticeList">
                      <div class="noticeItem" v-for="item in noticeList" @click="$router.push('/serviceCenter/3/' + item.id)">
                        <div class="el1" style="width: 170px; display: flex; align-items: center">
                          <div style="width: 5px; height: 5px; border-radius: 50%; background: #5da7c7; margin-right: 6px"></div>
                          {{ item.title }}
                        </div>
                        <!-- <span>{{sjVal(item.createTime,6)}}</span> -->
                      </div>

                      <!--
											<div v-if="lableAction == 0 && token != undefined"
													 style="margin-top: 10px;">
												<div class="fl">
													<span class="newTitle">买家</span>
													<div class="newItem1">选择</br>商品</div>
													<img :src="require('@/static/image/newImage/icon7.png')"
															 style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem1">下单</br>购买</div>
													<img :src="require('@/static/image/newImage/icon7.png')"
															 style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem1">收货</div>
												</div>
												<div class="fl" style="margin-top: 30px;">
													<span class="newTitle">卖家</span>
													<div class="newItem">发布</br>商品</div>
													<img :src="require('@/static/image/newImage/icon6.png')"
															 style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem">买家</br>下单</div>
													<img :src="require('@/static/image/newImage/icon6.png')"
															 style="width:16px;height: 4px;margin-left: 6px;" alt="">
													<div class="newItem">发货</div>
												</div>
											</div>

											<div v-if="lableAction == 1" class="noticeItem" v-for="item in commonProblemList"
													 @click="$router.push('/serviceCenter/2/'+item.id)">
												<div class="el1" style="width: 170px;">{{ item.title }}</div>
												<span>{{ sjVal(item.createTime, 6) }}</span>
											</div>
											<div v-if="lableAction == 2" class="noticeItem" v-for="item in protocolList"
													 @click="$router.push('/serviceCenter/3/'+item.id)">
												<div class="el1" style="width: 170px;">{{ item.name }}</div>
												<span>{{ sjVal(item.createTime, 6) }}</span>
											</div>
-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="content">
      <el-row>
        <el-col :span="24">
          <div class="TopCard">
            <div class="TopCard_left">
              <img :src="require('@/static/image/newImage/icon8.png')" style="width: 20px; margin: 0 15px" />
              <span class="noticeTh">温馨提示</span>
              <!-- <img :src="require('@/static/image/newImage/noticeBox.png')"
								style="width:64px;margin-right: 2px;" /> -->
            </div>
            <div class="notice-bar-container">
              <div class="notice-bar__wrap">
                <div v-for="(item, index) in noticeData" :key="index" class="notice-bar__wrap_text">
                  <span style="color: #ff5c00">{{ item.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="content" style="display: flex; align-items: center; justify-content: space-between; margin-top: 24px">
      <div class="funList" v-for="item in funListNew" :key="item.color" :style="{ background: item.url }" @click="handelGo(item)">
        <div class="funTitle" :style="{ color: item.color }">{{ item.title }}</div>
        <div class="msg">{{ item.msg }}</div>
        <div class="funBtnBox" :style="{ background: item.color }">
          点击查看
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>

    <div class="content" style="margin-top: 26px">
      <div style="display: flex; align-items: center; justify-content: space-between">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div class="typeListNew">
            <div class="typeListBox" @click="handelChangeInfo(item)" :class="typeInfo == item.value ? 'istypeListBox' : ''" v-for="item in typeListNew" :key="item.value">{{ item.label }}</div>
          </div>
          <div class="typeAll" @click="$router.push('/game/select/0')">更多 <i class="el-icon-arrow-right"></i></div>
        </div>
      </div>
      <div style="background-color: #fff">
        <div class="swiperBox">
          <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-if="item.terminalType == typeInfo" v-for="item in swiperList" :key="item.id" class="swiper-slide" :style="{ background: 'url(' + getImgView(item.pic) + ') no-repeat ', backgroundSize: 'cover' }">
                <div class="swiper_box"></div>
                <!-- <div class="swiperBoxTop"><img :src="require('@/static/image/newImage/icon10.png')" alt=""> {{ item.seeCount }}人正在看</div> -->
                <div class="swiperBottom">
                  <div style="display: flex; justify-content: space-between">
                    <div style="color: black; font-size: 18px; font-weight: 600">{{ item.name }}</div>
                    <div>
                      ￥<span>{{ item.totalPrice }}</span>
                    </div>
                  </div>
                  <div class="swiperIcon">
                    <el-progress :text-inside="true" :show-text="false" :stroke-width="15" :percentage="item.hotNum || 0" color="#FF3E20" status="exception"></el-progress>
                    <img :src="require('@/static/image/newImage/icon9.png')" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" style="margin-top: 26px; display: flex; justify-content: space-between">
      <div style="width: 850px; background: #fff; padding: 15px; border-radius: 10px">
        <div class="titleBox">
          <img :src="require('@/static/image/newImage/hot.png')" alt="" />
          <div :class="titlTypeListIndex == item.id ? 'titleBtnItem' : 'titleBtnItem1'" v-for="(item, index) in titlTypeList" :key="item.icon" @click="handelChangeIndex(item.id)">
            <img :src="titlTypeListIndex == item.id ? item.icon : item.isIcon" alt="" />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="shopList">
          <div v-for="(item, index) in shopTitleList" :class="shopTitleIndex == index ? 'isShopTitle' : 'shopTitle'" :key="item.id" @click="handelChangeShopTitleIndex(index)">{{ item.name }}</div>
          <div class="shopListAll" @click="handelGoShop(1)">
            更多
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div class="shopLi" v-for="item in goodsList" @click="handelGoDetail(item)">
            <img :src="getImgView(item.pic)" alt="" />
            <div class="shopLiMsg">{{ item.gameId_dictText }} {{ item.title }}</div>

            <div class="shopLiTitle">游戏区服：{{ item.districtName }}</div>
            <div class="shopListNum">￥{{ item.price }}</div>

            <div class="shopListBottom">
              <div>{{ item.userId_dictText }}</div>
              <div>累计交易{{ item.orderNums || 0 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 300px; background: #fff; border-radius: 10px; overflow: hidden">
        <div class="rightTop">
          <div>
            <div class="rightTopTitle">昨日成交</div>
            <div>Daily Transaction</div>
          </div>
          <!--					<div style="cursor: pointer;">更多<i class="el-icon-arrow-right"></i></div>-->
        </div>
        <div class="rightList">
          <div class="rightLi" v-for="(item, index) in orderRankList">
            <div class="rightLiActor" :style="{ background: 'url(' + item.icon + ') no-repeat center center' }">
              <img v-if="index == 0" :src="require('@/static/image/newImage/one.png')" alt="" />
              <img v-if="index == 1" :src="require('@/static/image/newImage/two.png')" alt="" />
              <img v-if="index == 2" :src="require('@/static/image/newImage/three.png')" alt="" />
            </div>
            <div>
              <div class="rightLiTitle">{{ item.name }}</div>
              <div class="rightLiMsg">昨日交易{{ Math.round(item.practicalPrice) }}</div>
              <div style="display: flex; justify-content: space-between; font-size: 12px; color: #999999; border-top: 1px solid #e6e6e6; padding-top: 8">
                <div>{{ yesterday }}</div>
                <!--								<div>查看详情</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="functionList">
        <div class="functionLi" v-for="(item, index) in funcList" :key="index">
          <div class="functionLeft">
            {{ item.icon }}
          </div>
          <div class="functionRight">
            <div>{{ item.title }}</div>
            <div>{{ item.detaile }}</div>
          </div>
        </div>
      </div>
    </div>

    <shopping-card></shopping-card>
    <slidingBlock :shoWData="shoWData" @submit="verifySubmit"></slidingBlock>
    <el-dialog :visible.sync="dialogVisible" width="25%" style="border-radius: 50%" :before-close="handleClose">
      <div style="margin-bottom: 30px">
        <div style="font-size: 18px; margin-bottom: 5px; font-family: Source Han Sans CN; color: #333">黑号查询</div>
        <div style="display: flex">
          <el-input v-model="blackCode" placeholder="请输入账号" style="width: 300px; margin-right: 20px"></el-input>
          <div @click="handelSearchblackCode" style="width: 100px; height: 40px; background: #1861ee; border-radius: 12px; text-align: center; font-family: Source Han Sans CN; font-weight: 400; font-size: 16px; cursor: pointer; line-height: 40px; color: #ffffff">查询</div>
        </div>
      </div>
      <div>
        <div style="font-size: 18px; margin-bottom: 5px; font-family: Source Han Sans CN; color: #333">收款账户查询</div>
        <div style="display: flex">
          <el-input v-model="beneficiary" placeholder="请输入收款账户查询" style="width: 300px; margin-right: 20px"></el-input>
          <div @click="handelSearchbeneficiary" style="width: 100px; height: 40px; background: #1861ee; border-radius: 12px; text-align: center; font-family: Source Han Sans CN; font-weight: 400; font-size: 16px; line-height: 40px; cursor: pointer; color: #ffffff">查询</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Swiper from 'swiper' // 注意引入的是Swiper
  import 'swiper/css/swiper.min.css' // 注意这里的引入
  import { noticeList, gameCommodityCategories, gameList, gameCommodityTypes, districtList, userCenter, gameBanner, tipsList, commonProblemList, protocolList, statPrice, gameCommodityList, statGame, accountVerification, blackQuery, fush } from '@/api/index.js'
  import shoppingCard from '@/components/shoppingCart.vue'
  import newBar from '@/components/newBar.vue'
  import { getToken } from '@/utils/auth.js'
  import slidingBlock from '@/components/slidingBlock.vue'
  import { EventBus } from '@/static/js/event-bus.js'

  export default {
    name: 'home',
    components: {
      shoppingCard,
      slidingBlock,
      newBar,
    },
    // activated() {
    // 	setTimeout(() => {
    //
    // 		if (getToken() != undefined && getToken() != '' && getToken() != null) {
    // 			this.token = getToken()
    // 			// this.userInfo = this.$store.state.userInfo
    // 			userCenter().then(res => {
    // 				if (res.code == 200) {
    // 					this.userInfo = res.result
    // 				}
    // 			})
    // 		} else {
    // 			this.token = undefined
    // 		}
    // 	}, 200)
    // },
    data() {
      return {
        beneficiary: '',
        blackCode: '',
        dialogVisible: false,
        funcList: [
          {
            icon: '多',
            title: '游戏交易 应有尽有',
            detaile: '保障交易安全的事，我们都做',
          },
          {
            icon: '保',
            title: '交易安全 资金保障',
            detaile: '保障交易安全的事，我们都做',
          },
          {
            icon: '客',
            title: '10:00-22:00 专属客服',
            detaile: '时时刻刻想联系我，客服都在',
          },
          {
            icon: '快',
            title: '发货快捷 安全可靠',
            detaile: '我是值得信赖平台，大家都造',
          },
        ],
        shopTitleIndex: 0,
        shopTitleList: [],
        visible: false,
        titlTypeListIndex: 5,
        titlTypeList: [
          {
            id: 5,
            name: '账号',
            icon: require('@/static/image/newImage/icon14.png'),
            isIcon: require('@/static/image/newImage/icon15.png'),
          },
          {
            id: 1,
            name: '金币',
            icon: require('@/static/image/newImage/icon12.png'),
            isIcon: require('@/static/image/newImage/icon13.png'),
          },
          {
            id: 3,
            name: '装备道具',
            icon: require('@/static/image/newImage/icon16.png'),
            isIcon: require('@/static/image/newImage/icon17.png'),
          },
        ],
        swiper: null,
        swiperList: [],
        typeInfo: '1',
        // 类型数组
        typeListNew: [
          {
            label: '网页游戏',
            value: '1',
          },
          {
            label: '手机游戏',
            value: '2',
          },
        ],
        // 卡片数组
        funListNew: [
          {
            title: '签署合同',
            msg: '法律保护 安全交易',
            url: 'url(' + require('@/static/image/newImage/fn1.png') + ') no-repeat center center',
            color: '#1861EE',
            info: '/assure?id=1859148840850444289',
          },
          {
            title: '找回包赔',
            msg: '法律保护 安全交易',
            url: 'url(' + require('@/static/image/newImage/fn2.png') + ') no-repeat center center',
            color: '#FF443E',
            info: '/assure?id=1859148420295970818',
          },
          {
            title: '服务至上',
            msg: '法律保护 安全交易',
            url: 'url(' + require('@/static/image/newImage/fn3.png') + ') no-repeat center center',
            color: '#AD44FF',
            info: '/assure?id=1859148518090362881',
          },
          {
            title: '黑号查询',
            msg: '法律保护 安全交易',
            url: 'url(' + require('@/static/image/newImage/fn4.png') + ') no-repeat center center',
            color: '#1AAB70',
          },
        ],
        shoWData: false,
        lableAction: 0,
        headShow: true,
        hoverIndex: 0,
        hoverShow: false,
        userInfo: {},
        token: undefined,
        selectShow: false,
        selectShow1: false,
        selectShow2: false,
        selectShow3: false,
        selectShow4: false,
        selectShow5: false,
        // 验证码登录表单
        initialIndex: 0,
        loginForm: {
          account: '',
          code: '',
        },
        // 密码登录表单
        loginPassForm: {
          account: '',
          password: '',
        },
        // 倒计时
        countDown: 0,
        countdownInterval: null,
        // 密码登录验证
        loginPassRule: {
          account: [
            {
              required: true,
              message: '请输入登录手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ],
        },
        // 验证码登录验证
        loginRule: {
          account: [
            {
              required: true,
              message: '请输入登录手机号',
              trigger: 'blur',
            },
            {
              required: true,
              pattern: /^1\d{10}$/,
              message: '手机号格式不正确',
              trigger: 'blur',
            },
          ],
          code: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ],
        },
        loginType: 1,
        searchKey: '',
        defaultActive: '1',
        titleList: [
          {
            title: '首页',
            router: '/home',
            id: '1',
          },
          {
            title: '个人中心',
            router: '/mineIndex/1',
            id: '2',
          },
          {
            title: '订单聊天',
            router: '/chat/0',
            id: '3',
          },
          {
            title: '我的订单',
            router: '/mineIndex/13',
            id: '4',
          },
          {
            title: '买家中心',
            router: '/mineIndex/8',
            id: '5',
          },
          {
            title: '卖家中心',
            router: '/mineIndex/12',
            id: '6',
          },
          // {
          // 	title: '账户设置',
          // 	id: 7,
          // 	router: '/setting/1',
          // },
        ],
        equityList: [
          {
            image: require('@/static/image/index/equity1.png'),
            text: '交易安全',
          },
          {
            image: require('@/static/image/index/equity2.png'),
            text: '账号包赔',
          },
          {
            image: require('@/static/image/index/equity3.png'),
            text: '24H客服',
          },
          {
            image: require('@/static/image/index/equity4.png'),
            text: '闪电发货',
          },
          {
            image: require('@/static/image/index/equity5.png'),
            text: '资金保证',
          },
          {
            image: require('@/static/image/index/equity6.png'),
            text: '安全注册',
          },
        ],
        funList: [],
        // 热门标签
        hotTagList: [],

        // 热门游戏列表
        hotSort: [],
        // 公告列表
        noticeList: [],
        //首字母
        initial: ['热门游戏', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
        gameSearch: {
          initial: '',
          isHot: '',
          name: '',
          pageNo: 1,
          pageSize: 1000,
          terminalType: '',
        },
        gameList: [],
        selectGame: {},

        gameTypeList: [],
        selectGameTypeList: {},

        sonGameTypeList: [],
        selectSonGameTypeList: {},

        // 区服列表
        districtList: [],
        selectDistrict: {},

        // 大区
        sonDistrictList: [],
        sonSelectDistrict: {},
        // 系统
        systemList: [],
        selectSystem: {},

        // 轮播图列表
        bannerList: [],

        // 适应屏幕分辨率
        noticeWidth: 700,
        // 公告展示（过长时会重复两遍）
        showNotice: '',
        // 用于公告过长时，获取重复两遍中第一遍的长度
        notice: '',
        // 公告初始位置
        marginLeft: 150,
        noticeData: [],
        checked: false,

        hotGameList: [],
        // 网游数据
        onlineGameList: [],
        // 手游数据
        handGameList: [],
        queryPicList: {
          f1: {},
          f1Pics: [],
          f2: {},
          f2Pics: [],
        },
        commonProblemList: [],
        protocolList: [],
        selectTypeGames: 1,
        onelikeList: [],
        twolikeList: [],
        threelikeList: [],
        fourlikeList: [],
        orderRankList: [],
        yesterday: '',
        goodsList: [],
      }
    },
    created() {
      // 获取公告列表
      this.toServiceCenter(0)
      this.getGameList()
      // this.getGameList1();
      // this.getHotList();
      this.getGameBanner()
      this.getOnlineGameList()
      // this.getQueryPic();
      // this.likeList();
      this.loadStatPrice()
      this.loadGoodsList()
      this.loadGameStat()
      this.isLogin()
    },
    mounted() {
      this.getSwiper()
      // ref="dialogBox"
      // 监听全局点击事件
      document.addEventListener('click', (e) => {
        if (e.target.className == 'selectSpan') return
        if (this.$refs.dialogBoxs && !this.$refs.dialogBoxs.contains(e.target)) {
          this.selectShow = false
          this.selectShow1 = false
          this.selectShow2 = false
          this.selectShow3 = false
          this.selectShow4 = false
          this.selectShow5 = false
        }
      })

      tipsList().then((res) => {
        if (res.code == 200) {
          this.noticeData = res.result.records
          // setTimeout(()=>{
          // 	this.noticeData= [...this.noticeData,...this.noticeData]
          // },60000)

          // this.noticeData.forEach((val, index) => {
          // 	if (index === 0) {
          // 		this.showNotice += '交易快报:' + val +
          // 			'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
          // 	} else {
          // 		this.showNotice += '交易快报:' + val +
          // 			'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
          // 	}
          // })

          // // 公告上面先赋值，再获取宽度
          // this.$nextTick(() => {
          // 	setTimeout(() => {

          // 		if (document.getElementsByClassName('showNotice')[0] == undefined ||
          // 			document.getElementsByClassName('noticeBox')[0] == undefined) {
          // 			return
          // 		}
          // 		// 公告div长度
          // 		var oneNoticeWidth = document.getElementsByClassName('showNotice')[0]
          // 			.offsetWidth

          // 		// 公告外层div长度
          // 		var noticeBoxWidth = document.getElementsByClassName('noticeBox')[0]
          // 			.offsetWidth
          // 		// 一条公告长度太大时，才滚动

          // 		if (oneNoticeWidth > noticeBoxWidth) {
          // 			// 滚动公告需要将公告重复两遍进行滚动，两遍中间需要加空格
          // 			this.notice = this.showNotice +
          // 				'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
          // 			// 上面先赋值，再获取宽度
          // 			setTimeout(() => {
          // 				// 获取一遍加中间空格的长度，即左移时第二遍与第一遍完全重合时的长度
          // 				var oneNoticeAddEmptyWidth = document
          // 					.getElementsByClassName('notice')[0].offsetWidth
          // 				// 公告内容重复两遍
          // 				this.showNotice = this.notice + this.showNotice
          // 				this.timer = setInterval(() => {
          // 					this.marginLeft -= 1
          // 					// 第二遍与第一遍起始位置重合时（第一条已完全移到左侧隐藏），marginLeft置0，即回到第一条
          // 					if (this.marginLeft === (-1) *
          // 						oneNoticeAddEmptyWidth) {
          // 						this.marginLeft = 0
          // 					}
          // 				}, 40)
          // 			}, 10)
          // 		} else { //公告并没有很长时不滚动
          // 			this.marginLeft = 0
          // 		}
          // 	}, 1000)

          // })
        }
      })
    },

    methods: {
      //收款账户验证
      handelSearchbeneficiary() {
        if (this.beneficiary == '') {
          return
        } else {
          accountVerification({ text: this.beneficiary }).then((res) => {
            if (res.result) {
              this.message('该账号未查询到拉黑信息', 'success')
            } else {
              this.message('该账号已被拉入黑信息', 'error')
            }
          })
        }
      },
      //黑号查询
      handelSearchblackCode() {
        if (this.blackCode == '') {
          return
        } else {
          blackQuery({ text: this.blackCode }).then((res) => {
            if (res.result) {
              this.message('该账号未查询到拉黑信息', 'success')
            } else {
              this.message('该账号已被拉入黑信息', 'error')
            }
          })
        }
      },
      handleClose(done) {
        this.dialogVisible = false
        this.beneficiary = ''
        this.blackCode = ''
        done()
      },
      handelGo(item) {
        if (item.info) {
          this.$router.push(item.info)
        } else {
          this.dialogVisible = true
        }
      },
      handelGoDetail(item) {
        this.$router.push('/gameDetaile/' + item.id)
      },

      handelChangeIndex(id) {
        this.titlTypeListIndex = id
        this.shopTitleIndex = 0
        for (let item of this.hotSort) {
          if (!id || item.gameCommodityCategory === id) {
            this.shopTitleList = item.games
            break
          }
        }
        this.loadGoodsList()
      },
      handelChangeShopTitleIndex(index) {
        this.shopTitleIndex = index
        this.loadGoodsList()
      },
      getSwiper() {
        this.swiper = new Swiper('.swiper-container', {
          loop: false, // 无缝
          observer: true,
          observeParents: true,
          autoplay: {
            //自动开始
            delay: 2000, //时间间隔
            disableOnInteraction: false, //*手动操作轮播图后不会暂停*
          },
          paginationClickable: true,
          slidesPerView: 5.5, // 一组三个
          spaceBetween: 3, // 间隔
          // 如果需要前进后退按钮
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
          // 如果需要分页器
          pagination: {
            el: '.swiper-pagination',
            clickable: true, // 分页器可以点击
          },
        })
      },

      handelChangeInfo(item) {
        this.typeInfo = item.value
      },

      handelGoShop(num) {
        let url = num == 1 ? '/game/select/0' : '/game/add/add'
        this.$router.push(url)
      },
      isLogin() {
        // this.token = getToken()
        if (this.$store.state.token) {
          fush().catch(() => {
            this.$store.dispatch('loginOut')
          })
        }
      },
      handleSelect(key, keyPath) {
        let currentRoute = this.$router.currentRoute
        if (currentRoute.path != this.titleList[key - 1].router) {
          this.$router.push(this.titleList[key - 1].router)
        }
        this.defaultActive = key
      },
      toServiceCenter(type) {
        if (type == 0) {
          noticeList({
            pageNo: '1',
            pageSize: '5',
          }).then((res) => {
            if (res.code == 200) {
              this.noticeList = res.result.records
            }
          })
        } else if (type == 1) {
          commonProblemList({
            pageNo: '1',
            pageSize: '5',
          }).then((res) => {
            if (res.code == 200) {
              this.commonProblemList = res.result.records
            }
          })
        } else if (type == 2) {
          protocolList({
            pageNo: '1',
            pageSize: '5',
          }).then((res) => {
            if (res.code == 200) {
              this.protocolList = res.result.records
            }
          })
        }
        this.lableAction = type
      },
      // getQueryPic() {
      // 	queryPic().then(res => {
      // 		if (res.code == 200) {
      // 			this.funList = res.result.banner.splice(0, 3);
      // 			// this.queryPicList = res.result
      // 			// this.queryPicList.f1.terminalType = 1
      // 			// this.queryPicList.f2.terminalType = 2
      // 		}
      // 	});
      // },
      // 获取网络游戏和手机游戏
      getOnlineGameList() {
        gameList({
          // isHot:0,
          pageNo: 1,
          pageSize: 5,
        }).then((res) => {
          if (res.code == 200) {
            this.hotGameList = res.result.records
          }
        })
        // gameList({
        // 	// isHot:0,
        // 	pageNo      : 1,
        // 	pageSize    : 12,
        // 	terminalType: 2
        // }).then(res => {
        // 	if (res.code == 200) {
        // 		this.handGameList = res.result.records;
        // 	}
        // });
      },
      // 获取轮播图
      getGameBanner() {
        gameBanner({ location: 11 }).then((res) => {
          if (res.code == '200') {
            this.bannerList = res.result
          }
        })
      },
      // // 获取热门搜索
      // getHotList() {
      // 	hotList({
      // 		pageNo  : 1,
      // 		pageSize: 6
      // 	}).then(res => {
      // 		this.hotTagList = res.result.records;
      // 	});
      //
      // },
      getGameList() {
        gameCommodityCategories({
          pageNo: 1,
          pageSize: 10,
        }).then((res) => {
          this.hotSort = res.result
          this.handelChangeIndex(this.titlTypeListIndex)
        })
      },
      // getGameList1() {
      // 	gameList(this.gameSearch).then(res => {
      // 		if (res.code == 200) {
      // 			this.gameList = res.result.records;
      // 		}
      // 	});
      // },

      // 获取验证码
      getCodes(type, account) {
        if (this.countDown > 0) {
          return
        }
        this.getCode(type, account).then((res) => {
          // 开始倒计时
          this.startCountdown()
        })
      },
      startCountdown() {
        this.countDown = 60 // 初始倒计时时间（秒）
        this.countdownInterval = setInterval(() => {
          this.countDown--
          if (this.countDown <= 0) {
            clearInterval(this.countdownInterval)
            this.countDown = 0
            // 可以选择是否自动重新生成验证码
            // this.generateCode();
          }
        }, 1000)
      },
      // 切换登录方式
      swiperType(type) {
        this.loginType = type
      },

      // 登录
      login() {
        if (!this.checked) {
          this.message('请阅读并勾选协议', 'error')
          return
        }
        this.shoWData = true
      },
      verifySubmit(e, verSuccess) {
        if (verSuccess) {
          this.shoWData = false
          // 短信登录
          if (this.loginType == 0) {
            this.$refs.loginForm.validate((valid) => {
              if (valid) {
                this.$store.dispatch('loginCode', this.loginForm).then(() => {
                  this.$refs.headBar.isLogin()
                  this.message('登陆成功', 'success')
                  this.token = getToken()
                  userCenter().then((res) => {
                    if (res.code == 200) {
                      this.userInfo = res.result
                    }
                  })
                })
              }
            })

            // 验证码登录
          } else {
            this.$refs.loginPassForm.validate((valid) => {
              if (valid) {
                this.$store.dispatch('login', this.loginPassForm).then(() => {
                  this.$refs.headBar.isLogin()
                  this.message('登陆成功', 'success')
                  this.token = getToken()
                  userCenter().then((res) => {
                    if (res.code == 200) {
                      this.userInfo = res.result
                    }
                  })
                })
              }
            })
          }
        }
      },

      loadMore() {
        const lastIndex = this.noticeList.length - 1
        const lastItem = this.noticeList[lastIndex]
        const nextIndex = lastItem.id + 1

        setTimeout(() => {
          const newData = this.noticeList.slice(nextIndex - 1, nextIndex + 9).map((item) => {
            return {
              id: item.id,
              content: item.content,
            }
          })

          this.noticeList = [...this.noticeList, ...newData]
        }, 1000)
      },
      // 选择游戏的时候清空其他数据
      selectGameShow(type) {
        if (type == 'selectGame') {
          this.selectShow = true
          this.selectShow1 = false
          this.selectShow2 = false
          this.selectShow3 = false
          this.selectShow4 = false
          this.selectShow5 = false
        } else if (type == 'selectGameTypeList') {
          this.selectShow = false
          this.selectShow1 = true
          this.selectShow2 = false
          this.selectShow3 = false
          this.selectShow4 = false
          this.selectShow5 = false
        } else if (type == 'selectSonGameTypeList') {
          this.selectShow = false
          this.selectShow1 = false
          this.selectShow2 = true
          this.selectShow3 = false
          this.selectShow4 = false
          this.selectShow5 = false
        }
      },
      // 选择游戏
      gameSelect(item) {
        this.selectGame = {
          id: item.id,
          name: item.name,
          terminalType: item.terminalType,
          icon: item.icon,
        }
        // 获取商品类型
        gameCommodityTypes({
          id: item.id,
        }).then((res) => {
          if (res.result.length != 0) {
            this.gameTypeList = res.result
            this.selectShow = false
            this.selectShow1 = true

            this.selectGameTypeList = {}

            this.sonGameTypeList = []
            this.selectSonGameTypeList = {}

            this.districtList = []
            this.selectDistrict = {}

            this.sonDistrictList = []
            this.sonSelectDistrict = {}

            this.systemList = []
            this.selectSystem = {}
          } else {
            this.searchShop()
          }
        })
      },
      bannerChange(e) {
        this.initialIndex = e
      },
      // 选择游戏类型
      gameTypeSelect(item) {
        this.selectGameTypeList = {
          id: item.id,
          name: item.name,
        }
        this.systemList = []
        this.selectSystem = {}
        // 如果是手游的话先获取系统
        if (this.selectGame.terminalType == 2) {
          if (item.childrenName != null) {
            this.sonGameTypeList = item.childrenName.split(' ')
            // this.sonGameTypeList=['111','2222']
            this.selectShow1 = false
            this.selectShow2 = true
          } else {
            districtList({
              id: this.selectGame.id,
            }).then((res) => {
              if (res.result.length != 0) {
                this.systemList = res.result
                this.selectShow1 = false
                this.selectShow2 = false
                this.selectShow5 = true
              } else {
                this.searchShop()
              }
            })
          }
        } else {
          if (item.childrenName != null && item.childrenName != '') {
            this.sonGameTypeList = item.childrenName.split(' ')
            // this.sonGameTypeList=['111','2222']
            this.selectShow1 = false
            this.selectShow2 = true
          } else {
            districtList({
              id: this.selectGame.id,
            }).then((res) => {
              if (res.result.length != 0) {
                this.districtList = res.result
                this.selectShow2 = false
                this.selectShow1 = false
                this.selectShow3 = true
              } else {
                this.searchShop()
              }
            })
          }
        }
      },
      // 选择系统
      systemSelect(item) {
        this.selectSystem = {
          id: item.id,
          name: item.name,
        }
        districtList({
          id: this.selectSystem.id,
        }).then((res) => {
          if (res.result.length != 0) {
            this.districtList = res.result
            this.selectShow5 = false
            this.selectShow3 = true
          } else {
            this.searchShop()
          }
        })
      },
      // 选择商品子类
      sonGameTypeSelect(item) {
        this.selectSonGameTypeList = {
          name: item,
        }
        if (this.selectGame.terminalType == 2) {
          districtList({
            id: this.selectGame.id,
          }).then((res) => {
            if (res.result.length != 0) {
              this.systemList = res.result
              this.selectShow2 = false
              this.selectShow5 = true
            } else {
              this.searchShop()
            }
          })
        } else {
          districtList({
            id: this.selectGame.id,
          }).then((res) => {
            if (res.result.length != 0) {
              this.districtList = res.result
              this.selectShow2 = false
              this.selectShow3 = true
            } else {
              this.searchShop()
            }
          })
        }
      },
      // 选择区服
      districtSelect(item) {
        this.selectDistrict = {
          id: item.id,
          name: item.name,
        }
        districtList({
          id: this.selectDistrict.id,
        }).then((res) => {
          if (res.result.length != 0) {
            this.sonDistrictList = res.result
            this.selectShow3 = false
            this.selectShow4 = true
          } else {
            this.searchShop()
          }
        })
      },
      // 选择大区
      sonDistrictSelect(item) {
        this.sonSelectDistrict = {
          id: item.id,
          name: item.name,
        }
        this.selectShow4 = false
        this.searchShop()
      },
      // 筛选游戏名称
      selectSearch(value, type) {
        if (type == 'type') {
          this.gameSearch.terminalType = value
        }
        if (type == 'initial') {
          if (value == '热门游戏') {
            this.gameSearch.initial = ''
          } else {
            this.gameSearch.initial = value
          }
        }
        // this.getGameList1();
      },

      // 鼠标划过事件
      handleMouseEnter(event, index) {
        this.hoverShow = true
        this.hoverIndex = index
      },
      handleMouseLeave() {
        this.hoverShow = false
      },
      // 微信登录
      weixinLogin() {
        // let url = 'http://'+window.location.host+ '/#/login/login'
        let redirect_uri = encodeURIComponent(window.location.origin + '/#/login/login')
        let appid = 'wx34ce80f4fc983974'
        window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=123#wechat_redirect`
      },
      handelGoLogin() {
        window.location.href = window.location.origin + '/#/login/login'
      },
      // 跳转到商品列表
      toShopList(item1, item2) {
        let info = {
          id: item1.id,
          terminalType: item1.terminalType,
          name: item1.name,
          icon: item1.icon,
          addType: 'select',
          displayType: '',
          orderType: 1,
          priceMin: '',
          priceMax: '',
        }
        if (item2) {
          info.gameTypeSelect = {
            id: item2.id,
            name: item2.name,
          }
        }
        this.$router.push({
          path: '/gameList',
          query: {
            info: JSON.stringify(info),
          },
        })
      },
      toShopLists(item) {
        let info = {
          id: item.id,
          terminalType: item.terminalType,
          name: item.name,
          icon: item.icon,
        }
        // localStorage.setItem('gameList', JSON.stringify(info));
        window.open(
          this.$router.resolve({
            path: '/gameList',
            query: {
              info: JSON.stringify(info),
            },
          }).href,
          '_blank'
        )
      },
      toShopLists1(item, item1) {
        let info = {
          id: item.id,
          terminalType: item.terminalType,
          name: item.name,
          icon: item.icon,
          gameTypeSelect: {
            id: item1.id,
            name: item1.name,
          },
        }
        // localStorage.setItem('gameList', JSON.stringify(info));
        window.open(
          this.$router.resolve({
            path: '/gameList',
            query: {
              info: JSON.stringify(info),
            },
          }).href,
          '_blank'
        )
      },
      toShopLists2(item) {
        let info = {
          id: item.gameId,
          terminalType: item.terminalType,
          name: item.name,
          icon: item.icon,
        }
        // localStorage.setItem('gameList', JSON.stringify(info));
        window.open(
          this.$router.resolve({
            path: '/gameList',
            query: {
              info: JSON.stringify(info),
            },
          }).href,
          '_blank'
        )
      },
      toLink(item) {
        if (!this.isEmpty(item.url) && item.bannerType == 1) {
          window.open(item.url)
        }
      },
      toLink1(item) {
        if (item.url == 'serve') {
          this.$confirm('是否需要联系在线客服？')
            .then((_) => {
              EventBus.$emit('sericeCard', 'true')
            })
            .catch((_) => {})
          return
        }
        if (!this.isEmpty(item.url)) {
          window.open(item.url)
        }
      },
      searchShop() {
        if (this.searchKey != '') {
          window.open(
            this.$router.resolve({
              path: '/gameDetaile/' + this.searchKey,
            }).href,
            '_blank'
          )
          return
        }
        if (Object.keys(this.selectGame).length == 0) {
          this.message('请先选择游戏', 'error')
          return
        }

        let info = {
          id: this.selectGame.id,
          name: this.selectGame.name,
          terminalType: this.selectGame.terminalType,
          icon: this.selectGame.icon,
          title: this.searchKey,
        }
        if (Object.keys(this.selectGameTypeList).length != 0) {
          info.gameTypeSelect = {
            id: this.selectGameTypeList.id,
            name: this.selectGameTypeList.name,
          }
        }
        if (Object.keys(this.selectSonGameTypeList).length != 0) {
          info.gameTypeSonSelect = {
            name: this.selectSonGameTypeList.name,
          }
        }

        if (Object.keys(this.selectDistrict).length != 0) {
          info.selectDistrict = {
            id: this.selectDistrict.id,
            name: this.selectDistrict.name,
          }
        }
        if (Object.keys(this.sonSelectDistrict).length != 0) {
          info.sonSelectDistrict = {
            id: this.sonSelectDistrict.id,
            name: this.sonSelectDistrict.name,
          }
        }
        if (Object.keys(this.selectSystem).length != 0) {
          info.selectSystem = {
            id: this.selectSystem.id,
            name: this.selectSystem.name,
          }
        }
        // localStorage.setItem('gameList', JSON.stringify(info));
        window.open(
          this.$router.resolve({
            path: '/gameList',
            query: {
              info: JSON.stringify(info),
            },
          }).href,
          '_blank'
        )
      },

      //加载昨日成交排行榜
      loadStatPrice() {
        let d = new Date()
        d.setDate(d.getDate() - 1)
        this.yesterday = d.toLocaleDateString()
        statPrice({
          limit: 7,
          successTimeBegin: d.toLocaleDateString(),
          successTimeEnd: new Date().toLocaleDateString(),
        }).then((res) => {
          this.orderRankList = res.result.list
        })
      },
      //加载商品列表
      loadGoodsList(e) {
        // this.pageNo = 1
        gameCommodityList({
          title: this.title,
          gameId: this.shopTitleList[this.shopTitleIndex]?.id,
          gameCommodityCategory: this.titlTypeListIndex,
          pageNo: 1,
          pageSize: 6,
          orderType: 1,
        }).then((res) => {
          this.goodsList = res.result.records
        })
      },
      //加载游戏统计
      loadGameStat() {
        statGame({}).then((res) => {
          for (let item of res.result) {
            item.id = item.id + item.gameCommodityCategory
          }
          this.swiperList = res.result
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-card {
    border-radius: 10px;
    border: none;
  }

  ::v-deep .el-card__body,
  .el-main {
    padding: 0;
  }

  // ::v-deep .el-checkbox__inner {
  // 	width: 20px;
  // 	height: 20px;
  // }

  // ::v-deep .el-input-group{
  // 	border: 1px solid #DEDEDE;
  // }

  .search {
    width: 670px;
    height: 44px;
    // background-color: #0085FE;
    border-radius: 22px;
    border: 1px solid #1861ee;
    padding: 2px 0;
    display: flex;
    align-items: center;
    margin-top: 27px;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .submitSearch {
    line-height: 44px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    letter-spacing: 5px;
    cursor: pointer;
    width: 110px;
    height: 44px;
    background: #1861ee;
    border-radius: 22px;
  }

  .el-dropdown-link {
    font-size: 18px;
    color: #000;
  }

  .downList {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 40px;
    // margin-left: 2px;
    // margin-right: 2px;
    margin-left: 10px;
    width: calc(100% - 80px);
    border-radius: 5px;
    position: relative;
  }

  .dialogBox {
    position: absolute;
    top: 60px;
    width: 800px;
    background-color: #fff;
    z-index: 99999 !important;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }

  .downItem {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep .el-input__inner {
    height: 40px;
  }

  ::v-deep .el-form-item {
    margin-bottom: 15px;
  }

  .hotList {
    color: #a5a7b1;
    display: flex;
    font-size: 14px;
    margin-top: 10px;
    align-items: center;
    white-space: nowrap;

    .hotItem {
      margin-right: 10px;
      border-radius: 5px;
      // border: 1px solid #ECECEC;
      padding: 2px 2px;
      font-size: 14px;
      white-space: nowrap;
      cursor: pointer;
    }

    .hotItem:hover {
      background-color: #0085fe;
      color: #fff;
      border: none;
    }
  }

  .sort {
    height: 426px;
    // overflow: hidden;
    width: 100%;
    border-radius: 12px;
    position: relative;

    .hoverList {
      position: absolute;
      right: -708px;
      top: 55px;
      background-color: #fff;
      width: 700px;
      height: 583px;
      z-index: 999;
      border: 4px solid #0085fe;

      .hoverTitle {
        font-size: 20px;
        font-weight: 600;
        padding: 20px 30px;
      }

      .hoverTag {
        padding: 20px 40px;
        display: flex;
        flex-wrap: wrap;

        .tagItem {
          width: 16%;
          margin-bottom: 30px;
          margin-right: 5%;
          font-weight: bold;
          font-size: 14px;
          color: #888;
          cursor: pointer;
        }

        .tagItem:nth-child(5n) {
          margin-right: 0;
        }

        .tagItem:hover {
          color: #0085fe;
        }
      }
    }

    .sortTop {
      color: #fff;
      height: 40px;
      background: #1a1a1a;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .topLeft {
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 2px;
      }

      .topRight {
        font-size: 12px;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .sortItemAll {
      background: linear-gradient(150deg, #d3f3ff, #ffffff);
      border-radius: 12px;
      margin-top: -10px;
    }

    .sortItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      border-bottom: 1px solid #f6f6f6;

      .sortTitle {
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }

      .sortTag {
        color: #888;
        font-size: 12px;
        // font-weight: 600;
        margin-right: 6px;
        white-space: nowrap;
        display: inline-block;
        max-width: 100px;
        cursor: pointer;
      }

      .sortTag:hover {
        color: #0085fe;
      }

      .sortTitle:hover {
        color: #0085fe;
      }
    }
  }

  .centerTop {
    color: #000;
    height: 25px;
    // width: 300px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 18px;

    .centerItem {
      margin-right: 30px;
      font-size: 18px;
    }

    .centerItem:hover {
      color: #0085fe;
      cursor: pointer;
    }
  }

  .loginText {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #a5a7b1;
    margin-bottom: 20px;
    margin-top: 10px;

    .login {
      margin-right: 20px;
      font-weight: 500;
      cursor: pointer;
    }

    .login1 {
      padding-left: 20px;
      border-left: 3px solid #ececec;
      cursor: pointer;
    }

    .actionLogin {
      color: #0085ff;
    }
  }

  .feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #777a8a;
    font-size: 14px;
    margin-top: -15px;

    .feature2 {
      cursor: pointer;
    }

    .feature2 {
      color: #0085ff;
      cursor: pointer;
    }
  }

  .agreement {
    margin-top: 15px;
    font-size: 16px;
    display: flex;
  }

  .functionImage img {
    border-radius: 5px;
    width: 216px;
    // height: 160px;
    transition: all 0.5s; //控制速度，也可不加
    cursor: pointer;
  }

  .functionImage img:hover {
    transform: scale(1.05); //放大倍数
  }

  .functions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .function {
      width: 403px;
      height: 150px;
      background-color: #fff;
      border-radius: 5px;
      transition: all 0.5s; //控制速度，也可不加
      overflow: hidden;
      background: linear-gradient(0deg, #feffff 0%, #d9f5ff 100%);
      border-radius: 12px;

      .lables {
        padding: 0 16px;
        height: 45px;
        border-bottom: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #5ca7c7;

        .lableBox {
          display: flex;
          align-items: center;
        }
      }

      .lable {
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;

        margin-right: 15px;
      }

      .lableAction {
        color: #0085ff;
        position: relative;
      }

      .lableAction::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 70px;
        bottom: -16px;
        left: 0px;
        background-color: #0085fe;
      }

      .noticeList {
        padding: 16px;
        overflow-y: scroll;
        height: 211px;

        .noticeItem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-size: 12px;
          cursor: pointer;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #5ca7c7;
        }

        .noticeItem:hover {
          color: #0085ff;
        }
      }

      .noticeList::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 46px;

    .titleH1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 20px;
      color: #1d223c;
      line-height: 42px;
    }

    .titleH2 {
      font-weight: 400;
      font-size: 14px;
      color: #777a8a;
      line-height: 21px;
      margin-top: 8px;
    }
  }

  .gameImage img {
    border-radius: 5px;
    width: 317px;
    height: 460px;
    transition: all 0.5s; //控制速度，也可不加
  }

  .gameImage img:hover {
    transform: scale(1.04); //放大倍数
  }

  ::v-deep .el-carousel__button {
    background-color: #0085ff;
    height: 4px;
    // border-radius: 10px;
    // color: #0085FF;
  }

  // ::v-deep .el-carousel__indicators--outside button {
  // 	background-color: #0085FF;
  // 	height: 4px;
  // 	border-radius: 10px;
  // }

  .game {
    display: flex;
    justify-content: space-between;
    // width: 1920px;
    // height: 536px;
    // align-items: center;

    // .gameLeft {
    // 	// width: 320px;
    // 	height: 536px;

    // }

    .gameRight {
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      // justify-content: space-between;
      // margin-left: 20px;
      // height: 400px;
      // width: 1200px;

      .rightItem {
        width: 200px;
        height: 124px;
        // margin-right: 12px;
        background: #ffffff;
        border-radius: 12px 12px 12px 12px;
        // border: 1px solid #ECECEC;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        margin-top: 15px;
        cursor: pointer;

        .rightItem img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }

        .itemName {
          margin: 12px;
          font-weight: bold;
          font-size: 14px;
          width: 160px;
          text-align: center;
        }

        .itemTag {
          font-weight: 400;
          font-size: 14px;
          color: #a5a7b1;

          .itemTags:hover {
            color: #ff5b01;
          }
        }
      }

      // .rightItem:hover {
      // 	background: linear-gradient(180deg, #03B5FF 0%, #0085FF 100%);

      // }

      // .rightItem:hover div {
      // 	color: #fff;

      // }
    }
  }

  .showMore {
    border: 1px solid #0085ff;
    color: #0085ff;
    font-size: 15px;
    width: 158px;
    height: 39px;
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    line-height: 39px;
    margin: 10px auto;
  }

  .showMore:hover {
    background-color: rgba(102, 177, 255, 0.2);
    // color: #fff;
  }

  .sellItem {
    img {
      transition: all 0.5s; //控制速度，也可不加
    }

    img:hover {
      transform: scale(1.04); //放大倍数
    }

    .sellTitle {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 1px solid #ececec;
    }

    .sellPrice {
      padding: 20px;
      font-weight: bold;
      font-size: 20px;
      color: #ff5c00;
      line-height: 28px;
    }
  }

  .getCode {
    position: absolute;
    // top: 7px;
    right: 5px;
    // line-height: 80px;
    font-size: 12px;
    color: #0085ff;
  }

  .getCode::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    left: -10px;
    top: 10px;
    background-color: #ececec;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .infoImage {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .userName {
      font-weight: 400;
      font-size: 14px;
      margin-top: 12px;
    }

    .userList {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .userItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin-top: 10px;

        .userNumber {
          font-weight: bold;
          font-size: 20px;
          color: #1d223c;
        }

        .userText {
          font-weight: 400;
          font-size: 14px;
          color: #777a8a;
          margin-top: 6px;
        }

        .userBtn {
          margin-top: 14px;
          width: 94px;
          height: 38px;
          background: #0085ff;
          border-radius: 8px 8px 8px 8px;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          line-height: 38px;
          text-align: center;
        }

        .userBtn1 {
          margin-top: 14px;
          width: 94px;
          height: 38px;
          background: #00e0b5;
          border-radius: 8px 8px 8px 8px;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          line-height: 38px;
          text-align: center;
        }
      }
    }
  }

  .equityList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 30px;

    .equityItem {
      width: 30%;
      display: flex;
      font-size: 12px;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .searchItem {
    min-width: 90px;
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    cursor: pointer;

    i {
      margin-left: 10px;
    }

    margin-left: 10px;
  }

  .searchName {
    padding: 0 20px;

    .searchLable {
      color: #000;
      opacity: 0.8;
      font-size: 14px;
    }

    .searchTag {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .typeTop {
    height: 35px;
    background-color: #fafafa;
    justify-content: space-between;

    ::v-deep .el-input__inner {
      height: 35px;
    }

    .gameType {
      width: 150px;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
    }

    .gameTypeAction {
      background-color: #0085fe;
      color: #fff;
    }
  }

  .selectList {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;

    .selectItem {
      width: 190px;
      margin-bottom: 20px;
      // color: #0085FE;
      font-size: 14px;
    }

    .selectSpan {
      cursor: pointer;
    }

    .selectSpan:hover {
      background-color: #0085fe;
      color: #fff;
    }
  }

  .initialList {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    .initialItem {
      font-size: 14px;
    }
  }

  /* 公告card */
  .TopCard .el-card__body {
    padding: 0 10px;
  }

  .showNotice {
    font-weight: 500;
    font-size: 14px;
    color: red;
  }

  .TopCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 50px;
    border: 1px solid #1861ee;
    height: 40px;
    margin-top: 20px;
    // width: 2000px;
    .TopCard_left {
      width: 157px;
      height: 40px;
      background: #1861ee;
      border-radius: 50px 0px 0px 50px;
      display: flex;
      align-items: center;
    }
  }

  .floor {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .floorLeft {
      font-weight: bold;
      display: flex;
      font-size: 26px;
    }

    .floorRight {
      display: flex;
      align-items: center;
      // margin-right: 80px;
      color: #666;
    }

    .floorRight:hover {
      color: #ff5b01;
    }
  }

  .noticeTh {
    font-weight: bold;
    color: #1d223c;
    white-space: nowrap;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
  }

  // /* 主内容宽度 */
  // .content {
  // 	margin: 0 auto;
  // 	width: 1920px;

  // 	font-family: 'PingFangSC';
  // }

  // /* 宽度低于1920，给主体内容左右加留白 */
  // @media screen and (max-width: 1920px) {
  // 	.content {
  // 		overflow: hidden;
  // 		overflow-x: auto;
  // 	}
  // }
  .selectGames {
    width: 556px;
    height: 58px;
    border-radius: 12px 12px 12px 12px;
    margin: 0 auto;
    border: 1px solid #ececec;
    margin-bottom: 30px;
    margin-top: -20px;

    .gamedItem {
      width: 278px;
      display: flex;

      justify-content: center;
    }

    .gamedSelct {
      background: #0085ff;
      border-radius: 12px 12px 12px 12px;
      height: 58px;
      color: #fff;
    }
  }

  .xhCard {
    width: 287px;
    // height: 360px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;

    .xhTitle {
      font-weight: 400;
      font-size: 14px;
      color: #1d223c;
      padding: 10px;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
    }

    .xhArea {
      font-weight: 400;
      font-size: 14px;
      color: #a5a7b1;
    }

    .xhPrice {
      font-weight: bold;
      font-size: 24px;
      color: #ff5c00;
      padding: 10px;
    }
  }

  .mians {
    background-position: 0px 55px;
  }

  .newTitle {
    font-weight: 400;
    font-size: 13px;
    color: #777a8a;
  }

  .newItem {
    width: 40px;
    height: 40px;
    background: #fffaf4;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ff7104;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
    margin-left: 5px;
  }

  .newItem1 {
    width: 40px;
    height: 40px;
    background: #f1f9ff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #0992ff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }

  .notic-bar {
    display: flex;
    background: #67c23a;
    margin: 5px;
    border-radius: 5px;
    padding: 2px 5px;
  }

  .notice-bar-container {
    display: flex;
    width: calc(100% - 30px);
    height: 20px;
    overflow: hidden;
    margin-left: 5px;
  }

  .notice-img {
    width: 20px;
    height: 20px;
  }

  .notice-bar__wrap {
    margin-left: 10px;
    animation: move 6s linear infinite;
    line-height: 20px;
    color: #000;

    .notice-bar__wrap_text {
      width: max-content;
      min-width: 100px;
      margin-bottom: 5px;
      margin-right: 150px;
    }
  }

  @keyframes move {
    0% {
      transform: translateY(100%); /* 动画开始时，元素在页面底部 */
    }
    100% {
      transform: translateY(-400%); /* 动画结束时，元素回到原来的位置 */
    }
  }

  .rightBtn {
    display: flex;

    .rightBtnBox {
      width: 110px;
      height: 44px;
      background: #1861ee;
      border-radius: 22px;
      text-align: center;
      line-height: 44px;
      color: #fff;
      margin-bottom: 5px;
      margin-left: 12px;
    }
  }

  .rightImg {
    width: 60px;
    height: 60px;
    margin: 30px auto;
    display: block;
  }

  .rightText {
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: 600;
    font-size: 16px;
    color: #333333;

    span {
      color: #1861ee;
      cursor: pointer;
    }
  }

  .rightText1 {
    text-align: center;
    font-family: Microsoft YaHei;
    font-size: 12px;
    color: #333333;
    margin-top: 16px;

    span {
      color: #1861ee;
      cursor: pointer;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .bottomBtnBox {
      width: 100px;
      height: 32px;
      background: #f8fdff;
      border-radius: 10px;
      border: 1px solid #1861ee;
      text-align: center;
      line-height: 32px;
      color: #1861ee;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    .bottomBtnBox:hover {
      color: #fff;
      background: #1861ee;
    }
  }

  .funList {
    width: 292px;
    height: 128px;
    padding-left: 120px;
    padding-top: 20px;
    box-sizing: border-box;

    .funTitle {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 24px;
    }

    .msg {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #4d4d4d;
      margin: 8px 0;
    }

    .funBtnBox {
      width: 96px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .typeListNew {
    height: 40px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    display: flex;
    padding: 5px 10px;
    box-sizing: border-box;
    text-align: center;

    .typeListBox {
      padding: 0 20px;
      height: 30px;
      border-radius: 10px;
      text-align: center;
      line-height: 30px;
      margin-right: 5px;
      cursor: pointer;
    }

    .istypeListBox {
      background: #ff4436;
      color: #fff;
    }

    .typeListBox:last-child {
      margin-right: 0;
    }
  }

  .typeAll {
    width: 83px;
    height: 26px;
    background: #ffffff;
    border-radius: 13px;
    border: 1px solid #d1d1d1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    text-align: center;
    cursor: pointer;
    line-height: 26px;
  }

  .swiperBox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    .swiper-slide {
      position: relative;
      width: 222px;
      height: 275px;
      box-sizing: border-box;
      border-radius: 10px;
      .swiper_box {
        position: absolute;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 70%, rgba(255, 255, 255, 0.3) 80%, rgba(255, 255, 255, 0.7) 90%, #ffffff 100%);
        width: 100%;
        height: 100%;
      }
      .el-button {
        z-index: 2;
      }
    }

    .swiperBoxTop {
      position: absolute;
      right: 10px;
      top: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      min-width: 104px;
      height: 21px;
      background-image: url('@/static/image/newImage/red.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      align-items: center;
      display: flex;
      padding: 10px 15px;
      box-sizing: border-box;
    }

    .swiperBottom {
      height: 95px;
      width: 170px;
      margin: 0 auto;
      margin-top: 160px;
      background: #ffffff;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;

      h2 {
        font-size: 18px;
      }

      div {
        font-size: 12px;
        color: #ff3e20;

        span {
          font-size: 18px;
          font-weight: 600;
        }
      }

      .swiperIcon {
        width: 150px;
        height: 33px;
        background: #fff4f1;
        border-radius: 10px;
        margin-top: 10px;
        padding-top: 10px;
        position: relative;
        box-sizing: border-box;

        img {
          position: absolute;
          right: 0;
          height: 33px;
          width: 43px;
          bottom: 0;
        }
      }
    }
  }

  .swiper-button-next {
    background: url('@/static/image/newImage/left.png') no-repeat;
    background-size: contain;
    width: 50px;
    transform: scaleX(-1);
  }

  .swiper-button-prev {
    background: url('@/static/image/newImage/left.png') no-repeat;
    background-size: contain;
    width: 50px;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: '';
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: '';
  }

  .titleBox {
    display: flex;
    align-items: center;
  }

  .titleBtnItem {
    padding: 0 24px;
    height: 36px;
    border-radius: 10px;
    border: 1px solid #1861ee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #1861ee;
    background: #eefaff;
    cursor: pointer;

    img {
      margin-right: 16px;
    }
  }

  .titleBtnItem1 {
    padding: 0 24px;
    height: 36px;
    border-radius: 10px;
    border: 1px solid #d1d1d1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #b3b3b3;
    cursor: pointer;

    img {
      margin-right: 16px;
    }
  }

  .shopList {
    width: 850px;
    height: 70px;
    background: #eefaff;
    border-radius: 10px;
    border: 1px solid #c2eaff;
    display: flex;
    box-sizing: border-box;
    padding: 0 20px;
    padding-left: 50px;
    align-items: center;
    margin-top: 5px;
    overflow: hidden;
    position: relative;

    .isShopTitle {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #1861ee;
      padding: 0 5px;
      position: relative;
      margin-right: 40px;
      cursor: pointer;
      white-space: nowrap;
    }

    .isShopTitle::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #1861ee;
      left: 0;
      bottom: -10px;
    }

    .shopTitle {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #000;
      padding: 0 5px;
      position: relative;
      margin-right: 40px;
      cursor: pointer;
      white-space: nowrap;
    }

    .shopListAll {
      width: 100px;
      height: 70px;
      position: absolute;
      right: 0;
      top: 0;
      background: #eefaff;
      text-align: center;
      line-height: 70px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      color: #1861ee;
      cursor: pointer;
    }
  }

  .shopLi:nth-child(3n) {
    margin-right: 0;
  }

  .shopLi {
    margin-top: 30px;
    width: 268px;
    height: 389px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 0px 9px 0px #e0e0e0;
    overflow: hidden;
    margin-right: 22px;
    cursor: pointer;
    position: relative;

    img {
      width: 268px;
      height: 226px;
      object-fit: cover;
    }

    .shopLiMsg {
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      line-height: 20px;
      padding: 3px 8px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .shopLiTitle {
      padding: 5px 8px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      line-height: 14px;
    }

    .shopListNum {
      font-family: Microsoft YaHei;
      font-weight: 600;
      font-size: 22px;
      padding: 2px 8px;
      color: #f39f0b;
    }

    .shopListBottom {
      display: flex;
      justify-content: space-between;
      padding: 4px 8px;
      font-size: 14px;
      box-sizing: border-box;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 268px;

      div:nth-child(1) {
        color: #1861ee;
      }
    }
  }

  .rightTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #d7ffea;
    width: 300px;
    height: 100px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;

    .rightTopTitle {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 24px;
      color: #19cb51;
      font-style: italic;
    }
  }

  .rightList {
    width: 300px;
    margin-top: -20px;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;

    .rightLi {
      width: 280px;
      height: 110px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px #e0e0e0;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      margin: auto;
      display: flex;
      margin-bottom: 20px;
      cursor: pointer;

      .rightLiTitle {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
      }

      .rightLiActor {
        width: 80px;
        height: 80px;
        background: url('@/static/image/newImage/nezha.png') no-repeat;
        background-size: cover;
        margin-right: 20px;
        position: relative;
        border-radius: 5px;

        img {
          position: absolute;
          top: -15px;
          left: -15px;
          width: 43px;
          height: 28px;
          object-fit: cover;
        }
      }

      .rightLiMsg {
        width: 140px;
        height: 28px;
        background: url('@/static/image/newImage/order.png') no-repeat;
        background-size: contain;
        line-height: 28px;
        padding-left: 5px;
        color: #ffffff;
        margin: 8px 0;
      }
    }
  }

  .functionList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 16px;
    margin-top: 20px;
    height: 86px;
    padding: 0 20px;

    .functionLi {
      flex: 1;
      display: flex;

      .functionLeft {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #1861ee;
        text-align: center;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 20px;
        color: #1861ee;
        line-height: 38px;
      }

      .functionRight {
        margin-left: 14px;

        div:nth-child(1) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #1a1a1a;
          margin-bottom: 5px;
        }

        div:nth-child(2) {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
  ::v-deep .el-progress-bar__outer {
    background: #f6d0d2 !important;
  }
</style>
